import { SelectableListItem } from '@components/Form/SelectableList';
import SelectableBlockView from '@components/SelectableBlockView';
import { EFormType } from '@containers/Consignments/__generated__/ConsignmentCopyQuery.graphql';
import { TAirtableConsignment } from '@containers/Consignments/ConsignmentEdit';
import { actionTypes, useGlobalState } from '@state';
import { getField } from '@utils/data-manipulator';
import { getFormAlias } from '@utils/form-alias';
import React from 'react';

import { IStaticQuestionProps } from './StaticQuestion';

const StaticRequiredSingleChoiceQuestion: React.FC<IStaticQuestionProps> = ({ question, data }) => {
    const [{ staticPendingAnswers }, dispatch] = useGlobalState();
    let answer: any = getField<any, TAirtableConsignment>(question.field, staticPendingAnswers.answers, []);

    if (answer.length === 0) {
        // Fallback when data has not populated yet
        answer = getField<any, TAirtableConsignment>(question.field, data, []).map((form: any) => (typeof form === 'string' ? form : form?.type));
    }
    // const isReadOnly = readOnlyWhenSubmittedV4(data, user);
    const isReadOnly = answer && answer.length > 0;

    return (
        <SelectableBlockView
            isReadOnly={isReadOnly}
            submitCount={0}
            initialRequiredItems={
                data?.forms?.map((x: { type: EFormType | null; serialNumber: string | null } | null) => {
                    const formAlias = getFormAlias(x?.type || '');
                    return {
                        id: formAlias?.program || '',
                        title: formAlias?.alias || '',
                        subtitle: formAlias?.subtitle || '',
                        image: formAlias?.image,
                        selected: true,
                    };
                }) || []
            }
            requiredItemsProps={{
                labelText: 'Here are the forms you selected',
                // errorText: ConsignmentSpecies.CATTLE ? 'You can select only one (NVD or EU NVD required but not both)' : 'You must select at least one (1) NVD form',
                errorText: '',
            }}
            initialOptionalItems={[]}
            optionalItemsProps={{
                labelText: '',
                errorText: '',
            }}
            isRadioListReadOnly={isReadOnly}
            isCheckListReadOnly={isReadOnly}
            onSubmit={() => null}
            onChange={(updatedForms: SelectableListItem[]) => {
                // Only change the forms that are part of our options, leave the rest as is
                let forms = answer;

                updatedForms
                    .filter((x) => question.options?.map((o) => o.program === x.id))
                    .forEach((form) => {
                        // This form can be saved since it is a valid option
                        const value = form.id.replace(/\./g, '');
                        const selected = form.selected;

                        // If this option is selected, append it to the list, if it is not then remove it
                        forms = selected ? [...(forms ?? []), value].distinct() : forms?.filter((x: any) => x !== value);
                        forms = forms.coalesce();
                    });
                dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: { forms } });
            }}
        />
    );
};

export default StaticRequiredSingleChoiceQuestion;
