import { ICommentFields } from '@typings';
import MLALogger from '@utils/logger';
import { graphql } from 'relay-runtime';

import { CreateCommentInput } from './__generated__/createConsignmentCommentMutation.graphql';

export const mutation = graphql`
    mutation createConsignmentCommentMutation($input: CreateCommentInput!, $envdAccountId: String!) {
        createComment(input: $input, envdAccountId: $envdAccountId) {
            data {
                consignmentNumber
                commentText
                commentId
                firstName
                lastName
                role
                createdAt
            }
        }
    }
`;

const commitAsync = async (mutate: any, commentInput: CreateCommentInput, envdAccountId: String): Promise<ICommentFields | undefined> => {
    MLALogger.Log(['comment', 'commitAsync', 'Sending'], { commentInput });

    try {
        const data = await mutate({
            variables: { input: commentInput, envdAccountId },
            onCompleted: (response: any, error: any) => {
                MLALogger.Log(['comment', 'commitAsync', 'onComplete'], { response, error });
                if (error) {
                    MLALogger.Log(['comment', 'commitAsync'], { commentInput, error }, 'error');
                } else {
                    MLALogger.Log(['comment', 'commitAsync', 'success'], { response, request: commentInput });
                }
            },
        });

        return data?.createComment?.data;
    } catch (error) {
        MLALogger.Log(['comment'], error, 'error');
        return;
    }
};

export { mutation as AddCommentMutation };
export default commitAsync;
