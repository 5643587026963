import { IGlobalState } from '@typings';

import { ActionType } from '../index';
import copyErrorsReducer, { CopyErrorActionTypes, CopyErrorsInitialState } from './copyErrorsReducer';
import dynamicPendingAnswersReducer, { DynamicPendingAnswersActionTypes, DynamicPendingAnswersInitialState } from './dynamicPendingAnswersReducer';
import { elevatedSearchReducer, ElevatedViewerSearchActionTypes, ElevatedViewerSearchFields } from './elevatedViewerSearchReducer';
import genericReducer, { ConsignmentOptionsState, GenericActionTypes, GenericInitialState } from './genericReducer';
import metaReducer, { MetaActionTypes, MetaInitialState } from './metaReducer';
import originalAnswersReducer, { OriginalAnswersActionTypes, OriginalAnswersInitialState } from './originalAnswersReducer';
import staticPendingAnswersReduer, { StaticPendingAnswersActionTypes, StaticPendingAnswersInitialState } from './staticPendingAnswersReducer';
import triggerValidatorCountReducer, { TriggerValidatorCountInitialState, TVCActionTypes } from './triggerValidatorCountReducer';

export const initialState: IGlobalState = {
    dynamicPendingAnswers: DynamicPendingAnswersInitialState,
    staticPendingAnswers: StaticPendingAnswersInitialState,
    originalAnswers: OriginalAnswersInitialState,
    meta: MetaInitialState,
    generic: GenericInitialState,
    triggerValidatorCount: TriggerValidatorCountInitialState,
    copyErrors: CopyErrorsInitialState,
    optionsState: ConsignmentOptionsState,
    elevatedViewerSearchFields: ElevatedViewerSearchFields,
};

export const actionTypes = {
    dynamicPendingAnswers: DynamicPendingAnswersActionTypes,
    staticPendingAnswers: StaticPendingAnswersActionTypes,
    originalAnswers: OriginalAnswersActionTypes,
    meta: MetaActionTypes,
    generic: GenericActionTypes,
    triggerValidatorCount: TVCActionTypes,
    copyErrors: CopyErrorActionTypes,
    elevatedViewerSearch: ElevatedViewerSearchActionTypes,
};

const reducers = (
    { dynamicPendingAnswers, staticPendingAnswers, originalAnswers, meta, generic, triggerValidatorCount, copyErrors, optionsState, elevatedViewerSearchFields }: IGlobalState,
    action: ActionType
) => ({
    dynamicPendingAnswers: dynamicPendingAnswersReducer(dynamicPendingAnswers, action),
    staticPendingAnswers: staticPendingAnswersReduer(staticPendingAnswers, action),
    originalAnswers: originalAnswersReducer(originalAnswers, action),
    meta: metaReducer(meta, action),
    generic: genericReducer(generic, action),
    triggerValidatorCount: triggerValidatorCountReducer(triggerValidatorCount, action),
    copyErrors: copyErrorsReducer(copyErrors, action),
    optionsState: genericReducer(optionsState, action),
    elevatedViewerSearchFields: elevatedSearchReducer(elevatedViewerSearchFields, action),
});

export default reducers;
