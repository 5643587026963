import 'react-app-polyfill/ie11'; // < This must be first.
import 'react-app-polyfill/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/promise';
import 'core-js/es/object';
import 'core-js/es/string';
import 'core-js/es/string/repeat';
import '@utils/array-extensions';
import '@utils/date-extensions';
import '@utils/string-extensions';

import environment from '@common/relay/environment';
import AppRouter from '@containers/AppNavigator/router';
import { Suspense } from 'react';
import ReactDom from 'react-dom';
import { RelayEnvironmentProvider } from 'relay-hooks';

import * as serviceWorker from './serviceWorker';

ReactDom.render(
    <RelayEnvironmentProvider environment={environment}>
        <Suspense fallback={<div />}>
            <AppRouter />
        </Suspense>
    </RelayEnvironmentProvider>,
    document.getElementById('root')
);

serviceWorker.register();
