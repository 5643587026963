import MLALogger from '@utils/logger';
import { graphql } from 'relay-hooks';

import { DeleteConsignmentInput } from './__generated__/deleteConsignmentMutation.graphql';

const mutation = graphql`
    mutation deleteConsignmentMutation($input: DeleteConsignmentInput!, $envdAccountId: String!) {
        deleteConsignment(input: $input, envdAccountId: $envdAccountId) {
            success
        }
    }
`;

const commitAsync = async (mutate: any, consignment: DeleteConsignmentInput & { storeId: string }, envdAccountId: String): Promise<any | undefined> => {
    try {
        await mutate({
            variables: { input: { id: consignment.id }, envdAccountId },
            onCompleted: (response: any, error: any) => {
                if (error) {
                    MLALogger.Log(['deleteConsignment', 'commitAsync'], { error }, 'error');
                } else {
                    MLALogger.Log(['deleteConsignment', 'commitAsync'], { response });
                }
            },
            updater: (store: any) => {
                // get data from server
                const payload = store.getRootField('deleteConsignment');
                MLALogger.Log(['deleteConsignment', 'commitAsync', 'updater'], { payload });
                if (payload) {
                    store.delete(consignment.storeId);
                } else {
                    MLALogger.Log(['deleteConsignment', 'commitAsync'], { error: 'Payload came back as null' }, 'error');
                }
            },
        });

        return true;
    } catch (error) {
        MLALogger.Log(['deleteConsignment', 'commitAsync'], error, 'error');
        throw error;
    }
};

export default commitAsync;
export { mutation as DeleteConsignmentMutation };
