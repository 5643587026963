/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ConsignmentUserMappingQueryVariables = {
    id: string;
    role: string;
    envdAccountId: string;
};
export type ConsignmentUserMappingQueryResponse = {
    readonly consignmentUserMapping: ReadonlyArray<{
        readonly email: string | null;
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly consignmentNumber: string;
        readonly role: string;
        readonly mappingId: number | null;
        readonly pic: string | null;
        readonly numOfHeads: number | null;
    } | null> | null;
};
export type ConsignmentUserMappingQuery = {
    readonly response: ConsignmentUserMappingQueryResponse;
    readonly variables: ConsignmentUserMappingQueryVariables;
};



/*
query ConsignmentUserMappingQuery(
  $id: String!
  $role: String!
  $envdAccountId: String!
) {
  consignmentUserMapping(consignmentNumber: $id, role: $role, envdAccountId: $envdAccountId) {
    email
    firstName
    lastName
    consignmentNumber
    role
    mappingId
    pic
    numOfHeads
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envdAccountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "role"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "consignmentNumber",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      },
      {
        "kind": "Variable",
        "name": "role",
        "variableName": "role"
      }
    ],
    "concreteType": "ConsignmentUserMappingType",
    "kind": "LinkedField",
    "name": "consignmentUserMapping",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "consignmentNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mappingId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pic",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "numOfHeads",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConsignmentUserMappingQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ConsignmentUserMappingQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "db237b42efe4834dd9163b2acb693c40",
    "id": null,
    "metadata": {},
    "name": "ConsignmentUserMappingQuery",
    "operationKind": "query",
    "text": "query ConsignmentUserMappingQuery(\n  $id: String!\n  $role: String!\n  $envdAccountId: String!\n) {\n  consignmentUserMapping(consignmentNumber: $id, role: $role, envdAccountId: $envdAccountId) {\n    email\n    firstName\n    lastName\n    consignmentNumber\n    role\n    mappingId\n    pic\n    numOfHeads\n  }\n}\n"
  }
};
})();
(node as any).hash = '4498c0b97bd5fbd576421ffe1aed4b44';
export default node;
