import { EntityInput } from '@containers/Consignments/mutations/__generated__/createOrSaveConsignmentMutation.graphql';
import { IPerson, TPersonType } from '@typings';

export const mapUserToPerson = (user: any, type: TPersonType): IPerson => ({
    pic: user.accountDetails.pic,
    businessName: user.accountDetails.entityName,
    firstName: user.accountDetails.firstName,
    lastName: user.accountDetails.lastName,
    locationAddress: user.accountDetails.locationAddress,
    locationTown: user.accountDetails.locationTown,
    locationState: user.accountDetails.locationState,
    locationPostcode: user.accountDetails.locationPostcode,
    type,
});

export function mapPicToIPerson(
    person: {
        address: { line1: string; town: string; state: string; postcode: string | null } | null;
        name: string | null;
        pic: string | null;
        postcode: string | null;
        state: string | null;
        town: string | null;
    } | null,
    type: TPersonType
): IPerson | null {
    if (!person) {
        return null;
    }

    if (!person.pic && !person.name) {
        return null;
    }

    return {
        pic: person.pic!!,
        businessName: person.name || undefined,
        firstName: /*pic.givenName ||*/ undefined,
        lastName: /*pic.surname ||*/ undefined,
        locationAddress: person.address?.line1 || undefined,
        locationTown: person?.address?.town || undefined,
        locationState: person?.address?.state || undefined,
        locationPostcode: person?.address?.postcode || undefined,
        type: type || undefined,
    };
}

export const mapPersonToEntity = (person: IPerson): EntityInput => ({
    address: {
        line1: person.locationAddress,
        postcode: person.locationPostcode,
        state: person.locationState,
        town: person.locationTown,
    },
    name: person.businessName ? person.businessName : person.firstName && person.lastName ? `${person.firstName} ${person.lastName}` : '',
    pic: person.pic,
});
