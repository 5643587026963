/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EdecRow_edec = {
    readonly edecCustomerId: string;
    readonly pic: string;
    readonly lpaId: string;
    readonly species: string;
    readonly formType: string;
    readonly formDisplayName: string;
    readonly serialNumber: string;
    readonly downloadURL: string | null;
    readonly " $refType": "EdecRow_edec";
};
export type EdecRow_edec$data = EdecRow_edec;
export type EdecRow_edec$key = {
    readonly " $data"?: EdecRow_edec$data;
    readonly " $fragmentRefs": FragmentRefs<"EdecRow_edec">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EdecRow_edec",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "edecCustomerId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pic",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lpaId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "species",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formDisplayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serialNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downloadURL",
      "storageKey": null
    }
  ],
  "type": "Edec",
  "abstractKey": null
};
(node as any).hash = '1c958faca0eef81e2ec4ed949b0b4a4a';
export default node;
