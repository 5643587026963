import * as React from 'react';
const SvgComment = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} fill="none" {...props} viewBox="0 0 27 27">
        <path
            fill="#1D2A33"
            d="M23.833 3.167V20.18l-1.511-1.512H3.167v-15.5zm0-2.583H3.167A2.59 2.59 0 0 0 .583 3.167v15.5a2.59 2.59 0 0 0 2.584 2.584H21.25l5.167 5.166V3.167A2.59 2.59 0 0 0 23.833.584M21.25 13.501H5.75v2.583h15.5zm0-3.875H5.75v2.583h15.5zm0-3.875H5.75v2.583h15.5z"
        />
    </svg>
);
export default SvgComment;
