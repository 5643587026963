import { useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import Checkbox from '@components/Form/Checkbox';
import Modal from '@components/Modal';
import useUserDetails from '@effects/useUserdetails';
import { actionTypes, useGlobalState } from '@state';
import { accounts } from '@typings';
import { getCarrierRegNum } from '@utils/account';
import { BLANK_IMAGE, FILE_REGEX } from '@utils/constants';
import { setValueToField } from '@utils/data-manipulator';
import { SectionName } from '@utils/enums';
import { fetchFileUrl, isBase64Image } from '@utils/file-ops';
import { readOnlyWhenSubmittedV4 } from '@utils/question-editable';
import { useGetStaticAnswer } from '@utils/question-getter';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Signature from '../Signature';
import { IStaticQuestionProps } from './StaticQuestion';

const StaticImageQuestion: React.FC<IStaticQuestionProps> = ({ question, data }) => {
    // console.count('[Count] StaticImageQuestion');
    const [, dispatch] = useGlobalState();
    const [{ user }] = useUserState();
    const { setUserSignature } = useUserDetails();
    const isReadOnly = readOnlyWhenSubmittedV4(data, user);
    const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false);
    const originalValue = useGetStaticAnswer(question, data);
    const [value, setValue] = useState<string>(originalValue);
    const [currentValue, setCurrentValue] = useState<string>(originalValue);
    const [checkboxSaveSignature, setCheckboxSaveSignature] = useState<boolean>(user?.claims.signature && user?.claims.signature !== BLANK_IMAGE ? false : true);

    const formatValue = useCallback(async (v: string) => {
        let retValue = v;

        // Fix up base64 updates if required
        const isB64 = isBase64Image(v);
        const isUrl = v?.startsWith('http');

        if (isB64) {
            const strippedValue = v?.replace(FILE_REGEX, '') ?? BLANK_IMAGE;
            if (strippedValue !== v) {
                // Strip contents
                retValue = strippedValue;
            }
        } else if (v?.startsWith('data:image/')) {
            // Do nothing
        } else if (!isUrl) {
            const urlValue = (await fetchFileUrl(v)) ?? BLANK_IMAGE;
            if (urlValue !== v) {
                // This is a file upload with the key as a value
                retValue = urlValue;
            }
        }

        return retValue;
    }, []);

    useEffect(() => {
        const action = async () => {
            const newValue = await formatValue(value);
            const newCurrentValue = await formatValue(currentValue);
            if (newValue !== value) {
                setValue(newValue);
            }

            if (newCurrentValue !== currentValue) {
                setCurrentValue(newCurrentValue);
            }
        };

        action();
    }, [value, formatValue, currentValue]);

    const modalFooter = useMemo(() => {
        return (
            <>
                <Button
                    buttonType="primary"
                    buttonSize="small"
                    type="submit"
                    onClick={async () => {
                        // We've already got the latest signature changes in local state so we just save them out
                        setShowSignatureModal(false);
                        setValue(currentValue);
                        dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: setValueToField(question.field, currentValue ?? '') });
                        if (checkboxSaveSignature) {
                            const isTransporter = user?.claims.accounts?.some((acc: accounts) => acc.accountType === SectionName.TRANSPORTER.toUpperCase());
                            const userData = {
                                defaultAccount: user?.claims.defaultAccount || user?.claims?.accounts[0]?.envdAccountId,
                                firstName: user?.claims.firstName,
                                lastName: user?.claims.lastName,
                                mobilePhone: user?.claims.mobilePhone,
                                isTransporter: isTransporter,
                                rego: isTransporter ? getCarrierRegNum(user) : '',
                                receiveEmails: user?.claims.receiveEmails,
                            };
                            if (currentValue && currentValue !== BLANK_IMAGE) {
                                setCheckboxSaveSignature(false);
                                setUserSignature(userData, currentValue);
                            } else {
                                setUserSignature(userData, 'null');
                            }
                        }
                    }}
                >
                    Add
                </Button>
                <Button
                    buttonType="secondary"
                    buttonSize="small"
                    type="submit"
                    onClick={() => {
                        //dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: setValueToField(question.field, originalValue) });
                        setValue(originalValue);
                        setShowSignatureModal(false);
                    }}
                >
                    Cancel
                </Button>
            </>
        );
    }, [checkboxSaveSignature, currentValue, dispatch, originalValue, question.field, setUserSignature, user]);

    const modalChildren = useMemo(() => {
        return (
            <Signature
                title={question.text}
                value={value}
                isReadOnly={isReadOnly}
                onUpdate={(v?: string) => {
                    setCurrentValue(v ?? '');
                }}
                hideClear={currentValue === BLANK_IMAGE || currentValue === ''}
            />
        );
    }, [currentValue, isReadOnly, question.text, value]);

    const modalOnOutClick = useCallback(() => {
        // Reset the signature data that the edit canvas will use, removing the changes made within it but not saved
        setShowSignatureModal(false);
    }, []);

    const onSavedSignature = () => {
        setCheckboxSaveSignature((value) => !value);
    };

    if (isReadOnly) {
        return value ? <img src={value} alt={question.text} /> : null;
    }

    // We want to ditch signature pad, so display a file upload request
    // But for backwards compatibility allow for a signature via a modal
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                img {
                    background-color: $color-white;
                    margin-bottom: grid(2);

                    min-height: grid(18);
                    max-width: grid(130);
                }

                .SignDeclarationContainer {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: grid(2);
                    max-width: grid(130);
                }

                .questionText {
                    @include text-label;
                }
            `}</style>
            <label className="questionText">{question.text}</label>
            {!isReadOnly && !value?.startsWith('http') && (
                <div className="SignDeclarationContainer">
                    <Button className={'BtnDrawSignature'} buttonType="link" buttonSize="small" onClick={() => setShowSignatureModal(true)}>
                        {question.field.includes('declaration') ? 'Sign declaration' : 'Draw signature'}
                    </Button>
                    {value !== BLANK_IMAGE && (
                        <Button
                            className={'BtnClearSignature'}
                            buttonType="link"
                            buttonSize="small"
                            onClick={() => {
                                setValue(BLANK_IMAGE);
                                dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: setValueToField(question.field, BLANK_IMAGE) });
                            }}
                        >
                            {'Clear'}
                        </Button>
                    )}
                </div>
            )}
            {value && <img src={value} alt={question.text} />}
            <Modal
                id={'Signature-Modal'}
                savedSignature={user?.claims.signature}
                modalHeader={'Draw your signature'}
                modalFooter={modalFooter}
                show={showSignatureModal}
                onOutClick={modalOnOutClick}
                onUseMySignatureClick={() => {
                    setValue(user?.claims.signature);
                    setCurrentValue(user?.claims.signature);
                    //dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: setValueToField(question.field, user?.claims.signature ?? '') });
                }}
                checkbox={<Checkbox optionText={'Save the signature for future consignments'} checked={checkboxSaveSignature} onChange={onSavedSignature} />}
            >
                {modalChildren}
            </Modal>
        </>
    );
};

export default StaticImageQuestion;
