import EmptyList from '@common/components/Listing/EmptyList';
import Loader from '@common/components/Loader';
import { useUserState } from '@common/context/userContext';
import Alert, { AlertType } from '@components/Alert';
import Config from '@config';
import { ALL_DEPRECATED_FORMS, mapFAToForm } from '@utils/form-alias';
import MLALogger from '@utils/logger';
import { canCreateConsignment } from '@utils/question-editable';
import { isBefore } from 'date-fns';
import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ConnectionConfig } from 'react-relay';
import { graphql, usePagination } from 'relay-hooks';

import { TemplateListFragment_query } from './__generated__/TemplateListFragment_query.graphql';
import TemplateRow from './TemplateRow';

interface Props {
    query: TemplateListFragment_query;
}

export const Header = () => {
    return (
        <div className="tbl-row row-header">
            <div className="tbl-cell-40 column-heading">Template</div>
            <div className="tbl-cell-10 column-heading">Species</div>
            <div className="tbl-cell-35 column-heading">Forms</div>
            <div className="tbl-cell-15 column-heading" />
        </div>
    );
};

const Empty = () => {
    return <EmptyList emptyText="You currently have no templates. To create one select a consignment from your consignment list, then create template" />;
};

const TemplateOODMessageZone: React.FC<{ templates: { updatedAt: string; forms: string[] }[] }> = ({ templates }) => {
    const tags = ['TemplateList', 'TemplateOODMessageZone'];

    MLALogger.LogEnterAndExit(tags);

    MLALogger.Log(tags, { templates });
    // ENVDR-989
    // Check if the dates are prior to July 1st 2020 and then run the check to see if it has any forms that aren't currently active
    // If there is anything that matches the criteria then show the message notifying the user to check their templates
    const datesAsDate = templates.map((c) => new Date(c.updatedAt));
    const cutoffDate = new Date(2020, 7, 1);
    if (datesAsDate?.some((date) => isBefore(date, cutoffDate))) {
        // This checks that every single form in the template has to have a counter part in our mapping
        // If there isn't one, then it is out of date as we only hold the latest mappings
        const hasAllValidForms = templates.every((c) => !ALL_DEPRECATED_FORMS.map(mapFAToForm).some((x) => c.forms.includes(x)));

        MLALogger.Log(tags, { message: 'Something is before July 1st 2020', hasAllValidForms });

        if (!hasAllValidForms) {
            MLALogger.LogEnterAndExit(tags, false);
            return (
                <Alert
                    type={AlertType.Warn}
                    title="Your templates need to be updated"
                    subtitle="New forms and questions have been added. Some of your templates were created using older forms that may have missing questions and will need to be reviewed and updated."
                />
            );
        }
    }

    MLALogger.LogEnterAndExit(tags, false);
    return null;
};

export const TemplateListFragmentQuery = graphql`
    query TemplateListFragmentPagedQuery($envdAccountId: String!, $count: Int!, $cursor: String, $searchText: String, $species: ConsignmentSpecies) {
        ...TemplateListFragment_query
    }
`;

export const TemplateListPaginationSpec = graphql`
    fragment TemplateListFragment_query on Query @refetchable(queryName: "TemplateListRefetchableQuery") {
        templates(envdAccountId: $envdAccountId, first: $count, after: $cursor, searchText: $searchText, species: $species) @connection(key: "TemplateListFragment_templates") {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    id
                    ...TemplateRow_template
                    updatedAt
                    forms {
                        type
                    }
                }
            }
        }
    }
`;

export const TemplateListConnectionConfig: ConnectionConfig = {
    direction: 'forward',
    query: TemplateListFragmentQuery as any,
    // Don't use props because it complains about null errors in pageInfo and edges...
    getConnectionFromProps(props: any) {
        return props?.templates;
    },

    getVariables(props: any, paginationInfo: any, fragmentVariables: any) {
        console.log(props, paginationInfo, fragmentVariables);
        return {
            count: paginationInfo.count,
            cursor: props.templates.pageInfo.endCursor,
            searchText: fragmentVariables.searchText,
            species: fragmentVariables.species,
            status: fragmentVariables.status,
            fromDate: fragmentVariables.fromDate,
            toDate: fragmentVariables.toDate,
        };
    },
};

const TemplateListFragment: React.FunctionComponent<Props> = ({ query }) => {
    const [{ user }] = useUserState();
    const { data, isLoading, hasNext, loadNext } = usePagination(TemplateListPaginationSpec, query as any);
    const templatesList = data?.templates?.edges?.map((c: any) => c && c.node).coalesce() ?? [];

    const _loadMore = useCallback(() => {
        if (!hasNext || isLoading) {
            MLALogger.Log(['TemplateList'], 'Skipped loading');
            return;
        }
        loadNext(Config.PAGINATION_PER_PAGE_LIMIT);
    }, [hasNext, isLoading, loadNext]);

    const canCreate = canCreateConsignment(user);

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';
                .tbl {
                    @media (max-width: $md-max) {
                        border: none;
                        background: transparent;
                    }
                }
            `}</style>
            <TemplateOODMessageZone templates={templatesList.map((x: any) => ({ updatedAt: x.updatedAt, forms: x.forms?.map((f: any) => f.type) }))} />
            <div className="tbl tbl-collapse">
                <Header />
                <InfiniteScroll
                    className="tbl-body"
                    data-cy="consignment-records"
                    pageStart={0}
                    initialLoad={false}
                    loadMore={() => {
                        if (!hasNext || isLoading) {
                            console.log('[ConsignmentList] Relay has no more or is currently loading', { hasMore: hasNext, isLoading });
                            return;
                        }

                        console.log('[ConsignmentList] Relay is attempting to load more', { hasMore: hasNext, isLoading });

                        _loadMore();
                    }}
                    hasMore={hasNext}
                    loader={
                        <div className="tbl-row" key={0}>
                            <Loader isLoading={true} error={''} retry={() => null} timedOut={false} pastDelay={false} />
                        </div>
                    }
                >
                    {templatesList.length === 0
                        ? canCreate && <Empty />
                        : templatesList.map((c: any) => {
                              return <TemplateRow key={c!.id} template={c!} />;
                          })}
                </InfiniteScroll>
            </div>
        </>
    );
};

export const TemplatesLoader = () => {
    return (
        <div className="tbl tbl-collapse">
            <Header />
            <Loader isLoading={true} error={false} retry={() => null} timedOut={false} pastDelay={false} />
        </div>
    );
};

// Need subscriptions? https://github.com/howtographql/react-relay/blob/master/src/subscriptions/NewVoteSubscription.js

export default TemplateListFragment;
