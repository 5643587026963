import { IAnswer } from '@typings';

export const sortByQuestionIdAndIndex = (a: IAnswer, b: IAnswer) => {
    if (a === null || b === null) {
        return 0;
    }

    // When id equal then sort by index
    return Number.parseInt(a.questionId, 10) - Number.parseInt(b.questionId, 10) || (a.index ?? 0) - (b.index ?? 0);
};

export const sortByIndex = (a: IAnswer, b: IAnswer) => {
    if (a === null || b === null) {
        return 0;
    }

    // When id equal then sort by index
    return (a.index ?? 0) - (b.index ?? 0);
};

export function sortByNumberDesc(a: number, b: number) {
    return a > b ? 1 : b > a ? -1 : 0;
}
