import * as React from 'react';
const SvgSourceReceiver = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" fill="none" viewBox="0 0 200 200" {...props}>
        <path d="M143 201H1V1h200v200zM17.635 84.028c-5.341 3.183-8.332 8.028-9.472 13.987-2.557 13.364 3.63 22.698 14.196 29.822 13.207 8.903 28.376 11.584 43.698 11.471 15.382-.113 30.887-1.438 46.09-3.818 22.81-3.573 44.56-10.654 62.737-25.793 9.848-8.201 17.019-18.248 18.47-31.374.595-5.386-.096-11.032-5.311-14.147-4.741-2.832-8.624.723-12.122 3.336-3.98 2.973-7.526 6.524-11.257 9.827-19.46 17.226-41.34 26.228-67.702 19.825-5.746-1.396-11.438-3.011-17.155-4.525 23.14-6.91 45.9-13.2 70.334-9.499-2.987-10.574-5.68-20.487-8.604-30.332-2.146-7.225-8.135-11.756-15.687-12.535-7.668-.79-14.416 1.76-20.806 5.545-9.752 5.776-19.687 10.942-31.178 12.478-1.392.186-3.364 2.546-3.56 4.09-1.092 8.541-1.716 17.142-2.54 26.158-7.486-1.997-14.756-4.226-22.16-5.838-9.127-1.987-18.367-3.485-27.97 1.322" />
        <path
            fill="#044B78"
            d="M17.953 83.83c9.286-4.609 18.526-3.11 27.653-1.124 7.404 1.612 14.674 3.84 22.16 5.838.824-9.016 1.448-17.617 2.54-26.158.196-1.544 2.168-3.904 3.56-4.09 11.491-1.536 21.426-6.702 31.178-12.478 6.39-3.785 13.138-6.336 20.806-5.545 7.552.779 13.54 5.31 15.687 12.535 2.924 9.845 5.617 19.758 8.604 30.332-24.434-3.7-47.194 2.589-70.334 9.499 5.717 1.514 11.41 3.129 17.155 4.525 26.362 6.403 48.243-2.599 67.702-19.825 3.731-3.303 7.278-6.854 11.257-9.827 3.498-2.613 7.381-6.168 12.122-3.336 5.215 3.115 5.906 8.76 5.31 14.147-1.45 13.126-8.621 23.173-18.469 31.374-18.177 15.139-39.927 22.22-62.738 25.793-15.202 2.38-30.707 3.705-46.089 3.818-15.322.113-30.491-2.568-43.698-11.471-10.566-7.124-16.753-16.458-14.196-29.822 1.14-5.959 4.13-10.804 9.79-14.186"
        />
    </svg>
);
export default SvgSourceReceiver;
