import * as React from 'react';
const SvgViewer = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" fill="none" viewBox="0 0 200 200" {...props}>
        <path d="M119 201H1.037V1.1h199.801V201zm53.38-30.996c3.213-1.835 6.618-3.403 9.607-5.55 16.332-11.733 20.339-27.983 17.255-45.758-2.268-13.072-9.678-23.858-16.122-35.029-9.616-16.671-19.476-33.202-29.12-49.857-5.742-9.914-14.218-13.95-25.512-12.85-7.706.751-14.962 6.748-17.392 16.174-1.348 5.228-1.104 10.866-1.594 16.508-5.506-4.13-11.122-5.167-17.502.103 0-4.544.024-8.134-.005-11.724-.066-8.342-6.682-17.42-14.564-20.024-11.753-3.882-23.707 1.2-29.506 12.086-4.104 7.705-8.826 15.078-13.152 22.667C25.49 73.038 16.108 89.275 7.11 105.72c-5.455 9.97-6.797 21.086-4.637 31.982 4.221 21.287 23.657 36.177 45.635 35.471 18.116-.582 36.318-12.966 40.632-30.214 2.588-10.341 2.5-21.395 3.18-32.156.436-6.924.88-7.729 8.054-7.801 1.167-.012 2.35-.13 3.497.023 3.931.523 6.616 1.92 6.623 6.769.013 8.924-.471 18.04 1.122 26.735 5.096 27.813 33.833 43.876 61.165 33.475" />
        <path
            fill="#004976"
            d="M171.978 170.037c-26.93 10.368-55.667-5.695-60.763-33.508-1.593-8.694-1.109-17.811-1.122-26.735-.007-4.85-2.692-6.246-6.623-6.77-1.146-.152-2.33-.034-3.497-.022-7.174.072-7.618.877-8.055 7.801-.68 10.761-.591 21.815-3.179 32.156-4.314 17.248-22.516 29.632-40.632 30.214-21.978.706-41.414-14.184-45.635-35.47-2.16-10.897-.818-22.014 4.637-31.983 8.999-16.445 18.38-32.682 27.664-48.97 4.326-7.59 9.048-14.962 13.152-22.667 5.8-10.885 17.753-15.968 29.506-12.086 7.882 2.604 14.498 11.682 14.564 20.024.029 3.59.005 7.18.005 11.724 6.38-5.27 11.996-4.233 17.502-.103.49-5.642.246-11.28 1.594-16.508 2.43-9.426 9.686-15.423 17.392-16.174 11.294-1.1 19.77 2.936 25.511 12.85 9.645 16.655 19.505 33.186 29.121 49.857 6.444 11.17 13.854 21.957 16.122 35.029 3.084 17.775-.923 34.025-17.255 45.757-2.989 2.148-6.394 3.716-10.009 5.584M7.99 135.476c2.087 16.798 20.617 34.684 43.691 31.733 22.787-2.914 38.369-26.363 33.408-47.52-4.074-17.37-21.615-31.27-38.546-30.868-13.45.32-23.828 5.943-31.609 16.036-6.596 8.556-9.86 18.605-6.944 30.619M162.345 167c2.003-.588 4.014-1.15 6.007-1.767 16.177-5.013 26.697-21.27 26.646-36.99-.044-13.432-6.12-23.951-16.434-31.923-8.828-6.822-19.348-9.022-30.153-6.943-20.92 4.024-36.289 24.971-31.511 46.94 4.323 19.875 24.477 34.655 45.445 30.683M119.34 46.157l-.182 3.437c9.016-11.432 19.586-11.63 31.147-6.827-7.674-7.625-22.882-5.76-30.965 3.39m-58.404-8.154-9.575 3.6c4.661.048 8.963-1.415 13.267-1.42 4.176-.006 8.354 1.485 13.645 2.542-5.553-4.256-10.815-5.169-17.337-4.722m21.079 10.345 3.622 7.882 1.736-1.02c-1.642-2.524-3.285-5.047-5.358-6.862m33.057 6.972 4.276-5.692-1.664-1.105c-.89 2.001-1.78 4.003-2.612 6.797m-64.46-12.29-1.507.8.698 1.084c.512-.492 1.024-.983.809-1.883m102.154 1.34-1.525-.444c.33.331.661.663 1.525.444"
        />
        <path
            fill="#FFF"
            d="M7.956 135.035c-2.883-11.573.382-21.622 6.978-30.178 7.781-10.093 18.16-15.717 31.609-16.036 16.931-.402 34.472 13.498 38.546 30.868 4.96 21.157-10.62 44.606-33.408 47.52-23.074 2.951-41.604-14.935-43.725-32.174m3.072.887c8.034 20.575 17.85 28.075 36.732 28.08 13.988.003 23.873-6.987 30.404-18.738 6.746-12.138 6.302-24.878-1.04-36.345-8.7-13.59-21.675-19.085-37.923-15.949-12.927 2.496-21.955 9.957-26.003 22.204-2.043 6.182-1.545 13.205-2.17 20.748M161.908 167c-20.531 3.972-40.685-10.808-45.008-30.684-4.778-21.968 10.59-42.915 31.511-46.939 10.805-2.079 21.325.12 30.153 6.943 10.314 7.972 16.39 18.49 16.434 31.923.051 15.72-10.469 31.977-26.646 36.99-1.993.618-4.004 1.18-6.444 1.767m28.817-47.77c-1.032-2.696-1.663-5.641-3.16-8.049C178.41 96.455 165.544 89.508 147.972 93c-24.108 4.79-34.348 29.71-25.536 49.552 6.426 14.472 18.143 21.725 34.65 21.619 14.758-.095 29.175-11.978 31.78-23.195 1.57-6.762 1.46-13.914 1.859-21.745M119.624 45.895c7.799-8.887 23.007-10.753 30.68-3.128-11.56-4.804-22.13-4.605-31.146 6.827.107-2.03.145-2.733.466-3.699M61.38 38.002c6.078-.446 11.34.467 16.893 4.723-5.291-1.057-9.47-2.548-13.645-2.543-4.304.006-8.606 1.469-13.267 1.42 3.192-1.2 6.383-2.4 10.019-3.6M82.23 47.994c1.858 2.17 3.5 4.692 5.143 7.216l-1.736 1.02c-1.207-2.628-2.415-5.255-3.407-8.236"
        />
        <path
            fill="#FFF"
            d="M115.043 54.924c.86-2.398 1.75-4.4 2.641-6.401l1.664 1.105c-1.426 1.897-2.851 3.795-4.305 5.296M50.976 43.235c-.149.696-.66 1.187-1.173 1.679l-.698-1.084c.502-.266 1.005-.533 1.87-.595M152.5 44.646c-.598-.056-.928-.388-1.26-.72.51.149 1.017.297 1.26.72"
        />
        <path
            fill="#004976"
            d="M11.015 135.471c.638-7.092.14-14.115 2.183-20.297 4.048-12.247 13.076-19.708 26.003-22.204 16.248-3.136 29.222 2.36 37.924 15.95 7.341 11.466 7.785 24.206 1.039 36.344-6.531 11.751-16.416 18.741-30.404 18.737-18.882-.004-28.698-7.504-36.745-28.53m58.037 11.15c6.997-8.07 8.953-17.517 5.496-27.323-3.853-10.928-11.76-18.53-23.578-20.188-22.262-3.122-38.361 17.895-32.92 36.527 4.176 14.296 15.967 22.078 29.594 22.115 8.212.021 15.427-4.404 21.408-11.131M190.849 119.658c-.522 7.404-.413 14.556-1.984 21.318-2.604 11.217-17.021 23.1-31.78 23.195-16.506.106-28.223-7.147-34.65-21.619-8.81-19.843 1.43-44.762 25.537-49.552 17.572-3.492 30.437 3.455 39.593 18.181 1.497 2.408 2.128 5.353 3.284 8.477m-21.662-17.632c-1.806-.67-3.576-1.46-5.422-1.99-19.34-5.553-36.918 8.854-37.718 27.312-.813 18.761 17.64 33.98 35.717 29.536 13.402-3.295 22.765-14.091 23.166-28.145.334-11.669-5.852-19.966-15.743-26.713"
        />
        <path
            fill="#FFF"
            d="M69.003 146.988c-5.932 6.36-13.147 10.785-21.36 10.764-13.626-.037-25.417-7.82-29.593-22.115-5.441-18.632 10.658-39.649 32.92-36.527 11.817 1.657 19.725 9.26 23.578 20.188 3.457 9.806 1.5 19.252-5.545 27.69m-46.599-12.093c3.928 1.644 6.855.295 6.546-3.016-1.125-12.042 4.877-20.266 16.275-21.784 2.683-.358 4.563-1.233 3.579-3.764-.582-1.496-3.193-3.313-4.712-3.164-17.027 1.673-25.917 16.856-21.688 31.728M169.531 102.198c9.547 6.575 15.733 14.872 15.4 26.54-.402 14.055-9.765 24.851-23.167 28.146-18.077 4.445-36.53-10.775-35.717-29.536.8-18.458 18.377-32.865 37.718-27.312 1.846.53 3.616 1.32 5.766 2.162m-5.638 2.188c-1.55-.44-3.177-1.407-4.635-1.198-2.142.309-5.39.94-5.986 2.353-1.313 3.11.786 4.135 4.17 4.615 10.87 1.54 17.167 10.682 15.798 21.428-.167 1.316 1.67 3.256 3.06 4.195.464.315 3.056-1.305 3.607-2.523 3.773-8.355-2.816-25.755-16.014-28.87"
        />
        <path
            fill="#004976"
            d="M22.223 134.52c-4.048-14.497 4.842-29.68 21.869-31.353 1.52-.15 4.13 1.668 4.712 3.164.984 2.531-.896 3.406-3.58 3.764-11.397 1.518-17.4 9.742-16.274 21.784.31 3.31-2.618 4.66-6.727 2.64M164.202 104.654c12.89 2.847 19.478 20.247 15.705 28.602-.55 1.218-3.143 2.838-3.608 2.523-1.389-.94-3.226-2.88-3.059-4.195 1.37-10.746-4.929-19.888-15.799-21.428-3.383-.48-5.482-1.505-4.17-4.615.597-1.414 3.845-2.044 5.987-2.353 1.458-.21 3.084.757 4.944 1.466"
        />
    </svg>
);
export default SvgViewer;
