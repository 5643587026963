import { IAnswer, IDynamicDirtyAnswer, IDynamicQuestion } from '@typings';
import { answerArrayMerge } from '@utils/deepmerge-strategies';
import MLALogger from '@utils/logger';
import { sortByQuestionIdAndIndex } from '@utils/question-sort';
import deepmerge from 'deepmerge';

export const DynamicPendingAnswersActionTypes = {
    setDynamic: 'setDynamic',
    removeDynamic: 'removeDynamic',
    removeBatchDynamic: 'removeBatchDynamic',
    updateDynamic: 'updateDynamic',
    updateBatchDynamic: 'updateBatchDynamic',
    resetDynamic: 'resetDynamic',
    updateDynamicAnswers: 'updateDynamicAnswers',
};

export const DynamicPendingAnswersInitialState: IDynamicDirtyAnswer = {
    dirty: false,
    answers: [],
};

// payload is: IAnswer | IAnswer[] but too lazy to re-type in each handler
const reducer = (state: IDynamicDirtyAnswer, action: { type: string; payload: any; questions?: IDynamicQuestion[]; isDirty?: boolean }) => {
    switch (action.type) {
        case DynamicPendingAnswersActionTypes.updateDynamic:
            // Check id AND index, not just id
            let singleReturnValue = state.answers.some((x: any) => x.questionId === action.payload.questionId && x.index === action.payload.index)
                ? state.answers.map((x: any) => (x.questionId === action.payload.questionId && x.index === action.payload.index ? action.payload : x)).sort(sortByQuestionIdAndIndex)
                : [...state.answers, action.payload].sort(sortByQuestionIdAndIndex);

            MLALogger.Log(['Store'], {
                message: 'Update Dynamic',
                state,
                action,
                singleReturnValue,
            });

            return {
                answers: singleReturnValue,
                dirty: true,
            };
        case DynamicPendingAnswersActionTypes.updateBatchDynamic:
            const batchReturnValue: IAnswer[] = (deepmerge(state.answers, action.payload, { arrayMerge: answerArrayMerge }) as IAnswer[]).sort(sortByQuestionIdAndIndex);
            MLALogger.Log(['Store'], {
                message: 'Update Dynamic Batch',
                state,
                action,
                ret: [...state.answers, ...action.payload].distinct().sort(sortByQuestionIdAndIndex),
                retNew: batchReturnValue,
            });
            return { answers: batchReturnValue, dirty: true };
        case DynamicPendingAnswersActionTypes.updateDynamicAnswers:
            const returnValue: IAnswer[] = action.payload.sort(sortByQuestionIdAndIndex);

            MLALogger.Log(['Store'], {
                message: 'Update Dynamic Answers',
                state,
                action,
                ret: returnValue,
                retNew: returnValue,
            });
            return { answers: returnValue, dirty: true };
        case DynamicPendingAnswersActionTypes.removeDynamic:
            MLALogger.Log(['Store'], {
                message: 'Removing Dynamic',
                state,
                action,
                one: state.answers.map((x: any) => (x.questionId === action.payload.questionId && x.index === action.payload.index ? null : x)),
            });

            return {
                answers: state.answers
                    .map((x: any) => (x.questionId === action.payload.questionId && x.index === action.payload.index ? null : x))
                    .sort(sortByQuestionIdAndIndex)
                    .coalesce(),
                dirty: true,
            };
        case DynamicPendingAnswersActionTypes.removeBatchDynamic:
            let result = state.answers
                .map((x: any) => (action.payload.some((p: any) => x.questionId === p.questionId && x.index === p.index) ? null : x))
                .sort(sortByQuestionIdAndIndex)
                .coalesce();

            MLALogger.Log(['Store'], {
                message: 'Removing Dynamic Batch',
                state,
                action,
                result: result,
            });
            return {
                answers: result,
                dirty: true,
            };
        case DynamicPendingAnswersActionTypes.setDynamic:
            MLALogger.Log(['Store'], { message: 'Setting Dynamic', state, action });
            return { answers: action.payload, dirty: action.isDirty ?? false };
        case DynamicPendingAnswersActionTypes.resetDynamic:
            MLALogger.Log(['Store'], { message: 'Resetting Dynamic', state, action });
            return DynamicPendingAnswersInitialState;
        default:
            return state;
    }
};

export default reducer;
