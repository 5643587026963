/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EFormType = "EUC0" | "EUC1" | "G0517" | "HSC0" | "HSC1" | "HSC2" | "HSG0" | "HSSL0" | "HSSL1" | "LPABC0" | "LPABC1" | "LPAC0" | "LPAC1" | "LPAG0" | "LPAG2" | "LPASL0" | "LPASL1" | "MSAC0" | "MSAC1" | "NFASC0" | "NFASDDC0" | "NFASDDC1" | "NFASEUC0" | "NFASEUC1" | "%future added value";
export type ConsignmentListFragment_query = {
    readonly consignments: {
        readonly pageInfo: {
            readonly hasNextPage: boolean;
            readonly endCursor: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly cursor: string;
            readonly node: {
                readonly id: string;
                readonly updatedAt: unknown | null;
                readonly forms: ReadonlyArray<{
                    readonly type: EFormType | null;
                } | null> | null;
                readonly " $fragmentRefs": FragmentRefs<"ConsignmentRow_consignment">;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "ConsignmentListFragment_query";
};
export type ConsignmentListFragment_query$data = ConsignmentListFragment_query;
export type ConsignmentListFragment_query$key = {
    readonly " $data"?: ConsignmentListFragment_query$data;
    readonly " $fragmentRefs": FragmentRefs<"ConsignmentListFragment_query">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "consignments"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "envdAccountId"
    },
    {
      "kind": "RootArgument",
      "name": "fromDate"
    },
    {
      "kind": "RootArgument",
      "name": "searchText"
    },
    {
      "kind": "RootArgument",
      "name": "sortField"
    },
    {
      "kind": "RootArgument",
      "name": "species"
    },
    {
      "kind": "RootArgument",
      "name": "status"
    },
    {
      "kind": "RootArgument",
      "name": "toDate"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./ConsignmentListRefetchQuery.graphql.ts')
    }
  },
  "name": "ConsignmentListFragment_query",
  "selections": [
    {
      "alias": "consignments",
      "args": [
        {
          "kind": "Variable",
          "name": "envdAccountId",
          "variableName": "envdAccountId"
        },
        {
          "kind": "Variable",
          "name": "fromDate",
          "variableName": "fromDate"
        },
        {
          "kind": "Variable",
          "name": "searchText",
          "variableName": "searchText"
        },
        {
          "kind": "Variable",
          "name": "sortField",
          "variableName": "sortField"
        },
        {
          "kind": "Variable",
          "name": "species",
          "variableName": "species"
        },
        {
          "kind": "Variable",
          "name": "status",
          "variableName": "status"
        },
        {
          "kind": "Variable",
          "name": "toDate",
          "variableName": "toDate"
        }
      ],
      "concreteType": "ConsignmentConnection",
      "kind": "LinkedField",
      "name": "__ConsignmentListFragment_consignments_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ConsignmentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ConsignmentType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FormType",
                  "kind": "LinkedField",
                  "name": "forms",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ConsignmentRow_consignment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = 'de8de76d9c174cf035bda9f0306f3be8';
export default node;
