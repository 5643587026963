import { actionTypes, useGlobalState } from '@state';
import { IDynamicQuestion } from '@typings';
import { getAllChildQuestions } from '@utils/question-getter';
import { useCallback } from 'react';

const useUpdateDynamicValue = () => {
    const [{ dynamicPendingAnswers, originalAnswers }, dispatch] = useGlobalState();

    const action = useCallback(
        (question: IDynamicQuestion, index: number | null, value: string) => {
            let skipClearing = question.id === '8' || question.id === '9';
            // Get all the child questions for this question that have a trigger of which are not valid anymore
            const allChildQuestions = getAllChildQuestions(question)
                .filter((child) => child.triggers)
                .filter((child) => child.triggers?.some((ct) => ct.value !== value));

            const childAnswers = allChildQuestions
                .map((child) => {
                    const existsInPending = dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === child.id);
                    let answers = existsInPending ? dynamicPendingAnswers.answers.filter((dpa) => dpa.questionId === child.id) : originalAnswers.filter((oa) => oa.questionId === child.id);
                    if (child.type === 'REPEATABLE') {
                        // Handle the special case for when this parent holds the trigger and none of the children
                        const repeatableChildQuestions = getAllChildQuestions(child);
                        for (const rcq of repeatableChildQuestions) {
                            const repeatableChildExistsInPending = dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === rcq.id);
                            const repeatableChildAnswers = repeatableChildExistsInPending
                                ? dynamicPendingAnswers.answers.filter((dpa) => dpa.questionId === rcq.id)
                                : originalAnswers.filter((oa) => oa.questionId === rcq.id);
                            answers = [...answers, repeatableChildAnswers].flatMap((x) => x);
                        }
                    }
                    return answers;
                })
                .flatMap((x) => x)
                .coalesce();

            // Now that we have the list of answers, we want to delete them from state, do this by removing the value from both so we don't fallback into it
            if (childAnswers.length > 0) {
                // We only want to delete them from state if the direct parents are being deleted. When we have 3-level nested children, we want to make sure this isn't
                // set to null so that we don't accidently trigger the deletion of the previous answers
                if (!skipClearing) {
                    dispatch({ type: actionTypes.dynamicPendingAnswers.updateBatchDynamic, payload: childAnswers.map((ca) => ({ ...ca, value: null, index: null })) });
                    dispatch({ type: actionTypes.originalAnswers.removeBatchOriginal, payload: childAnswers });
                } else {
                    let updateDPA = dynamicPendingAnswers.answers.filter((dpa) => !childAnswers.some((ca) => ca.questionId === dpa.questionId));
                    dispatch({ type: actionTypes.dynamicPendingAnswers.updateDynamicAnswers, payload: updateDPA });
                }
            }

            // Delete the value when it has been cleared and isn't required
            if (!value && question.validators.every((x) => x.type !== 'required')) {
                // Remove any updated values
                dispatch({ type: actionTypes.dynamicPendingAnswers.removeDynamic, payload: { questionId: question.id, index } });
                // Remove any original values since that's what our fallback would go into, which in this case we do not want
                // as the user has modified the answer
                dispatch({ type: actionTypes.originalAnswers.removeOriginal, payload: { questionId: question.id, index } });
            } else {
                dispatch({ type: actionTypes.dynamicPendingAnswers.updateDynamic, payload: { questionId: question.id, index, value } });
            }
        },
        [dynamicPendingAnswers, originalAnswers, dispatch]
    );

    return action;
};

export default useUpdateDynamicValue;
