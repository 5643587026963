/* eslint-disable react/jsx-no-target-blank */
const FeedbackButton = function ({ url }: { url: string }) {
    return (
        <>
            {
                <style jsx>
                    {`
                        .feedback-button {
                            height: 40px;
                            border: solid 2px #cccccc;
                            width: 120px;
                            line-height: 35px;
                            background-color: #61bbea;
                            -webkit-transform: rotate(-90deg);
                            font-weight: 600;
                            transform: rotate(-90deg);
                            -ms-transform: rotate(-90deg);
                            -moz-transform: rotate(-90deg);
                            transition-duration: 0.4s;
                            text-align: center;
                            font-size: 17px;
                            position: fixed;
                            right: -40px;
                            top: 45%;
                        }
                        .feedback-button:hover {
                            background-color: #34a7e5;
                            box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 40px 0 rgba(0, 0, 0, 0.35);
                        }
                        .feedback-text {
                            text-decoration: none;
                            color: #fff;
                        }
                        .feedback-text:hover {
                            text-decoration: none;
                            color: #fff;
                        }
                    `}
                </style>
            }

            <div className="feedback-button">
                <a href={url} target="_blank" referrerPolicy="no-referrer-when-downgrade" className="feedback-text">
                    Feedback
                </a>
            </div>
        </>
    );
};

export default FeedbackButton;
