import { actionTypes, useGlobalState } from '@state';
import { IDynamicQuestion, IDynamicQuestionValidator, IStaticQuestion } from '@typings';
import { TRANSPORTER_DECLARED_BOX_QUESTION_ID, UNIQUE_WHEN_VALIDATOR_TYPE } from '@utils/constants';
import { TransporterQuestionId } from '@utils/enums';
import MLALogger from '@utils/logger';
import { getAllChildQuestions } from '@utils/question-getter';
import { filterByAnswered, filterByRequired, filterByUnanswered, getErrorMessage, getIsRequired, validate, validateRepeatable } from '@utils/question-validator';
import { getQuestionSection } from '@utils/section-mapper';
import { useCallback, useState } from 'react';

const useValidation = (initialValue?: boolean) => {
    const [evaluated, setEvaluated] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean | undefined>(initialValue);
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const [{ dynamicPendingAnswers, copyErrors, meta }, dispatch] = useGlobalState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updatedValidatorForTransporter = (validators: IDynamicQuestionValidator[], answer: string, questionId: string) => {
        const transporterDeclarationBoxValue = dynamicPendingAnswers.answers?.firstOrDefault((a) => a?.questionId === TRANSPORTER_DECLARED_BOX_QUESTION_ID && a.index === meta.selectedIndex)?.value;
        const isUniqueValidatorExist = validators.some((x) => x.type === UNIQUE_WHEN_VALIDATOR_TYPE);
        if (meta.selectedIndex !== -1 && transporterDeclarationBoxValue !== 'True') {
            if ((questionId === TransporterQuestionId.email || questionId === TransporterQuestionId.phone || isUniqueValidatorExist) && answer) {
                return validators;
            }
            return [];
        }
        // Adding this condition since email field is optional in transporter section.
        if (TransporterQuestionId.email === questionId && !answer) {
            return [];
        }
        return validators;
    };

    const validateAction = useCallback(
        (question: IDynamicQuestion | IStaticQuestion, answer: string, clearCopyErrors?: boolean) => {
            MLALogger.Log(['useValidation'], { question, answer });
            let validators = question.validators;
            const isRepeatable = question.type === 'REPEATABLE';
            const isRepeatableRequired = getIsRequired(question.validators);
            if (clearCopyErrors) {
                // clear all the copyerrors so copyerrors list can be populated with all the latest errors after change
                dispatch({ type: actionTypes.copyErrors.clearErrors });
            }
            if (isRepeatable) {
                // Remove required since we handle it here
                validators = validators.filter((validator) => validator.type !== 'required');

                const isValidRepeatable = validateRepeatable(question as IDynamicQuestion, dynamicPendingAnswers.answers, false);

                let errorMessages = getErrorMessage(validators, isRepeatableRequired && !isValidRepeatable ? 'invalid' : '');
                setIsValid(isValidRepeatable);

                MLALogger.Log(['ConsignmentEdit', 'validateSection', 'isValidDynamicSection', 'isRepeatable'], { isRepeatableRequired, question, isValidRepeatable, errorMessages });

                setErrorMessage(errorMessages.join(', '));

                // EPLAT-346 aggregate errors into store
                if (copyErrors.isCopy) {
                    // Handle this question // parent
                    if (errorMessages?.length > 0) {
                        const questionSection = getQuestionSection(question);
                        // Only dispatch if it is a new error
                        if (copyErrors?.errors?.every((x) => x.question !== question.text)) {
                            const payload = { question: question.text, messages: errorMessages, section: questionSection };
                            dispatch({ type: actionTypes.copyErrors.addError, payload });
                        }
                    }

                    // EPLAT-359: Support acceptable answers as an error message from child questions
                    const allChildQuestions = getAllChildQuestions(question as IDynamicQuestion);
                    const hasAllValidAnswers = allChildQuestions.filter(filterByRequired).every(filterByAnswered(dynamicPendingAnswers.answers));

                    if (!hasAllValidAnswers) {
                        // TODO: Though we normally only care about required, we might need to do this generically if it has an
                        // answer associated with it from a copy
                        const missingValidChildQuestions = allChildQuestions.filter(filterByRequired).filter(filterByUnanswered(dynamicPendingAnswers.answers));

                        for (const cq of missingValidChildQuestions) {
                            const cqErrorMessages = getErrorMessage(cq.validators, '');
                            const questionSection = getQuestionSection(cq);
                            if (copyErrors?.errors?.every((x) => x.question !== cq.text)) {
                                const payload = { question: cq.text, messages: cqErrorMessages, section: questionSection };
                                dispatch({ type: actionTypes.copyErrors.addError, payload });
                            }
                        }
                    }
                }
            } else {
                let questionId = '';
                if ('id' in question) {
                    questionId = question.id;
                }
                const updatedValidator: IDynamicQuestionValidator[] = (Object.values(TransporterQuestionId) as string[]).includes(questionId)
                    ? updatedValidatorForTransporter(validators, answer, questionId)
                    : validators;
                const errorMessages = getErrorMessage(updatedValidator, answer, meta.selectedIndex, dynamicPendingAnswers.answers);
                setIsValid(validate(updatedValidator, answer, question, meta.selectedIndex, dynamicPendingAnswers.answers));
                setErrorMessage(errorMessages.join(', '));
                MLALogger.Log(['ConsignmentEdit', 'validateSection', 'isValidDynamicSection', 'isNormal'], { isRepeatableRequired, question, errorMessages });

                const questionSection: NonNullable<any> = getQuestionSection(question);
                if (copyErrors.isCopy && errorMessages?.length > 0) {
                    // making sure error is not duplicated
                    if (copyErrors?.errors?.every((x) => x.question !== question.text) && isRepeatableRequired) {
                        const payload = { question: question.text, messages: errorMessages, section: questionSection };
                        dispatch({ type: actionTypes.copyErrors.addError, payload });
                    }
                }
            }
            if (!evaluated) {
                setEvaluated(true);
            }
        },
        [evaluated, dynamicPendingAnswers.answers, copyErrors.isCopy, copyErrors.errors, dispatch, meta.selectedIndex, updatedValidatorForTransporter]
    );

    const resetIsValid = useCallback(() => setIsValid(initialValue), [initialValue]);

    // TODO: Check why the array destructure way didn't work
    // return [isValid, action]
    return { isValid, checkIsValid: validateAction, evaluated, errorMessage, resetIsValid };
};

export default useValidation;
