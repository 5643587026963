import { IConsignment } from '@typings';
import MLALogger from '@utils/logger';
import { graphql } from 'relay-runtime';

import { ConvertTemplateInput } from './__generated__/convertTemplateMutation.graphql';

// TODO: To make use of cache mechanism of Relay, we should capture the fields that we're updating in the mutation
// Doesn't look like we can do a dynamic mutation return, so just grab everything
export const mutation = graphql`
    mutation convertTemplateMutation($input: ConvertTemplateInput!, $envdAccountId: String!) {
        convertTemplate(input: $input, envdAccountId: $envdAccountId) {
            data {
                id
                name
                updatedAt
            }
        }
    }
`;

// https://github.com/facebook/relay/pull/2482/files
// There is currently a bug in data normalization in relay so we cannot assume that relay will fix our optimisitic updates correctly (as we'd need to apply the whole tree)
// for the update since we can't do dynamic mutation data returns.
// As such we can't use optimisitic updates right now OR alternatively, each mutation area will need to be converted to a fragment with an associated optimisitc update
const commitAsync = async (mutate: any, consignment: ConvertTemplateInput, envdAccountId: String): Promise<Partial<IConsignment>> => {
    MLALogger.Log(['convertTemplate', 'commitAsync', 'Sending'], { consignment });
    try {
        const data = await mutate({
            variables: { input: consignment, envdAccountId },
            onCompleted: (response: any, error: any) => {
                MLALogger.Log(['convertTemplate', 'commitAsync'], { response, error });
                if (error) {
                    MLALogger.Log(['convertTemplate', 'commitAsync'], { error }, 'error');
                } else {
                    MLALogger.Log(['convertTemplate', 'commitAsync'], { response, data: response.convertTemplate.data, request: consignment });
                }
            },
        });
        return data.convertTemplate.data;
    } catch (error) {
        MLALogger.Log(['convertTemplate'], error, 'error');
        throw error;
    }
};

export default commitAsync;
