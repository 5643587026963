import { ClearIcon, HelpIcon } from '@assets/icons';
import Button from '@common/components/Button';
import Checkbox from '@common/components/Form/Checkbox';
import Input, { InputType } from '@common/components/Form/Input';
import UserSelect from '@common/components/UserSelect';
import { useUserState } from '@common/context/userContext';
import { isValidString } from '@common/utils/string-extensions';
import ClickableIcon from '@components/ClickableIcon';
import Modal from '@components/Modal';
import { IPerson } from '@typings';
import { SectionName } from '@utils/enums';
import { mapUserToPerson } from '@utils/person';
import { StaticQuestions } from '@utils/section-mapper';
import React, { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useMediaQuery } from 'react-responsive';

import PICSearch from './fragments/PICSearch';

interface LocalState {
    ownedByUser: boolean;
    owner: IPerson | null;
    consignor: IPerson | null;
    sameConsigneeAsDestination: boolean;
    destination: IPerson | null;
    consignee: IPerson | null;
    movementDate: Date | null;
    movementTime: string | null;
}

interface Props {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    state: LocalState;
    setState: React.Dispatch<React.SetStateAction<LocalState>>;
    onSubmit(): void;
}

export const hasValidName = (person: IPerson): boolean => {
    return isValidString(person.businessName) || (isValidString(person.firstName) && isValidString(person.lastName));
};

// const timeRegex: RegExp = /\d\d:\d\d/;

const ConsignmentAddStep1: React.FunctionComponent<Props> = ({ title, subtitle, state, setState, onSubmit }: Props) => {
    const [{ user }] = useUserState();

    // Question data for Movement
    const questions = StaticQuestions[SectionName.MOVEMENT];

    // Modal for notes
    const [showModal, setShowModal] = useState<boolean>(false);
    const [note, setNote] = useState<{ title: string; notes: string }>();

    // Error messages
    const [errorMessageConsignor, setErrorMessageConsignor] = useState<string>('');
    const [errorMessageOwner, setErrorMessageOwner] = useState<string>('');
    const [errorMessageConsignee, setErrorMessageConsignee] = useState<string>('');
    const [errorMessageDestination, setErrorMessageDestination] = useState<string>('');
    const [errorMessageMovementDate, setErrorMessageMovementDate] = useState<string>('');
    const [submitCount, setSubmitCount] = useState<number>(0);

    // All validations commented out below are due to constantly changing business rules
    // We are keeping them here because of how often it goes from required to optional

    useEffect(() => {
        let valid = false;
        setErrorMessageConsignor(!valid ? 'An consignor is required.' : '');

        if (state.consignor) {
            const validName = hasValidName(state.consignor);

            // For address: Required field + <300
            // const validAddress = isValidString(state.consignor.locationAddress) && state.consignor.locationAddress!.length <= 300;

            // For State: required
            // const validState = isValidString(state.consignor.locationState) && states.includes(state.consignor.locationState!);

            // For Town: required, <100
            // const validTown = isValidString(state.consignor.locationTown) && state.consignor.locationTown!.length <= 100;

            // For PIC: required, length = 8
            const validPIC = state.consignor.pic.length === 8;

            // For Postcode: 4 length
            // const validPostcode = isValidString(state.consignor.locationPostcode) && state.consignor.locationPostcode!.length === 4;

            valid = validName && validPIC;

            setErrorMessageConsignor(!valid ? 'All consignor fields are required.' : '');
        }
    }, [state.consignor, setErrorMessageConsignor, submitCount]);

    useEffect(() => {
        let valid = false;
        setErrorMessageConsignee(!valid ? 'A consignee is required.' : '');

        if (state.consignee) {
            const validName = hasValidName(state.consignee);

            // For address: Required field + <300
            // const validAddress = isValidString(state.consignee.locationAddress) && state.consignee.locationAddress!.length <= 300;

            // For State: required
            // const validState = isValidString(state.consignee.locationState) && states.includes(state.consignee.locationState!);

            // For Town: required, <100
            // const validTown = isValidString(state.consignee.locationTown) && state.consignee.locationTown!.length <= 100;

            // For PIC: required, length = 8
            const validPIC = state.consignee.pic.length === 0 || state.consignee.pic.length === 8;

            // For Postcode: 4 length
            // const validPostcode = isValidString(state.consignee.locationPostcode) && state.consignee.locationPostcode!.length === 4;

            valid = validName && validPIC;

            setErrorMessageConsignee(!valid ? 'All consignee fields are required' : '');
        }
    }, [state.consignee, setErrorMessageConsignee, submitCount]);

    useEffect(() => {
        let valid = false;
        setErrorMessageMovementDate(!valid ? 'Movement date must be within the next 28 days, or within the past year.' : '');

        if (state.movementDate) {
            valid = true;
            setErrorMessageMovementDate('');
        }
    }, [state.movementDate, setErrorMessageMovementDate, submitCount]);

    useEffect(() => {
        setErrorMessageDestination(!state.destination ? 'A destination is required.' : '');

        if (state.destination) {
            const validName = hasValidName(state.destination);

            // For address: Required field + <300
            // const validAddress = isValidString(state.destination.locationAddress) && state.destination.locationAddress!.length <= 300;

            // For State: required
            // const validState = isValidString(state.destination.locationState) && states.includes(state.destination.locationState!);

            // For Town: required, <100
            // const validTown = isValidString(state.destination.locationTown) && state.destination.locationTown!.length <= 100;

            // For PIC: required, length = 8
            const validPIC = state.destination.pic.length === 0 || state.destination.pic.length === 8;

            // For Postcode: 4 length
            // const validPostcode = isValidString(state.destination.locationPostcode) && state.destination.locationPostcode!.length === 4;

            const valid = validName && validPIC;

            setErrorMessageDestination(!valid ? 'Destination name and PIC must be valid. Please save when complete.' : '');
        }
    }, [state.destination, setErrorMessageDestination, submitCount]);

    useEffect(() => {
        setErrorMessageOwner(!state.owner ? 'An owner is required.' : '');

        if (state.owner) {
            const validName = hasValidName(state.owner);

            // For address: Required field + <300
            // const validAddress = isValidString(state.destination.locationAddress) && state.destination.locationAddress!.length <= 300;

            // For State: required
            // const validState = isValidString(state.destination.locationState) && states.includes(state.destination.locationState!);

            // For Town: required, <100
            // const validTown = isValidString(state.destination.locationTown) && state.destination.locationTown!.length <= 100;

            // For PIC: required, length = 8
            const validPIC = state.owner.pic.length === 0 || state.owner.pic.length === 8;

            // For Postcode: 4 length
            // const validPostcode = isValidString(state.destination.locationPostcode) && state.destination.locationPostcode!.length === 4;

            const valid = validName && validPIC;

            setErrorMessageOwner(!valid ? 'All owner fields are required if selected.' : '');
        }
    }, [state.owner, setErrorMessageOwner, submitCount]);

    const isValidStepOne = useCallback(() => {
        const isValidConsingor = state.consignor !== null && errorMessageConsignor === '';
        const isValidConsignee = state.consignee !== null && errorMessageConsignee === '';
        const isValidDestination = state.destination !== null && errorMessageDestination === '';
        const isValidMovementDate = state.movementDate !== null && errorMessageMovementDate === '';
        return isValidConsingor && isValidConsignee && isValidDestination && isValidMovementDate;
    }, [state.consignor, state.consignee, state.destination, state.movementDate, errorMessageConsignor, errorMessageConsignee, errorMessageDestination, errorMessageMovementDate]);

    const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    const isDesktop = !isTabletOrMobile;

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                :global(.QuestionModalNotesClose) {
                    flex: 1 0 auto;
                    justify-content: flex-end;
                    color: $color-copy;

                    @media (prefers-color-scheme: dark) {
                        color: $color-white;
                    }

                    :global(p) {
                        word-break: break-all;
                    }
                }

                :global(.ModalQuestionNotes--Header) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex: 1 1 auto;

                    p {
                        margin-right: grid(3);
                    }
                }

                :global(.Question--Info) {
                    display: inline-block;
                    float: right;
                    margin-left: grid(4);
                    cursor: pointer;
                    vertical-align: middle;
                }

                .ConsignmentAddStep1--Footer {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: grid(1);
                }

                .error {
                    color: $color-error;
                    margin-bottom: grid(5);
                }
            `}</style>
            <div className="title-section">
                {title}
                {subtitle}
            </div>
            <div className="wizard-step">
                <>
                    <h3 className="m-b-16">
                        {questions[0].text}
                        <HelpIcon
                            className="icon-xs Question--Info"
                            onClick={() => {
                                setShowModal((s) => !s);
                                setNote({ title: questions[0].text, notes: questions[0].help ?? '' });
                            }}
                            role="button"
                            aria-pressed="false"
                        />
                    </h3>
                    <Checkbox
                        optionText={`I am the owner of the livestock (${user.accountDetails.entityName ?? user.accountDetails.id} ${user.accountDetails.pic})`}
                        checked={state.ownedByUser}
                        onChange={() =>
                            setState((s) => {
                                const newValue = !s.ownedByUser;
                                return {
                                    ...s,
                                    ownedByUser: newValue,
                                    owner: newValue ? s.consignor ?? mapUserToPerson(user, 'OWNER') : null,
                                };
                            })
                        }
                    />
                    {!state.owner && (
                        <PICSearch
                            onSelected={(owner) =>
                                setState((s) => {
                                    const ownedByUser = owner.pic === user?.accountDetails.pic;
                                    return { ...s, owner, ownedByUser };
                                })
                            }
                            isValid={submitCount === 0 ? undefined : errorMessageOwner === ''}
                            errorText={errorMessageOwner}
                            searchMode="OWNER"
                        />
                    )}

                    {state.owner && !state.ownedByUser && (
                        <UserSelect
                            onChange={(p) =>
                                setState((s) => {
                                    // EPLAT-326 Match StaticEntityQuestion.isEqualPerson to determine if owner is user
                                    // i.e. owner and user match only if PIC, business name and address line 1 match
                                    const ownedByUser =
                                        p.pic === user?.accountDetails.pic && p.businessName === user?.accountDetails.entityName && p.locationAddress === user?.accountDetails.locationAddress;
                                    return { ...s, owner: { ...p, type: 'OWNER' }, ownedByUser };
                                })
                            }
                            person={state.owner}
                            showEdit={{ title: 'Edit details', active: true, expandOnDefault: state.owner.pic === '' || !hasValidName(state.owner) }}
                            showChange={{ title: 'Select a different PIC', active: true, onClicked: () => setState((s) => ({ ...s, owner: null })) }}
                            onCancel={() => setState((s) => ({ ...s, owner: s.owner?.pic ? s.owner : null }))}
                        />
                    )}
                </>

                <h3 className="m-b-16">
                    {questions[1].text}
                    <HelpIcon
                        className="icon-xs Question--Info"
                        onClick={() => {
                            setShowModal((s) => !s);
                            setNote({ title: questions[1].text, notes: questions[1].help ?? '' });
                        }}
                        role="button"
                        aria-pressed="false"
                    />
                </h3>

                {state.consignor && (
                    <>
                        <UserSelect
                            onChange={(p) => {
                                setState((s) => {
                                    return { ...s, consignor: p, ownedByUser: !s.ownedByUser };
                                });
                            }}
                            person={state.consignor}
                        />
                        {errorMessageConsignor && <p className="error">{errorMessageConsignor}</p>}
                    </>
                )}

                <h3 className="m-b-16">
                    {questions[2].text}
                    <HelpIcon
                        className="icon-xs Question--Info"
                        onClick={() => {
                            setShowModal((s) => !s);
                            setNote({ title: questions[2].text, notes: questions[2].help ?? '' });
                        }}
                        role="button"
                        aria-pressed="false"
                    />
                </h3>

                {state.destination ? (
                    <>
                        <UserSelect
                            onChange={(p) => {
                                setState((s) => {
                                    const newDestination = p.pic ? p : p.businessName ? { ...p } : null;
                                    return {
                                        ...s,
                                        destination: newDestination,
                                        sameConsigneeAsDestination: !s.sameConsigneeAsDestination,
                                    };
                                });
                            }}
                            person={state.destination}
                            showEdit={{ title: 'Edit details', active: true, expandOnDefault: !hasValidName(state.destination) }}
                            showChange={{ active: true, onClicked: () => setState((s) => ({ ...s, destination: null })), title: 'Select a different PIC' }}
                            onCancel={() => setState((s) => ({ ...s, destination: s.destination?.pic ? s.destination : null }))}
                        />
                        {errorMessageDestination && submitCount > 0 && <p className="helper error">{errorMessageDestination}</p>}
                    </>
                ) : (
                    <PICSearch
                        onSelected={(destination) => setState((s) => ({ ...s, destination, consignee: s.sameConsigneeAsDestination ? destination : s.consignee }))}
                        isValid={submitCount === 0 ? undefined : errorMessageDestination === ''}
                        errorText={errorMessageDestination}
                        searchMode="DESTINATION"
                    />
                )}

                <h3 className="m-b-16">
                    {questions[3].text}
                    <HelpIcon
                        className="icon-xs Question--Info"
                        onClick={() => {
                            setShowModal((s) => !s);
                            setNote({ title: questions[3].text, notes: questions[3].help ?? '' });
                        }}
                        role="button"
                        aria-pressed="false"
                    />
                </h3>

                <Checkbox
                    optionText="Same as destination (untick this box if the livestock are being supplied to a different person or business, e.g. an agent)"
                    checked={state.sameConsigneeAsDestination}
                    onChange={() =>
                        setState((s) => {
                            const newValue = !s.sameConsigneeAsDestination;
                            return {
                                ...s,
                                sameConsigneeAsDestination: newValue,
                                consignee: newValue ? s.destination : null,
                            };
                        })
                    }
                />

                {state.consignee && !state.sameConsigneeAsDestination && (
                    <UserSelect
                        onChange={(p) => setState((s) => ({ ...s, consignee: p.pic ? p : p.businessName ? { ...p, pic: '' } : null }))}
                        person={state.consignee}
                        showEdit={{ title: 'Edit details', active: true, expandOnDefault: state.consignee.pic === '' || !hasValidName(state.consignee) }}
                        showChange={{ title: 'Select a different PIC', active: true, onClicked: () => setState((s) => ({ ...s, consignee: null })) }}
                        onCancel={() => setState((s) => ({ ...s, consignee: s.consignee?.pic ? s.consignee : null }))}
                    />
                )}

                {!state.consignee && !state.sameConsigneeAsDestination && (
                    <PICSearch
                        onSelected={(consignee) => setState((s) => ({ ...s, consignee }))}
                        isValid={submitCount === 0 ? undefined : errorMessageConsignee === ''}
                        errorText={errorMessageConsignee}
                        searchMode="DESTINATION"
                    />
                )}

                <h3 className="m-b-16 p-t-16">When are they being moved</h3>

                <Input
                    errorText={errorMessageMovementDate}
                    // Ensure that we don't display tick/cross on load
                    isValid={state.movementDate === null && submitCount === 0 ? undefined : errorMessageMovementDate === '' && state.movementDate !== null}
                    minDate={new Date(new Date().setDate(new Date().getDate() - 365))}
                    maxDate={new Date(new Date().setDate(new Date().getDate() + 28))}
                    helperText={'This can just be an estimate'}
                    className="class-for-input"
                    inputType={InputType.Date}
                    value={state.movementDate ? new Date(state.movementDate).toISODateString() : ''}
                    onChange={(e) => {
                        const d = e.target.value;
                        if (d) {
                            setState((s) => ({ ...s, movementDate: new Date(d) }));
                        }
                    }}
                />

                <Input
                    helperText={'This can just be an estimate'}
                    className="class-for-input"
                    inputType={InputType.Time}
                    value={state.movementTime ?? ''}
                    onChange={(e) => setState((s) => ({ ...s, movementTime: e.target.value }))}
                />

                {isDesktop && (
                    <Button
                        buttonType="primary"
                        children="Next step"
                        action={() => {
                            // But then we can't use the back button
                            // Unless we navigate to step two via a param instead but then we'd lose the state

                            // Validate previous inputs prior to next step
                            setSubmitCount((s) => s + 1);
                            if (isValidStepOne()) {
                                onSubmit();
                            } else {
                                console.error('Invalid step one', state);
                            }
                        }}
                    />
                )}
                {isTabletOrMobile && (
                    <div className="ConsignmentAddStep1--Footer">
                        <Button
                            buttonType="primary"
                            children="Next step"
                            fullWidth
                            action={() => {
                                // But then we can't use the back button
                                // Unless we navigate to step two via a param instead but then we'd lose the state

                                // Validate previous inputs prior to next step
                                setSubmitCount((s) => s + 1);
                                if (isValidStepOne()) {
                                    onSubmit();
                                } else {
                                    console.error('Invalid step one', state);
                                }
                            }}
                        />
                    </div>
                )}
            </div>
            <Modal
                id={'modal-question-notes'}
                show={showModal}
                onOutClick={() => setShowModal(false)}
                modalHeader={
                    <div className={'ModalQuestionNotes--Header'}>
                        <h3>Help</h3>
                        <ClickableIcon icon={<ClearIcon />} alt="Remove" onClick={() => setShowModal(false)} classNames={'QuestionModalNotesClose'} />
                    </div>
                }
                zIndex={10}
            >
                <h3 className="m-b-16">{note?.title}</h3>
                <ReactMarkdown source={note?.notes ?? ''} />
            </Modal>
        </>
    );
};

export default ConsignmentAddStep1;
