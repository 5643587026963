import Autocomplete from '@common/components/Form/Autocomplete';
import { useUserState } from '@common/context/userContext';
import { useWhyDidYouUpdate } from '@common/effects/useWhyDidYouUpdate';
import Button from '@components/Button';
import { DropdownOption } from '@components/Form/DropdownOptions';
import { IPerson } from '@typings';
import MLALogger from '@utils/logger';
import React, { useCallback, useMemo, useState } from 'react';
import { graphql, useQuery } from 'relay-hooks';

import { PicSearchMode, PICSearchQuery, PICSearchQueryResponse } from './__generated__/PICSearchQuery.graphql';

const query = graphql`
    query PICSearchQuery($envdAccountId: String!, $searchText: String, $searchMode: PicSearchMode) {
        pics(envdAccountId: $envdAccountId, searchText: $searchText, searchMode: $searchMode) {
            items {
                id
                businessName
                firstName
                lastName
                town
                state
                address
                pic
            }
        }
    }
`;

interface Props {
    onSelected: (person: IPerson) => void;
    searchMode: PicSearchMode;
    errorText?: string;
    isValid?: boolean;
    labelText?: string;
    dropDownTopHeightAdjustment?: number;
    requiredText?: boolean;
}

const PICSearchError: React.FC = () => {
    return (
        <div>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';
                div {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    padding: grid(4);
                }
            `}</style>
            <p>Please try again later.</p>
        </div>
    );
};

const PICSearch: React.FC<Props> = (searchProps: Props) => {
    const MANUAL_PERSON: IPerson = {
        firstName: '',
        lastName: '',
        businessName: '',
        locationTown: '',
        locationState: '',

        pic: '',
        manual: '',
    };

    const [searchText, setSearchText] = useState<string>('');
    const { onSelected, errorText, isValid, searchMode, labelText, dropDownTopHeightAdjustment, requiredText } = searchProps;
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;
    const { data, error } = useQuery<PICSearchQuery>(
        query,
        { envdAccountId, searchText, searchMode },
        {
            skip: searchText === '' && searchMode !== 'DESTINATION',
        }
    );

    useWhyDidYouUpdate('PICSearch', searchProps);

    // const [searchResults, setSearchResults] = useState<any[]>([]);
    MLALogger.LogCount('PICSearch');

    const mapToIPerson = useCallback((pic: string, response: PICSearchQueryResponse | null | undefined): IPerson => {
        const item = response?.pics?.items
            ?.coalesce()
            .filter((x) => x.pic === pic)
            .first();

        if (!item) {
            return {
                firstName: '',
                lastName: '',
                businessName: '',
                locationTown: '',
                locationState: '',

                pic: pic === 'MANUAL' ? '' : pic,
                manual: '',
            };
        }

        return {
            pic,

            firstName: item.firstName || '',
            lastName: item.lastName || '',
            businessName: item.businessName || (item.firstName || item.lastName ? `${item.firstName ?? ''} ${item.lastName ?? ''}`.trim() : undefined),
            locationTown: item.town || '',
            // Uppercase the state because sometimes they can come in 'Qld' or the like which is invalid
            locationState: item.state?.toUpperCase() || '',
            locationAddress: item.address || '',
        };
    }, []);

    const doSearch = useCallback((q: string) => {
        setSearchText(q.trim());
    }, []);

    const isError = error instanceof Error;

    // Move manual pic selector here

    const internalOnSelect = useCallback(
        (item: DropdownOption) => {
            if (item) {
                onSelected(mapToIPerson(item.id, data));
            }
        },
        [onSelected, mapToIPerson, data]
    );

    const errorSlot = useMemo(() => <PICSearchError />, []);

    return (
        <>
            {labelText && <h3 className="m-b-16">{labelText}</h3>}
            {requiredText && <div className="required">Required</div>}
            <Autocomplete
                placeholder={'Search by PIC, town, or company name'}
                errorText={errorText}
                isValid={isValid}
                isError={isError}
                errorSlot={errorSlot}
                search={doSearch}
                onSelected={internalOnSelect}
                heading={isError ? 'Uh oh, there has been a problem' : searchText.length > 0 ? 'Select a Location' : 'Recent Locations'}
                emptyText="No results"
                searchResults={
                    data?.pics?.items
                        ?.coalesce()

                        .map((x: any, i: number) => ({
                            id: x.pic || i,
                            title: x.businessName ?? `${x.firstName ?? ''} ${x.lastName ?? ''}`.trim(),
                            subtitle: (`${x.pic} • ` || '') + x.town,
                        })) || undefined
                }
                dropDownTopHeightAdjustment={dropDownTopHeightAdjustment}
            />
            <div className="m-b-32">
                <span>Or</span>&nbsp;
                <Button buttonType={'link'} buttonSize={'small'} onClick={() => onSelected(MANUAL_PERSON)}>
                    enter the details manually
                </Button>
            </div>
            <style jsx>
                {`
                    @import 'vars';
                    @import 'utils';
                    @import 'mixins';
                    .required {
                        @include text-xsmall;
                        @include text-muted;
                        margin: grid(-1) 0 grid(4) 0;
                    }
                `}
            </style>
        </>
    );
};

export default PICSearch;
