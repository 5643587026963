import { accounts, AccreditationListBase, IAccountDetails, IUser, IUserClaims } from '@typings';
import { differenceInDays, format } from 'date-fns';

import { AccountTypeEnum, AccreditationExpiryStatusTimeEnum, AccreditationStatusEnum, SectionName } from './enums';

/**
 * Return the clean rolePermissions(filtered null)
 */
export const getFilteredUserDetails = (userDetails: IUserClaims | undefined): IUserClaims => {
    let filteredUserDetails = Object.assign({}, userDetails);
    filteredUserDetails.accounts = filteredUserDetails.accounts.map((account: accounts) => {
        let filteredAccount = { ...account, rolePermissions: account?.roles };
        return filteredAccount;
    });

    return filteredUserDetails;
};

export function isIAccountDetails(item: any): item is IAccountDetails {
    return (item as IAccountDetails)?.roles !== undefined && !('properties' in item);
}

export const getCarrierRegNum = (user: IUser) => {
    const transporterAccount = user?.claims?.accounts?.find((acc: accounts) => acc.accountType === SectionName.TRANSPORTER.toUpperCase());
    const reg_num = transporterAccount?.properties?.rego || '';
    return reg_num;
};

/**
 * Safely access the accreditations of an LPA account.
 * This provides safer access in the case the LPA account
 * has expired and there is no accreditations object
 * @param account Account to get the accreditations from
 * @returns object with the accreditations
 */
export const getLpaAccreditations = (account: IAccountDetails): AccreditationListBase => {
    const accreditations = account.accreditations;
    if (account.accountType === AccountTypeEnum.LPA && accreditations) {
        return accreditations;
    }
    return {};
};

/**
 * Get LPA accreditations expiry status by calculating the reAccreditationDate. Non-LPA account will return null
 * @param account Account to get the accreditations from
 * @returns status: AccreditationExpiryStatusTimeEnum
 */
export const getAccreditationStatus = (account: accounts | IAccountDetails) => {
    const reAccreditationDate = getReAccreditationDate(account);
    // Check the lPA field in Accrediation object and return as Invalid if it is false
    if ('accreditations' in account && account.accountType === AccountTypeEnum.LPA) {
        const isLPAAccredited = account?.accreditations?.lPA || false;
        if (!isLPAAccredited) {
            return AccreditationStatusEnum.INVALID;
        }
    }

    if (reAccreditationDate) {
        //calculate expiry date
        //get the result show pop up or not
        const to = reAccreditationDate;
        const today = format(new Date(), 'yyyy-MM-dd');
        const differenceInDay = differenceInDays(new Date(to), new Date(today));

        // Valid --> (reAccreditationDate > 60 days)
        // Due to expire notice --> (0 days <= reAccreditationDate <= 60 days)
        // Expired(But the LPA status is true(ENVDW-1230)) --> reAccreditationDate < 0 days
        return differenceInDay > AccreditationExpiryStatusTimeEnum.WITHIN_2MONTHS
            ? AccreditationStatusEnum.VALID
            : differenceInDay >= 0
            ? AccreditationStatusEnum.WITHIN_2MONTHS
            : AccreditationStatusEnum.EXPIRED_ACCESS;
    } else {
        // Invalid LPA account doesn't have token return so it doesn't have nextRecommitment date
        // Non-LPA account would be null
        return account?.accountType === AccountTypeEnum.LPA ? AccreditationStatusEnum.INVALID : null;
    }
};

/**
 * Check if the account is a LPA account or Not
 * @param account Account object to check for LPA/Non-LPA
 * @returns Returns true/false for LPA/Non-LPA
 */
export const isLPABasedAccount = (account: accounts | IAccountDetails) => {
    return account?.accountType === AccountTypeEnum.LPA;
};

/**
 * Get nextRecommitment date from account
 * @param account Account to get the recommitment date from
 * @param asString To be returned as a string or Date object
 * @returns recommitmentDate: Date
 */
export const getReAccreditationDate = (account: accounts | IAccountDetails, asString: boolean = true) => {
    const reAccreditationDate = isIAccountDetails(account) ? account?.reAccreditationDate : account?.properties?.reAccreditationDate;
    return reAccreditationDate ? (asString ? reAccreditationDate : new Date(reAccreditationDate)) : null;
};
