import { useUserState } from '@common/context/userContext';
import React from 'react';

const UserCard: React.FC = () => {
    const [{ user }] = useUserState();

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                p {
                    @include text-small();
                }
            `}</style>
            <p>
                <strong>Logged in as</strong>
                {user?.claims?.email && (
                    <>
                        <br />
                        <span>{user?.claims?.email}</span>
                    </>
                )}
            </p>
        </>
    );
};

export default UserCard;
