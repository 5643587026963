import classnames from 'classnames';
import React from 'react';

interface Props {
    icon?: any;

    url?: string;
    onClick?: (...args: any) => any;
    alt?: string;

    noFollow?: boolean;
    classNames?: string;
}

const ClickableIcon: React.FC<Props> = ({ icon, url, onClick, alt, noFollow, classNames }) => {
    return (
        <a href={url} onClick={onClick} aria-label={alt} rel={noFollow ? 'noopener noreferrer' : ''} className={classnames('ClickableIcon', classNames)}>
            {icon}
        </a>
    );
};

export default ClickableIcon;
