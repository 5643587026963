import { AdminIcon, AgentIcon, BuyerIcon, CheckIcon, ChevronDownIcon, ProcessorIcon, SaleyardIcon, SourceReceiver, TransporterIcon, ViewerIcon } from '@assets/icons';
import { useUserState } from '@common/context/userContext';
import { actionTypes } from '@common/context/userContext';
import ContextMenu, { ContextMenuRef } from '@components/Form/ContextMenu';
import { AccreditationStatusModal } from '@containers/Profile/fragments/AccreditationStatus';
import useRoleValidation from '@effects/useRoleValidation';
import { accounts, IAccountDetails } from '@typings';
import { getAccreditationStatus, getLpaAccreditations, isIAccountDetails } from '@utils/account';
import { FETCH_USER_DETAILS_ERROR_MSG } from '@utils/constants';
import { AccountTypeEnum, AccreditationCodeEnum, NlisAccountTypeEnum, RoleTypeEnum } from '@utils/enums';
import React, { useCallback, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';

import ProgramIcon from './ProgramIcon';

const AccountAvatar: React.FC<{ account: accounts | IAccountDetails }> = ({ account }) => {
    const { hasRoleInAccount } = useRoleValidation();
    const nlisAccountType = isIAccountDetails(account) ? account.nlisAccountType : account.properties?.nlisAccountType;
    const getNlisIcon = (nlisAccountType: NlisAccountTypeEnum) => {
        switch (nlisAccountType.toUpperCase()) {
            case NlisAccountTypeEnum.AGENT:
                return <AgentIcon />;
            case NlisAccountTypeEnum.PROCESSOR:
                return <ProcessorIcon />;
            case NlisAccountTypeEnum.SALEYARD:
                return <SaleyardIcon />;
            default:
                const hasElevatedViewer = hasRoleInAccount(account, RoleTypeEnum.ELEVATEDVIEWER);
                return hasElevatedViewer ? <ViewerIcon /> : <SourceReceiver />;
        }
    };
    switch (account.accountType) {
        case AccountTypeEnum.LPA:
            return <SourceReceiver />;
        case AccountTypeEnum.NLIS:
            return nlisAccountType ? getNlisIcon(nlisAccountType) : <SourceReceiver />;
        case AccountTypeEnum.TRANSPORTER:
            return <TransporterIcon />;
        case AccountTypeEnum.AUTHVIEWER:
            return <ViewerIcon />;
        case AccountTypeEnum.BUYER:
            return <BuyerIcon />;
        case AccountTypeEnum.ADMIN:
            return <AdminIcon />;
        case '':
            // No account
            return <></>;
        default:
            return <SourceReceiver />;
    }
};

const AccountCard: React.FC<{ account: accounts | IAccountDetails; active?: boolean; onClick?: () => void }> = ({ account, active = false, onClick }) => {
    const clickable = onClick ? true : false;
    const envdAccountId = isIAccountDetails(account) ? account.id : account.envdAccountId;
    const accountIdText =
        account.accountType === AccountTypeEnum.LPA || account.accountType === AccountTypeEnum.NLIS ? `${account.accountType} ID: ${account.accountId}` ?? '' : account.accountId ?? '';
    return (
        <>
            <div onClick={onClick} key={envdAccountId + active ? 'active' : 'inactive'} className={`account-card flex-center-row flex-between ${clickable ? 'hover' : ''}`}>
                <style jsx>{`
                    @import 'vars';
                    @import 'utils';

                    .avatar-icon {
                        margin-right: grid(2);
                        font-size: 1rem;
                        line-height: 1rem;
                        :global(svg) {
                            min-width: $icon-xl;
                            min-height: $icon-xl;
                        }
                    }
                    .check-icon {
                        color: $color-primary;
                        :global(svg) {
                            width: $icon-lg;
                            height: $icon-lg;
                        }
                    }
                    .account-card {
                        flex: 4;
                        padding: grid(3);
                        background-color: $color-white;
                        @media (prefers-color-scheme: dark) {
                            background-color: darken($color-white, 80%);
                        }
                    }
                    .hover {
                        &:hover {
                            background-color: $color-grey96;

                            @media (prefers-color-scheme: dark) {
                                background: darken($color-grey96, 80%);
                            }
                        }
                    }
                    .account-details {
                        overflow-wrap: anywhere;
                    }
                    .account-label {
                        color: $color-secondary;
                        @media (prefers-color-scheme: dark) {
                            color: lighten($color-secondary, 50%);
                        }
                    }
                `}</style>
                <div className="flex-center-row">
                    <div className="avatar-icon">
                        <AccountAvatar account={account} />
                    </div>
                    <div className="account-details">
                        <span className="h3 title account-label">{account.accountLabel}</span>
                        <div className="small" data-cy="account-id-text">
                            {accountIdText}
                        </div>
                    </div>
                </div>
                <div className="check-icon">{active && <CheckIcon />}</div>
            </div>
        </>
    );
};

const AccountSelector = () => {
    const contextMenuRef = useRef<ContextMenuRef>();
    const [{ user }, dispatch] = useUserState();
    const history = useHistory();
    const accountList = user.claims?.accounts;
    const currentAccount = user.accountDetails;
    // Non LPA account doesn't have accreditattion, should be null
    const accreditationStatus = (currentAccount && getAccreditationStatus(currentAccount)) ?? null;

    const programs = useMemo(() => {
        const noPrograms = <div>No registered programs to display</div>;
        if (currentAccount?.accountType === AccountTypeEnum.LPA) {
            const validAccreditations: JSX.Element[] = [];
            Object.entries(getLpaAccreditations(currentAccount))
                .sort(([accA], [accB]) => accA.length - accB.length) // sort to have the longer accreditations at the end
                .forEach(([accreditation, bool]) => {
                    if (bool) {
                        validAccreditations.push(<ProgramIcon program={accreditation as AccreditationCodeEnum} key={accreditation} />);
                    }
                });
            if (validAccreditations.length === 0) {
                return noPrograms;
            } else {
                return validAccreditations;
            }
        } else {
            return noPrograms;
        }
    }, [currentAccount]);

    const getAccountList = useCallback(() => {
        let options: any = [];
        accountList?.forEach((account) => {
            options.push({
                id: account?.envdAccountId,
                component: (
                    <AccountCard
                        account={account}
                        active={user?.accountDetails?.id === account.envdAccountId ? true : false}
                        onClick={() => {
                            contextMenuRef.current && contextMenuRef.current.hide();

                            if (account.accountType === AccountTypeEnum.ADMIN) {
                                history.push('/admin');
                            } else {
                                dispatch({
                                    type: actionTypes.user.setAccount,
                                    value: account,
                                });
                            }
                        }}
                    />
                ),
            });
        });
        return options;
    }, [accountList, dispatch, history, user?.accountDetails?.id]);
    return (
        <>
            <div>
                <style jsx>{`
                    @import 'vars';
                    @import 'utils';

                    .header {
                        margin: grid(2.5) 0 grid(4) 0;
                    }

                    .account-wrapper {
                        flex: 1;
                        background-color: $color-white;
                        border-radius: grid(1/2);
                        border: 1px solid $color-border;
                        margin-right: grid(3);
                        // Fixed height for align with moving today panel
                        height: 405px;
                        @media (prefers-color-scheme: dark) {
                            background-color: darken($color-white, 80%);
                        }
                        @media (max-width: $sm-min) {
                            margin-right: 0;
                        }
                    }
                    .divider {
                        border-top: 1px solid $color-border;
                    }
                    .text-align-start {
                        text-align: start;
                    }
                    .accreditations-wrapper {
                        margin: grid(4);
                        @media (max-width: $lg-min) {
                            margin: grid(1);
                        }
                    }
                `}</style>
                <h2 data-cy="account-profile" className="header">
                    Profile
                </h2>
                <div className="account-wrapper">
                    <div className="flex-center-row flex-around p-r-16 p-v-20">
                        {currentAccount && <AccountCard account={currentAccount} />}
                        <ContextMenu ref={contextMenuRef} options={getAccountList()} icon={<ChevronDownIcon />} fixedWidth="250px" />
                    </div>
                    <div className="divider"></div>
                    <div className="accreditations-wrapper flex-column column">
                        {user.claims?.auth0Id?.length === 0 ? (
                            <h4 className="text-error">{FETCH_USER_DETAILS_ERROR_MSG} </h4>
                        ) : (
                            <>
                                <div className="title text-align-start" data-cy="programs-label">
                                    Registered Programs:
                                </div>
                                <div className="flex-center-row row m-t-12 text-align-start" data-cy="programs">
                                    {programs}
                                </div>
                                {accreditationStatus && (
                                    <div className="flex-center-row row m-t-12 text-align-start">
                                        {currentAccount && <AccreditationStatusModal account={currentAccount} accreditationStatus={accreditationStatus} />}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default AccountSelector;
