import { useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import FeedbackButton from '@components/FeedbackButton';
import Config from '@config';
import useBrowserDetection from '@effects/useBrowserDetection';
import useWindowTitle from '@effects/useWindowTitle';
import { MOBILE_OR_TABLET_WIDTH } from '@utils/constants';
import classnames from 'classnames';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import { graphql, useQuery } from 'relay-hooks';

import ConsignmentAddSelectTemplateRow from './components/ConsignmentAddSelectTemplateRow';

const ConsignmentAddSelectQuery = graphql`
    query ConsignmentAddSelectPagedQuery($envdAccountId: String!, $count: Int!, $cursor: String, $searchText: String, $species: ConsignmentSpecies) {
        templates(envdAccountId: $envdAccountId, first: $count, after: $cursor, searchText: $searchText, species: $species) @connection(key: "ConsignmentAddSelect_templates") {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    id
                    ...ConsignmentAddSelectTemplateRow_template
                }
            }
        }
    }
`;

interface Props {
    templates: any;
}

const ConsignmentAddSelectPage: React.FC<Props> = ({ templates }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MOBILE_OR_TABLET_WIDTH });
    const isIE = useBrowserDetection();
    const history = useHistory();

    const areTemplatesAvailable = Array.isArray(templates);

    return (
        <div className="ConsignmentAddSelect">
            <FeedbackButton url={Config.ISC_CUSTOMERFEEDBACK_URL} />
            <style jsx>{`
                @import 'vars';
                @import 'mixins';
                @import 'utils';

                .ConsignmentAddSelect {
                }

                .ContentBox {
                    display: flex;
                    border: 1px solid $color-border;
                    border-radius: $border-radius;
                    background-color: $color-white;

                    @media (max-width: $md-max) {
                        background-color: transparent;
                        border: none;
                        flex-direction: column;
                        padding: grid(2) 0;
                    }

                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }
                }

                .Column {
                    flex: 1 0 33%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    padding: grid(8);

                    p {
                        margin: grid(8) 0;
                    }

                    @media (max-width: $md-max) {
                        border: none;
                        border-radius: $border-radius;
                        background-color: transparent;
                        margin: grid(4) 0;
                        padding: grid(4) 0;

                        @media (prefers-color-scheme: dark) {
                            border: none;
                            border-radius: $border-radius;
                        }
                    }
                }

                .Left {
                    @extend .Column;

                    border-right: 1px solid $color-border;

                    @media (max-width: $md-max) {
                        border-right: none;
                    }

                    &--IE {
                        p {
                            // IE11 Fix
                            width: 100%;
                        }

                        @media (max-width: $md-max) {
                            // IE11 Fix
                            min-height: 230px;
                        }
                    }
                }

                .Right {
                    @extend .Column;

                    :global(.tbl) {
                        margin-top: grid(8);
                    }

                    &--Footer {
                        margin-top: grid(6);
                        align-self: center;
                    }

                    &--IE {
                        :global(.tbl) {
                            // IE11 Fix
                            width: 85%;
                        }

                        p {
                            // IE11 Fix
                            width: 85%;
                        }

                        @media (max-width: $md-max) {
                            :global(.tbl) {
                                // IE11
                                width: 100%;
                            }
                        }
                    }

                    @media (max-width: $md-max) {
                        background-color: transparent;
                        border: none;
                        padding: grid(6) 0 0;

                        :global(.tbl) {
                            background: transparent;
                            border: none;
                            margin-top: grid(2);
                        }

                        :global(.tbl-row) {
                            background: $color-white;
                            margin: grid(4) 0;
                            border: 1px solid $color-border;
                            border-radius: $border-radius;

                            @media (prefers-color-scheme: dark) {
                                background-color: darken($color-white, 80%);
                            }
                        }
                    }

                    &--Bigger {
                        flex: 2 0 66%;
                    }
                }
            `}</style>
            <div className="title-section">
                <h1>What would you like to do?</h1>
            </div>
            <div className="ContentBox">
                <div className={classnames('Left', { 'Left--IE': isIE })}>
                    <h2>Create from scratch</h2>
                    <p>Creating from scratch allows you to select and fill in the forms you need. This is useful for creating your first digital consignment or a one-off consignment.</p>
                    <Button
                        buttonType="primary"
                        fullWidth={isTabletOrMobile}
                        buttonSize={isTabletOrMobile ? 'small' : 'normal'}
                        onClick={() => history.push('/consignments/add')}
                        data-cy="create-scrach"
                    >
                        Create from scratch
                    </Button>
                </div>
                <div className={classnames('Right', { 'Right--Bigger': areTemplatesAvailable, 'Right--IE': isIE })}>
                    <h2>Use an existing template</h2>
                    {!areTemplatesAvailable || templates.length === 0 ? (
                        <p>
                            <strong>You have not created a template yet.</strong>
                            <br />
                            <br />
                            A template allows you to create a new consignment quickly and easily using your answers from a previous consignment. These are great for when you are sending similar
                            consignments on a regular basis whilst still allowing you to change key pieces of information if needed.
                            <br />
                            <br />
                            Templates can be saved after creating a new consignment, or by clicking the options button next to an consignment on the{' '}
                            <Button buttonType="link" onClick={() => history.push('/consignments')}>
                                consignment listing page
                            </Button>
                            .
                        </p>
                    ) : null}
                    {areTemplatesAvailable && templates.length > 0 && (
                        <>
                            <div className="tbl tbl-collapse">
                                <div className="tbl-row row-header">
                                    <div className="tbl-cell-40 column-heading">Template</div>
                                    <div className="tbl-cell-15 column-heading">Species</div>
                                    <div className="tbl-cell-25 column-heading">Forms</div>
                                    <div className="tbl-cell-20 column-heading" />
                                </div>
                                {templates.map((template: any) => (
                                    <ConsignmentAddSelectTemplateRow template={template} key={template.id} />
                                ))}
                            </div>
                            <div className="Right--Footer">
                                <Button buttonType="link" onClick={() => history.push('/templates')}>
                                    View more templates
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const ConsignmentAddSelect: React.FC = () => {
    useWindowTitle('Select add method');
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;

    const { data } = useQuery<any>(
        ConsignmentAddSelectQuery,
        {
            envdAccountId,
            count: 3,
        },
        { fetchPolicy: 'network-only' }
    );

    return <ConsignmentAddSelectPage templates={data?.templates?.edges?.map((c: any) => c && c.node).coalesce()} />;
};

export default ConsignmentAddSelect;
