/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ConsignmentTypeEnum = "CONSIGNMENT" | "TEMPLATE" | "%future added value";
export type CopyConsignmentInput = {
    clientMutationId?: string | null;
    number: string;
    vendorTrackingCode?: string | null;
    type?: ConsignmentTypeEnum | null;
    origin?: EntityInput | null;
    destination?: EntityInput | null;
    consignee?: EntityInput | null;
    owner?: EntityInput | null;
    movementDate?: unknown | null;
    movementTime?: string | null;
    referenceNumber?: string | null;
    name?: string | null;
};
export type EntityInput = {
    name?: string | null;
    pic?: string | null;
    address?: PropertyAddressInput | null;
};
export type PropertyAddressInput = {
    line1?: string | null;
    town?: string | null;
    state?: string | null;
    postcode?: string | null;
};
export type copyConsignmentMutationVariables = {
    input: CopyConsignmentInput;
    envdAccountId: string;
};
export type copyConsignmentMutationResponse = {
    readonly copyConsignment: {
        readonly data: {
            readonly id: string;
            readonly number: string | null;
        } | null;
    } | null;
};
export type copyConsignmentMutation = {
    readonly response: copyConsignmentMutationResponse;
    readonly variables: copyConsignmentMutationVariables;
};



/*
mutation copyConsignmentMutation(
  $input: CopyConsignmentInput!
  $envdAccountId: String!
) {
  copyConsignment(input: $input, envdAccountId: $envdAccountId) {
    data {
      id
      number
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envdAccountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CopyConsignment",
    "kind": "LinkedField",
    "name": "copyConsignment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ConsignmentType",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "number",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "copyConsignmentMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "copyConsignmentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0abe87c19bc6fcabe2c3a008cffa7f3c",
    "id": null,
    "metadata": {},
    "name": "copyConsignmentMutation",
    "operationKind": "mutation",
    "text": "mutation copyConsignmentMutation(\n  $input: CopyConsignmentInput!\n  $envdAccountId: String!\n) {\n  copyConsignment(input: $input, envdAccountId: $envdAccountId) {\n    data {\n      id\n      number\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '70ade58031e6bb90c30220fb3967fb80';
export default node;
