import Popover from '@components/Form/Popover';
import classnames from 'classnames';
import React, { useState } from 'react';

interface IMonth {
    name: string;
    value: number;
    selected: boolean;
}

export const MONTHS: IMonth[] = [
    { name: 'Jan', value: 1, selected: false },
    { name: 'Feb', value: 2, selected: false },
    { name: 'Mar', value: 3, selected: false },
    { name: 'Apr', value: 4, selected: false },
    { name: 'May', value: 5, selected: false },
    { name: 'Jun', value: 6, selected: false },
    { name: 'Jul', value: 7, selected: false },
    { name: 'Aug', value: 8, selected: false },
    { name: 'Sep', value: 9, selected: false },
    { name: 'Oct', value: 10, selected: false },
    { name: 'Nov', value: 11, selected: false },
    { name: 'Dec', value: 12, selected: false },
];

export const numberToMonthString = (n: number): string => {
    const found = MONTHS.firstOrDefault((m) => m.value === n)?.name;

    if (!found) return '';

    return found;
};

interface Props {
    onSelect: (month: number) => void;
    initialValue?: number;
}

interface CellProps extends Props {
    item: IMonth;
}

const Cell: React.FC<CellProps> = ({ onSelect, item }) => {
    return (
        <div className={classnames('MonthPicker--Cell', { 'MonthPicker--Cell-Selected': item.selected })} onClick={() => onSelect(item.selected ? -1 : item.value)}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .MonthPicker--Cell {
                    width: 32%;
                    text-align: center;
                    padding: grid(2);
                    cursor: pointer;

                    &-Selected {
                        border-radius: $border-radius;
                        color: $color-white;
                        background-color: $color-primary;
                    }

                    @media (max-width: $sm-max) {
                        width: 45%;
                    }
                }
            `}</style>
            {item.name}
        </div>
    );
};

const MonthPicker: React.FC<Props> = ({ onSelect, initialValue }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<number>(initialValue ?? -1);
    return (
        <>
            <style jsx>{`
                .MonthPicker {
                    &--Grid {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                    }
                }
            `}</style>
            <input onClick={() => setOpen((s) => !s)} value={selectedValue > 0 ? MONTHS[selectedValue - 1].name : ''} readOnly />
            {open && (
                <>
                    <Popover>
                        <div className="MonthPicker--Grid">
                            {MONTHS.map((month) => (
                                <Cell
                                    key={month.name}
                                    onSelect={(value: number) => {
                                        setSelectedValue(value);
                                        onSelect(value);
                                        setOpen(false);
                                    }}
                                    item={{ ...month, selected: selectedValue === month.value }}
                                />
                            ))}
                        </div>
                    </Popover>
                </>
            )}
        </>
    );
};

export default MonthPicker;
