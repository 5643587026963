import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useUserState } from '@common/context/userContext';
import Header from '@components/Header';
import Loader from '@components/Loader';
import useUserDetails from '@effects/useUserdetails';
import { AccountTypeEnum } from '@utils/enums';
import axios from 'axios';
import { ComponentType, FC, useEffect } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';

export const ProtectedRoute = ({ component, path, ...args }: RouteProps) => {
    const RegisteredComponent = withUserDetails(component as ComponentType, path);
    const MyProtectedComponent = withAuthenticationRequired(RegisteredComponent as ComponentType, {
        onRedirecting: () => (
            <>
                <Loader isLoading={true} error={false} retry={() => null} timedOut={false} pastDelay={false} />
            </>
        ),
        returnTo: args.location?.pathname,
    });
    return <Route component={MyProtectedComponent} {...args} />;
};

export const AdminProtectedRoute = ({ component, path, ...args }: RouteProps) => {
    const [{ user }] = useUserState();
    if (user?.claims?.accounts?.some((acc) => acc.accountType === AccountTypeEnum.ADMIN)) return <ProtectedRoute component={component} {...args} />;
    else {
        return <Redirect to="/" />;
    }
};

const withUserDetails = <P extends object>(Component: ComponentType<P>, path: any): FC<P> => {
    return function WithUserDetails(props: P): JSX.Element {
        const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
        const { isLoading, isNewUser, fetchUserDetails } = useUserDetails();
        const [{ user }, dispatch] = useUserState();
        const history = useHistory();

        if (isNewUser || user?.claims?.isNewUser) {
            if (path !== '/register') history.push('/register');
        }
        const isRegisterPage = window.location.pathname === '/register';

        useEffect(() => {
            if (isLoading) {
                return;
            }
            (async (): Promise<void> => {
                var accessToken;
                try {
                    accessToken = await getAccessTokenSilently();
                } catch (e) {
                    console.error('Something is wrong. no access token returned.');
                    logout({ logoutParams: { returnTo: window.location.origin + '/logout' } });
                }

                if (!accessToken) {
                    console.error('no access token returned.');
                    logout({ logoutParams: { returnTo: window.location.origin + '/logout' } });
                }

                if (accessToken) {
                    if (accessToken !== user?.token) {
                        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                        // Signin call
                        await fetchUserDetails(accessToken);
                    }
                }

                if (!isLoading && isNewUser) {
                    if (path !== '/register') history.push('/register');
                }
            })();
        }, [isLoading, fetchUserDetails, isNewUser, history, user?.token, dispatch, getAccessTokenSilently, logout]);

        return isLoading || (isAuthenticated && !user.token) ? (
            <Loader isLoading={true} error={false} retry={() => null} timedOut={false} pastDelay={false} />
        ) : (
            <>
                {!isRegisterPage && <Header />}
                <div className={!isRegisterPage ? 'container' : ''}>
                    <Component {...props} />
                </div>
                <style jsx global>
                    {`
                        .container {
                            margin-top: 50px;
                        }
                    `}
                </style>
            </>
        );
    };
};
