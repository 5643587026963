/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConsignmentSpecies = "BOBBY_CALVES" | "CATTLE" | "GOAT" | "SHEEP_LAMB" | "%future added value";
export type TemplateListFragmentPagedQueryVariables = {
    envdAccountId: string;
    count: number;
    cursor?: string | null;
    searchText?: string | null;
    species?: ConsignmentSpecies | null;
};
export type TemplateListFragmentPagedQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"TemplateListFragment_query">;
};
export type TemplateListFragmentPagedQuery = {
    readonly response: TemplateListFragmentPagedQueryResponse;
    readonly variables: TemplateListFragmentPagedQueryVariables;
};



/*
query TemplateListFragmentPagedQuery(
  $envdAccountId: String!
  $count: Int!
  $cursor: String
  $searchText: String
  $species: ConsignmentSpecies
) {
  ...TemplateListFragment_query
}

fragment TemplateListFragment_query on Query {
  templates(envdAccountId: $envdAccountId, first: $count, after: $cursor, searchText: $searchText, species: $species) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        ...TemplateRow_template
        updatedAt
        forms {
          type
        }
        __typename
      }
    }
  }
}

fragment TemplateRow_template on Template {
  id
  name
  number
  species
  origin {
    name
  }
  destination {
    name
  }
  forms {
    type
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envdAccountId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchText"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "species"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "envdAccountId",
    "variableName": "envdAccountId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "searchText",
    "variableName": "searchText"
  },
  {
    "kind": "Variable",
    "name": "species",
    "variableName": "species"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TemplateListFragmentPagedQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TemplateListFragment_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "TemplateListFragmentPagedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "TemplateConnection",
        "kind": "LinkedField",
        "name": "templates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Template",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "species",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TemplateOrigin",
                    "kind": "LinkedField",
                    "name": "origin",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TemplateDestination",
                    "kind": "LinkedField",
                    "name": "destination",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FormType",
                    "kind": "LinkedField",
                    "name": "forms",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [
          "envdAccountId",
          "searchText",
          "species"
        ],
        "handle": "connection",
        "key": "TemplateListFragment_templates",
        "kind": "LinkedHandle",
        "name": "templates"
      }
    ]
  },
  "params": {
    "cacheID": "c747b8d53fdfde0c3997097229eddfe4",
    "id": null,
    "metadata": {},
    "name": "TemplateListFragmentPagedQuery",
    "operationKind": "query",
    "text": "query TemplateListFragmentPagedQuery(\n  $envdAccountId: String!\n  $count: Int!\n  $cursor: String\n  $searchText: String\n  $species: ConsignmentSpecies\n) {\n  ...TemplateListFragment_query\n}\n\nfragment TemplateListFragment_query on Query {\n  templates(envdAccountId: $envdAccountId, first: $count, after: $cursor, searchText: $searchText, species: $species) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      cursor\n      node {\n        id\n        ...TemplateRow_template\n        updatedAt\n        forms {\n          type\n        }\n        __typename\n      }\n    }\n  }\n}\n\nfragment TemplateRow_template on Template {\n  id\n  name\n  number\n  species\n  origin {\n    name\n  }\n  destination {\n    name\n  }\n  forms {\n    type\n  }\n}\n"
  }
};
})();
(node as any).hash = '892fd238109a7f69a8e16e6b0294311c';
export default node;
