/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateCommentInput = {
    clientMutationId?: string | null;
    comment?: string | null;
    consignmentNumber?: string | null;
};
export type createConsignmentCommentMutationVariables = {
    input: CreateCommentInput;
    envdAccountId: string;
};
export type createConsignmentCommentMutationResponse = {
    readonly createComment: {
        readonly data: {
            readonly consignmentNumber: string;
            readonly commentText: string;
            readonly commentId: number;
            readonly firstName: string;
            readonly lastName: string;
            readonly role: string;
            readonly createdAt: unknown;
        } | null;
    } | null;
};
export type createConsignmentCommentMutation = {
    readonly response: createConsignmentCommentMutationResponse;
    readonly variables: createConsignmentCommentMutationVariables;
};



/*
mutation createConsignmentCommentMutation(
  $input: CreateCommentInput!
  $envdAccountId: String!
) {
  createComment(input: $input, envdAccountId: $envdAccountId) {
    data {
      consignmentNumber
      commentText
      commentId
      firstName
      lastName
      role
      createdAt
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envdAccountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateComment",
    "kind": "LinkedField",
    "name": "createComment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CommentType",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "consignmentNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "commentText",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "commentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createConsignmentCommentMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createConsignmentCommentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e1e29c3eb42f97b1a01d43a62e41d35c",
    "id": null,
    "metadata": {},
    "name": "createConsignmentCommentMutation",
    "operationKind": "mutation",
    "text": "mutation createConsignmentCommentMutation(\n  $input: CreateCommentInput!\n  $envdAccountId: String!\n) {\n  createComment(input: $input, envdAccountId: $envdAccountId) {\n    data {\n      consignmentNumber\n      commentText\n      commentId\n      firstName\n      lastName\n      role\n      createdAt\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fbc137e19249cb228621a6f44ec3ef5b';
export default node;
