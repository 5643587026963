import CheckboxInput, { Size } from '@components/Form/CheckboxInput';
import FormRow, { HelperLocation } from '@components/Form/FormRow';
import { SelectableListItemProps } from '@components/Form/SelectableList';
import classnames from 'classnames';
import React from 'react';

const CheckboxList: React.FunctionComponent<SelectableListItemProps> = ({ items, errorText, labelText, helperText, isValid, onChange, isReadOnly, required }) => {
    if (isReadOnly && !items.some((i) => i.selected)) {
        return null;
    }

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                .Chk-Item {
                    display: flex;
                    max-width: $form-input-maxwidth;
                    margin-bottom: grid(2);

                    padding: grid(2) 0;
                    align-items: start;
                    user-select: none;
                    cursor: pointer;
                    transition: border 300ms ease-out;

                    &--Active {
                        border-color: $color-primary;
                    }

                    > img {
                        width: grid(6);
                        height: grid(6);
                        margin: 0 grid(2) 0 grid(2);
                    }

                    &--ReadOnly {
                        cursor: not-allowed;
                    }
                }

                ul {
                    margin: 0;
                }
            `}</style>

            <FormRow errorText={errorText} errorTextAtTop isValid={isValid} helperText={helperText} labelText={labelText} helperLocation={HelperLocation.Top} readOnly={isReadOnly} required={required}>
                {isReadOnly ? (
                    <ul>
                        {items
                            .filter((i) => i.selected)
                            .map((i) => (
                                <li key={i.id}>{i.title}</li>
                            ))}
                    </ul>
                ) : (
                    <div className="Chk--Group">
                        {items.map((item) => {
                            const isExclusive = Boolean(item.exclusive && items.filter((i) => i.selected && i.id !== item.id).some((x) => item.exclusive?.includes(x.id)));

                            return (
                                <label
                                    className={classnames('Chk-Item', {
                                        'Chk-Item--Active': item.selected,
                                        'Chk-Item--ReadOnly': isReadOnly,
                                    })}
                                    key={item.id}
                                >
                                    {!isReadOnly && !isExclusive && <CheckboxInput size={Size.md} checked={item.selected || false} onChange={() => onChange(item)} containerClass={'m-r-8'} />}
                                    {isExclusive && <CheckboxInput size={Size.md} checked={item.selected || false} containerClass={'m-r-8'} disabled />}
                                    {item.image && <img src={item.image} alt={''} />}
                                    <div className="m-l-8">
                                        <div className="small">{item.title}</div>
                                        {item.subtitle && <div className="helper muted m-t-4">{item.subtitle}</div>}
                                    </div>
                                </label>
                            );
                        })}
                    </div>
                )}
            </FormRow>
        </>
    );
};

export default CheckboxList;
