import { useUserState } from '@common/context/userContext';
import { createEnvironment } from '@common/relay/environment';
import Checkbox from '@components/Form/Checkbox';
import UserSelect from '@components/UserSelect';
import PICSearch from '@containers/Consignments/components/fragments/PICSearch';
import { TAirtableConsignment } from '@containers/Consignments/ConsignmentEdit';
import { actionTypes, useGlobalState } from '@state';
import { IPerson, TPersonType } from '@typings';
import { getField, setValueToField } from '@utils/data-manipulator';
import { readOnlyWhenSubmittedV4 } from '@utils/question-editable';
import React, { useCallback, useEffect, useState } from 'react';
import { RelayEnvironmentProvider } from 'relay-hooks';

import { IStaticQuestionProps } from './StaticQuestion';

const isEqualPerson = (a: any, b: any) => {
    if ((!a && b) || (!b && a) || (!a && !b)) {
        return false;
    }
    return a.name === b.name && a.pic === b.pic && a.address.line1 === b.address.line1;
};

const StaticEntityQuestion: React.FC<IStaticQuestionProps> = ({ question, data }) => {
    const [{ staticPendingAnswers }, dispatch] = useGlobalState();
    const [{ user }] = useUserState();
    const isReadOnly = readOnlyWhenSubmittedV4(data, user);

    const [answer, setAnswer] = useState<any>(getField<any, TAirtableConsignment>(question.field, staticPendingAnswers.answers, null));
    useEffect(() => setAnswer(getField<any, TAirtableConsignment>(question.field, staticPendingAnswers.answers, null)), [question.field, staticPendingAnswers.answers, question]);

    const [otherField, setOtherField] = useState(question.field === 'owner' ? 'origin' : question.field === 'consignee' ? 'destination' : '');
    useEffect(() => setOtherField(question.field === 'owner' ? 'origin' : question.field === 'consignee' ? 'destination' : ''), [question.field]);

    const [other, setOther] = useState<any>(getField<any, TAirtableConsignment>(otherField, staticPendingAnswers.answers, null));
    useEffect(() => setOther(getField<any, TAirtableConsignment>(otherField, staticPendingAnswers.answers, null)), [staticPendingAnswers.answers, otherField]);

    // TODO: Check type of person (TPersonType) since we've changed to origin
    const [isOwner, setIsOwner] = useState(question.field === 'owner' ? isEqualPerson(answer, other) : false);
    useEffect(() => setIsOwner(question.field === 'owner' ? isEqualPerson(answer, other) : false), [question.field, answer, other]);

    const [isSameDestination, setIsSameDestination] = useState(question.field === 'consignee' ? isEqualPerson(answer, other) : false);
    useEffect(() => setIsSameDestination(question.field === 'consignee' ? isEqualPerson(answer, other) : false), [question.field, answer, other]);

    const [showUserSelect, setShowUserSelect] = useState(!isOwner && !isSameDestination);
    useEffect(() => setShowUserSelect(!isOwner && !isSameDestination), [isOwner, isSameDestination]);

    const searchMode = question.field === 'owner' ? 'OWNER' : question.field === 'destination' ? 'DESTINATION' : 'DEFAULT';

    const [person, setPerson] = useState<IPerson | null>(
        answer
            ? {
                  businessName: answer.name,
                  pic: answer.pic,
                  locationAddress: answer.address.line1,
                  locationState: answer.address.state,
                  locationTown: answer.address.town,
                  locationPostcode: answer.address.postcode,
                  type: question.field.toLocaleUpperCase() as TPersonType,
              }
            : null
    );

    const savePerson = useCallback(
        (p: IPerson | null) => {
            // Save to local state
            setPerson(p);

            // Save to global state
            const output = {
                name: p?.businessName,
                pic: p?.pic,
                address: {
                    line1: p?.locationAddress,
                    town: p?.locationTown,
                    state: p?.locationState,
                    postcode: p?.locationPostcode,
                },
            };

            dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: setValueToField(question.field, output) });
        },
        [dispatch, question.field]
    );

    useEffect(
        () =>
            setPerson(
                answer
                    ? {
                          businessName: answer.name,
                          pic: answer.pic,
                          locationAddress: answer.address.line1,
                          locationState: answer.address.state,
                          locationTown: answer.address.town,
                          locationPostcode: answer.address.postcode,
                          type: question.field.toLocaleUpperCase() as TPersonType,
                      }
                    : null
            ),
        [answer, question.field]
    );

    return (
        <>
            <h3 className="m-b-16">{question.text}</h3>

            {(question.field === 'owner' || question.field === 'consignee') && (
                <Checkbox
                    optionText={
                        question.field === 'owner'
                            ? `They are owned by Myself (${user.accountDetails.entityName ?? user.accountDetails.id} ${user.accountDetails.pic})`
                            : 'Livestock are being consigned to the same location'
                    }
                    checked={isOwner || isSameDestination}
                    isReadOnly={isReadOnly}
                    onChange={() => {
                        // We would need to change 'other' to match this if selected (false to true), if from (true to false), we need to null 'other'
                        if (question.field === 'owner') {
                            dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: setValueToField(question.field, isOwner ? null : other) });
                        }

                        if (question.field === 'consignee') {
                            // Change consignee to destination if selected
                            dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: setValueToField(question.field, isSameDestination ? null : other) });
                        }
                    }}
                />
            )}

            {person ? (
                <>
                    {showUserSelect && (
                        <UserSelect
                            person={person}
                            onChange={(p) => {
                                // p is person
                                savePerson(p);
                            }}
                            onCancel={() => null}
                            indent={true}
                            isReadOnly={isReadOnly}
                        />
                    )}
                </>
            ) : (
                <RelayEnvironmentProvider environment={createEnvironment()}>
                    <PICSearch onSelected={savePerson} searchMode={searchMode} />
                </RelayEnvironmentProvider>
            )}
        </>
    );
};

export default StaticEntityQuestion;
