import React, { useState } from 'react';

const Navbar: React.FC = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [icon, setIcon] = useState('/img/bars-icon.svg');
    const navbarResponsive = () => {
        setShowMenu((showMenu) => !showMenu);
        setIcon(!showMenu ? '/img/close-icon.svg' : '/img/bars-icon.svg');
    };
    return (
        <>
            <div className={showMenu ? 'navbar responsive' : 'navbar'} id="myTopnav">
                <div className="logo-container">
                    <div className="isc-logo" />
                </div>
                <div className={showMenu ? 'header-navs responsive' : 'header-navs'}>
                    <div>
                        <a href="https://www.integritysystems.com.au/on-farm-assurance/national-vendor-declaration-nvd/">About eNVD</a>
                        <a href="https://www.integritysystems.com.au/on-farm-assurance/how-to-guides/how-to-create-eNVD/">STARTING WITH eNVD</a>
                    </div>
                    {showMenu ? (
                        <div className="mob-footer">
                            <div>
                                <h2>SUPPORT</h2>
                            </div>
                            <a href="https://www.integritysystems.com.au/on-farm-assurance/how-to-guides/how-to-create-a-new-mymla-account-and-link-your-integrity-system-accounts/">
                                myMLA How to guide
                            </a>
                            <a href="https://www.integritysystems.com.au/help/faqs/">FAQs</a>
                            <div className="gap">
                                <h2>CONTACT US</h2>
                            </div>
                            <a href="mailto:envd@integritysystems.com.au">envd@integritysystems.com.au </a>
                            <a href="tel:1800 683 111">1800 683 111</a>
                        </div>
                    ) : null}
                </div>
                <button className="icon" onClick={navbarResponsive}>
                    <img src={icon} alt="my MLA" />
                </button>
            </div>
            <style jsx>
                {`
                    @import 'mixins';
                    @import 'vars';
                    @import 'layout';

                    .navbar {
                        overflow: hidden;
                        display: flex;
                        justify-content: space-between;
                        background-color: $color-white;
                    }

                    .isc-logo {
                        height: calc(min(76px, 20vw));
                        aspect-ratio: 478/101;
                        background-image: url('/img/envd-header.png');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        border: none;
                        float: left;
                        display: block;
                    }

                    .logo-container a {
                        float: left;
                        display: block;
                        text-align: center;
                        padding: 14px 16px;
                        text-decoration: none;
                        font-size: 17px;
                    }

                    .header-navs {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    .header-navs a {
                        float: left;
                        display: block;
                        text-align: center;
                        padding: 14px 16px;
                        text-decoration: none;
                        font-size: 17px;
                        border-left: 1px solid $color-black;
                    }

                    .navbar a {
                        float: left;
                        display: block;
                        color: #003e6a;
                        text-align: center;
                        padding: 28px 20px;
                        text-decoration: none;
                        font-size: 14px;
                        text-decoration: underline;
                    }

                    .navbar a:hover {
                        background-color: $color-secondary-hover;
                    }

                    .navbar a.active {
                        color: white;
                    }

                    .navbar .icon {
                        display: none;
                    }

                    @media screen and (max-width: $md-min) {
                        .isc-logo {
                            height: calc(min(76px, 10vw));
                            aspect-ratio: 293/60;
                        }
                        .header-navs a {
                            font-size: 12px;
                            padding: 20px;
                        }
                    }

                    @media screen and (max-width: 600px) {
                        .isc-logo {
                            height: calc(min(79px, 20vw));
                            aspect-ratio: 193/120;
                            background-image: url('/img/envd-header-mob.svg');
                        }

                        .header-navs a {
                            display: none;
                            border: none;
                        }

                        .header-navs {
                            display: block;
                        }

                        .header-navs.responsive {
                            display: block;
                            position: fixed;
                            z-index: 1000;
                            top: 74px;
                            bottom: 151px;
                            background-color: $color-white;
                            width: 100%;
                        }

                        .navbar button.icon {
                            float: right;
                            display: block;
                            padding: 24px 20px;
                            background-color: white;
                            border: none;
                        }

                        .navbar {
                            overflow: hidden;
                            display: block;
                        }

                        .navbar.responsive {
                            position: relative;
                        }

                        .navbar.responsive .icon {
                            position: absolute;
                            right: 0;
                            top: 0;
                        }

                        .navbar.responsive a {
                            float: none;
                            display: block;
                            text-align: center;
                            text-decoration: underline;
                            height: calc(min(91px, 20vw));
                            padding: 28px 20px;
                            color: #003e6a;
                            font-size: 20px;
                        }

                        .gap {
                            margin-top: 30px;
                        }

                        .mob-footer {
                            position: fixed;
                            padding: 25px;
                            background-color: $isc-blue;
                            top: 240px;
                            bottom: 151px;
                            left: 0px;
                            right: 0px;
                            color: $color-white;
                        }

                        .mob-footer a {
                            width: 70vw;
                            text-align: left !important;
                            padding: 10px 0px !important;
                            height: calc(min(91px, 10vw)) !important;
                            color: $color-white !important;
                            font-size: 11px !important;
                            text-decoration: none !important;
                            border-bottom: 1px solid $color-white;
                        }
                    }
                `}
            </style>
        </>
    );
};

export default Navbar;
