import { useUserState } from '@common/context/userContext';
import Loader from '@components/Loader';
import { ConsignmentEditPage } from '@containers/Consignments/ConsignmentEdit';
import { TemplateEditQuery } from '@containers/Consignments/queries/__generated__/TemplateEditQuery.graphql';
import { TemplateEditGraphQLQuery } from '@containers/Consignments/queries/TemplateEdit';
import useWindowTitle from '@effects/useWindowTitle';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'relay-hooks';

const TemplateEdit: React.FC = () => {
    const { id } = useParams<any>();
    const history = useHistory();
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;
    const { data } = useQuery<TemplateEditQuery>(TemplateEditGraphQLQuery, { id, envdAccountId }, { fetchPolicy: 'network-only' });

    useWindowTitle('Edit Template');

    if (data) {
        const { template } = data;

        // Save forms to the reducer
        if (template) {
            return <ConsignmentEditPage consignment={template} />;
        } else {
            history.push('/templates');
            return null;
        }
    } else {
        return <Loader error={''} isLoading pastDelay={false} timedOut={false} retry={() => null} />;
    }
};

export default TemplateEdit;
