import React, { useCallback } from 'react';

import Checkbox from './Checkbox';

export interface IFilterMenuOption {
    id: string;
    title: string;
    selected: boolean;
    // disabled?: boolean;
    customRender?: {
        render: () => React.ReactNode;
        isConditional: boolean;
    };
}

export interface IFilterGroupMenuGroup {
    title: string;
    selectionType: 'single' | 'multiple';
    options: IFilterMenuOption[];
}

interface Props {
    groups: IFilterGroupMenuGroup[];
    onUpdate: (groups: IFilterGroupMenuGroup[]) => void;
}

const FilterMenuOptions: React.FC<Props> = ({ groups, onUpdate }) => {
    const update = useCallback(
        (group: IFilterGroupMenuGroup, option: IFilterMenuOption) => {
            const updatedGroups = groups.map((g) => {
                if (g.title === group.title) {
                    return {
                        ...g,
                        options: g.options.map((o) => (o.id === option.id ? { ...option, selected: !option.selected } : { ...o, selected: group.selectionType === 'single' ? false : o.selected })),
                    };
                }

                return g;
            });
            onUpdate(updatedGroups);
        },
        [groups, onUpdate]
    );

    return (
        <div className="FilterMenuOptions">
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                h4 {
                    margin-bottom: grid(4);
                }
            `}</style>
            {groups.map((group) => (
                <div key={group.title} className={'filter-group'}>
                    <h4 key={group.title}>{group.title}</h4>
                    {group.options.map((option) => {
                        if (option.customRender) {
                            return option.customRender.isConditional ? (
                                <React.Fragment key={option.id}>
                                    <Checkbox optionText={option.title} checked={option.selected} onChange={() => update(group, option)} />
                                    {option.selected && option.customRender.render()}
                                </React.Fragment>
                            ) : (
                                option.customRender.render()
                            );
                        }
                        return <Checkbox key={option.id} optionText={option.title} checked={option.selected} onChange={() => update(group, option)} />;
                    })}
                </div>
            ))}
        </div>
    );
};

export default FilterMenuOptions;
