import { useUserState } from '@common/context/userContext';
import Accordion from '@components/Form/Accordion';
import Checkbox from '@components/Form/Checkbox';
import useValidation from '@effects/useValidation';
import { actionTypes, useGlobalState } from '@state';
import { setValueToField } from '@utils/data-manipulator';
import { readOnlyWhenSubmittedV4 } from '@utils/question-editable';
import { useGetStaticAnswer } from '@utils/question-getter';
import { getIsRequired } from '@utils/question-validator';
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

import { IStaticQuestionProps } from './StaticQuestion';

const formProgramToAlias = (program: string) => {
    let ret = '';

    if (program.startsWith('LPA')) {
        ret = 'NVD';
    } else if (program.startsWith('EU')) {
        ret = 'EU NVD';
    } else if (program.startsWith('NFAS')) {
        ret = 'NFAS ';
        if (program.startsWith('NFASDD')) {
            ret += 'DD';
        } else if (program.startsWith('NFASEU')) {
            ret += 'EU GF HQB';
        } else {
            ret += 'Form B';
        }
    } else if (program.startsWith('HS')) {
        ret = 'Health Declaration';
    } else if (program.startsWith('MSA')) {
        ret = 'MSA';
    }

    return ret;
};

const StaticBooleanQuestion: React.FC<IStaticQuestionProps> = ({ question, data }) => {
    const answer = useGetStaticAnswer(question, data)?.toString();
    const [{ triggerValidatorCount, copyErrors }, dispatch] = useGlobalState();
    const [{ user }] = useUserState();
    const { isValid, checkIsValid, evaluated, resetIsValid, errorMessage } = useValidation();

    useEffect(() => {
        if (copyErrors.isCopy) {
            checkIsValid(question, answer);
        }
    });
    useEffect(() => {
        if (!evaluated && answer) {
            checkIsValid(question, answer);
        }
    }, [evaluated, answer, checkIsValid, question]);

    useEffect(() => {
        if (triggerValidatorCount > 0) {
            checkIsValid(question, answer);
        }
        if (triggerValidatorCount === 0 && !copyErrors.isCopy) {
            resetIsValid();
        }
    }, [triggerValidatorCount, checkIsValid, resetIsValid, question, answer, copyErrors.isCopy]);

    if (!question.field) {
        return null;
    }

    const isReadOnly = readOnlyWhenSubmittedV4(data, user);
    const isRequired = getIsRequired(question.validators);

    return (
        <>
            <Checkbox
                optionText={question.text}
                data-cy={question.field}
                helperText={''}
                checked={answer?.toLocaleLowerCase() === 'true'}
                onChange={(e) => {
                    if (copyErrors.isCopy) {
                        // clear all the copyerrors so copyerrors list can be populated with all the latest errors after change
                        dispatch({ type: actionTypes.copyErrors.clearErrors });
                    }
                    checkIsValid(question, e.target.checked ? 'True' : 'False');
                    dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: setValueToField(question.field, e.target.checked ? 'True' : 'False') });
                }}
                isValid={isValid}
                isReadOnly={isReadOnly}
                required={isRequired}
                errorText={errorMessage}
            />
            {question.options
                ?.filter((option) => data?.forms?.some((form: any) => form?.type === option.form.replace(/\./g, '')))
                .map((option) => (
                    <Accordion key={option.form} title={formProgramToAlias(option.form)} openText="View" small>
                        <ReactMarkdown source={option.text} />
                    </Accordion>
                ))}
        </>
    );
};

export default StaticBooleanQuestion;
