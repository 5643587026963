import { TAirtableConsignment } from '@containers/Consignments/ConsignmentEdit';
import { IStaticQuestion } from '@typings';
import React from 'react';

import StaticBooleanQuestion from './StaticBooleanQuestion';
import StaticCalculateQuestion from './StaticCalculateQuestion';
import StaticDateTimeQuestion from './StaticDateTimeQuestion';
import StaticEntityQuestion from './StaticEntityQuestion';
import StaticImageQuestion from './StaticImageQuestion';
import StaticOptionalMultipleChoiceQuestion from './StaticOptionalMultipleChoiceQuestion';
import StaticRequiredSingleChoiceQuestion from './StaticRequiredSingleChoiceQuestion';
import StaticSingleChoiceQuestion from './StaticSingleChoiceQuestion';
import StaticStringQuestion from './StaticStringQuestion';

export interface IStaticQuestionProps {
    question: IStaticQuestion;
    data: TAirtableConsignment;
}

const StaticQuestion: React.FC<IStaticQuestionProps> = (props) => {
    const { question } = props;

    // Hide question if forms is defined and said forms aren't selected
    if (question.forms && !question.forms.map((x) => x.program.replace(/\./g, '')).some((x) => props.data?.forms?.map((df: any) => df?.type).includes(x as any))) {
        return null;
    }

    // Hide question if this is a template and the question isn't meant to be displayed
    if (question.hiddenForTemplate && props.data ? 'name' in props.data : false) {
        return null;
    }

    const dataTestId = process.env.CI || process.env.NODE_ENV === 'test' ? question.text.replace(/[ ]/g, '-') : undefined;

    return (
        <div data-cy={dataTestId}>
            {question.type === 'Entity' && <StaticEntityQuestion {...props} />}
            {question.type === 'Date' && <StaticDateTimeQuestion {...props} />}
            {question.type === 'Time' && <StaticDateTimeQuestion {...props} />}
            {question.type === 'RequiredSingleChoice' && <StaticRequiredSingleChoiceQuestion {...props} />}
            {question.type === 'OptionalMultipleChoice' && <StaticOptionalMultipleChoiceQuestion {...props} />}
            {question.type === 'String' && <StaticStringQuestion {...props} />}
            {question.type === 'SingleChoice' && <StaticSingleChoiceQuestion {...props} />}
            {question.type === 'Image' && <StaticImageQuestion {...props} />}
            {question.type === 'Boolean' && <StaticBooleanQuestion {...props} />}
            {question.type === 'Calculate' && <StaticCalculateQuestion {...props} />}
        </div>
    );
};

export default StaticQuestion;
