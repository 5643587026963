import classnames from 'classnames';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import FormErrorText from './FormErrorText';

export enum HelperLocation {
    Top,
    Bottom,
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    errorText?: string;
    labelText?: string;
    helperText?: string;
    isValid?: boolean;
    helperLocation?: HelperLocation;
    required?: boolean;
    readOnly?: boolean;
    errorTextAtTop?: boolean;
    small?: boolean;
    questionType?: string;
}

const FormRow: React.FunctionComponent<Props> = ({
    errorText,
    errorTextAtTop,
    labelText,
    helperText,
    isValid,
    helperLocation = HelperLocation.Bottom,
    required,
    className,
    children,
    readOnly,
    small,
    questionType,
}) => {
    const isError = isValid === false;
    const isSuccess = isValid === true;
    const labelClassName = questionType === 'FILE' ? 'label fileLabel' : 'label';

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .FormRow {
                    width: 100%;
                    margin-bottom: grid(4);

                    .required {
                        @include text-xsmall;
                        @include text-muted;
                        margin: grid(-1) 0 grid(4) 0;
                    }

                    &--error {
                        :global(label > *) {
                            @include text-error();
                        }
                    }

                    &--maxwidth {
                        max-width: $form-input-maxwidth;
                    }

                    &--readOnly {
                        margin-bottom: grid(8);
                    }

                    :global(p) {
                        margin-bottom: 0;
                    }
                    :global(.label) {
                        padding: 0 grid(8) grid(2) 0;
                        display: flex;
                        flex-direction: column;

                        :global(ul) {
                            margin-left: grid(4);
                            :global(li) {
                                @include text-regular();
                                @include text-h6();
                            }
                        }

                        :global(> *) {
                            display: inline-block;

                            margin: 0;

                            &:last-child {
                                margin-bottom: grid(1);
                            }
                        }
                        :global(&:empty) {
                            display: none;
                        }
                    }

                    :global(.fileLabel) {
                        width: 30%

                        :global(p) {
                            margin-left: grid(4);
                        }
                    }

                    :global(.conditional &) {
                        margin-bottom: 0;
                    }
                }
            `}</style>

            <div
                className={classnames('FormRow', className, {
                    'FormRow--error': isError,
                    'FormRow--success': isSuccess,
                    'FormRow--maxwidth': small,
                    'FormRow--readOnly': readOnly,
                    required,
                })}
            >
                {labelText && <ReactMarkdown className={labelClassName} source={labelText} />}
                {required && !isError && !readOnly && <div className="required">Required</div>}
                {helperText && !readOnly && helperLocation === HelperLocation.Top && <div className="helper muted m-b-16">{helperText}</div>}

                {errorTextAtTop && isError && <FormErrorText text={errorText || ''} />}
                {children}
                {!errorTextAtTop && isError && <FormErrorText text={errorText || ''} />}
            </div>
        </>
    );
};

export default FormRow;
