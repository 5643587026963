import * as React from 'react';
const SvgWarning = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props} viewBox="0 0 24 24">
        <g clipPath="url(#warning_svg__a)">
            <path fill="currentColor" d="M1 20h22L12 1zm12-3h-2v-2h2zm0-4h-2V9h2z" />
        </g>
        <defs>
            <clipPath id="warning_svg__a">
                <path fill="currentColor" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgWarning;
