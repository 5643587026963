import Input from '@components/Form/Input';
import useUpdateDynamicValue from '@effects/useUpdateDynamicValue';
import useValidation from '@effects/useValidation';
import { useGlobalState } from '@state';
import { NOTEDITABLE_WHEN_DECLARETICKED_IDS, TRANSPORTER_DECLARED_BOX_QUESTION_ID } from '@utils/constants';
import { ConsignmentStatus } from '@utils/enum-transformers';
import { useGetDynamicAnswers } from '@utils/question-getter';
import { getIsRequired } from '@utils/question-validator';
import { isAnyQuestionAnsweredInCurrentSection } from '@utils/section-mapper';
import React, { useEffect } from 'react';

import { IDynamicQuestionProps } from './DynamicQuestion';

const DynamicStringQuestion: React.FC<IDynamicQuestionProps> = ({ question, index }) => {
    // console.count(`[Rendering in DynamicStringQuestion: ${question.id}]`);

    const [{ triggerValidatorCount, originalAnswers, meta, copyErrors }] = useGlobalState();
    const update = useUpdateDynamicValue();
    const { isValid, checkIsValid, evaluated, errorMessage, resetIsValid } = useValidation();

    const isRequired = getIsRequired(question.validators);

    // Prefer pending if it exists, fallback to original if not
    const answers = useGetDynamicAnswers(question, index);
    const value = answers?.firstOrDefault((answer) => answer.questionId === question.id && answer.index === index)?.value ?? '';
    let isReadOnly = question.readOnly;

    if (
        NOTEDITABLE_WHEN_DECLARETICKED_IDS.includes(question.id) &&
        meta.status === ConsignmentStatus.SUBMITTED &&
        Boolean(originalAnswers.firstOrDefault((a) => a.questionId === TRANSPORTER_DECLARED_BOX_QUESTION_ID && a.index === index)?.value)
    ) {
        isReadOnly = true;
    }

    useEffect(() => {
        if (value === '' && copyErrors.isCopy && isAnyQuestionAnsweredInCurrentSection(question, meta, originalAnswers)) {
            checkIsValid(question, '');
        }
    });
    useEffect(() => {
        if (!evaluated && value) {
            checkIsValid(question, value);
        }
    }, [evaluated, checkIsValid, question, value]);

    useEffect(() => {
        if (triggerValidatorCount > 0) {
            checkIsValid(question, value);
        }
        if (triggerValidatorCount === 0 && !copyErrors.isCopy) {
            resetIsValid();
        }
    }, [triggerValidatorCount, checkIsValid, resetIsValid, question, value, copyErrors.isCopy]);

    return (
        <Input
            labelText={question.text}
            helperText={''}
            errorText={errorMessage}
            value={value}
            onChange={(e) => {
                checkIsValid(question, e.target.value, true);
                update(question, index, e.target.value);
            }}
            isValid={isValid}
            isReadOnly={isReadOnly}
            autoFocus={false}
            required={isRequired}
        />
    );
};

export default DynamicStringQuestion;
