import { useUserState } from '@common/context/userContext';
import React, { forwardRef, useState } from 'react';
import { useMutation } from 'relay-hooks';

import { ConsignmentTypeEnum } from '../mutations/__generated__/copyConsignmentMutation.graphql';
import deleteConsignment, { DeleteConsignmentMutation } from '../mutations/deleteConsignment';
import ConfirmModal, { ConfirmModalRef } from './ConfirmModal';

interface DeleteConsignmentModalProps {
    consignment: {
        id: string;
        type: ConsignmentTypeEnum;
        number: string;
    };
}

export const DeleteConsignmentModal = forwardRef(({ consignment }: DeleteConsignmentModalProps, ref: React.Ref<ConfirmModalRef | undefined | null>) => {
    const [warningMessage, setWarningMessage] = useState<string>();
    const [mutate] = useMutation(DeleteConsignmentMutation);
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;

    return (
        <ConfirmModal
            actions={[
                {
                    style: 'delete',
                    text: 'Delete',
                    action: async () => {
                        const response = await deleteConsignment(mutate, { id: consignment.number, storeId: consignment.id }, envdAccountId);
                        if (!response) {
                            console.error('[DeleteConsignmentModal] error deleting consignment', response);
                            setWarningMessage('An error occurred, changes may not be saved.');
                        } else {
                            setWarningMessage(undefined);
                        }
                    },
                },
            ]}
            ref={ref as any}
            modalId={`delete-modal-${consignment.number}`}
            title={`Are you sure you want to delete this ${consignment.type === 'CONSIGNMENT' ? 'consignment' : 'template'}?`}
            onCancel={() => setWarningMessage(undefined)}
            modalWarning={warningMessage}
        />
    );
});

export default DeleteConsignmentModal;
