import * as React from 'react';
const SvgLpa = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={26} fill="none" {...props} viewBox="0 0 24 26">
        <path
            fill="#1D2A33"
            d="M5.403 21.012c-.15 1.024-.29 1.952-.418 2.706-.273 1.757-1.364 2.375-2.407 2.27S.536 25.056.876 23.008c.123-.74.47-2.798.92-5.45 0 0 2.92 3.115 3.175 3.26q.196.127.417.205M14.058 13.887c1.353 2.663 2.444 4.813 2.92 5.72.751 1.41.196 2.355-.686 2.825-.883.469-2.086.464-2.844-.926-.35-.647-1.27-2.525-2.294-4.657.976-1.024 2.902-2.962 2.902-2.962M8.922 12.202c-.993-2.077-1.75-3.67-1.75-3.67s-.386 2.797-.858 6.133v.04l-.208.15-2.888-2.979s-.048-.143-.396-.289A1481 1481 0 0 1 4.597 1.411c.148-.82.384-1.153.956-1.346.638-.215 1.326.113 1.714.82 1.388 2.527 3.065 5.744 4.646 8.818z"
        />
        <path
            fill="#ED1C24"
            d="M.446 12.605a1.462 1.462 0 0 1 1.827-.178c.647.475 2.906 3.056 3.843 4.143C9.51 12.486 19.53 4.862 22.826 3.15a.814.814 0 0 1 1.084.274c.158.287.125.8-.24 1.006C18.58 7.262 9.276 17.203 7.213 19.693c-.684.83-1.919.336-2.234-.162-.524-.831-3.586-3.979-4.59-4.97a1.37 1.37 0 0 1-.388-.99 1.35 1.35 0 0 1 .45-.966"
        />
    </svg>
);
export default SvgLpa;
