import { NewWindow } from '@assets/icons';
import classnames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router';

type LinkType = 'internal' | 'external';

interface Props {
    type: LinkType;
    title?: string;
    path: string;
    preAction?: () => void;
    internal?: {
        isActive: boolean;
    };
    external?: {
        newTab: boolean;
        showIcon: boolean;
    };
}

const NavItem: React.FunctionComponent<Props> = ({ type, title, path, internal, external, preAction }) => {
    const history = useHistory();
    const titleText = title || path;

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                a {
                    text-decoration: none;
                    display: flex;
                    cursor: pointer;
                    margin: grid(2);
                    padding: grid(2) grid(2);
                    color: $color-copy;
                    border-bottom: 1px solid transparent;
                    &.active {
                        border-color: $color-copy;
                    }

                    &:hover {
                        text-decoration: none;
                        background-color: $color-grey96;
                    }

                    @media (max-width: $sm-max) {
                        width: 100%;
                        padding: grid(4) 0;
                        margin: 0;
                        border: 0;
                        @include text-h3;

                        &.active {
                            text-decoration: underline;
                        }
                    }

                    :global(svg) {
                        width: $icon-xs;
                        height: $icon-xs;
                    }

                    @media (prefers-color-scheme: dark) {
                        color: $color-white;

                        &.active {
                            border-color: $color-white;
                        }

                        &:hover {
                            text-decoration: none;
                            background-color: darken($color-white, 80%);
                        }
                    }
                }
            `}</style>
            {type === 'internal' && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                    className={classnames('nav-item h6', { active: internal?.isActive })}
                    onClick={() => {
                        if (preAction) {
                            preAction();
                        } else {
                            history.push(path);
                        }
                    }}
                >
                    {titleText}
                </a>
            )}
            {type === 'external' && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                    className={`nav-item h6`}
                    target={external?.newTab ? '_blank' : undefined}
                    onClick={() => {
                        if (preAction) {
                            preAction();
                        } else {
                            if (external?.newTab) {
                                window.open(path, '_blank');
                            } else {
                                window.location.href = path;
                            }
                        }
                    }}
                >
                    {external?.showIcon && (
                        <>
                            <NewWindow alt="New window" />
                            &nbsp;
                        </>
                    )}
                    {titleText}
                </a>
            )}
        </>
    );
};

export default NavItem;
