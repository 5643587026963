import 'react-nice-dates/build/style.css';

import { DateIcon, ErrorIcon, SuccessIcon, TimeIcon } from '@assets/icons';
import MonthPicker from '@components/Calendar/MonthPicker';
import FormRow from '@components/Form/FormRow';
import TimeSelector from '@components/TimeSelector';
import useEventListener from '@effects/useEventListenter';
import { DeepPartial } from '@typings';
import classnames from 'classnames';
import { enAU } from 'date-fns/locale';
import React, { useCallback, useRef } from 'react';
import { DatePicker } from 'react-nice-dates';

export enum InputType {
    Text,
    Date,
    Time,
    Password,
    File,
    Number,
    DateTimeNumber,
}

const getInputTypeString = (inputType: InputType) => {
    switch (inputType) {
        case InputType.Text:
            return 'text';
        case InputType.Date:
            return 'date';
        case InputType.Time:
            return 'time';
        case InputType.Password:
            return 'password';
        case InputType.File:
            return 'file';
        case InputType.Number:
            return 'number';
        case InputType.DateTimeNumber:
            return 'dateTimeNumber';
        default:
            return 'text';
    }
};

const getInputTypeIcon = (inputType: InputType) => {
    switch (inputType) {
        case InputType.Text:
        case InputType.Number:
        case InputType.Password:
        case InputType.File:
            return null;
        case InputType.Date:
        case InputType.DateTimeNumber:
            return <DateIcon />;
        case InputType.Time:
            return <TimeIcon />;
        default:
            return null;
    }
};

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    inputType?: InputType;
    errorText?: string;
    labelText?: string;
    helperText?: string;
    placeholderText?: string;
    isValid?: boolean;
    isReadOnly?: boolean;
    endDate?: Date;
    maxDate?: Date;
    minDate?: Date;
    regex?: string | RegExp;
}

const Input: React.FunctionComponent<InputProps> = ({
    inputType = InputType.Text,
    errorText,
    labelText,
    helperText,
    placeholderText,
    isValid,
    maxDate,
    minDate,
    endDate,
    isReadOnly,
    className,
    children,
    ...inputProps
}) => {
    const dateInputRef = useRef<HTMLDivElement>(null);
    const isError = isValid === false;
    const isSuccess = isValid === true;
    const successOrErrorIcon = isSuccess ? <SuccessIcon className="icon-success" /> : isError ? <ErrorIcon className="icon-error" /> : null;
    const typeIcon = getInputTypeIcon(inputType);
    const icon = successOrErrorIcon || typeIcon;
    const inputTypeString = getInputTypeString(inputType);

    useEventListener('resize', () => {
        if (/Android/.test(navigator.appVersion)) {
            const element = document.activeElement;
            if (element) {
                if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') {
                    element.scrollIntoView();
                }
            }
        }
    });

    const scrollDateInput = useCallback(() => {
        if (dateInputRef.current) {
            dateInputRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [dateInputRef]);

    const renderDate = inputType === InputType.Date && (
        <>
            <style jsx>{`
                @import 'vars';

                :global(.-today) {
                    border-bottom: 1px solid $color-secondary;
                    color: $color-secondary;
                }
            `}</style>
            <DatePicker
                date={inputProps.value ? new Date(inputProps.value as string) : undefined}
                minimumDate={minDate}
                maximumDate={maxDate}
                onDateChange={(d: any) => {
                    if (d && inputProps.onChange) {
                        // Check to ensure the date is within the min and max IF they exist
                        // as user can select the minDate therefore while checking
                        // we extend minDate and maxDate by one Day
                        if (minDate && d < new Date(minDate.setDate(minDate.getDate() - 1))) {
                            return;
                        }
                        if (maxDate && d > new Date(maxDate.setDate(maxDate.getDate() + 1))) {
                            return;
                        }
                        const proxyEvent: DeepPartial<React.ChangeEvent<HTMLInputElement>> = { target: { value: d.toISODateString() } };
                        inputProps.onChange(proxyEvent as React.ChangeEvent<HTMLInputElement>);
                    }
                }}
                locale={enAU}
            >
                {({ inputProps: dpInputProps, focused }: any) => <input onClick={scrollDateInput} className={'input' + (focused ? ' -focused' : '')} {...dpInputProps} />}
            </DatePicker>
            {icon}
        </>
    );

    const renderTime = inputType === InputType.Time && (
        <>
            <TimeSelector
                initialValue={inputProps.value ? (inputProps.value as string) : inputProps.defaultValue ? (inputProps.defaultValue as string) : undefined}
                onChange={(value: string) => {
                    if (inputProps.onChange) {
                        const proxyEvent: DeepPartial<React.ChangeEvent<HTMLInputElement>> = { target: { value } };
                        inputProps.onChange(proxyEvent as React.ChangeEvent<HTMLInputElement>);
                    }
                }}
            />
        </>
    );

    // Right now this field is only used with a MonthPicker. Can be refactor when needed.
    const renderDateTimeNumberInput = inputType === InputType.DateTimeNumber && (
        <>
            {icon}
            <MonthPicker
                initialValue={parseInt(inputProps.value as string, 10)}
                onSelect={(value: number) => {
                    if (inputProps.onChange) {
                        const proxyEvent: DeepPartial<React.ChangeEvent<HTMLInputElement>> = { target: { value: value.toString() } };
                        inputProps.onChange(proxyEvent as React.ChangeEvent<HTMLInputElement>);
                    }
                }}
            />
        </>
    );

    const renderInput = inputType !== InputType.Date && inputType !== InputType.Time && inputType !== InputType.DateTimeNumber && (
        <>
            <input type={inputTypeString} value={''} {...inputProps} data-hj-whitelist />
            {icon}
        </>
    );

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .form-element,
                :global(input) {
                    font-size: grid(4);
                    width: 100%;
                    border-radius: $border-radius;
                    border: 1px solid $color-border;
                    vertical-align: middle;
                    height: grid(11);
                    padding: grid(3) grid(8) grid(3) grid(3);

                    &:focus {
                        border-color: $color-secondary;
                    }
                    &::placeholder {
                        @include text-small;
                    }

                    &:read-only:not[type='file'] {
                        background-color: $color-line;
                        cursor: not-allowed;
                    }

                    @media (prefers-color-scheme: dark) {
                        color: lighten($color-copy, 100%);
                        background-color: darken($color-white, 90%);
                    }
                }

                :global(input[type='radio']),
                :global(input[type='checkbox']) {
                    width: initial;
                }

                :global(input[type='file']) {
                    padding: 11px;
                    border: none;
                }

                .input-container {
                    position: relative;
                    max-width: $form-input-maxwidth;
                    margin-bottom: grid(0.5);

                    &.with-icon {
                        :global(svg) {
                            position: absolute;
                            right: 7px;
                            top: 12px;
                            width: $icon-sm;
                            height: $icon-sm;
                            pointer-events: none;
                        }
                    }

                    &.with-datetime {
                        :global(input) {
                            padding-left: grid(8);
                        }
                        :global(svg) {
                            left: 7px;
                        }
                    }

                    &.with-time {
                        :global(svg) {
                            position: relative;
                            right: unset;
                            top: unset;
                        }
                    }

                    &.with-success {
                        input {
                            border-color: $color-success;
                        }
                    }

                    &.with-error {
                        input {
                            border-color: $color-error;
                        }
                    }
                }
            `}</style>

            <FormRow errorText={errorText} isValid={isValid} helperText={helperText} labelText={labelText} required={inputProps.required} readOnly={isReadOnly}>
                <div
                    ref={dateInputRef}
                    className={classnames('input-container', className, {
                        'with-icon': icon,
                        'with-success': isSuccess,
                        'with-error': isError,
                        'with-datetime': inputType === InputType.Date,
                        'with-time': inputType === InputType.Time,
                    })}
                >
                    {!isReadOnly ? (
                        <>
                            {renderDate}
                            {renderTime}
                            {renderDateTimeNumberInput}
                            {renderInput}
                        </>
                    ) : (
                        <p>{inputProps.value ? inputProps.value : `Not answered`}</p>
                    )}
                </div>
                {children}
            </FormRow>
        </>
    );
};

export default Input;
