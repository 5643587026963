import React from 'react';

import CheckboxList from './CheckboxList';
import RadioList from './RadioList';

export interface SelectableListItem {
    id: any;
    title: string;
    subtitle?: string;
    image?: any;
    selected?: boolean;
    exclusive?: string[]; // List of id's that this is incompatible with
}

export interface SelectableListItemProps {
    items: SelectableListItem[];
    errorText?: string;
    labelText?: string;
    helperText?: string;
    isValid?: boolean;
    isReadOnly?: boolean;
    onChange(item: SelectableListItem): any;

    required?: boolean;
    horizontal?: boolean;
}

interface Props extends SelectableListItemProps {
    checkList?: boolean;
    radioList?: boolean;

    isCheckListReadOnly?: boolean;
    isRadioListReadOnly?: boolean;
}

const SelectableList: React.FC<Props> = ({ checkList, radioList, isCheckListReadOnly, isRadioListReadOnly, ...rest }) => {
    return checkList ? <CheckboxList {...rest} isReadOnly={rest.isReadOnly || isCheckListReadOnly} /> : radioList ? <RadioList {...rest} isReadOnly={rest.isReadOnly || isRadioListReadOnly} /> : null;
};

export default SelectableList;
