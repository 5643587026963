import SelectableList from '@components/Form/SelectableList';
import useValidation from '@effects/useValidation';
import { actionTypes, useGlobalState } from '@state';
import { getIsRequired } from '@utils/question-validator';
import { isAnyQuestionAnsweredInCurrentSection } from '@utils/section-mapper';
import React, { useEffect } from 'react';

import { IDynamicQuestionProps } from './DynamicQuestion';

const DynamicMultipleChoiceQuestion: React.FC<IDynamicQuestionProps> = ({ question }) => {
    const [{ dynamicPendingAnswers, triggerValidatorCount, copyErrors, originalAnswers, meta }, dispatch] = useGlobalState();
    const { isValid, checkIsValid, errorMessage, resetIsValid } = useValidation();

    const isReadOnly = question.readOnly;
    const isRequired = getIsRequired(question.validators);

    const answers = dynamicPendingAnswers.answers;
    const current = answers?.filter((answer) => answer.questionId === question.id);
    useEffect(() => {
        if (current.length === 0 && copyErrors.isCopy && isAnyQuestionAnsweredInCurrentSection(question, meta, originalAnswers)) {
            checkIsValid(question, '');
        }
    });
    useEffect(() => {
        if (triggerValidatorCount > 0) {
            checkIsValid(question, current.toString());
        }
        if (triggerValidatorCount === 0 && !copyErrors.isCopy) {
            resetIsValid();
        }
    }, [triggerValidatorCount, checkIsValid, question, current, isRequired, copyErrors.isCopy, resetIsValid]);

    return (
        <SelectableList
            checkList
            helperText={''}
            errorText={errorMessage ?? 'Required field'}
            isValid={isValid}
            items={question.acceptableAnswers.map((acceptableAnswer) => ({
                id: acceptableAnswer.value,
                title: acceptableAnswer.displayName,
                selected: current?.some((answer) => answer.value === acceptableAnswer.value),
            }))}
            onChange={(listItem) => {
                const currentlySelected = current?.some((answer) => answer.value === listItem.id);
                const index = question.acceptableAnswers.findIndex((x) => x.value === listItem.id);
                if (currentlySelected) {
                    // Set to null
                    const removePayload = current.filter((x) => x.value === question.acceptableAnswers[index].value).map((x) => ({ questionId: question.id, index: x.index, value: null }));
                    const remainingPayload = current.filter((x) => x.value !== question.acceptableAnswers[index].value);
                    if (removePayload.length) {
                        dispatch({ type: actionTypes.dynamicPendingAnswers.removeDynamic, payload: removePayload[0] });
                        checkIsValid(question, remainingPayload.toString(), true);
                    }

                    if (remainingPayload.length) {
                        dispatch({ type: actionTypes.dynamicPendingAnswers.updateBatchDynamic, payload: remainingPayload });
                        checkIsValid(question, remainingPayload.toString(), true);
                    }
                } else {
                    const newAnswer = { questionId: question.id, value: listItem.id, index };
                    const payload = [newAnswer, ...current.map((x) => ({ ...x, index: question.acceptableAnswers.findIndex((aa) => aa.value === x.value) }))];

                    for (let curIndex = 0; curIndex < payload.length; curIndex++) {
                        if (current.filter((x) => x.value === payload[curIndex].value)) {
                            dispatch({ type: actionTypes.dynamicPendingAnswers.updateDynamic, payload: { questionId: question.id, index: curIndex, value: payload[curIndex].value } });
                            checkIsValid(question, newAnswer.value, true);
                        }
                    }
                }
            }}
            labelText={question.text}
            required={isRequired}
            isReadOnly={isReadOnly}
        />
    );
};

export default DynamicMultipleChoiceQuestion;
