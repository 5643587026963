import { ListIcon } from '@assets/icons';
import classnames from 'classnames';
import React from 'react';

import EmptyList from './EmptyList';

interface Props {
    action?: () => void;
    errorTitle: React.ReactNode;
    children?: React.ReactNode;
}

const EmptyListCard: React.FC<Props> = ({ action, errorTitle, children }) => {
    return (
        <EmptyList noPadding>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .EmptyListCard {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    flex: 1;
                    background-color: $color-white;

                    @media (prefers-color-scheme: dark) {
                        background-color: $color-black;
                        color: $color-white;
                    }

                    border: 1px solid $color-line;

                    padding: grid(5);

                    cursor: pointer;

                    margin-bottom: grid(5);

                    :global(p:first-of-type) {
                        margin: grid(3) 0 0;
                    }

                    :global(p:last-of-type) {
                        margin: 0 0 grid(3);
                    }

                    &--Error {
                        &-Title {
                            margin: grid(5) 0 0;

                            font-size: grid(4);
                            font-weight: 600;
                            color: $color-error;
                        }

                        border-color: $color-error;

                        :global(.Button) {
                            background-color: $color-error;
                            color: $color-white;
                            border: none;
                        }

                        :global(p) {
                            color: $color-error;
                        }

                        :global(svg) {
                            color: $color-error;
                            fill: $color-error;
                        }
                    }
                }
            `}</style>
            <div className={classnames('EmptyListCard', { 'EmptyListCard--Error': !!errorTitle })} onClick={action}>
                <ListIcon style={{ width: 32, height: 32 }} />
                {errorTitle && <div className="EmptyListCard--Error-Title">{errorTitle}</div>}
                {children}
            </div>
        </EmptyList>
    );
};

export default EmptyListCard;
