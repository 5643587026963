import Button from '@components/Button';
import React from 'react';
import { useFragment } from 'relay-hooks';
import { graphql } from 'relay-runtime';

const fragment = graphql`
    fragment EdecRow_edec on Edec {
        edecCustomerId
        pic
        lpaId
        species
        formType
        formDisplayName
        serialNumber
        downloadURL
    }
`;

interface Props {
    edec: any;
}

const EdecRow: React.FC<Props> = (props) => {
    const edec = useFragment(fragment, props.edec);

    return (
        <div className="tbl-row" key={edec.serialNumber}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .tbl-row {
                    .tbl-cell-30 {
                        div {
                            width: 100%;
                        }
                    }

                    @media (max-width: $md-max) {
                        border: 1px solid $color-line;
                        border-radius: $border-radius;
                        background: $color-white;
                        margin: grid(4) 0;

                        @media (prefers-color-scheme: dark) {
                            background: darken($color-white, 80%);
                        }
                    }
                }

                .title {
                    :global(.Button--Link) {
                        text-align: left;
                    }
                }

                .cell-content {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    align-content: flex-start;
                    :global(.Button--Link) {
                        justify-content: flex-start;
                        margin-top: grid(1);

                        :global(svg) {
                            width: grid(4);
                            height: grid(4);
                        }
                    }
                }

                .responsive-heading {
                    @media (max-width: $md-max) {
                        flex-direction: column;
                        align-items: flex-start !important;

                        .mobile-header-options {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 100%;
                        }
                    }
                }
            `}</style>

            <div className="tbl-cell-60" data-cy="movement-date">
                <div className="cell-content">{edec.formDisplayName !== '' ? edec.formDisplayName : 'Unknown'}</div>
            </div>

            <div className="tbl-cell-20" data-cy="movement-date">
                <div className="cell-content">{edec.serialNumber}</div>
            </div>

            <div className="tbl-cell-20" data-cy="movement-date">
                <div className="cell-content">
                    <div>
                        <Button buttonType="link" url={edec.downloadURL ?? ''} openNewTab>
                            Download
                        </Button>
                    </div>
                    <div>
                        <Button buttonType="link" buttonSize="xsmall" url={edec.downloadURL ?? ''}>
                            {'(Alternative link)'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EdecRow;
