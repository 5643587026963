import { CheckIcon } from '@assets/icons';
import classnames from 'classnames';
import React from 'react';

type TagType = 'normal' | 'muted' | 'success' | 'warning' | 'error';

interface Props {
    text?: React.ReactFragment;
    tagType?: TagType;
    className?: any;
    sticky?: 'left' | 'right';
}

const Tag: React.FunctionComponent<Props> = ({ text, sticky, tagType = 'muted', className }) => {
    const TagClasses = classnames(
        'Tag',
        {
            'Tag--Muted': tagType === 'muted',
            'Tag--Normal': tagType === 'normal',
            'Tag--Success': tagType === 'success',
            'Tag--Warning': tagType === 'warning',
            'Tag--Error': tagType === 'error',
            'Tag--Right': sticky === 'right',
        },
        className
    );
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .Tag {
                    display: inline-flex;
                    padding: grid(1) grid(2);
                    border-radius: $border-radius;
                    border: 1px solid transparent;
                    @include text-topic;
                    letter-spacing: 0.6px;

                    $color-border-secondary: rgba($color-secondary, 0.8);

                    &--Normal {
                        background: rgba($color-secondary, 0.2);
                        color: rgba($color-secondary, 0.8);
                        border: 1px solid $color-border-secondary;
                    }

                    &--Right {
                        position: absolute;
                        top: grid(5);
                        right: grid(3);
                    }

                    &--Muted {
                        @include text-muted();

                        background: rgba($color-grey60, 0.2);
                        border: 1px solid rgba($color-grey60, 0.8);

                        @media (prefers-color-scheme: dark) {
                            color: $color-subtitle;
                        }
                    }

                    &--Warning {
                        background: rgba($color-warn, 0.2);
                        color: rgba($color-warn, 0.8);
                        border: 1px solid rgba($color-warn, 0.8);
                    }

                    &--Error {
                        background: rgba($color-error, 0.2);
                        color: rgba($color-error, 0.8);
                        border: 1px solid rgba($color-error, 0.8);
                    }

                    &--Success {
                        background: rgba($color-primary, 0.2);
                        color: rgba($color-primary, 0.8);
                        border: 1px solid rgba($color-primary, 0.8);
                    }
                }
            `}</style>
            <div className={TagClasses}>
                {tagType === 'success' && <CheckIcon className="icon-xxs m-r-4" alt="Complete" />}
                {text}
            </div>
        </>
    );
};
export default Tag;
