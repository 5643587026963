import { useAuth0 } from '@auth0/auth0-react';

export default function useLogoutRedirect() {
    const { logout } = useAuth0();
    const text = 'Logout';

    const action = () => {
        // We now need to clear the token in session storage
        // Need to wrap to ensure it runs and isn't discarded
        sessionStorage.clear();
        // Log the user out by clearing state
        // dispatch({
        //     type: actionTypes.user.resetUserParam,
        //     value: null,
        // });
        // Call logout Auth0 functionality to redirect to the origin page.
        logout({ logoutParams: { returnTo: window.location.origin + '/logout' } });
    };

    return { action, text };
}
