import Button from '@components/Button';
import Input, { InputType } from '@components/Form/Input';
import LinkAccountToAdminUser, { linkAccountToAdminUserMutation } from '@containers/Consignments/mutations/linkAccountToAdminUser';
import validatePIC from '@services/pic';
import { GraphqlError, IUser } from '@typings';
import { GraphqlErrorType } from '@utils/enum-transformers';
import { AccountTypeEnum } from '@utils/enums';
import MLALogger from '@utils/logger';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'relay-hooks';

import { ResponseContentType } from '.';

interface LinkAccountProps {
    user: IUser;
    setResponseContent: React.Dispatch<React.SetStateAction<ResponseContentType>>;
    onSuccess: () => void;
}
const validUseridRegex = new RegExp('^[0-9]*$'.replace(/\//g, ''));
const LinkAccount: React.FunctionComponent<LinkAccountProps> = ({ user, setResponseContent, onSuccess }) => {
    const [linkAccountToAdminMutate] = useMutation(linkAccountToAdminUserMutation);
    const linkDetails = {
        pic: '',
        userId: '',
    };
    const [linkFields, setLinkFields] = useState(linkDetails);
    const [linkBtnDisable, setLinkBtnDisable] = useState(false);
    const [loading, setLoading] = useState(false);

    // disbale link button based on pic and user id field level validation
    useEffect(() => {
        const picLength = linkFields.pic.trim().length;
        const userIdLength = linkFields.userId.toString().length;
        if (picLength > 0 || userIdLength > 0) {
            setLinkBtnDisable(!validatePIC(linkFields.pic) || !(userIdLength <= 7 && validUseridRegex.test(linkFields.userId)));
        }
        if (picLength === 0 || userIdLength === 0) {
            setLinkBtnDisable(true);
        }
    }, [linkFields.pic, linkFields.userId]);

    const onClickLinkAcount = async () => {
        setLoading(true);
        if (linkFields.pic && linkFields.userId) {
            const envdAccountId = user?.claims.accounts.filter((acc) => acc.accountType === AccountTypeEnum.ADMIN)[0].envdAccountId;
            try {
                const response = await LinkAccountToAdminUser(linkAccountToAdminMutate, linkFields, envdAccountId);
                setResponseContent({
                    title: 'Link Account',
                    successMessage: response?.linkAccountToAdminUser?.data,
                    warningMessage: undefined,
                });
                onSuccess();
            } catch (e) {
                var errors = e as GraphqlError[];
                var warningMsg = errors.firstOrDefault((error) => error.extensions?.data?.OutcomeStatus.toLowerCase() === GraphqlErrorType.Error.toLowerCase())?.extensions?.data?.Message;
                setResponseContent({
                    title: '',
                    successMessage: undefined,
                    warningMessage: warningMsg,
                });
                MLALogger.Log(['LinkAccountToAdmin'], e, 'error');
            }
        }
        setLoading(false);
    };

    return (
        <>
            <style jsx>
                {`
                    @import 'vars';
                    @import 'utils';

                    .adminHeaderH2 h2 {
                        margin: grid(10) 0;
                        color: $color-black;
                        font-size: 26px;
                        margin-bottom: 20px;
                        margin: 40px 0px;
                        @media (max-width: $sm-min) {
                            margin: grid(6) 0;
                        }
                    }

                    .no-consignment {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin: auto;
                        width: 100%;
                    }

                    .input-field {
                        width: 100%;
                        margin: 10px 0px;
                    }

                    .adminHeaderH2 {
                        max-width: $form-input-maxwidth;
                        margin-bottom: 10px;
                    }

                    .btn-parent {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                    }

                    .btn {
                        display: flex;
                        justify-content: flex-end;
                        padding: 16px 0px;
                        margin-top: 10px;
                    }
                `}
            </style>
            <div className="flex-column adminHeaderH2">
                <h2 data-cy="welcome-heading">{`Add Linking Account`}</h2>
            </div>
            <div className="tbl-collapse">
                <div className={'no-consignment'}>
                    <div className={'input-field'}>
                        <Input
                            labelText="PIC*"
                            data-cy="pic"
                            errorText={'Please enter a valid PIC'}
                            isValid={linkFields.pic.trim().length > 0 ? validatePIC(linkFields.pic) : undefined}
                            inputType={InputType.Text}
                            value={linkFields?.pic}
                            onChange={(e) => {
                                setLinkFields({ ...linkFields, pic: e.target.value });
                            }}
                        />
                    </div>
                    <div className={'input-field'}>
                        <Input
                            labelText="ID*"
                            data-cy="userid"
                            errorText={'Please enter a valid user id'}
                            inputType={InputType.Text}
                            value={linkFields?.userId}
                            maxLength={7}
                            isValid={linkFields.userId.trim().length > 0 ? validUseridRegex.test(linkFields.userId) : undefined}
                            onChange={(e) => {
                                setLinkFields({ ...linkFields, userId: e.target.value });
                            }}
                        />
                    </div>
                    <div className="btn-parent">
                        <div className="btn">
                            <Button buttonType={linkBtnDisable ? 'disable' : 'primary'} fullWidth={true} buttonSize="medium" disabled={linkBtnDisable} onClick={onClickLinkAcount} loading={loading}>
                                Link Account
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LinkAccount;
