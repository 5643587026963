import { useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import { MOBILE_OR_TABLET_WIDTH } from '@utils/constants';
import { speciesEnumToName } from '@utils/enum-transformers';
import { SectionName } from '@utils/enums';
import { getFormAlias } from '@utils/form-alias';
import { canCreateConsignment } from '@utils/question-editable';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import { graphql, useFragment } from 'relay-hooks';

const fragment = graphql`
    fragment ConsignmentAddSelectTemplateRow_template on Template {
        id
        number
        status
        name
        createdBy
        forms {
            type
        }
        origin {
            name
        }
        destination {
            name
        }
        species
    }
`;

interface Props {
    template: any;
}

const ConsignmentAddSelectTemplateRow: React.FC<Props> = (props) => {
    const template = useFragment(fragment, props.template as any);
    const [{ user }] = useUserState();
    const canCreate = canCreateConsignment(user);
    const isTabletOrMobile = useMediaQuery({ maxWidth: MOBILE_OR_TABLET_WIDTH });

    const history = useHistory();

    return (
        <div className="tbl-row" key={template.consignmentNumber}>
            <div className="tbl-cell-40 responsive-heading">
                <div>
                    <h4 className="title m-b-4">
                        <Button buttonType="link" onClick={() => history.push(`/consignments/copy/${template.number}`)}>
                            {template.name}
                        </Button>
                    </h4>
                    <div className="cell-content">
                        {template.origin && template.origin.name && `From ${template.origin.name} `}
                        {template.destination && template.destination.name && `to ${template.destination.name}`}
                    </div>
                </div>
            </div>
            <div className="tbl-cell-15">
                <div className="cell-title">Species</div>
                <div className="cell-content">{speciesEnumToName(template.species)}</div>
            </div>
            <div className="tbl-cell-25">
                <div className="cell-title">Forms</div>
                <div className="cell-content">
                    {template.forms
                        .map((x: any) => getFormAlias(x.type))
                        .coalesce()
                        .map((x: any) => x.alias)
                        .map((x: any) => (
                            <span key={x} className="list-separator">
                                {x}
                            </span>
                        ))}
                </div>
            </div>
            <div className="tbl-cell-20">
                <Button
                    fullWidth={isTabletOrMobile}
                    buttonSize={isTabletOrMobile ? 'small' : 'normal'}
                    buttonType="secondary"
                    onClick={() => {
                        history.push(canCreate ? `/consignments/copy/${template.number}` : `/templates/edit/${template.number}/${SectionName.LIVESTOCK_DESCRIPTION}`);
                    }}
                >
                    Select
                </Button>
            </div>
        </div>
    );
};

export default ConsignmentAddSelectTemplateRow;
