import MLALogger from '@utils/logger';
import { graphql } from 'relay-hooks';

import { CreateOrSaveUserMappingInput } from './__generated__/createUserMappingMutation.graphql';

const mutation = graphql`
    mutation createUserMappingMutation($input: CreateOrSaveUserMappingInput!, $envdAccountId: String!) {
        createOrSaveUserMapping(input: $input, envdAccountId: $envdAccountId) {
            data {
                email
                firstName
                lastName
                consignmentNumber
                role
                mappingId
                pic
                numOfHeads
            }
        }
    }
`;

const commitAsync = async (mutate: any, userMappingInput: CreateOrSaveUserMappingInput, envdAccountId: String): Promise<any | undefined> => {
    try {
        const data = await mutate({
            variables: { input: userMappingInput, envdAccountId },
            onCompleted: (response: any, error: any) => {
                if (error) {
                    MLALogger.Log(['createConsignmentUserMapping', 'commitAsync'], { error }, 'error');
                } else {
                    MLALogger.Log(['createConsignmentUserMapping', 'commitAsync'], { response });
                }
            },
        });
        console.log(data);

        return data.createOrSaveUserMapping.data;
    } catch (error) {
        MLALogger.Log(['createConsignmentUserMapping', 'commitAsync'], error, 'error');
        throw error;
    }
};

export default commitAsync;
export { mutation as CreateUserMappingMutation };
