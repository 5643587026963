import { useAuth0 } from '@auth0/auth0-react';
import Loader from '@components/Loader';
import { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router';

interface CallbackProps {}

const Callback: FunctionComponent<CallbackProps> = () => {
    const { handleRedirectCallback } = useAuth0();
    const history = useHistory();
    useEffect(() => {
        (async () => {
            try {
                let result = await handleRedirectCallback();
                history.push(result.appState.returnTo);
            } catch (e) {
                history.push('/');
            }
        })();
    }, [handleRedirectCallback, history]);

    return <Loader isLoading={true} error={false} retry={() => null} timedOut={false} pastDelay={false} />;
};

export default Callback;
