import { ChevronDownIcon, ChevronUpIcon } from '@assets/icons';
import useKeyPress, { KeyCode, useLocalKeyPress } from '@common/effects/useKeyPress';
import DropdownOptions, { DropdownOption } from '@components/Form/DropdownOptions';
import FormRow from '@components/Form/FormRow';
import RelativePortal from '@components/RelativePortal';
import usePrevious from '@effects/usePrevious';
import MLALogger from '@utils/logger';
import classnames from 'classnames';
import React, { FunctionComponent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

interface Props {
    value: any;
    errorText?: string;
    labelText?: string;
    helperText?: string;
    isValid?: boolean;
    options: DropdownOption[];
    onChange: (item: DropdownOption) => void;
    isReadOnly?: boolean;
    tabIndex?: number;
    autoFocus?: boolean;
    required?: boolean;
    icon?: React.ReactNode;
    emptyText?: string;
}

const calculateActiveOptionIndex = (value: number, options: DropdownOption[], currentOptionIndex?: number): number => {
    let val = (currentOptionIndex ? currentOptionIndex : 0) + value;
    if (val < 0) {
        val = options.length - 1;
    }
    const optionIndex = val % options.length;
    return optionIndex;
};

const Dropdown: FunctionComponent<Props> = ({
    value,
    errorText,
    labelText,
    helperText,
    isValid,
    options,
    onChange,
    isReadOnly,
    tabIndex = 0,
    autoFocus,
    required,
    icon,
    emptyText = 'Please select',
}) => {
    const isError = isValid === false;
    const isSuccess = isValid === true;
    const dropdownNode = useRef<HTMLDivElement>(null);

    const root = useRef<HTMLDivElement>(null);
    const selectedOption = options.find((o) => o.id === value);
    const selectedOptionIndex = options.findIndex((o) => o.id === value);
    const selectedText = selectedOption ? selectedOption.title : emptyText;
    const [show, setShow] = useState(false);
    const [activeOptionIndex, setActiveOptionIndex] = useState<number>(0);
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(selectedOptionIndex !== -1 ? selectedOptionIndex : undefined);
    const previousSelectedIndex = usePrevious(selectedIndex);

    const optionSelected = useCallback(
        (option: any) => {
            if (option !== null && option !== undefined) {
                MLALogger.Log(['Dropdown Portal'], { option, onChange });
                setShow(false);
                setSelectedIndex(option);
            }
        },
        [onChange]
    );

    useEffect(() => {
        if (selectedIndex !== undefined && selectedIndex !== previousSelectedIndex) {
            MLALogger.Log(['Dropdown Portal'], { selectedIndex });
            onChange(options[selectedIndex]);

            setShow(false);
        }
    }, [selectedIndex, onChange, options, previousSelectedIndex]);

    useEffect(() => {
        setActiveOptionIndex(0);
    }, [options]);

    const downKeyPress = useLocalKeyPress(KeyCode.downArrow, dropdownNode.current);
    const upKeyPress = useLocalKeyPress(KeyCode.upArrow, dropdownNode.current);
    const deleteKeyPress = useLocalKeyPress(KeyCode.delete, dropdownNode.current);
    const enterKeyPress = useLocalKeyPress(KeyCode.enter, dropdownNode.current);
    const escKeyPress = useKeyPress(KeyCode.escape);

    useLayoutEffect(() => {
        if (downKeyPress) {
            setActiveOptionIndex((internalAOI) => calculateActiveOptionIndex(1, options, internalAOI));
        }
    }, [downKeyPress, options]);

    useLayoutEffect(() => {
        if (upKeyPress) {
            setActiveOptionIndex((internalAOI) => calculateActiveOptionIndex(-1, options, internalAOI));
        }
    }, [upKeyPress, options]);

    useLayoutEffect(() => {
        if (deleteKeyPress) {
            setActiveOptionIndex(0);
        }
    }, [deleteKeyPress]);

    useLayoutEffect(() => {
        if (enterKeyPress) {
            // onChange(options[activeOptionIndex || 0]);
            optionSelected(activeOptionIndex || 0);
        }
    }, [enterKeyPress, optionSelected, activeOptionIndex]);

    useLayoutEffect(() => {
        if (escKeyPress) {
            setShow(false);
        }
    }, [escKeyPress]);

    useEffect(() => {
        if (autoFocus && dropdownNode.current && document.activeElement && dropdownNode.current.id !== document.activeElement.id) {
            MLALogger.Log(['Dropdown'], { message: 'Attempting to autofocus' });
            dropdownNode.current.focus();
            setShow(true);
        }
    }, [autoFocus]);

    return (
        <div ref={root}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                .form-element {
                    padding: grid(3);
                    line-height: grid(4);
                    user-select: none;
                    max-width: $form-input-maxwidth;
                    height: 44px;
                    width: 100%;
                    border-radius: $border-radius;
                    border: 1px solid $color-border;
                    cursor: pointer;
                    background: $color-white;
                    align-items: center;

                    @media (prefers-color-scheme: dark) {
                        background: darken($color-white, 90%);
                    }

                    &:focus {
                        border-color: $color-secondary;
                    }

                    > img {
                        width: $icon-sm;
                        height: $icon-sm;
                    }

                    > :global(svg) {
                        pointer-events: none;
                    }

                    &.with-success {
                        border-color: $color-success;
                        @media (prefers-color-scheme: dark) {
                            border-color: lighten($color-success, 20%);
                        }
                    }

                    &.with-error {
                        border-color: $color-error;
                        @media (prefers-color-scheme: dark) {
                            border-color: lighten($color-error, 20%);
                        }
                    }

                    &.active {
                        border-color: $color-secondary;
                        @media (prefers-color-scheme: dark) {
                            border-color: lighten($color-secondary, 20%);
                        }
                    }
                }
                :global(select) {
                    width: 100%;
                    border-radius: $border-radius;
                    border: 1px solid $color-border;

                    &:focus {
                        border-color: $color-primary;
                    }
                }
            `}</style>

            <FormRow errorText={errorText} isValid={isValid} helperText={helperText} labelText={labelText} readOnly={isReadOnly} required={required}>
                {!isReadOnly ? (
                    <div>
                        <div
                            ref={dropdownNode}
                            tabIndex={tabIndex}
                            className={classnames('form-element flex-row flex-between', {
                                'with-success': isSuccess,
                                'with-error': isError,
                                active: show,
                            })}
                            onClick={() => setShow(!show)}
                        >
                            {icon}
                            {selectedText}
                            {show ? <ChevronUpIcon className={'icon-dark'} alt="View" /> : <ChevronDownIcon className={'icon-dark'} alt="View" />}
                        </div>

                        <RelativePortal
                            id={'dropdown-portal'}
                            fitToParentWidth
                            show={show}
                            onParentClick={optionSelected}
                            onOutClick={() => setShow((s) => (s ? !s : s))}
                            parentElementRef={root}
                            fallbackAlignmentElementRef={dropdownNode}
                        >
                            <DropdownOptions options={options} value={value} selectedIndex={activeOptionIndex} />
                        </RelativePortal>
                    </div>
                ) : (
                    <p>{value ? value : `Not answered`}</p>
                )}
            </FormRow>
        </div>
    );
};

export default Dropdown;
