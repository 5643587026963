import { ConsignmentSpecies } from './enums';

export function speciesEnumToName(speciesEnum: ConsignmentSpecies | string | null) {
    switch (speciesEnum) {
        case ConsignmentSpecies.GOAT:
            return 'Goat';
        case ConsignmentSpecies.BOBBY_CALVES:
            return 'Bobby Calves';
        case ConsignmentSpecies.CATTLE:
            return 'Cattle';
        case ConsignmentSpecies.SHEEP_LAMB:
            return 'Sheep/Lamb';
        default:
            return;
    }
}

export function speciesEnumToPluralName(speciesEnum: ConsignmentSpecies | string | null) {
    switch (speciesEnum) {
        case ConsignmentSpecies.GOAT:
            return 'Goats';
        case ConsignmentSpecies.BOBBY_CALVES:
            return 'Bobby Calves';
        case ConsignmentSpecies.CATTLE:
            return 'Cattle';
        case ConsignmentSpecies.SHEEP_LAMB:
            return 'Sheep/Lambs';
        default:
            return;
    }
}

export const ALL_SPECIES = [ConsignmentSpecies.BOBBY_CALVES, ConsignmentSpecies.CATTLE, ConsignmentSpecies.GOAT, ConsignmentSpecies.SHEEP_LAMB];

export enum ConsignmentStatus {
    DRAFT = 'DRAFT',
    SUBMITTED = 'SUBMITTED',
    LOCKED = 'LOCKED',
    DELETED = 'DELETED',
}

export enum GraphqlErrorType {
    Error = 'Error',
    Warning = 'Warning',
    Success = 'Success',
}

export enum UserRole {
    AUTHVIEWER = 'AUTHVIEWER',
}

export function consignmentEnumToName(statusEnum: ConsignmentStatus | string | null) {
    switch (statusEnum) {
        case ConsignmentStatus.DRAFT:
            return 'Draft';
        case ConsignmentStatus.SUBMITTED:
            return 'Submitted';
        case ConsignmentStatus.LOCKED:
            return 'Completed';
        case ConsignmentStatus.DELETED:
            return 'Deleted';
        default:
            return;
    }
}

export function consignmentEnumToColor(statusEnum: ConsignmentStatus | string | null) {
    switch (statusEnum) {
        case ConsignmentStatus.DRAFT:
            return 'muted';
        case ConsignmentStatus.SUBMITTED:
            return 'normal';
        case ConsignmentStatus.LOCKED:
            return 'success';
        case ConsignmentStatus.DELETED:
            return 'error';
        default:
            return;
    }
}
