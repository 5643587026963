import Input, { InputType } from '@components/Form/Input';
import useUpdateDynamicValue from '@effects/useUpdateDynamicValue';
import useValidation from '@effects/useValidation';
import { useGlobalState } from '@state';
import { useGetDynamicAnswers } from '@utils/question-getter';
import { getIsRequired } from '@utils/question-validator';
import { isAnyQuestionAnsweredInCurrentSection } from '@utils/section-mapper';
import classnames from 'classnames';
import React, { useEffect } from 'react';

import { IDynamicQuestionProps } from './DynamicQuestion';

const DynamicDateQuestion: React.FC<IDynamicQuestionProps> = ({ question, index }) => {
    const { isValid, checkIsValid, evaluated, errorMessage, resetIsValid } = useValidation();
    const [{ triggerValidatorCount, copyErrors, meta }] = useGlobalState();

    const update = useUpdateDynamicValue();

    const isReadOnly = question.readOnly;
    const isRequired = getIsRequired(question.validators);

    const [{ originalAnswers }] = useGlobalState();
    // Prefer pending if it exists, fallback to original if not
    const answers = useGetDynamicAnswers(question, index);
    const value =
        answers?.firstOrDefault((answer) => answer.questionId === question.id && answer.index === index)?.value ??
        originalAnswers?.firstOrDefault((answer) => answer.questionId === question.id && index === null)?.value;

    useEffect(() => {
        if ((value === null || value === undefined) && copyErrors.isCopy && isAnyQuestionAnsweredInCurrentSection(question, meta, originalAnswers)) {
            checkIsValid(question, value);
        }
    });
    useEffect(() => {
        if (!evaluated && value) {
            checkIsValid(question, value);
        }
    }, [evaluated, checkIsValid, question, value]);

    useEffect(() => {
        if (triggerValidatorCount > 0) {
            checkIsValid(question, value);
        }
        if (triggerValidatorCount === 0 && !copyErrors.isCopy) {
            resetIsValid();
        }
    }, [triggerValidatorCount, checkIsValid, resetIsValid, question, value, copyErrors.isCopy]);

    // TODO: We can derive min/max dates as required for inline validation
    const customClassName = question.text.replace(/[ \\/ ]/gm, '_').toLocaleLowerCase();

    return (
        <>
            <style jsx>{`
                @import 'vars';
                :global(.${customClassName}) {
                    :global(.nice-dates-popover.-open) {
                        height: 400px;
                        @media (max-width: $sm-max) {
                            height: 450px;
                        }
                    }
                }
            `}</style>

            <Input
                errorText={errorMessage}
                labelText={question.text}
                className={classnames('DateField', customClassName)}
                inputType={InputType.Date}
                value={value}
                onChange={(e) => {
                    checkIsValid(question, e.target.value, true);
                    update(question, index, e.target.value);
                }}
                isValid={isValid}
                isReadOnly={isReadOnly}
                autoFocus={false}
                required={isRequired}
            />
        </>
    );
};

export default DynamicDateQuestion;
