import { IndexedObject } from '@typings';

class MLALogger {
    public static LogToNewRelic(prefixes?: string[], args?: any) {
        const newRelicAgentInstalled = 'newrelic' in window;
        // Rebuild the object so we can capture everything as an attribute
        if (newRelicAgentInstalled && args) {
            if (args instanceof Error) {
                (window as any).newrelic.noticeError(
                    args,
                    prefixes?.map((p, i) => ({
                        i: p,
                    }))
                );
            } else {
                let err: Error | null = null;
                let leftover: IndexedObject<any, any> = {};
                let index: number = 0;

                const keys = Object.keys(args);
                for (const key of keys) {
                    const arg = args[key];
                    if (arg instanceof Error) {
                        if (!err || err.message === '') {
                            err = arg;
                        }
                    } else {
                        (leftover as any)[index] = arg as any;
                        index += 1;
                    }
                }
                if (err) {
                    (window as any).newrelic.noticeError(err, { ...leftover, ...prefixes?.map((p, i) => ({ i: p })) });
                }
            }
        }
    }

    public static LogEnterAndExit(tags: string[], isStart: boolean = true) {
        // Ignore when in test
        if (process.env.NODE_ENV === 'test') {
            return;
        }
        console.log(`%c ${isStart ? 'Start' : 'End'} - ${tags?.map((tag) => `[${tag}]`).join(' + ')} ${Array(40).fill('-').join('')}`, `color: #007549; font-size: 18px;`);
        if (isStart) {
            console.time(tags?.map((tag) => `[${tag}]`).join(' + '));
        } else {
            console.timeEnd(tags?.map((tag) => `[${tag}]`).join(' + '));
        }
    }

    public static Log(prefixes?: string[], args?: any, level: 'log' | 'error' | 'warn' = 'log') {
        // Ignore when in test unless its an error
        if (process.env.NODE_ENV === 'test' && level !== 'error') {
            return;
        }
        if (level === 'log') {
            console.log(`%c ${prefixes?.map((prefix) => `[${prefix}]`).join(' + ')}`, `color: #007549`, args);
        } else if (level === 'error') {
            this.LogToNewRelic(prefixes, args);
            console.error(`%c ${prefixes?.map((prefix) => `[${prefix}]`).join(' + ')}`, `color: #c64e3e`, args);
        } else if (level === 'warn') {
            console.warn(`%c ${prefixes?.map((prefix) => `[${prefix}]`).join(' + ')}`, `color: #eeb932`, args);
        }
    }

    public static LogCount(item: string) {
        // Ignore when in test
        if (process.env.NODE_ENV === 'test') {
            return;
        }
        console.count(item);
    }
}

export default MLALogger;
