import { CommenterIcon } from '@assets/icons';
import { CommenterRole } from '@utils/enums';
import { format } from 'date-fns';

export interface CommentRowProps {
    firstName: string;
    lastName: string;
    role: string;
    commentText: string;
    createdAt: any;
    commentId?: number | undefined;
}

function getValueByKeyForCommenterRole(value: string) {
    return Object.entries(CommenterRole).find(([key, val]) => key === value)?.[1];
}

const CommentRow: React.FC<CommentRowProps> = (props) => {
    const date = new Date(props?.createdAt);
    return (
        <>
            <div className="comment-row m-v-24">
                <div className="icon">
                    <CommenterIcon />
                </div>
                <div className="comment-attributes">
                    <div className="commenter-name">{`${props?.firstName} ${props?.lastName}`}</div>
                    <div className="role">{getValueByKeyForCommenterRole(props?.role)}</div>
                    <div data-cy="comment-text" className="comment-text">
                        {props?.commentText}
                    </div>
                </div>
                <div className="timestamp">{format(date, 'dd/MM/yyyy, hh:mm a')}</div>
            </div>
            <div className="horizontal-divider">
                <hr />
            </div>
            <style jsx>
                {`
                    @import 'vars';

                    .comment-row {
                        display: flex;
                        width: 100%;
                        gap: 15px;
                        :global(svg) {
                            height: 34px;
                            width: 34px;
                        }
                    }

                    .comment-attributes {
                        display: flex;
                        flex-direction: column;
                        max-width: 82%;
                    }
                    .timestamp {
                        margin-left: auto;
                        color: $color-grey60;
                        font-size: small;
                    }

                    .commenter-name {
                        color: $isc-blue;
                        font: initial;
                    }

                    .role {
                        color: $color-grey47;
                    }

                    .comment-text {
                        margin-top: 15px;
                        font-size: medium;
                        white-space: pre-wrap;
                        overflow-wrap: anywhere;
                    }

                    .icon {
                        @media (max-width: $lg-max) {
                            min-width: 10%;
                        }
                    }

                    .horizontal-divider {
                        hr {
                            border: 1px solid $color-grey79;
                            margin: auto;
                        }
                    }
                `}
            </style>
        </>
    );
};

const ViewComment = (commentData: any) => {
    return (
        <div className="m-v-32">
            {commentData?.comments?.map((comment: CommentRowProps) => {
                return (
                    <CommentRow key={comment.commentId} firstName={comment.firstName} lastName={comment.lastName} role={comment.role} commentText={comment.commentText} createdAt={comment.createdAt} />
                );
            })}
        </div>
    );
};

export default ViewComment;
