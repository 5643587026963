import { IConsignment } from '@typings';
import { UNKNOWN_PIC } from '@utils/constants';
import MLALogger from '@utils/logger';
import _ from 'lodash';
import { graphql } from 'relay-hooks';

export const createOrSaveConsignmentMutation = graphql`
    mutation createOrSaveConsignmentMutation($input: CreateOrSaveConsignmentInput!, $envdAccountId: String!) {
        createOrSaveConsignment(input: $input, envdAccountId: $envdAccountId) {
            data {
                ...ConsignmentEditConsignmentFragment @relay(mask: false)
            }
        }
    }
`;

export const cleanConsignmentPIC = (consignment: any, fieldToUpdate: string, value: string) => {
    let con = _.clone(consignment[fieldToUpdate]);
    con.pic = value;
    consignment[fieldToUpdate] = con;
    return consignment;
};

const commitAsync = async (mutate: any, consignment: any, envdAccountId: String): Promise<Partial<IConsignment>> => {
    MLALogger.Log(['createConsignment', 'commitAsync', 'Sending'], { consignment });
    // ENVDB-896: Adding AAAAAAAA to destination pic if destination pic is empty
    // as graphql doesnt allow empty destination pic
    if (consignment?.destination?.pic === '') {
        consignment = cleanConsignmentPIC(consignment, 'destination', UNKNOWN_PIC);
    }
    try {
        const data = await mutate({
            variables: { input: consignment, envdAccountId },
            onCompleted: (response: any, error: any) => {
                MLALogger.Log(['createOrSaveConsignment', 'commitAsync'], { response, error });
                if (error) {
                    MLALogger.Log(['createOrSaveConsignment', 'commitAsync'], { error }, 'error');
                } else {
                    MLALogger.Log(['createOrSaveConsignment', 'commitAsync'], { response, data: response.createOrSaveConsignment.data, request: consignment });
                }
            },
        });

        let con = _.clone(data?.createOrSaveConsignment?.data);
        // ENVDB-896: Removing AAAAAAAA from consignment recieved from graphql as
        // we dont want to display AAAAAAAA to user
        if (data?.createOrSaveConsignment?.data?.destination?.pic === UNKNOWN_PIC) {
            con = cleanConsignmentPIC(con, 'destination', '');
        }
        return con;
    } catch (error) {
        MLALogger.Log(['createOrSaveConsignment'], error, 'error');
        throw error;
    }
};

export default commitAsync;
