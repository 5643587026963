import classnames from 'classnames';
import React from 'react';
import posed from 'react-pose';

interface Props {
    children?: React.ReactNode;
    show: boolean;
}

const Content = posed.div({
    closed: { height: 0 },
    open: { height: 'auto' },
});

const Conditional: React.FunctionComponent<Props> = ({ show, children }) => {
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .conditional--content {
                    margin-left: grid(2);
                    padding-left: grid(4);
                    padding-bottom: grid(3);
                    border-left: 1px solid $color-line;
                    margin-bottom: grid(8);
                    &:empty {
                        margin-bottom: 0;
                        padding-bottom: 0;
                        display: none;
                    }
                }
            `}</style>

            {React.Children.count(children) > 0 && (
                <Content className={classnames('conditional')} pose={show ? 'open' : 'closed'} data-pose={show ? 'open' : 'closed'} withParent={false}>
                    <div className="conditional--content">{children}</div>
                </Content>
            )}
        </>
    );
};

export default Conditional;
