import Button from '@common/components/Button';
import classnames from 'classnames';
import React from 'react';

interface Props {
    emptyText?: string | React.ReactNode;
    action?: () => void;
    actionText?: string;
    actionIcon?: any;
    error?: React.ReactNode;

    showInline?: boolean;
    noPadding?: boolean;
    children?: React.ReactNode;
}

const EmptyList: React.FunctionComponent<Props> = ({ emptyText, action, actionText, actionIcon, showInline, error, children, noPadding }) => {
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .EmptyList {
                    text-align: center;
                    padding: grid(10);
                    flex-grow: 1;

                    h3:empty,
                    h4:empty {
                        display: none;
                    }

                    &--Inline {
                        border-left: 1px solid $color-line;
                        margin: grid(3) 0 grid(6) grid(2);
                        padding: grid(3) 0 grid(3) grid(4);
                        text-align: left;
                    }

                    &--NoPadding {
                        padding: 0;
                    }
                }
            `}</style>
            {(emptyText || actionText || children) && (
                <div className={classnames('EmptyList', { 'EmptyList--Inline': showInline, 'EmptyList--NoPadding': noPadding })}>
                    {emptyText && <h4 className="m-b-16">{emptyText}</h4>}
                    {children}
                    {error}
                    {actionText && (
                        <Button buttonType="secondary" buttonSize="small" action={action}>
                            {actionIcon && <img className="icon-primary" src={actionIcon} alt="Primary action" />}
                            {actionText}
                        </Button>
                    )}
                </div>
            )}
        </>
    );
};
export default EmptyList;
