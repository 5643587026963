/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type GetConfigurationsQueryVariables = {};
export type GetConfigurationsQueryResponse = {
    readonly configurations: {
        readonly items: ReadonlyArray<{
            readonly key: string | null;
            readonly value: string | null;
        } | null> | null;
    } | null;
};
export type GetConfigurationsQuery = {
    readonly response: GetConfigurationsQueryResponse;
    readonly variables: GetConfigurationsQueryVariables;
};



/*
query GetConfigurationsQuery {
  configurations {
    items {
      key
      value
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ConfigurationConnection",
    "kind": "LinkedField",
    "name": "configurations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ConfigurationType",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetConfigurationsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetConfigurationsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "b821fb2c37a139e922e09f39a3230150",
    "id": null,
    "metadata": {},
    "name": "GetConfigurationsQuery",
    "operationKind": "query",
    "text": "query GetConfigurationsQuery {\n  configurations {\n    items {\n      key\n      value\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9b97e35108cf349db5c30d58d670091b';
export default node;
