import { useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import { IPerson } from '@typings';
import { UNKNOWN_PIC_DESTINATION } from '@utils/constants';
import classnames from 'classnames';
import React from 'react';

interface Props {
    person: IPerson;
    indent?: boolean;
    showEdit?: {
        title: string;
        active: boolean;
        onClicked?: () => void;
    };

    showChange?: {
        title: string;
        active: boolean;
        onClicked?: () => void;
    };
    displayNonPICField?: boolean;
}

const UserView: React.FunctionComponent<Props> = ({ person, showEdit, showChange, indent = true, displayNonPICField }) => {
    const [{ user }] = useUserState();
    const isMyself = user && user.accountDetails && user.accountDetails.pic === person.pic;
    return (
        <div className={classnames('UserView', { 'UserView--Indent': indent })}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .UserView {
                    &--Indent {
                        margin-left: grid(2);
                        padding-left: grid(4);
                        padding-bottom: grid(0.5);
                        border-left: 1px solid $color-line;
                    }
                }
            `}</style>

            {!displayNonPICField && <h3 className="m-b-16">Destination PIC</h3>}
            {displayNonPICField && (
                <p className="midbold m-b-8">
                    {person.businessName ? person.businessName : person.firstName && person.lastName ? `${person.firstName} ${person.lastName}` : ''}
                    {isMyself && ' (Myself)'}
                </p>
            )}
            {person.pic && person.pic !== UNKNOWN_PIC_DESTINATION && <p className="m-b-8">{person.pic}</p>}
            {displayNonPICField && (
                <>
                    <p className="m-b-8">{[person.locationAddress, person.locationTown, person.locationState, person.locationPostcode].coalesce().join(', ')}</p>
                    {person.phone && <p className="m-b-8">{person.phone}</p>}
                    {person.msaRegNum && <p>{person.msaRegNum}</p>}
                </>
            )}
            {showEdit && showEdit.active && (
                <Button buttonType={'primary'} onClick={showEdit.onClicked}>
                    {showEdit.title}
                </Button>
            )}
            {showChange && showChange.active && (
                <Button buttonType={'secondary'} className={displayNonPICField ? 'm-l-24' : ''} onClick={showChange.onClicked}>
                    {showChange.title}
                </Button>
            )}
        </div>
    );
};

export default UserView;
