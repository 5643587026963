import { graphql } from 'relay-hooks';

export const GetConfigurations = graphql`
    query GetConfigurationsQuery {
        configurations {
            items {
                key
                value
            }
        }
    }
`;
