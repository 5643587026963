import { TimeIcon } from '@assets/icons';
import Dropdown from '@components/Form/Dropdown';
import { DropdownOption } from '@components/Form/DropdownOptions';
import MLALogger from '@utils/logger';
import React, { useEffect, useState } from 'react';

interface Props {
    onChange: (formattedTime: string) => void;
    initialValue?: string;
}

const isValidTimeNumber = (num: any) => typeof num === 'number' && num >= 0;

const hours = new Array(12).fill(0).map((_: number, idx: number) => idx + 1);
const minutes = [0, 10, 20, 30, 40, 50];
const periods = ['AM', 'PM'];

export const calculateTime = (hour: any, minute: any, period: string): string => {
    let formattedTime = '';
    let formattedHour = '';

    if (!isValidTimeNumber(hour) || !isValidTimeNumber(minute) || !periods.find((x) => x === period)) {
        return '';
    }

    const formattedMinute = minute === 0 ? '00' : `${minute}`;
    // For hour we need to convert to 24 hour time then to a 2 digits
    formattedHour = period === 'PM' ? (hour < 12 ? `${(hour as number) + 12}` : '12') : hour < 12 ? `${hour < 10 ? '0' : ''}${hour}` : '00';

    // We only need the format of HH:mm
    formattedTime = `${formattedHour}:${formattedMinute}`;
    return formattedTime;
};

const BLANK_TIME_ID = 'blank';

export const EMPTY_HOUR = 'Hour';
export const EMPTY_MINUTE = 'Min';
export const EMPTY_PERIOD = 'AM/PM';

const TimeSelector: React.FC<Props> = ({ onChange, initialValue }) => {
    const [hourOptions] = useState<DropdownOption[]>([{ id: BLANK_TIME_ID, title: EMPTY_HOUR }, ...hours.map((x) => ({ id: x, title: x.toString().padStart(2, '0') }))]);
    const [minuteOptions] = useState<DropdownOption[]>([{ id: BLANK_TIME_ID, title: EMPTY_MINUTE }, ...minutes.map((x) => ({ id: x, title: x.toString().padStart(2, '0') }))]);
    const [periodOptions] = useState<DropdownOption[]>([{ id: BLANK_TIME_ID, title: EMPTY_PERIOD }, ...periods.map((x) => ({ id: x, title: x.toString() }))]);

    const initialHour = initialValue ? parseInt(initialValue.split(':')[0], 10) : -1;
    const initalMinute = initialValue ? parseInt(initialValue.split(':')[1], 10) : -1;

    MLALogger.Log(['TimeSelector'], { initialHour, initalMinute, initialValue });

    const [selectedHour, setSelectedHour] = useState<number>(initialHour > 12 ? initialHour - 12 : initialHour);
    const [selectedMinute, setSelectedMinute] = useState<number>(initalMinute);
    const [selectedPeriod, setSelectedPeriod] = useState<string>(initialValue ? (initialHour <= 12 ? periods[0] : periods[1]) : BLANK_TIME_ID);

    const [time, setTime] = useState<string>(calculateTime(selectedHour, selectedMinute, selectedPeriod));

    useEffect(() => {
        const newTime = calculateTime(selectedHour, selectedMinute, selectedPeriod);
        if (newTime !== time) {
            MLALogger.Log(['TimeSelector'], { newTime, time });
            setTime(newTime);
            onChange(newTime);
        }
    }, [selectedHour, selectedMinute, selectedPeriod, time, onChange]);

    return (
        <div className="TimeSelector">
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .TimeSelector {
                    display: flex;
                    flex-direction: row;
                    max-width: grid(79.5);
                    justify-content: space-between;
                }

                .Hour {
                    width: 97px;
                }
                .Minute {
                    width: 97px;
                }
                .Period {
                    width: 99px;
                }
            `}</style>
            <div className="Hour">
                <Dropdown emptyText={EMPTY_HOUR} value={selectedHour} options={hourOptions} onChange={(e) => setSelectedHour(e.id)} icon={<TimeIcon style={{ marginRight: 4 }} />} />
            </div>
            <div className="Minute">
                <Dropdown emptyText={EMPTY_MINUTE} value={selectedMinute} options={minuteOptions} onChange={(e) => setSelectedMinute(e.id)} icon={<TimeIcon style={{ marginRight: 4 }} />} />
            </div>
            <div className="Period">
                <Dropdown emptyText={EMPTY_PERIOD} value={selectedPeriod} options={periodOptions} onChange={(e) => setSelectedPeriod(e.id)} />
            </div>
        </div>
    );
};

export default TimeSelector;
