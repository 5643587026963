import { IWizardReducerAction, IWizardReducerState } from '@components/Wizard';
import classnames from 'classnames';
import React from 'react';

interface Props {
    children: React.ReactNode;

    state?: IWizardReducerState;
    dispatch?: React.Dispatch<IWizardReducerAction>;
    hidden?: boolean;
}

const WizardStep: React.FC<Props> = ({ children, hidden }) => {
    return (
        <div className={classnames('WizardStep', { 'WizardStep--Hidden': Boolean(hidden) })}>
            <style jsx>{`
                .WizardStep {
                    &--Hidden {
                        display: none;
                    }
                }
            `}</style>
            {children}
        </div>
    );
};

export default WizardStep;
