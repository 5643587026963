import * as React from 'react';
const SvgSpinner = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props} viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M17.215 6.6c-2.976-3.035-7.793-3.147-10.77-.112-2.975 3.046-2.975 7.958 0 11.005a7.49 7.49 0 0 0 10.77 0c1.483-1.507 2.224-3.272 2.224-5.497h2.18c0 2.225-.96 5.114-2.878 7.07a9.66 9.66 0 0 1-13.865 0c-3.815-3.9-3.847-10.24-.022-14.14s9.963-3.901 13.79 0z"
        />
    </svg>
);
export default SvgSpinner;
