import { useCallback, useEffect, useState } from 'react';

export const KeyCode = {
    delete: 8,
    backspace: 8,
    downArrow: 40,
    upArrow: 38,
    enter: 13,
    escape: 27,
};

export default function useKeyPress(targetKey: string | number) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState<boolean>(false);

    // If pressed key is our target key then set to true
    function downHandler({ key, which, keyCode }: any) {
        if (key === targetKey || which === targetKey || keyCode === targetKey) {
            setKeyPressed(true);
        }
    }

    // If released key is our target key then set to false
    const upHandler = ({ key, which, keyCode }: any) => {
        if (key === targetKey || which === targetKey || keyCode === targetKey) {
            setKeyPressed(false);
        }
    };

    // Add event listeners
    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }); // Empty array ensures that effect is only run on mount and unmount

    return keyPressed;
}

export function useLocalKeyPress(targetKey: string | number, target: HTMLElement | null) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState<boolean>(false);

    // If pressed key is our target key then set to true
    const downHandler = useCallback(
        ({ key, which, keyCode }: any) => {
            if (key === targetKey || which === targetKey || keyCode === targetKey) {
                setKeyPressed(true);
            }
        },
        [targetKey]
    );

    // If released key is our target key then set to false
    const upHandler = useCallback(
        ({ key, which, keyCode }: any) => {
            if (key === targetKey || which === targetKey || keyCode === targetKey) {
                setKeyPressed(false);
            }
        },
        [targetKey]
    );

    // Add event listeners
    useEffect(() => {
        if (target) {
            target.addEventListener('keydown', downHandler);
            target.addEventListener('keyup', upHandler);
        }
        // Remove event listeners on cleanup
        return () => {
            if (target) {
                target.removeEventListener('keydown', downHandler);
                target.removeEventListener('keyup', upHandler);
            }
        };
    }, [target, downHandler, upHandler]);

    return keyPressed;
}
