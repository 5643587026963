import { useUserState } from '@common/context/userContext';
import Input from '@components/Form/Input';
import useValidation from '@effects/useValidation';
import { actionTypes, useGlobalState } from '@state';
import { setValueToField } from '@utils/data-manipulator';
import { readOnlyWhenSubmittedV4 } from '@utils/question-editable';
import { useGetStaticAnswer } from '@utils/question-getter';
import { getIsRequired } from '@utils/question-validator';
import React, { useEffect } from 'react';

import { IStaticQuestionProps } from './StaticQuestion';

const StaticStringQuestion: React.FC<IStaticQuestionProps> = ({ question, data }) => {
    const answer = useGetStaticAnswer(question, data);

    const [{ triggerValidatorCount, copyErrors }, dispatch] = useGlobalState();
    const [{ user }] = useUserState();
    const isReadOnly = ('readOnly' in question && question.readOnly) || readOnlyWhenSubmittedV4(data, user);
    const isRequired = getIsRequired(question.validators);

    const { isValid, checkIsValid, evaluated, resetIsValid, errorMessage } = useValidation();
    useEffect(() => {
        if (copyErrors.isCopy) {
            checkIsValid(question, answer);
        }
    });
    useEffect(() => {
        if (!evaluated && answer) {
            checkIsValid(question, answer);
        }
    }, [evaluated, answer, checkIsValid, question]);

    useEffect(() => {
        if (triggerValidatorCount > 0) {
            checkIsValid(question, answer);
        }
        if (triggerValidatorCount === 0 && !copyErrors.isCopy) {
            resetIsValid();
        }
    }, [triggerValidatorCount, checkIsValid, resetIsValid, question, answer, copyErrors.isCopy]);

    return (
        <Input
            data-cy={question.field}
            labelText={question.text}
            helperText={''}
            value={answer ?? ''}
            onChange={(e) => {
                checkIsValid(question, e.target.value, true);
                dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: setValueToField(question.field, e.target.value) });
            }}
            maxLength={100}
            isValid={isValid}
            isReadOnly={isReadOnly}
            autoFocus={false}
            required={isRequired}
            errorText={errorMessage}
        />
    );
};

export default StaticStringQuestion;
