import { useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import EmptyListCard from '@components/Listing/EmptyListCard';
import ConfirmModal from '@containers/Consignments/components/ConfirmModal';
import { ConfirmModalRef } from '@containers/Consignments/components/ConfirmModal';
import { isValidPostSubmitSection } from '@containers/Consignments/ConsignmentEdit';
import useRoleValidation from '@effects/useRoleValidation';
import useValidation from '@effects/useValidation';
import { actionTypes, useGlobalState } from '@state';
import { IAnswer } from '@typings';
import { TRANSPORTER_DECLARED_BOX_QUESTION_ID, TRANSPORTER_EMAIL_QUESTION_ID, TRANSPORTER_QUESTION_TEXT } from '@utils/constants';
import { ConsignmentStatus } from '@utils/enum-transformers';
import { RoleTypeEnum, TransporterQuestionId } from '@utils/enums';
import { canCreateConsignment, isEditableAndDeletableDuplicateV4, isMyConsignment } from '@utils/question-editable';
import { getAllChildQuestions } from '@utils/question-getter';
import { sortByNumberDesc } from '@utils/question-sort';
import { isAnyQuestionAnsweredInCurrentSection } from '@utils/section-mapper';
import classnames from 'classnames';
import deepmerge from 'deepmerge';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import DynamicQuestion, { IDynamicQuestionProps } from './DynamicQuestion';
import DynamicRepeatableModal from './DynamicRepeatableModal';
import DynamicTableQuestion from './DynamicTableQuestion';

function isEditableEmptyPanel(sectionName: string, consignmentStatus: string, isProducer: boolean) {
    switch (consignmentStatus) {
        case ConsignmentStatus.DRAFT:
            return isProducer;
        case ConsignmentStatus.LOCKED:
            return false;
        case ConsignmentStatus.SUBMITTED:
            return isValidPostSubmitSection(sectionName) && isProducer;
    }
}

const DynamicRepeatableQuestion: React.FC<IDynamicQuestionProps> = ({ question, index }) => {
    const [{ dynamicPendingAnswers, originalAnswers, meta, triggerValidatorCount, copyErrors }, dispatch] = useGlobalState();
    const [{ user }] = useUserState();
    const { hasRole } = useRoleValidation();
    const transporterRemoveModal = useRef<ConfirmModalRef>();
    // console.count(`[Rendering in DynamicRepeatableQuestion: ${question.id}]`);

    // This is a special case where we want to re-use the questions up to X amount of times.
    // When reading, this is based on the highest index for this question being a parent
    // But this question itself is only asked once, the children are the ones that are repeated
    const allChildQuestions = getAllChildQuestions(question);

    let childAnswers = dynamicPendingAnswers?.answers.filter((answer) => allChildQuestions.some((cq) => cq.id === answer.questionId)) ?? [];
    if (!dynamicPendingAnswers?.dirty && (childAnswers.length === 0 || childAnswers.length < question.childQuestions.length)) {
        childAnswers = deepmerge(
            originalAnswers?.filter((answer) => question.childQuestions.some((cq) => cq.id === answer.questionId)),
            childAnswers
            // { arrayMerge: overwriteMerge }
        );
    }

    const highestIndex =
        (childAnswers
            ?.map((x) => x.index)
            ?.coalesce()
            .sort(sortByNumberDesc)
            ?.last() ?? 0) + 1;
    // Hide when the default movement information is set, but not when a real item has been inserted
    const hideView = (childAnswers?.some((x) => x.index === null) && childAnswers.filter((x) => x.index === null).length === childAnswers.length) ?? false;
    const showEmptyState = hideView || childAnswers.length === 0;
    const showNonEmptyState = !showEmptyState;

    // EPLAT-325 Don't let read-only users and non-creator modify anything
    const canCreateOrModifyConsignments = canCreateConsignment(user) && isMyConsignment({ createdBy: meta.createdBy }, user);

    const isReadOnly = question.readOnly || !canCreateOrModifyConsignments;
    const showHeaderAndFooter = !isReadOnly || (isReadOnly && isEditableAndDeletableDuplicateV4(user, isMyConsignment({ createdBy: meta.createdBy }, user), meta.status, question.id));

    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    // If we don't have any, then make sure not to insert at the incorrect index
    const [selectedIndex, setSelectedIndex] = useState<number>(highestIndex);

    // If repeatable question has max count, then hide add button when it equals to max count
    const maxArrayCount = question.validators.find(({ type }) => type === 'maxArrayCount')?.parameter;
    const showLessThanMax = maxArrayCount ? (highestIndex < parseInt(maxArrayCount, 10) ? true : false) : true;

    const { checkIsValid, errorMessage, resetIsValid } = useValidation();
    const [warningMessage, setWarningMessage] = useState<string>();
    const [isTransporterDetailsExist, setTransporterDetailsExist] = useState<boolean>(false);

    useEffect(() => {
        if (childAnswers.length === 0 && copyErrors.isCopy && isAnyQuestionAnsweredInCurrentSection(question, meta, originalAnswers)) {
            checkIsValid(question, '');
        }
    });
    useEffect(() => {
        if (triggerValidatorCount === 0 && !copyErrors.isCopy) {
            resetIsValid();
        }
        if (triggerValidatorCount > 0) {
            checkIsValid(question, '');
        }
    });

    useEffect(() => {
        const isTransporterAnswerPresent = question.text === TRANSPORTER_QUESTION_TEXT && originalAnswers.some((x) => Object.values(TransporterQuestionId).includes(x?.questionId));
        if (isTransporterAnswerPresent) {
            setTransporterDetailsExist(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalAnswers]);

    // HACK: Display this as a table when it is the chemical question
    if (question.text === 'Treatments') {
        return <DynamicTableQuestion question={question} index={index} />;
    }
    let transporterIndex = -1;
    if (question.text === TRANSPORTER_QUESTION_TEXT && hasRole(RoleTypeEnum.TRANSPORTER)) {
        const transporterEmailIds = childAnswers?.filter((x) => x.questionId === TRANSPORTER_EMAIL_QUESTION_ID && x.value === user?.claims?.email);
        if (!isEmpty(transporterEmailIds)) {
            transporterIndex = transporterEmailIds[0]?.index;
        }
    }

    const isEditRemoveButtonVisible = (showHeaderAndFooter || transporterIndex >= 0) && meta.status !== ConsignmentStatus.LOCKED;

    const showTransporterConfirmDeleteModal = (position: number) => {
        let removeCurrentOwnTransporter = question.text === TRANSPORTER_QUESTION_TEXT && hasRole(RoleTypeEnum.TRANSPORTER) && position === transporterIndex;

        return removeCurrentOwnTransporter ? transporterRemoveModal.current?.show() : removeAction(position);
    };

    const removeAction = async (position: number) => {
        // For deleting, we need to send the entire question set to pending because of the index changes
        setWarningMessage(`Confirm delete`);
        const deletedAnswers: IAnswer[] = [];
        childAnswers = dynamicPendingAnswers?.answers.filter((answer) => allChildQuestions.some((cq) => cq.id === answer.questionId)) ?? [];

        for (const cq of allChildQuestions) {
            childAnswers
                .map((x) => {
                    let retVal: IAnswer | null = x;

                    if (x.questionId === cq.id && x.index === position) {
                        retVal = null;
                        deletedAnswers.push(x);
                    }

                    return retVal;
                })
                .coalesce();
        }

        if (position === 0 && highestIndex === 1) {
            // This was the last one, so we want to delete everything
            // To do this, we need to send up just one value with null for the index
            dispatch({
                type: actionTypes.dynamicPendingAnswers.removeBatchDynamic,
                payload: [...deletedAnswers],
            });
            const isRequiredQuestionPresent = allChildQuestions?.some((x) => x?.validators?.some((y) => y?.type === 'required'));
            const deleteChildAnswers = allChildQuestions.map((x) => x.id).map((x) => ({ questionId: x, index: null, value: null }));
            dispatch({ type: actionTypes.dynamicPendingAnswers.updateBatchDynamic, payload: deleteChildAnswers });
            if (isRequiredQuestionPresent) {
                dispatch({ type: actionTypes.originalAnswers.removeBatchOriginal, payload: deletedAnswers });
            }
        } else if (position < highestIndex) {
            // Remove deleted answer
            let updatedDPA = dynamicPendingAnswers?.answers?.filter((ans) => !deletedAnswers.some((delAns) => (ans.questionId === delAns.questionId && ans.index === delAns.index ? true : false)));
            // indexing it properly after deletion
            const amountToAdjust = highestIndex - position;
            for (let i = 1; i <= amountToAdjust; i++) {
                const updatedIndex = position + i;
                // TODO: Can do better implementation
                for (const cq of allChildQuestions) {
                    // In-place update everything with this index to itself - 1, start at the lowest digit and move right for no conflicts
                    updatedDPA = updatedDPA.map((ans) => (ans.questionId === cq.id && ans.index === updatedIndex ? { ...ans, index: ans.index - 1 } : ans));
                }
            }
            dispatch({
                type: actionTypes.dynamicPendingAnswers.updateDynamicAnswers,
                payload: updatedDPA,
            });
            dispatch({ type: actionTypes.originalAnswers.updateOriginal, payload: { deletedAnswer: deletedAnswers, updatedAnswer: updatedDPA } });
        } else {
            dispatch({
                type: actionTypes.dynamicPendingAnswers.removeBatchDynamic,
                payload: [...allChildQuestions.map((x) => x.id).map((x) => ({ questionId: x, index: highestIndex - 1 }))],
            });
        }
        transporterRemoveModal.current?.hide();
    };
    const isRemoveButtonDisable = (position: number, highestIndex: number) => {
        if (question.text !== TRANSPORTER_QUESTION_TEXT) {
            return false;
        }
        const isTransporterAnswerPresent = dynamicPendingAnswers.answers.some((x) => Object.values(TransporterQuestionId).includes(x?.questionId));
        const transporterCheckboxAnswer = originalAnswers.firstOrDefault((a) => a.questionId === TRANSPORTER_DECLARED_BOX_QUESTION_ID && a.index === position)?.value;
        const isDisableRemoveButtonForLastTransporter = isTransporterDetailsExist ? isTransporterAnswerPresent && position === 0 && highestIndex === 1 : false;
        return meta.status === ConsignmentStatus.SUBMITTED && (isDisableRemoveButtonForLastTransporter || Boolean(transporterCheckboxAnswer));
    };

    const sectionPathName = window?.location?.pathname?.split('/')?.pop() || '';
    const isEditableEmptyCard = isEditableEmptyPanel(sectionPathName, meta.status, canCreateOrModifyConsignments);
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                :global(.EmptyList) {
                    :global(p) {
                        word-break: break-all;
                    }
                }

                .DuplicateQuestionGroup {
                    position: relative;
                    margin: grid(4) grid(8) 0 0;

                    @media (max-width: $md-max) {
                        margin: grid(4) grid(4) 0 0;
                    }

                    &--Header {
                        align-items: center;
                        display: flex;
                        justify-content: space-between;
                        padding: grid(2) grid(4);
                        border-bottom: 1px solid $color-border;
                        background-color: $color-fade;

                        h4 {
                            @include text-bold();
                        }

                        @media (prefers-color-scheme: dark) {
                            background-color: darken($color-fade, 80%);
                        }
                    }
                    &--Header--Buttons {
                        @include text-h6;
                    }
                    .DuplicateQuestionGroup--Header {
                        :global(.Greybutton) {
                            pointer-events: none;
                            background-color: $color-grey60;
                            border-color: $color-grey60;
                        }
                    }

                    :global(.label) {
                        margin-bottom: grid(2);
                    }

                    :global(.conditional--content) {
                        margin-top: grid(2);
                        padding-top: grid(2);
                    }

                    &--Inner {
                        margin: 0 0 grid(4);
                        &:empty {
                            display: none;
                        }
                        :global(.conditional--content) {
                            margin: 0;
                            padding: 0;
                            border: 0;
                        }
                    }

                    &--Table {
                        border: 1px solid $color-border;
                        border-radius: $border-radius;
                        &:empty {
                            display: none;
                        }
                    }

                    &--Shadow {
                        @include box-shadow-sm();
                    }
                }

                :global(.DuplicateQuestionGroup--DuplicateButton) {
                    margin-bottom: grid(8);
                }

                :global(.DuplicateQuestionGroup--Info) {
                    position: absolute;
                    top: 0;
                    right: grid(-8);
                    color: $color-grey47;
                    cursor: pointer;

                    @media (max-width: $md-max) {
                        right: grid(-6);
                    }
                }
            `}</style>
            {highestIndex > 1 && <h2>{question.text}</h2>}
            {!hideView &&
                new Array(highestIndex).fill(0).map((_, position) => (
                    <div key={`${highestIndex}${position}`} className={classnames('DuplicateQuestionGroup', { 'DuplicateQuestionGroup--Shadow': true })}>
                        {showNonEmptyState && (
                            <div className="DuplicateQuestionGroup--Inner">
                                <div className="DuplicateQuestionGroup--Table">
                                    {isEditRemoveButtonVisible && (
                                        <div className="DuplicateQuestionGroup--Header">
                                            {showHeaderAndFooter || hasRole(RoleTypeEnum.TRANSPORTER) ? (
                                                <h4>
                                                    {question.text === TRANSPORTER_QUESTION_TEXT ? 'Transporter' : 'Document'} {position + 1}
                                                </h4>
                                            ) : (
                                                <h4>Document</h4>
                                            )}
                                            <div className="DuplicateQuestionGroup--Header--Buttons">
                                                <Button
                                                    onClick={() => {
                                                        setShowEditModal(true);
                                                        setSelectedIndex(position);
                                                        dispatch({ type: actionTypes.triggerValidatorCount.resetTriggerValidatorCount });
                                                    }}
                                                    buttonType="tertiary"
                                                    buttonSize="xsmall"
                                                >
                                                    Edit details
                                                </Button>
                                                <Button
                                                    className={isRemoveButtonDisable(position, highestIndex) ? ' Greybutton' : ''}
                                                    onClick={() => showTransporterConfirmDeleteModal(position)}
                                                    buttonType="delete"
                                                    buttonSize="xsmall"
                                                >
                                                    Remove
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                    {question.childQuestions?.map((cq, idx) => (
                                        <DynamicQuestion key={`${highestIndex}${cq.id}${idx}${position}`} question={{ ...cq, readOnly: true }} index={position} isChildQuestion />
                                    ))}
                                </div>
                            </div>
                        )}
                        <ConfirmModal
                            actions={[
                                {
                                    style: 'delete',
                                    text: 'Remove',
                                    action: async () => {
                                        await removeAction(transporterIndex);
                                    },
                                },
                            ]}
                            ref={transporterRemoveModal}
                            modalId={`removeOwnTransporter-ConformModal`}
                            title={`Remove Transporter`}
                            onCancel={() => {
                                setWarningMessage(undefined);
                            }}
                            modalWarning={warningMessage}
                        >
                            <p>Once removed, {user?.claims?.email} (you) will no longer be able to access and view this consignment.</p>
                            <p>Are you sure you want to proceed with this action?</p>
                        </ConfirmModal>
                    </div>
                ))}
            {showEmptyState && (
                <EmptyListCard
                    action={() => {
                        if (!isEditableEmptyCard) return;
                        setShowEditModal(true);
                        setSelectedIndex(0);
                        dispatch({ type: actionTypes.triggerValidatorCount.resetTriggerValidatorCount });
                    }}
                    errorTitle={errorMessage}
                >
                    {isEditableEmptyCard ? (
                        <>
                            <ReactMarkdown source={question.text} />
                            <ReactMarkdown source={question.help ?? ''} />
                            <Button buttonType="secondary" buttonSize="small">
                                {question.tip}
                            </Button>
                        </>
                    ) : (
                        <>
                            <ReactMarkdown className="m-v-40" source={'No response provided'} />
                        </>
                    )}
                </EmptyListCard>
            )}

            {showNonEmptyState && isEditRemoveButtonVisible && showLessThanMax && (
                <Button
                    className="DuplicateQuestionGroup--DuplicateButton DuplicateQuestionGroup--DuplicateButton-NonEmpty"
                    buttonType="secondary"
                    buttonSize="small"
                    children={question.tip}
                    action={() => {
                        setShowEditModal(true);
                        setSelectedIndex(highestIndex);
                        dispatch({ type: actionTypes.triggerValidatorCount.resetTriggerValidatorCount });
                    }}
                />
            )}

            {showEditModal && (
                <DynamicRepeatableModal
                    question={question}
                    index={index}
                    onCancel={() => {
                        setShowEditModal(false);
                        dispatch({ type: actionTypes.triggerValidatorCount.resetTriggerValidatorCount });
                    }}
                    selectedIndex={selectedIndex}
                    transporterIndex={transporterIndex}
                />
            )}
        </>
    );
};

export default DynamicRepeatableQuestion;
