import Button from '@components/Button';
import { useWhyDidYouUpdate } from '@effects/useWhyDidYouUpdate';
import { useWindowWidth } from '@effects/useWindowWidth';
import { FILE_REGEX } from '@utils/constants';
import MLALogger from '@utils/logger';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import SignaturePad from 'react-signature-pad-wrapper';

import { ISignatureQuestionProps } from './Signature';

const SignatureWriteable: React.FC<ISignatureQuestionProps> = (props) => {
    const sigPadRef = useRef<any>(undefined);
    const { section: sectionRoute } = useParams<any>();

    MLALogger.LogCount('SignatureWriteable');
    useWhyDidYouUpdate('SignatureWriteable', props);

    const { title, value, onUpdate, hideClear } = props;

    const setImageData = useCallback((data: any) => {
        if (sigPadRef.current) {
            sigPadRef.current.clear();
            if (data) {
                sigPadRef.current.fromDataURL(data);
            }
        }
    }, []);

    const setImageDataFromValue = useCallback(() => {
        const current = sigPadRef.current.toDataURL();

        // Set the initial signature data
        if (value) {
            const data = value.replace(FILE_REGEX, '');
            if (current !== data) {
                setImageData(data);
            }
        } else if (current !== null || current !== '' || current !== 'data:') {
            setImageData(null);
        }
    }, [value, setImageData]);

    useEffect(() => {
        MLALogger.LogCount('SignatureWriteable resize on route change');
        // Call resize on load so density is calculated correctly
        // This is only needed when there is a signature
        if (sigPadRef.current?._signaturePad.isEmpty()) {
            (sigPadRef.current?._callResizeHandler as any)();
        }
    }, [sectionRoute]);

    const save = useCallback(() => {
        MLALogger.LogCount('[EffectCount] SignatureWriteable Save');
        const data = sigPadRef.current.toDataURL();
        if (onUpdate) {
            onUpdate(`file:signature.png;${data}`);
        }
        setImageData(data);
    }, [setImageData, onUpdate]);

    useEffect(() => {
        try {
            MLALogger.LogCount('[EffectCount] SignatureWriteable toggle read/write');

            sigPadRef.current.on();
            sigPadRef.current.onEnd = save;
        } catch (error) {
            MLALogger.Log(['SignatureWriteable'], { error }, 'error');
        }
    }, [save]);

    useEffect(() => {
        setImageDataFromValue();
    }, [setImageDataFromValue]);

    const windowWidth = useWindowWidth();
    useEffect(() => {
        MLALogger.LogCount('[EffectCount] SignatureWriteable resize on windowWidth changed');
        if (sigPadRef.current) {
            sigPadRef.current.handleResize();
        }
    }, [windowWidth]);

    const clearSignature = useCallback(() => {
        if (sigPadRef.current) {
            sigPadRef.current.clear();
            if (onUpdate) {
                onUpdate('');
            }
        }
    }, [onUpdate]);

    return (
        <div className="SignaturePadWrapper">
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                label {
                    display: block;
                    margin-bottom: grid(2);
                    &.has-link {
                        :global(.Button--Link) {
                            float: right;
                        }
                    }
                }

                .space-for-help-icon {
                    margin-right: grid(5);
                }

                .SignaturePadWrapper {
                    margin-bottom: grid(5);
                    max-width: $form-input-maxwidth;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: 100%;
                    height: 100%;

                    :global(canvas) {
                        min-height: grid(18);
                        max-height: grid(28);
                        background-color: $color-fade;
                        border: 1px dashed $color-border;
                        border-radius: $border-radius;

                        @media (prefers-color-scheme: dark) {
                            background: invert($color-fade, 50%);
                        }
                    }

                    @media not all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        &--Parent {
                            flex: 1;
                        }
                    }
                }
            `}</style>

            <label className={classnames('label has-link')}>
                <span>{title ?? 'Signature'}</span>
                {!hideClear && (
                    <Button buttonType="link" onClick={clearSignature}>
                        Clear
                    </Button>
                )}
            </label>
            <div className={'SignaturePadWrapper--Parent'}>
                <SignaturePad ref={sigPadRef} redrawOnResize />
            </div>
        </div>
    );
};

export default SignatureWriteable;
