/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteConsignmentInput = {
    clientMutationId?: string | null;
    id: string;
};
export type deleteConsignmentMutationVariables = {
    input: DeleteConsignmentInput;
    envdAccountId: string;
};
export type deleteConsignmentMutationResponse = {
    readonly deleteConsignment: {
        readonly success: boolean | null;
    } | null;
};
export type deleteConsignmentMutation = {
    readonly response: deleteConsignmentMutationResponse;
    readonly variables: deleteConsignmentMutationVariables;
};



/*
mutation deleteConsignmentMutation(
  $input: DeleteConsignmentInput!
  $envdAccountId: String!
) {
  deleteConsignment(input: $input, envdAccountId: $envdAccountId) {
    success
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envdAccountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteConsignment",
    "kind": "LinkedField",
    "name": "deleteConsignment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteConsignmentMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "deleteConsignmentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "4cdb2dcaa6297e76bbbfd6e239e341c6",
    "id": null,
    "metadata": {},
    "name": "deleteConsignmentMutation",
    "operationKind": "mutation",
    "text": "mutation deleteConsignmentMutation(\n  $input: DeleteConsignmentInput!\n  $envdAccountId: String!\n) {\n  deleteConsignment(input: $input, envdAccountId: $envdAccountId) {\n    success\n  }\n}\n"
  }
};
})();
(node as any).hash = '3127fd82582b542b88af2e75aab8701c';
export default node;
