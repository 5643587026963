import Button from '@components/Button';
import Modal from '@components/Modal';
import { actionTypes, useGlobalState } from '@state';
import { TRANSPORTER_DECLARED_BOX_QUESTION_ID, TRANSPORTER_QUESTION_TEXT, UNIQUE_WHEN_VALIDATOR_TYPE } from '@utils/constants';
import { TransporterQuestionId } from '@utils/enums';
import MLALogger from '@utils/logger';
import { getIsRequired, validate } from '@utils/question-validator';
import React, { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import DynamicQuestion, { IDynamicQuestionProps } from './DynamicQuestion';

const DynamicRepeatableModal: React.FC<IDynamicQuestionProps & { onCancel: () => void; selectedIndex: number; transporterIndex: number }> = ({
    question,
    onCancel,
    selectedIndex,
    transporterIndex,
}) => {
    const [{ dynamicPendingAnswers, copyErrors, meta }, dispatch] = useGlobalState();
    const [tempState] = useState<any>(dynamicPendingAnswers);
    const [isValid, setIsValid] = useState<boolean>(true);
    // console.count(`[Rendering in DynamicRepeatableModal: ${question.id}]`);

    useEffect(() => {
        const resize = setTimeout(() => {
            dispatch({ type: actionTypes.meta.setTransporterEdit, payload: { ...meta, selectedIndex: selectedIndex } });
            // Send resize for auto resizing components
            // IE11
            let event = null;
            if (typeof Event === 'function') {
                event = new Event('resize');
            } else {
                event = document.createEvent('Event');
                event.initEvent('resize', true, true);
            }
            window.dispatchEvent(event);
        }, 200);
        return () => clearTimeout(resize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateRepeatable = useCallback(() => {
        dispatch({ type: actionTypes.triggerValidatorCount.increaseTriggerValidatorCount });
        let valid = true;
        if (transporterIndex >= 0) {
            dispatch({ type: actionTypes.meta.setTransporterEdit, payload: { ...meta, isTransporterEdit: true, transporterIndex: transporterIndex } });
        } else {
            dispatch({ type: actionTypes.meta.setTransporterEdit, payload: { ...meta, isTransporterEdit: false, transporterIndex: -1 } });
        }

        for (const childQuestion of question.childQuestions) {
            let isChildQuestionRequired = getIsRequired(childQuestion.validators);

            const answer = dynamicPendingAnswers.answers.firstOrDefault((a) => a.questionId === childQuestion.id && a.index === selectedIndex);
            const transporterCheckboxAnswer = dynamicPendingAnswers.answers.firstOrDefault((a) => a.questionId === TRANSPORTER_DECLARED_BOX_QUESTION_ID && a.index === selectedIndex);
            // ENVDW-959 for transporter we have some special rules, need to check if they are answered or not
            // when declaration box ticked
            if (question.text === TRANSPORTER_QUESTION_TEXT) {
                const isUniqueValidatorExist = childQuestion.validators.some((x) => x.type === UNIQUE_WHEN_VALIDATOR_TYPE);
                if (transporterCheckboxAnswer?.value === 'True' && childQuestion.id !== TransporterQuestionId.email) {
                    valid = validate(childQuestion.validators, answer?.value, childQuestion, selectedIndex, dynamicPendingAnswers.answers);
                }
                if (transporterCheckboxAnswer?.value !== 'True' && answer && isUniqueValidatorExist) {
                    valid = validate(childQuestion.validators, answer?.value, childQuestion, selectedIndex, dynamicPendingAnswers.answers);
                }
                if (!valid) break;
            }
            if (answer) {
                valid = validate(childQuestion.validators, answer.value);
                // If the question isn't required then we will still allow for adding the repeatable
                // Only ignore if it is empty though since once it has an answer we should keep validation
                if (!isChildQuestionRequired && !valid && !Boolean(answer.value)) {
                    valid = true;
                }
                if (!valid) {
                    return false;
                }

                // Now because we have 3 level nested questions for just one thing, just check it here too
                // TODO: Move this function out so we can just recurse it
                if (childQuestion.childQuestions && Array.isArray(childQuestion.childQuestions)) {
                    for (const childChildQuestion of childQuestion.childQuestions) {
                        const isChildChildQuestionRequired = getIsRequired(childChildQuestion.validators);
                        const childChildAnswer = dynamicPendingAnswers.answers.firstOrDefault((a) => a.questionId === childChildQuestion.id && a.index === selectedIndex);
                        if (childChildAnswer) {
                            valid = validate(childChildQuestion.validators, childChildAnswer.value);
                            MLALogger.Log(['DynamicRepeatableModal'], { question, childQuestion, childChildQuestion, childChildAnswer, valid });
                            // If the question isn't required then we will still allow for adding the repeatable
                            // Only ignore if it is empty though since once it has an answer we should keep validation
                            if (!isChildChildQuestionRequired && !valid && !Boolean(childChildAnswer.value)) {
                                valid = true;
                            }
                            // If anything is invalid, just exist early
                            if (!valid) {
                                return false;
                            }
                        }
                    }
                }
            } else {
                // If we couldn't find an answer and it is required, also return false
                if (isChildQuestionRequired) {
                    MLALogger.Log(['DynamicRepeatableModal'], { message: 'Could not find an answer inside children which were required' });
                    return false;
                }
            }
        }
        if (valid) {
            dispatch({ type: actionTypes.triggerValidatorCount.resetTriggerValidatorCount });
        }
        MLALogger.Log(['DynamicRepeatableModal'], { question, valid });
        return valid;
    }, [dynamicPendingAnswers.answers, selectedIndex, dispatch, question, meta, transporterIndex]);

    return (
        <>
            <style jsx>
                {`
                    :global(.section-question-tip) {
                        word-break: break-word;
                    }

                    .DynamicRepeatableQuestion {
                        width: 100%;
                    }
                `}
            </style>

            <Modal
                id={'dynamic-repeatable-modal'}
                show
                modalHeader={
                    <>
                        <h3>{question.text}</h3>
                        {question.help && <ReactMarkdown source={question.help} className="section-question-tip helper muted" />}
                    </>
                }
                modalFooter={
                    <>
                        <Button
                            buttonType={isValid ? 'primary' : 'delete'}
                            buttonSize="small"
                            type={'submit'}
                            onClick={() => {
                                // But only allow close if all the answers entered are valid
                                if (validateRepeatable()) {
                                    // after all repeateded questions delete
                                    // then indexes for all child questionIds are null, and
                                    // if any child question is answered then it updates the dynamic pending answers
                                    // by making sure that there are no null indexes
                                    let updatedDPA = dynamicPendingAnswers.answers.filter((ans) => question.childQuestions.every((q) => !(ans.questionId === q.id && ans.index === null)));
                                    if (copyErrors.isCopy) {
                                        // clear all the copyerrors so copyerrors list can be populated with all the latest errors after change
                                        dispatch({ type: actionTypes.copyErrors.clearErrors });
                                    }
                                    dispatch({
                                        type: actionTypes.dynamicPendingAnswers.updateDynamicAnswers,
                                        payload: updatedDPA,
                                    });
                                    // Since all values should already have been updated, we can just close
                                    setIsValid(true);
                                    onCancel();
                                } else {
                                    setIsValid(false);
                                }
                            }}
                        >
                            Add
                        </Button>
                        <Button
                            buttonType={'secondary'}
                            buttonSize="small"
                            onClick={() => {
                                setIsValid(true);

                                // We need to loop over all the children to revert it (only if it is within pending)
                                dispatch({ type: actionTypes.dynamicPendingAnswers.setDynamic, payload: tempState.answers, isDirty: tempState.dirty });
                                onCancel();
                            }}
                        >
                            Cancel
                        </Button>
                    </>
                }
            >
                <div className={'DynamicRepeatableQuestion'}>
                    {[...question.childQuestions]
                        .sort((a, b) => a.order - b.order)
                        .map((cq, idx) => (
                            <DynamicQuestion key={`${cq.id}${idx}`} question={cq} index={selectedIndex} />
                        ))}
                </div>
            </Modal>
        </>
    );
};

export default DynamicRepeatableModal;
