import SelectableList, { SelectableListItem } from '@components/Form/SelectableList';
import React, { useEffect, useState } from 'react';

interface ItemProps {
    labelText: string;
    errorText: string;
}

interface Props {
    initialRequiredItems: SelectableListItem[];
    initialOptionalItems: SelectableListItem[];

    submitCount: number;
    onSubmit: (items: SelectableListItem[]) => void;
    onChange?: (items: SelectableListItem[]) => void;

    requiredItemsProps: ItemProps;
    optionalItemsProps: ItemProps;

    isReadOnly?: boolean;
    isCheckListReadOnly?: boolean;
    isRadioListReadOnly?: boolean;

    filterOptionalBySelectedRequired?: (selected: SelectableListItem, prevOptionals: SelectableListItem[]) => SelectableListItem[];
}

const SelectableBlockView: React.FC<Props> = ({
    initialRequiredItems,
    initialOptionalItems,
    submitCount,
    onSubmit,
    onChange,
    requiredItemsProps,
    optionalItemsProps,
    isReadOnly,
    isCheckListReadOnly,
    isRadioListReadOnly,
    filterOptionalBySelectedRequired,
}) => {
    // State for Radio List
    const [requiredItems, setRequiredItems] = useState<SelectableListItem[]>(initialRequiredItems);

    // State for Checkbox List
    const [optionalItems, setOptionalItems] = useState<SelectableListItem[]>(initialOptionalItems);

    const [isValid, setIsValid] = useState<boolean>(requiredItems.filter((x) => Boolean(x.selected)).length >= 1);

    useEffect(() => {
        const valid = requiredItems.filter((x) => Boolean(x.selected)).length >= 1;
        setIsValid(valid);

        if (valid && submitCount > 0) {
            onSubmit([...requiredItems, ...optionalItems]);
        }
    }, [submitCount, onSubmit, optionalItems, requiredItems]);

    return (
        <>
            {/* We want to group the Required ones inside a radio list */}
            <SelectableList
                radioList
                isReadOnly={isReadOnly || isRadioListReadOnly}
                isValid={submitCount === 0 ? undefined : isValid}
                items={requiredItems}
                onChange={(listItem) => {
                    setRequiredItems((s) => {
                        const updated = s.map((item) => (item.id === listItem.id ? { ...listItem, selected: !listItem.selected } : { ...item, selected: false }));
                        if (onChange) {
                            onChange([...updated, ...optionalItems]);
                        }
                        return updated;
                    });
                    if (filterOptionalBySelectedRequired) {
                        setOptionalItems((prev) => filterOptionalBySelectedRequired(listItem, prev));
                    }
                }}
                {...requiredItemsProps}
            />

            <hr />

            {/* And group the optional ones inside a checkbox list */}
            {optionalItems.length > 0 && (
                <SelectableList
                    checkList
                    isReadOnly={isReadOnly || isCheckListReadOnly}
                    isValid={submitCount === 0 ? undefined : isValid}
                    items={optionalItems}
                    onChange={(listItem) => {
                        setOptionalItems((s) => {
                            const updated = s.map((f) => (f.id === listItem.id ? { ...f, selected: !f.selected } : f));
                            if (onChange) {
                                onChange([...requiredItems, ...updated]);
                            }
                            return updated;
                        });
                    }}
                    {...optionalItemsProps}
                />
            )}
        </>
    );
};

export default SelectableBlockView;
