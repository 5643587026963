import MLALogger from '@utils/logger';
import { useEffect, useRef } from 'react';

export function useWhyDidYouUpdate(name: string, props: any) {
    // Get a mutable ref object where we can store props ...

    // ... for comparison next time this hook runs.

    const previousProps = useRef<any>(null);

    useEffect(() => {
        if (previousProps.current) {
            // Get all keys from previous and current props

            const allKeys = Object.keys({ ...previousProps.current, ...props });

            // Use this object to keep track of changed props

            const changesObj: { [key: string]: any } = {};

            // Iterate through keys

            allKeys.forEach((key) => {
                // If previous is different from current

                if (previousProps.current[key] !== props[key]) {
                    // Add to changesObj

                    changesObj[key] = {
                        from: previousProps.current[key],

                        to: props[key],
                    };
                }
            });

            // If changesObj not empty then output to console

            if (Object.keys(changesObj).length) {
                MLALogger.Log(['useWhyDidYouUpdate'], { name, changesObj });
            }
        }

        // Finally update previousProps with current props for next hook call
        previousProps.current = props;
    });
}
