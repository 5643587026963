import { useUserState } from '@common/context/userContext';
import { WarningsContext } from '@common/context/warningsContext';
import Button from '@components/Button';
import Input, { InputType } from '@components/Form/Input';
import Loader from '@components/Loader';
import CreateOrSaveTemplate, { createOrSaveTemplateMutation } from '@containers/Consignments/mutations/createOrSaveTemplate';
import useWindowTitle from '@effects/useWindowTitle';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { graphql, useMutation, useQuery } from 'relay-hooks';

import { TemplateAddQuery } from './__generated__/TemplateAddQuery.graphql';

const query = graphql`
    query TemplateAddQuery($consignmentNumber: String!, $envdAccountId: String!) {
        consignment(id: $consignmentNumber, envdAccountId: $envdAccountId) {
            id
            number
        }
    }
`;

const TemplateAddPage: React.FC<{ consignment: any }> = ({ consignment }) => {
    const [mutate, { loading }] = useMutation(createOrSaveTemplateMutation);

    const [, setWarningMessage] = useContext(WarningsContext);
    const [{ user }] = useUserState();
    const [state, setState] = useState<{
        name: string | null;
    }>({
        name: null,
    });
    const envdAccountId = user?.accountDetails?.id;
    const history = useHistory();

    const isValidSelection = state.name != null && state.name.length > 0;

    if (loading) {
        return <Loader isLoading={true} error={false} retry={() => null} timedOut={false} pastDelay={false} />;
    }

    return (
        <>
            <div className="title-section">
                <h1>Create template</h1>
            </div>

            <Input
                errorText={'Please enter a name'}
                isValid={isValidSelection}
                labelText={'Name'}
                className="class-for-input"
                inputType={InputType.Text}
                value={state.name || ''}
                onChange={(e) => {
                    const v = e.target.value;
                    setState((s) => ({ ...s, name: v }));
                }}
            />

            <Button
                buttonType="primary"
                children="Create"
                disabled={loading}
                action={async () => {
                    // Check if forms are valid before submission
                    if (!isValidSelection) {
                        console.error('Invalid form');
                        return;
                    }

                    try {
                        await CreateOrSaveTemplate(
                            mutate,
                            {
                                name: state.name,
                                basedOn: consignment?.number,
                            },
                            envdAccountId
                        );

                        history.push('/templates');
                    } catch (error) {
                        console.error('[TemplateAddFragment]', error);
                        setWarningMessage(true);
                    }
                }}
            />
        </>
    );
};

const TemplateAdd: React.FC = () => {
    const [{ user }] = useUserState();
    const { id } = useParams<any>();
    const envdAccountId = user?.accountDetails?.id;
    const { data } = useQuery<TemplateAddQuery>(query, { consignmentNumber: id, envdAccountId }, { fetchPolicy: 'network-only' });

    useWindowTitle('Add Template');

    if (data) {
        const { consignment } = data;

        return <TemplateAddPage consignment={consignment} />;
    } else {
        return <Loader error={''} isLoading pastDelay={false} timedOut={false} retry={() => null} />;
    }
};

export default TemplateAdd;
