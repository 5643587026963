import * as React from 'react';
const SvgBuyer = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" fill="none" viewBox="0 0 200 200" {...props}>
        <path d="M111 201H1.034V1.097h199.807V201zm-63.52-99H27.759V91.356H2.198v6.658h17.548V160.1H2.298v7.583h25.95v-10.079c7.372.545 14.284.836 21.141 1.615 9.39 1.067 18.736 2.506 28.101 3.781 6.622.903 13.238 1.854 19.868 2.686 9.352 1.173 18.259 2.334 27.596-2.445 23.482-12.019 47.536-22.918 71.348-34.294 4.05-1.935 5.011-5.69 2.579-9.572-8.769-13.992-26.23-17.716-40.13-8.653-5.38 3.509-10.906 6.797-17.023 10.594V113c-16.69 0-33.006.127-49.318-.098-3.642-.05-7.352-1.055-10.884-2.109-10.934-3.261-20.827-10.382-34.045-8.793M144 59.585c-.22-4.941.292-10.048-.782-14.795-5.273-23.306-26.708-35.631-48.883-31.623-27.122 4.902-41.7 34.251-30.128 59.393 6.444 14.001 24.17 26.74 44.572 23.68 17.815-2.672 33.936-18.561 35.22-36.655M8.746 148.086l-.957 6.683h6.975v-6.76c-1.848 0-3.474 0-6.018.077" />
        <path
            fill="#004976"
            d="M47.976 102c12.723-1.589 22.616 5.532 33.55 8.793 3.532 1.054 7.242 2.059 10.884 2.109 16.312.225 32.629.098 49.318.098v8.315c6.117-3.797 11.643-7.085 17.024-10.594 13.898-9.063 31.36-5.339 40.129 8.653 2.432 3.882 1.47 7.637-2.579 9.572-23.812 11.376-47.866 22.275-71.348 34.294-9.337 4.78-18.244 3.618-27.596 2.445-6.63-.832-13.246-1.783-19.868-2.686-9.365-1.275-18.712-2.714-28.1-3.78-6.858-.78-13.77-1.071-21.143-1.616v10.08H2.297v-7.584h17.449V98.014H2.199v-6.658h25.559V102zm104.41 38.947c12.903-6.2 25.805-12.402 38.73-18.614-4.964-8.495-18-11.257-27.107-5.362-6.518 4.22-13.39 7.915-19.739 12.367-10.871 7.625-22.188 12.019-36.022 10.972-10.468-.793-21.437-2.347-31.78 2.298-.642.288-3.048-2.019-3.681-3.52-.414-.98.59-3.614 1.48-3.902 3.35-1.085 6.903-2.011 10.401-2.093 10.821-.254 21.653-.035 32.48-.114 7.008-.05 15.511-3.834 16.458-11.979-11.343 0-22.7-.563-33.975.161-10.86.698-20.882-1.755-30.2-6.537-13.282-6.814-27.17-5.919-41.098-5.36v39.928l29.587 3.803c15.525 1.996 31.038 4.105 46.586 5.906 3.728.431 8.063.939 11.28-.484 12.134-5.365 23.923-11.512 36.6-17.47"
        />
        <path
            fill="#004976"
            d="M144 60.026c-1.284 17.653-17.405 33.542-35.22 36.214-20.403 3.06-38.128-9.679-44.572-23.68-11.572-25.142 3.006-54.491 30.128-59.393 22.175-4.008 43.61 8.317 48.883 31.623 1.074 4.747.562 9.854.781 15.236m-8.421-8.955c-.097-.135-.267-.264-.28-.407-1.54-17.854-20.663-31.988-38.126-28.884-16.692 2.966-31.25 21.153-27.362 38.975 3.01 13.798 16.293 27.109 31.984 27.185 18.403.089 34.534-14.697 33.784-36.87M9.204 148.048c2.085-.039 3.711-.039 5.559-.039v6.76H7.788c.338-2.36.648-4.522 1.416-6.721"
        />
        <path
            fill="#FFF"
            d="M152.007 140.999c-12.298 5.906-24.087 12.053-36.22 17.418-3.218 1.423-7.553.915-11.281.484-15.548-1.8-31.06-3.91-46.586-5.906l-29.587-3.803v-39.928c13.928-.559 27.816-1.454 41.098 5.36 9.318 4.782 19.34 7.235 30.2 6.537 11.275-.724 22.632-.161 33.975-.161-.947 8.145-9.45 11.929-16.458 11.98-10.827.078-21.659-.141-32.48.113-3.498.082-7.05 1.008-10.401 2.093-.89.288-1.894 2.922-1.48 3.902.633 1.501 3.038 3.808 3.68 3.52 10.344-4.645 21.313-3.091 31.781-2.298 13.834 1.047 25.15-3.347 36.022-10.972 6.348-4.452 13.22-8.148 19.739-12.367 9.106-5.895 22.143-3.133 27.107 5.362-12.925 6.212-25.827 12.413-39.109 18.666M135.766 51.469c.563 21.774-15.568 36.56-33.971 36.47-15.691-.075-28.974-13.386-31.984-27.184-3.888-17.822 10.67-36.009 27.362-38.975 17.463-3.104 36.586 11.03 38.127 28.884.012.143.182.272.466.805m-25.489-1.677c3.778.755 7.192.443 7.638-4.28.429-4.544-3.362-9.495-7.659-11.55-1.372-.656-2.121-2.54-3.275-3.748-1.4-1.467-2.912-3.889-4.405-3.911-1.505-.023-4.055 2.203-4.344 3.776-.468 2.547-1.37 3.303-3.683 4.275-4.133 1.737-7.36 4.621-7.616 9.848-.262 5.355 1.145 9.322 6.698 11.009 4.454 1.353 8.897 2.746 13.304 4.24 2.774.941 4.733 3.363 2.278 5.439-2.19 1.852-5.884 2.688-8.876 2.605-2.689-.074-5.537-1.423-5.587-5.558-.017-1.32-3.005-3.683-4.528-3.601-1.477.078-3.818 2.547-4.045 4.204-.903 6.578 3.233 11.234 11.65 13.981.054.453.132.936.166 1.422.168 2.443-.067 5.042 3.642 5.093 3.39.047 5.162-1.127 5.527-4.772.118-1.184 1.93-2.665 3.284-3.182 7.513-2.87 11.216-11.076 7.05-17.787-1.872-3.017-5.656-4.848-8.193-7.599.116-.136.233-.273.974.096"
        />
        <path
            fill="#004976"
            d="M108.923 50.077c2.917 2.37 6.7 4.2 8.574 7.218 4.165 6.711.462 14.918-7.051 17.787-1.354.517-3.166 1.998-3.284 3.182-.365 3.645-2.138 4.819-5.527 4.772-3.709-.05-3.474-2.65-3.642-5.093-.034-.486-.112-.97-.165-1.422-8.418-2.747-12.554-7.403-11.651-13.981.227-1.657 2.568-4.126 4.045-4.204 1.523-.082 4.511 2.28 4.528 3.601.05 4.135 2.898 5.484 5.587 5.558 2.992.083 6.686-.753 8.876-2.605 2.455-2.076.496-4.498-2.278-5.439-4.407-1.494-8.85-2.887-13.304-4.24-5.553-1.687-6.96-5.654-6.698-11.009.255-5.227 3.483-8.11 7.616-9.848 2.312-.972 3.215-1.728 3.683-4.275.289-1.573 2.839-3.8 4.344-3.776 1.493.022 3.004 2.444 4.405 3.911 1.154 1.208 1.903 3.092 3.275 3.748 4.297 2.055 8.088 7.006 7.66 11.55-.447 4.723-3.861 5.035-8.187 3.732-1.33-6.897-6.84-9.614-12-6.13-.734.495-1.69 1.57-1.572 2.194.161.852 1.128 1.966 1.966 2.204 3.556 1.01 7.193 1.736 10.8 2.565"
        />
        <path
            fill="#FFF"
            d="M109.113 49.887c-3.797-.64-7.434-1.366-10.99-2.375-.838-.238-1.805-1.352-1.966-2.204-.118-.623.838-1.699 1.572-2.194 5.16-3.484 10.67-.767 11.688 5.877.119.432.002.569-.304.896"
        />
    </svg>
);
export default SvgBuyer;
