import * as React from 'react';
const SvgDoc = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={38} fill="none" {...props} viewBox="0 0 30 38">
        <path
            fill="#004976"
            d="M7.667 26.333h14.667v3.666H7.667zm0-7.334h14.667v3.667H7.667zm11-18.333H4A3.677 3.677 0 0 0 .333 4.333v29.333c0 2.017 1.632 3.667 3.649 3.667H26a3.677 3.677 0 0 0 3.667-3.667v-22zm7.333 33H4V4.333h12.834v9.166H26z"
        />
    </svg>
);
export default SvgDoc;
