import Button from '@components/Button';
import Input, { InputType } from '@components/Form/Input';
import CreateAdmin, { createAdminMutation } from '@containers/Consignments/mutations/createAdmin';
import { GraphqlError, IUser } from '@typings';
import { GraphqlErrorType } from '@utils/enum-transformers';
import { AccountTypeEnum } from '@utils/enums';
import MLALogger from '@utils/logger';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'relay-hooks';

import { ResponseContentType } from '.';
interface AddAdminProps {
    user: IUser;
    setResponseContent: React.Dispatch<React.SetStateAction<ResponseContentType>>;
    onSuccess: () => void;
}

const validEmailRegex = new RegExp(`^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$`.replace(/\//g, ''));
const AddAdmin: React.FunctionComponent<AddAdminProps> = ({ user, setResponseContent, onSuccess }) => {
    const [createAdminMutate] = useMutation(createAdminMutation);

    const [addBtnDisable, setaddBtnDisable] = useState(false);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    // disbale add btn based on email field level validation
    useEffect(() => {
        const emailLength = email.toString().length;
        if (emailLength > 0) {
            setaddBtnDisable(!validEmailRegex.test(email));
        } else {
            setaddBtnDisable(true);
        }
    }, [email, user.claims.email]);

    const onClickAddAdmin = async () => {
        setLoading(true);
        if (email) {
            const envdAccountId = user?.claims.accounts.filter((acc) => acc.accountType === AccountTypeEnum.ADMIN)[0].envdAccountId;
            try {
                const response = await CreateAdmin(createAdminMutate, { email }, envdAccountId);
                setResponseContent({
                    title: 'Add Admin',
                    successMessage: response?.createAdmin?.data,
                    warningMessage: undefined,
                });
                onSuccess();
            } catch (e) {
                var errors = e as GraphqlError[];
                var warningMsg = errors.firstOrDefault((error) => error.extensions?.data?.OutcomeStatus.toLowerCase() === GraphqlErrorType.Error.toLowerCase())?.extensions?.data?.Message;
                setResponseContent({
                    title: '',
                    successMessage: undefined,
                    warningMessage: warningMsg,
                });
                MLALogger.Log(['CreateAdmin'], e, 'error');
            }
        }
        setLoading(false);
    };
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                .adminHeaderH2 h2 {
                    margin: grid(10) 0;
                    color: $color-black;
                    font-size: 26px;
                    margin-bottom: 20px;
                    margin: 40px 0px;
                    @media (max-width: $sm-min) {
                        margin: grid(6) 0;
                    }
                }

                .no-consignment {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: auto;
                    width: 100%;
                }

                .input-field {
                    width: 100%;
                    margin: 10px 0px;
                }

                .adminHeaderH2 {
                    max-width: $form-input-maxwidth;
                    margin-bottom: 10px;
                }

                .btn-parent {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                }

                .btn {
                    display: flex;
                    justify-content: flex-end;
                    padding: 16px 0px;
                    margin-top: 10px;
                }
            `}</style>
            <div className="flex-column adminHeaderH2">
                <h2 data-cy="welcome-heading">{`Add Another Admin`}</h2>
                <p>To access the producer's details, please ensure that user with entered email is an admin in the LPA system.</p>
            </div>
            <div className="tbl-collapse">
                <div className={'no-consignment'}>
                    <div className={'input-field'}>
                        <Input
                            className="input-field"
                            labelText="Email"
                            maxLength={100}
                            data-cy="email"
                            errorText={'Please enter a valid email'}
                            isValid={email.trim().length > 0 ? validEmailRegex.test(email) : undefined}
                            inputType={InputType.Text}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                        />
                    </div>
                    <div className="btn-parent">
                        <div className="btn">
                            <Button buttonType={addBtnDisable ? 'disable' : 'primary'} fullWidth={true} disabled={addBtnDisable ? true : false} onClick={onClickAddAdmin} loading={loading}>
                                Add Admin
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddAdmin;
