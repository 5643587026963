import { actionTypes, useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import createOrSaveUser, { CreateOrSaveUserMutation } from '@containers/Consignments/mutations/createOrSaveUserMutation';
import { IUserProfileFields } from '@typings';
import { PHONE_REGEX } from '@utils/constants';
import { useEffect, useState } from 'react';
import { useMutation } from 'relay-hooks';

import { checkValidField } from '../Register/index';

interface PropsDefinition {
    setReadMode: (value: boolean) => void;
    isReadMode: boolean;
    userFields: IUserProfileFields;
    setUserFields: (value: IUserProfileFields) => void;
}

const ProfileHeader = (props: PropsDefinition) => {
    const [{ user }, dispatch] = useUserState();
    const [mutate] = useMutation(CreateOrSaveUserMutation);
    const [loading, setLoading] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            setError(false);
        }, 4000);
    }, [isError]);

    const callUpdateUserProfile = async (userFields: IUserProfileFields) => {
        const dataParam = {
            defaultAccount: userFields?.defaultAccount || user?.claims?.accounts[0]?.envdAccountId,
            firstName: userFields?.firstName,
            lastName: userFields?.lastName,
            mobilePhone: userFields?.mobile,
            isTransporter: userFields?.isCarrier,
            rego: userFields?.isCarrier ? userFields?.reg_num : '',
            receiveEmails: userFields?.receiveEmails,
            signature: userFields?.signature,
        };
        setLoading(true);
        const response = await createOrSaveUser(mutate, dataParam);
        setLoading(false);

        if (response) {
            dispatch({
                type: actionTypes.user.setUserClaims,
                value: response,
            });
            props.setReadMode(true);
        } else {
            setError(true);
        }
    };

    const saveUserProfile = () => {
        const isValidFName = checkValidField(props.userFields.firstName);
        const isValidLName = checkValidField(props.userFields.lastName);
        const isValidMob = checkValidField(props.userFields.mobile) && PHONE_REGEX.test(props.userFields.mobile);
        const isValidReg = props.userFields.isCarrier ? checkValidField(props?.userFields?.reg_num || '') : undefined;

        props.setUserFields({
            ...props.userFields,
            isValidFirstName: isValidFName ? undefined : false,
            isValidLastName: isValidLName ? undefined : false,
            isValidMobileNum: isValidMob ? undefined : false,
            isValidRegNum: isValidReg ? undefined : false,
        });

        if (isValidFName && isValidLName && isValidMob && ((isValidReg === undefined && !props.userFields.isCarrier) || isValidReg)) {
            callUpdateUserProfile(props.userFields);
            dispatch({
                type: actionTypes.user.setAccount,
                value: user.claims.accounts.find(({ envdAccountId }) => envdAccountId === props.userFields.defaultAccount),
            });
            return;
        }
    };

    return (
        <>
            <div className="flex-center-row flex-between row">
                <h1 className="profile-heading">eNVD User Profile</h1>
                {props.isReadMode ? (
                    <div className="margin-v-center user-profile">
                        <Button className="update-button" buttonType="primary" children="Update Profile" action={() => props.setReadMode(false)} />
                    </div>
                ) : (
                    <div>
                        {!loading && <Button className="cancel-button" buttonType="secondary" children="Cancel" action={() => props.setReadMode(true)} />}
                        <Button className="save-button" buttonType="primary" children={'Save Changes'} action={saveUserProfile} loading={loading} />
                    </div>
                )}
            </div>
            {isError && (
                <div className="error-panel">
                    <h6 className="error-text">Please try again later.</h6>
                </div>
            )}
            <style jsx>{`
                @import 'mixins';
                @import 'vars';
                @import 'utils';
                @import 'layout';

                .user-profile {
                    @media (max-width: $sm-min) {
                        margin-bottom: grid(8);
                    }

                    @media (max-width: $md-max) {
                        display: flex;
                    }
                }

                .error-panel {
                    display: flex;
                    flex: 2 0 65%;
                    border: 2px solid $color-error-hover;
                    border-radius: 5px;
                    height: auto;
                    flex-direction: row;
                    width: 50%;
                    margin-bottom: 10px;
                    color: red;
                    justify-content: center;
                    margin-left: auto;
                    margin-right: auto;
                }

                h1 {
                    margin: grid(10) 0;
                    color: $isc-blue;
                    @media (max-width: $sm-min) {
                        margin: grid(6) 0;
                    }
                }
            `}</style>
        </>
    );
};

export default ProfileHeader;
