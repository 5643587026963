import { CheckIcon, ChevronDownIcon, ChevronUpIcon, ErrorIcon, InfoIcon } from '@assets/icons';
import Button from '@common/components/Button';
import classnames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import posed from 'react-pose';

export interface ValidationProps {
    total: number;
    valid: number;
    error?: string;
}

interface Props {
    children?: React.ReactNode;
    title: string;
    subtitle?: string;
    validation?: ValidationProps;
    active?: boolean;
    onToggle?: (...args: any) => void;
    openText?: string;
    closeText?: string;

    small?: boolean;
}

export interface AccordionRef {
    scrollTo: () => void;
}

const Content = posed.div({
    closed: { height: 0, transition: { ease: 'easeOut', duration: 400 } },
    open: { height: 'auto', transition: { type: 'spring', damping: 16 } },
});

const Accordion = forwardRef(({ title, subtitle, validation, active, onToggle, openText = 'View', closeText = 'Close', small, children }: Props, ref: React.Ref<AccordionRef | undefined | null>) => {
    // Watch active state with onToggle. Allows us to manage open/close from parent or locally/stateless
    const [open, setOpen] = useState(active || false);
    let validationIcon = null;
    let validatedSubtitle = subtitle;

    const rootRef = useRef<HTMLDivElement>(null);

    if (validation) {
        if (validation.valid < validation.total) {
            validationIcon = <InfoIcon className="icon-xs m-r-8" alt="Info" />;
            validatedSubtitle = `Section hasn't been completed yet`;
        } else {
            validationIcon = <CheckIcon className="icon-success icon-xs m-r-8" alt="Success" />;
        }
        if (validation.error) {
            validationIcon = <ErrorIcon className="icon-error icon-xs m-r-8" alt="Error" />;
            validatedSubtitle = validation.error;
        }
    }

    useEffect(() => (typeof active === 'boolean' ? setOpen(active) : undefined), [active]);

    useImperativeHandle(ref, () => ({
        scrollTo: () => rootRef.current!!.scrollIntoView(),
    }));

    return (
        <div className={classnames('Accordion', { 'Accordion--Small': small })} ref={rootRef}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .Accordion {
                    background: $color-white;
                    border: 1px solid $color-line;
                    margin-bottom: grid(2);
                    border-radius: $border-radius;

                    @media (prefers-color-scheme: dark) {
                        background: darken($color-white, 90%);
                    }

                    .accordion-head {
                        position: sticky;
                        top: 50px;
                        z-index: 3;
                        padding: grid(4) grid(2) grid(4) grid(4);
                        border-radius: $border-radius;
                        cursor: pointer;
                        user-select: none;
                        transition: border 300ms ease-out;
                        background: $color-white;
                        border-bottom: 1px solid transparent;

                        @media (prefers-color-scheme: dark) {
                            background: darken($color-white, 90%);
                        }

                        &.open {
                            border-bottom-color: $color-border;
                            border-radius: $border-radius $border-radius 0 0;
                        }

                        .up {
                            transform: scaleY(-1);
                        }

                        .subtitle {
                            margin-top: grid(2);
                        }
                    }

                    .accordion-body--content {
                        padding: grid(6);

                        :global(> h3) {
                            margin: 0 0 grid(5) 0;
                        }

                        :global(section) {
                            margin: 0 0 grid(4) 0;
                        }
                    }

                    /* Needs to be global to work with pose */
                    :global(.accordion-body) {
                        position: relative;
                        overflow: hidden;
                        .open {
                            overflow-x: hidden;
                            overflow-y: auto;
                        }
                    }

                    &--Small {
                        h2 {
                            @include text-label();
                        }
                        .accordion-head {
                            padding: grid(1) grid(4);
                        }
                    }
                }
            `}</style>

            <div
                className={classnames('accordion-head flex-center-row flex-between', { open })}
                onClick={() => {
                    setOpen(!open);
                    if (onToggle) {
                        onToggle(!open);
                    }
                }}
            >
                <div className="flex-column">
                    <h2 className="title">{title}</h2>
                    {validatedSubtitle && (
                        <span className={classnames('subtitle flex-center-row', { 'text-error': validation && validation.error })}>
                            {validationIcon}
                            {validatedSubtitle}
                        </span>
                    )}
                </div>
                <Button buttonType="tertiary" buttonSize="small">
                    {open ? <ChevronUpIcon className={classnames('icon-secondary')} alt="Close" /> : <ChevronDownIcon className={classnames('icon-secondary')} alt="View" data-testid="editbutton" />}
                    {open ? closeText : openText}
                </Button>
            </div>

            <Content className={classnames('accordion-body', { open })} pose={open ? 'open' : 'closed'} withParent={false}>
                <div className="accordion-body--content">{children}</div>
            </Content>
        </div>
    );
});

export default Accordion;
