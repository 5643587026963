import { ActionType } from '@state';

export interface IGenericState {
    exitingNavigation: boolean;
    toPath: string;
}
export interface IConsignmentOptionState {
    isOpenViewersModal: boolean;
    isScrollToCommentSection: boolean;
    isOpenBuyerModal: boolean;
}

export const GenericActionTypes = {
    resetGeneric: 'resetGeneric',
    setNavigation: 'setNavigation',
    setOptionStateAction: 'setOptionStateAction',
    resetOptionStateAction: 'resetOptionStateAction',
};

export const GenericInitialState: IGenericState = {
    exitingNavigation: false,
    toPath: '',
};

export const ConsignmentOptionsState: IConsignmentOptionState = {
    isOpenViewersModal: false,
    isScrollToCommentSection: false,
    isOpenBuyerModal: false,
};

const reducer = (state: IGenericState, action: ActionType) => {
    switch (action.type) {
        case GenericActionTypes.setNavigation:
            return action.value.payload;
        case GenericActionTypes.resetGeneric:
            return GenericInitialState;
        case GenericActionTypes.setOptionStateAction:
            return action.value.payload;
        case GenericActionTypes.resetOptionStateAction:
            return ConsignmentOptionsState;
        default:
            return state;
    }
};

export default reducer;
