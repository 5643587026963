import { ChevronDownIcon } from '@assets/icons';
import ContextMenu from '@common/components/Form/ContextMenu';
import { useUserState } from '@common/context/userContext';
import { speciesEnumToName } from '@common/utils/enum-transformers';
import { getFormAlias } from '@common/utils/form-alias';
import Button from '@components/Button';
import getConsignmentContextMenu from '@containers/Consignments/consignment-context-menu';
import { SectionName } from '@utils/enums';
import { canCreateConsignment } from '@utils/question-editable';
import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import { graphql, useFragment } from 'relay-hooks';

import { ConfirmModalRef } from '../ConfirmModal';
import DeleteConsignmentModal from '../DeleteConsignmentModal';
import { TemplateRow_template } from './__generated__/TemplateRow_template.graphql';

const fragment = graphql`
    fragment TemplateRow_template on Template {
        id
        name
        number
        species
        origin {
            name
        }
        destination {
            name
        }
        forms {
            type
        }
    }
`;

interface Props {
    template: TemplateRow_template;
    contextMenu?: React.ReactFragment | JSX.Element;
}

const TemplateRow: React.FunctionComponent<Props> = (props) => {
    const template = useFragment(fragment, props.template as any);
    const [{ user }] = useUserState();
    const canCreate = canCreateConsignment(user);
    const history = useHistory();
    const deleteModalRef = useRef<ConfirmModalRef>();

    // For when we delete a template
    if (!template) {
        return null;
    }

    return (
        <div className="tbl-row" key={template.number}>
            <div className="tbl-cell-40 responsive-heading">
                <div>
                    <h4 className="title m-b-4">
                        <Button
                            buttonType="link"
                            aria-pressed="false"
                            onClick={() => {
                                history.push(canCreate ? `/consignments/copy/${template.number}` : `/templates/edit/${template.number}/${SectionName.LIVESTOCK_DESCRIPTION}`);
                            }}
                        >
                            {template.name}
                        </Button>
                    </h4>
                    <div className="cell-content">
                        {template.origin && template.origin.name && `From ${template.origin.name} `}
                        {template.destination && template.destination.name && `to ${template.destination.name}`}
                    </div>
                </div>
            </div>
            <div className="tbl-cell-10">
                <div className="cell-content">{speciesEnumToName(template.species)}</div>
            </div>
            <div className="tbl-cell-35">
                <div className="cell-content">
                    {template.forms
                        .map((x: any) => getFormAlias(x.type))
                        .coalesce()
                        .map((x: any) => x.alias)
                        .map((x: any) => (
                            <span key={x} className="list-separator">
                                {x}
                            </span>
                        ))}
                </div>
            </div>
            <div className="tbl-cell-15 flex-end-desktop">
                {props.contextMenu ? (
                    props.contextMenu
                ) : (
                    <ContextMenu
                        options={getConsignmentContextMenu({ ...template, type: 'TEMPLATE' }, user, deleteModalRef, undefined, false, history)}
                        icon={<ChevronDownIcon />}
                        buttonText="Options"
                    />
                )}
            </div>
            <DeleteConsignmentModal consignment={template} ref={deleteModalRef} />
        </div>
    );
};

export default TemplateRow;
