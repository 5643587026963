import { useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import FeedbackButton from '@components/FeedbackButton';
import EmptyList from '@components/Listing/EmptyList';
import Loader from '@components/Loader';
import Config from '@config';
import useWindowTitle from '@effects/useWindowTitle';
import React from 'react';
import { useHistory } from 'react-router';
import { graphql, useQuery } from 'relay-hooks';

import { ConsignmentCopySelectTemplateQuery } from './__generated__/ConsignmentCopySelectTemplateQuery.graphql';
import { Header } from './components/fragments/TemplateListFragment';
import TemplateRow from './components/fragments/TemplateRow';

const query = graphql`
    query ConsignmentCopySelectTemplateQuery($envdAccountId: String!) {
        templates(envdAccountId: $envdAccountId) {
            items {
                id
                number
                name
                ...TemplateRow_template @relay(mask: false)
            }
        }
    }
`;

const ConsignmentCopySelectPage: React.FC<{ items: any }> = ({ items }) => {
    const history = useHistory();

    const templateList = (
        <>
            <div className="title-section">
                <h1>Select template you would like to use</h1>
            </div>

            <FeedbackButton url={Config.ISC_CUSTOMERFEEDBACK_URL} />
            <div className="tbl tbl-collapse">
                <Header />
                {items.coalesce().map((item: any) => (
                    <TemplateRow
                        key={item.id}
                        template={item}
                        contextMenu={
                            <Button
                                buttonType="primary"
                                buttonSize="xsmall"
                                children="Use this template"
                                action={() => {
                                    history.push(`/consignments/copy/${item.number}`);
                                }}
                            />
                        }
                    />
                ))}
            </div>
        </>
    );

    const emptyList = (
        <>
            <div className="title-section">
                <h1>Select template you would like to use</h1>
            </div>
            <div className="tbl tbl-collapse">
                <EmptyList
                    actionText="Back to consignments"
                    action={() => history.replace('/consignments')}
                    emptyText="You currently have no templates. To create one select a consignment from your consignment list, then create template"
                />
            </div>
        </>
    );

    return (
        <>
            {items && templateList} {!items && emptyList}
        </>
    );
};

const ConsignmentCopySelectTemplate: React.FC = () => {
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;
    const { data } = useQuery<ConsignmentCopySelectTemplateQuery>(query, { envdAccountId }, {});

    useWindowTitle('Select Template');

    if (data) {
        return <ConsignmentCopySelectPage items={data.templates?.items ?? []} />;
    } else {
        return <Loader error={''} isLoading pastDelay={false} timedOut={false} retry={() => null} />;
    }
};

export default ConsignmentCopySelectTemplate;
