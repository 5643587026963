/* eslint-disable no-useless-escape */

// The purpose of this module is to map the range of question id's into their respective sections
// Since, once published, the question id's should not map to something different, only to a new version of the
// same question
// The idea here is for the 'Section' component to extract the list of questions it requires from here.
// But need to figure out how to handle movement and forms, probably just have it go down a different path
// Same with declaration, but declaration will have a mixture of both "hardcoded" and "dynamic" questions
import Config from '@config';
import { IAnswer, IConsignmentMeta, IDynamicQuestion, IStaticQuestion } from '@typings';

import { INVALID_PHONE_NUMBER, INVALID_POSTCODE } from './constants';
import { SectionName } from './enums';
import {
    ALL_NFAS_FORMS,
    ALL_NVD_FORMS,
    FA_EU_NVD_CATTLE,
    FA_MSA_CATTLE,
    FA_NCHD_CATTLE,
    FA_NFASDD_CATTLE,
    FA_NFASEU_CATTLE,
    FA_NFASFB_CATTLE,
    FA_NGHD_GOAT,
    FA_NSHD_SHEEP,
    FA_NVD_BC,
    FA_NVD_CATTLE,
    FA_NVD_GOAT,
    FA_NVD_HRG_GOAT,
    FA_NVD_SHEEP,
} from './form-alias';
import { multiLineString } from './string-extensions';

export type THybridSectionStaticPosition = 'top' | 'bottom';
export interface IHybridSectionDisplay {
    name: SectionName;
    staticPosition: THybridSectionStaticPosition;
}
export const HYBRID_SECTIONS: IHybridSectionDisplay[] = [
    {
        name: SectionName.DECLARATION,
        staticPosition: 'top',
    },
    { name: SectionName.LIVESTOCK_DESCRIPTION, staticPosition: 'bottom' },
    { name: SectionName.LIVESTOCK_HISTORY, staticPosition: 'bottom' },
];

export const STATIC_SECTIONS: SectionName[] = [SectionName.MOVEMENT, SectionName.FORMS, SectionName.TEMPLATE];

export const StaticQuestions = {
    [SectionName.TEMPLATE]: [
        {
            type: 'String',
            text: 'Name',
            field: 'name',
            help: '',
            validators: [
                {
                    type: 'required',
                },
            ],
        },
    ],

    [SectionName.MOVEMENT]: [
        // Sub fields should be handled by the Entity component
        {
            type: 'Entity',
            text: 'Owner of livestock',
            field: 'owner',
            help: multiLineString`
Often the consignor and owner of livestock are the same person, company and PIC. In some cases, like **agistment** or **custom feeding** the owner may not be the person filling in the
consignment information (you). Where livestock are owned by someone different, the owner's details can be recorded by:

1. Unticking the **'I am the owner of the livestock'** checkbox
2. Search for the owner or enter their details manually`,
            validators: [],
        },
        {
            type: 'Entity',
            text: 'Location they are moving from',
            field: 'origin',
            help: multiLineString`
This is the PIC and property details from the location the livestock are being moved from. By default, this is the property and PIC you used to log in, if the stock is being moved from a
different property or PIC, you must log into that property's LPA account to create the consignment.`,
            validators: [],
        },
        {
            type: 'Entity',
            text: 'Where are they being transported to',
            field: 'destination',
            help: multiLineString`
This is the PIC and property details of the location the livestock are being moved to. The destination PIC is required in WA and TAS. Often the person and/or company the livestock are being
consigned to and the destination of are the same person, company and/or PIC.`,

            validators: [],
        },
        {
            type: 'Entity',
            text: 'Who are they being consigned to',
            field: 'consignee',
            help: multiLineString`
In some cases, like **selling through an agent** or **consigning to a brand or supermarket** the consignee may be different from the destination. Where the consignee of the livestock is
different from the destination they’re being delivered to, it can be recorded by:

1.  Unticking the '**Same as destination**’ check box
2.  Search for the consignee or enter their details manually


Include the name and address of the person and/or company the livestock are being consigned to. The PIC is not mandatory in this section because the person and/or company the livestock
are being consigned to may not have a PIC e.g. a **butcher** or **brand**.`,
            validators: [],
        },
        // TODO: Probably have these merged in the backend like originally planned if possible
        {
            type: 'Date',
            text: 'When are they being moved?',
            field: 'movementDate',
            validators: [],
        },
        {
            type: 'Time',
            text: 'Time of Movement',
            field: 'movementTime',
            validators: [],
        },
    ],

    [SectionName.FORMS]: [
        {
            type: 'String',
            text: 'What species are you sending?',
            field: 'species',
            validators: [],
            hiddenForTemplate: true,
            readOnly: true,
        },
        {
            type: 'RequiredSingleChoice',
            text: 'Select the NVD form you need',
            field: 'forms',
            options: ALL_NVD_FORMS,
            help: '',
            validators: [],
        },
        {
            type: 'OptionalMultipleChoice',
            text: 'Select any additional forms if you need them',
            field: 'forms',
            options: [],
            help: '',
            validators: [],
        },
    ],

    [SectionName.DECLARATION]: [
        {
            text: 'Full Name',
            type: 'String',
            help: '',
            field: 'declaration.fullName',
            validators: [
                {
                    type: 'required',
                },
                {
                    type: 'maxLength',
                    parameter: '300',
                },
            ],
        },
        {
            text: 'Address',
            type: 'String',
            help: '',
            field: 'declaration.address.line1',
            validators: [
                {
                    type: 'required',
                },
                {
                    type: 'maxLength',
                    parameter: '300',
                },
            ],
        },
        {
            text: 'Town / Suburb',
            type: 'String',
            help: '',
            field: 'declaration.address.town',
            validators: [
                {
                    type: 'required',
                },
                {
                    type: 'maxLength',
                    parameter: '100',
                },
            ],
        },
        {
            text: 'State',
            type: 'SingleChoice',
            help: '',
            options: ['NSW', 'QLD', 'VIC', 'NT', 'SA', 'WA', 'TAS', 'ACT'],
            field: 'declaration.address.state',
            validators: [
                {
                    type: 'required',
                },
            ],
        },
        {
            text: 'Postcode',
            type: 'String',
            help: '',
            field: 'declaration.address.postcode',
            validators: [
                {
                    type: 'required',
                },
                {
                    type: 'regex',
                    parameter: `^[0-9]{4,4}$`,
                    errorMessage: INVALID_POSTCODE,
                },
            ],
        },
        {
            text: 'Signature',
            type: 'Image',
            help: '',
            field: 'declaration.signature',
            validators: [],
        },
        {
            text: 'Date',
            type: 'Date',
            help: '',
            field: 'declaration.date',
            validators: [],
            hiddenForTemplate: true,
        },
        {
            text: 'Phone Number',
            type: 'String',
            help: '',
            field: 'declaration.phone',
            validators: [
                {
                    type: 'required',
                },
                {
                    type: 'regex',
                    parameter: `^(?:\\+?61|0)[2-478](?:[ -]?[0-9]){8}$`,
                    // regex: /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/,
                    errorMessage: INVALID_PHONE_NUMBER,
                },
            ],
        },
        {
            text: 'Email',
            type: 'String',
            help: '',
            field: 'declaration.email',
            validators: [
                {
                    type: 'required',
                },
                {
                    type: 'regex',
                    // regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    parameter: `^(([^<>()[\\]\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$`,
                    errorMessage: 'Please enter a valid email',
                },
            ],
        },
        {
            text: 'Certificate number',
            type: 'String',
            help: '',
            field: 'declaration.certificateNumber',
            validators: [
                {
                    type: 'required',
                },
                {
                    type: 'maxLength',
                    parameter: '10',
                },
            ],
            // This sucks, but for now we keep until we can think of a better way, or we can store it somewhere
            forms: ALL_NFAS_FORMS,
        },
        {
            text: 'I have read, understood and agree to the terms of the:',
            field: 'declaration.accept',
            type: 'Boolean',
            help: '',
            hiddenForTemplate: true,
            options: [
                {
                    text: multiLineString`I declare that, I am the owner or the person responsible for the husbandry of the livestock and that all the information in this document is true and correct. I also declare that I have read and understood all the questions that I have answered, I have read and understood the explanatory notes, and that, while under my control, the livestock were not fed restricted animal material (including meat and bone meal) in breach of State or Territory legislation. [Feed Ban Brochure](${Config.BASE_GRAPHQL_SERVER_URL}/declaration/feedban-notes/Ruminant-Feed-Ban-Brochure_Producers.pdf)`,
                    form: FA_NVD_CATTLE.program,
                },
                {
                    text: multiLineString`I declare as the manager responsible for the husbandry of the animals in this consignment, that the information stated in this declaration is true and correct. I also declare that none of the animals have ever been treated with HGPs; I have records available to demonstrate that the animals were either

                                    * born on the property the PIC of which is shown, or

                                    * for purchased cattle, accompanied by an EU vendor declaration attesting to their HGP freedom. I also declare that all cattle in this consignment have been properly identified by the use of the approved NLIS device. This declaration is made under the Export Control Act 1982. I will retain a copy of this declaration for two (2) years, three (3) years in WA (Giving false or misleading information is a serious offence under the Criminal Code Act 1995, the punishment for which is a period of imprisonment not exceeding 12 months).

                                    I also declare that I have read and understood all the questions that I have answered, I have read and understood the explanatory notes, and that, while under my control, the cattle were not fed restricted animal material (including meat and bone meal) in breach of State or Territory legislation. [Feed Ban Brochure](${Config.BASE_GRAPHQL_SERVER_URL}/declaration/feedban-notes/Ruminant-Feed-Ban-Brochure_Producers.pdf)`,
                    form: FA_EU_NVD_CATTLE.program,
                },
                {
                    text: 'I hereby declare that the information stated in this declaration is true, and that I have read, understood and abide by the MSA requirements for livestock production and transport.',
                    form: FA_MSA_CATTLE.program,
                },
                {
                    text: 'I declare that I am the owner or the person responsible for the husbandry of the livestock and that all the information in this document is true and correct. I also declare that I have read and understood all the questions that I have answered, that I have read and understood the explanatory notes, and that I have inspected the animals and deem them to be healthy, free of signs of disease and fit to travel.',
                    form: FA_NCHD_CATTLE.program,
                },
                {
                    text: multiLineString`I declare that the cattle detailed above have been managed under a 3rd party audited QA Program at an NFAS Accredited Feedlot. They have not met the feeding requirements of the AUS-MEAT Minimum Standards for Grain Fed Beef.
                                    I declare the above information to be true and correct and acknowledge that Beef derived from these cattle is not eligible to be described as Grain Fed Beef (GF) or Grain Fed Young Beef (GFYG).`,
                    form: FA_NFASFB_CATTLE.program,
                },
                {
                    text: multiLineString`I declare that:

                                    - The Cattle detailed above have been individually identified by RFID or by the means described in the Cattle Description section of this NFAS Delivery Docket and in accordance with the NFAS Standards (as amended);
                                    - Individual Cattle identification was verified at the time of dispatch;
                                    - The Cattle have been fed at an NFAS Accredited Feedlot;
                                    - The Cattle have been fed for the minimum days on feed on a nutritionally balanced ration of a recognised high energy feed in which the grain is the highest single component:
                                    - GFF - fed for not less than 35 days, with at least 28 days on rations having an average metabolizable energy (ME) content not less than 10 megajoules (MJ) per kilogram and MSA eligible.
                                    - GFYG - fed for not less than 60 days (heifers only), with at least 50 days on rations having an average metabolizable energy (ME) content not less than 10 megajoules (MJ) per kilogram.
                                    - GFYG - fed for not less than 70 days (heifers or steers), with at least 50 days on rations having an average metabolizable energy (ME) content not less than 10 megajoules (MJ) per kilogram.
                                    - GF - fed for not less than 100 days, with at least 80 days on rations having an average metabolizable energy (ME) content not less than 10 megajoules (MJ) per kilogram.
                                    - Where the cattle are being transferred between feedlots.
                                    - The Cattle described above are being transferred for on-feeding at another NFAS Accredited Feedlot. The number of days on feed (DOF) is as stated in the Cattle Description section.`,
                    form: FA_NFASDD_CATTLE.program,
                },
                {
                    text: multiLineString`I declare that:
                                    - The cattle have been fed on an EU HQB compliant ration for no less than the number of days on feed (DOF) declared in this consignment. The cattle in this consignment are being transferred for on feeding at another NFAS Accredited feedlot;
                                    - The cattle in this consignment have been individually identified by RFID and another method in accordance with the NFAS standards (as amended);
                                    - The EU status of individual cattle have been verified;
                                    - Individual cattle identification was verified at time of dispatch;
                                    - The cattle have been fed for at least 100 days before dispatch, on an EU HQB compliant ration with a minimum of 62% concentrates and/or grain (on a dry matter (DM) basis); a minimum energy content of 12.26 megajoules (MJ) per kilogram (on a DM basis) and a minimum composition intake off 1.4% live weight per day (on a DM basis).`,
                    form: FA_NFASEU_CATTLE.program,
                },
                {
                    text: multiLineString`I declare that, I am the owner or the person responsible for the husbandry of the calves and that all the information in this document is true and correct. I also declare that I have read and understood all the questions that I have answered, I have read and understood the explanatory notes, that the calves in this consignment meet the animal welfare requirements being they are at least in their fifth (5) day of life, are fit and strong enough to be transported for sale or slaughter, and have been fed milk or milk replacer on the farm within 6 hours of transport, and that, while under my control, the calves were not fed restricted animal material (including meat and bone meal) in breach of State and Territory legislation.`,
                    form: FA_NVD_BC.program,
                },
                {
                    text: multiLineString`I declare that, I am the owner or the person responsible for the husbandry of the livestock and that all the information in this document is true and correct. I also declare that I have read and understood all the questions that I have answered, I have read and understood the explanatory notes, and that, while under my control, the livestock were not fed restricted animal material (including meat and bone meal) in breach of State or Territory legislation. [Feed Ban Brochure](${Config.BASE_GRAPHQL_SERVER_URL}/declaration/feedban-notes/Ruminant-Feed-Ban-Brochure_Producers.pdf)`,
                    form: FA_NVD_SHEEP.program,
                },
                {
                    text: multiLineString`I declare that I am the owner or the person responsible for the husbandry of the livestock and that all the information in this document is true and correct. I also declare that I have read and understood all the questions that I have answered, that I have read and understood the explanatory notes, and that I have inspected the animals and deem them to be healthy, free of signs of disease and fit to travel.`,
                    form: FA_NSHD_SHEEP.program,
                },
                {
                    text: multiLineString`I declare that, I am the owner or the person responsible for the husbandry of the livestock and that all the information in this document is true and correct. I also declare that I have read and understood all the questions that I have answered, I have read and understood the explanatory notes, and that, while under my control, the livestock were not fed restricted animal material (including meat and bone meal) in breach of State or Territory legislation. [Feed Ban Brochure](${Config.BASE_GRAPHQL_SERVER_URL}/declaration/feedban-notes/Ruminant-Feed-Ban-Brochure_Producers.pdf)`,
                    form: FA_NVD_GOAT.program,
                },
                {
                    text: multiLineString`I declare that I am the owner or the person responsible for the husbandry of the livestock and that all the information in this document is true and correct. I also declare that I have read and understood all the questions that I have answered, that I have read and understood the explanatory notes, and that I have inspected the animals and deem them to be healthy, free of signs of disease and fit to travel.`,
                    form: FA_NGHD_GOAT.program,
                },
                {
                    text: multiLineString`I declare that I am the owner responsible for the dispatch of the harvested rangeland goats direct to an abattoir for slaughter or via one registered goat depot only. All the information in this document is true and correct. I declare that I have understood all the explanatory notes, and that, while under my control, the goats were not fed restricted animal material (including meat and bone meal) in breach of State of Territory legislation. [Feed Ban Brochure](${Config.BASE_GRAPHQL_SERVER_URL}/declaration/feedban-notes/Ruminant-Feed-Ban-Brochure_Producers.pdf)`,
                    form: FA_NVD_HRG_GOAT.program,
                },
            ],
            validators: [
                {
                    type: 'required',
                },
            ],
        },
    ],

    // Placeholders for typing complaints
    [SectionName.LIVESTOCK_DESCRIPTION]: [
        {
            type: 'Calculate',
            text: 'Total number of head',
            field: '',
            options: [{ id: '3' }],
            validators: [],
            hiddenForTemplate: false,
        },
        {
            type: 'Calculate',
            text: 'Total number of NLIS electronic ear tags',
            field: '',
            options: [{ id: '15' }],
            validators: [],
            hiddenForTemplate: false,
        },
        {
            type: 'Calculate',
            text: 'Total number of NLIS rumen devices',
            field: '',
            options: [{ id: '16' }],
            validators: [],
            hiddenForTemplate: false,
        },
    ],
    [SectionName.LIVESTOCK_HISTORY]: [
        {
            type: 'Calculate',
            text: 'Total for assurance',
            field: '',
            order: 41,
            options: [{ id: '39' }, { id: '40' }, { id: '41' }],
            validators: [],
            hiddenForTemplate: false,
        },
    ],
    [SectionName.FOOD_SAFETY]: [],
    [SectionName.CHEMICAL_AND_TREAMENT_HISTORY]: [],
    [SectionName.TRANSPORTER]: [],
    [SectionName.ADDITIONAL_INFO]: [],
};

export const DynamicQuestions: Record<SectionName, number[]> = {
    // These are handled by the StaticQuestion field
    [SectionName.MOVEMENT]: [],
    [SectionName.FORMS]: [],

    // Questions 1-16
    [SectionName.LIVESTOCK_DESCRIPTION]: new Array(16).fill(0).map((_: number, idx: number) => idx + 1),

    // Questions 17-66
    [SectionName.LIVESTOCK_HISTORY]: new Array(50).fill(0).map((_: number, idx: number) => idx + 17),

    // Questions 67 - 88
    [SectionName.FOOD_SAFETY]: new Array(22).fill(0).map((_: number, idx: number) => idx + 67),

    // Questions 89 - 154
    [SectionName.CHEMICAL_AND_TREAMENT_HISTORY]: new Array(65).fill(0).map((_: number, idx: number) => idx + 89),

    // Questions 155 - 168, ignore 167 and 168 time / date
    [SectionName.DECLARATION]: new Array(13).fill(0).map((_: number, idx: number) => idx + 155),

    // Questions 169-177
    [SectionName.TRANSPORTER]: new Array(8).fill(0).map((_: number, idx: number) => idx + 169),

    // For completeness sake
    [SectionName.TEMPLATE]: [],
    [SectionName.ADDITIONAL_INFO]: [],
};

export const getQuestionSection = (question: IDynamicQuestion | IStaticQuestion): SectionName | undefined => {
    if (question.order) {
        const order = question.order;
        return Object.keys(DynamicQuestions).find((section) => DynamicQuestions[section as SectionName].includes(order)) as SectionName | undefined;
    }

    if ('field' in question && question.field !== '') {
        return Object.keys(StaticQuestions).find((section) => StaticQuestions[section as SectionName].some((staticQn: any) => staticQn.field === question.field)) as SectionName | undefined;
    }
};

export const isAnyQuestionAnsweredInCurrentSection = (question: IDynamicQuestion, meta: IConsignmentMeta, originalAnswers: IAnswer[]) => {
    const sectionName = getQuestionSection(question);
    const questionOrders: number[] = DynamicQuestions[sectionName as SectionName];
    const questionFromCurrentSection: NonNullable<any> = questionOrders?.map((order) => meta?.questions?.find((q: any) => q?.order === order)).coalesce();
    return originalAnswers.some((x) => questionFromCurrentSection?.some((y: any) => y?.id === x.questionId) && x.value !== '');
};

export const isStaticSection = (sectionName: SectionName) =>
    sectionName === SectionName.MOVEMENT || sectionName === SectionName.TEMPLATE || sectionName === SectionName.FORMS || sectionName === SectionName.DECLARATION;

/* eslint-enable no-useless-escape */
