import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '@assets/icons';
import classnames from 'classnames';
import React from 'react';

export enum AlertType {
    Info,
    Error,
    Warn,
    Success,
}

interface Props {
    type: AlertType;
    action?: () => void;
    actionText?: string;
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    classNames?: string;
    theme?: 'light' | 'dark';
    children?: React.ReactNode;
}

const getIcon = (type: AlertType) => {
    switch (type) {
        case AlertType.Error:
            return <ErrorIcon alt="Error" />;
        case AlertType.Info:
            return <InfoIcon alt="Info" />;
        case AlertType.Success:
            return <SuccessIcon alt="Success" />;
        case AlertType.Warn:
            return <WarningIcon alt="Warning" />;
        default:
            return null;
    }
};

const Alert: React.FunctionComponent<Props> = ({ type, action, actionText, title, subtitle, classNames, theme, children }) => {
    const icon = getIcon(type);
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .Alert {
                    border-radius: $border-radius;
                    margin-bottom: grid(8);
                    display: flex;
                    padding: grid(3);
                    line-height: grid(7);
                    background: $color-white;
                    border: 1px solid $color-subtitle;
                    color: $color-copy;

                    @media (prefers-color-scheme: dark) {
                        background: $color-black;
                        color: $color-white;

                        .small {
                            color: $color-white;
                        }
                    }

                    &--i-m {
                        min-width: grid(5);
                        max-width: grid(5);
                        margin-right: grid(2);
                    }

                    p {
                        color: $color-copy;
                    }

                    > .link {
                        margin-left: grid(3);
                    }

                    &--Dark {
                        background: $color-secondary;
                        color: $color-white;
                        border: 0;

                        p {
                            color: $color-white;
                        }
                    }

                    &.success {
                        border-color: $color-success;

                        .Alert--i-m {
                            color: $color-success;
                        }
                    }

                    &.warning {
                        border-color: $color-warn;

                        .Alert--i-m {
                            color: $color-warn;
                        }
                    }

                    &.error {
                        border-color: $color-error;

                        .Alert--i-m {
                            color: $color-error;
                        }
                    }
                }
            `}</style>
            <div
                className={classnames('Alert', classNames, {
                    error: type === AlertType.Error,
                    warning: type === AlertType.Warn,
                    info: type === AlertType.Info,
                    success: type === AlertType.Success,
                    'Alert--Dark': theme === 'dark',
                })}
            >
                <div className={'Alert--i-m'}>{icon}</div>
                <div>
                    {title && <h3 className="m-b-8">{title}</h3>}
                    {subtitle && <p className="small">{subtitle}</p>}
                    {children}
                </div>
                {action && actionText ? (
                    <div className={'link'} onClick={action}>
                        {actionText}
                    </div>
                ) : null}
            </div>
        </>
    );
};
export default Alert;
