import { useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import FormRow from '@components/Form/FormRow';
import Input, { InputType } from '@components/Form/Input';
import useUpdateDynamicValue from '@effects/useUpdateDynamicValue';
import { ACCEPTED_FILE_EXTENSION_LABEL, ACCEPTED_FILE_EXTENSIONS, ACCEPTED_FILESIZE, ACCEPTED_MIMETYPES } from '@utils/constants';
import { convertFromUrlToFilename, fetchFileUrl, upload } from '@utils/file-ops';
import { useGetDynamicAnswers } from '@utils/question-getter';
import { getIsRequired } from '@utils/question-validator';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { IDynamicQuestionProps } from './DynamicQuestion';

const DynamicFileQuestion: React.FC<IDynamicQuestionProps> = ({ question, index }) => {
    const [{ user }] = useUserState();
    const currentAccount = user.accountDetails;
    const { id } = useParams<any>();
    const [errorMsg, setErrorMsg] = useState<string>();

    const answers = useGetDynamicAnswers(question, index);
    const value = answers?.firstOrDefault((answer) => answer.questionId === question.id && answer.index === index)?.value ?? '';

    const existing = Boolean(value);

    const update = useUpdateDynamicValue();
    const isRequired = getIsRequired(question.validators);

    const [tmpValue, setTmpValue] = useState<string>('');
    const [preKey, setPreKey] = useState<string>('');

    useEffect(() => {
        const action = async () => {
            const fetchedValue = await fetchFileUrl(value);
            if (fetchedValue !== tmpValue) {
                setTmpValue(fetchedValue ?? '');
                if (preKey === '') {
                    setPreKey(fetchedValue ?? '');
                }
            }
        };

        action();
    }, [tmpValue, value, preKey]);

    if (existing) {
        return (
            <FormRow labelText={question.text} helperText={'Existing attachment'} readOnly={question.readOnly} questionType={question.type}>
                <style jsx>{`
                    @import 'vars';
                    @import 'utils';
                    @import 'mixins';

                    .Attachment {
                        border-style: none;
                        position: relative;
                        padding: grid(4);

                        &:focus {
                            border-color: $color-primary;
                        }

                        :global(&--Remove) {
                            position: absolute;
                            right: grid(3);
                            top: 9px;
                            margin-left: grid(1);
                            @include text-error();
                            @include text-small();
                        }
                    }
                `}</style>
                <div className={'Attachment'}>
                    {
                        <a target="_blank" rel="noreferrer" href={tmpValue}>
                            {convertFromUrlToFilename(tmpValue)}
                        </a>
                    }
                    {!question.readOnly && (
                        <Button buttonType="link" className="Attachment--Remove" aria-pressed="false" onClick={() => update(question, index, '')}>
                            Remove
                        </Button>
                    )}
                </div>
            </FormRow>
        );
    }
    return (
        <>
            {!question.readOnly && (
                <>
                    <Input
                        inputType={InputType.File}
                        labelText={question.text}
                        helperText={question.tip}
                        onChange={async (e) => {
                            setErrorMsg('');
                            const response = await upload(e.target.files, ACCEPTED_MIMETYPES, ACCEPTED_FILESIZE, id, index, question.id, currentAccount, preKey);
                            if (!response) {
                                return;
                            }

                            const { url, error } = response;

                            if (error) {
                                setErrorMsg(error);
                            }

                            if (url) {
                                setTmpValue(url);
                                setPreKey(url);
                                update(question, index, url);
                                // value = await getBase64(file);
                            }
                        }}
                        accept={ACCEPTED_FILE_EXTENSIONS}
                        required={isRequired}
                    />
                    {errorMsg ? <p className="text-error">{errorMsg}</p> : null}
                    <p className="small">
                        You can upload up to 5 files. Allowed formats are {ACCEPTED_FILE_EXTENSION_LABEL} up to {ACCEPTED_FILESIZE}MB.
                    </p>
                </>
            )}
        </>
    );
};

export default DynamicFileQuestion;
