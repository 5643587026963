import { SearchIcon } from '@assets/icons';
import Input from '@components/Form/Input';
import { actionTypes, useGlobalState } from '@state';
import { useState } from 'react';

import ElevatedViewerSearchOptions from './ElevatedViewerSearchOptions';

export const ElevatedViewerSearchResultsHeader = () => {
    return (
        <>
            <div className="flex-center-row flex-between row">
                <style jsx>{`
                    @import 'vars';
                    @import 'utils';

                    h2 {
                        margin: grid(10) 0;

                        @media (max-width: $sm-min) {
                            margin: grid(6) 0;
                        }
                    }
                `}</style>
                <h2>Search Results</h2>
            </div>
        </>
    );
};

const ElevatedViewerSearchPanel = () => {
    const [{ elevatedViewerSearchFields }, dispatch] = useGlobalState();
    const [searchText, setSearchText] = useState<string>('');

    const changeTextSearchField = (text: string) => {
        setSearchText(text);
        dispatch({ type: actionTypes.elevatedViewerSearch.setElevatedViewerSearchField, payload: { ...elevatedViewerSearchFields, searchText: text } });
    };

    return (
        <>
            <h2>Consignment Search</h2>
            <div className="elevated-viewer-wrapper">
                <div className="search-box m-v-16 m-h-20">
                    <div className="search-icon">
                        <SearchIcon />
                    </div>
                    <Input value={searchText} onChange={(e) => changeTextSearchField(e.target.value)} placeholder="Search by PIC, name, serial number, or rego" />
                </div>
                <ElevatedViewerSearchOptions />
            </div>
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                .search-box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border: 1px solid $color-line;
                    border-radius: 3px;
                    :global(.FormRow) {
                        margin-bottom: 0;
                    }
                    :global(input) {
                        border: none;
                    }
                    .search-icon {
                        margin-left: 10px;
                    }
                }

                h2 {
                    margin: grid(3) 0;

                    @media (max-width: $sm-min) {
                        margin: grid(6) 0;
                    }
                }
                .elevated-viewer-wrapper {
                    background-color: $color-white;
                    border-radius: grid(1/2);
                    border: 1px solid $color-border;
                    /* Match the height of account selection panel */
                    height: 405px;

                    @media (max-width: $sm-min) {
                        margin-right: 0;
                    }
                    @media (max-width: $md-min) {
                        overflow-y: scroll;
                    }
                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }
                    :global(.input-container) {
                        max-width: 100%;
                    }
                    :global(.FilterMenuOptions) {
                        display: flex;
                        gap: 30px;
                        @media (max-width: $md-min) {
                            display: block;
                        }
                    }
                }
            `}</style>
        </>
    );
};

export default ElevatedViewerSearchPanel;
