import Checkbox from '@components/Form/Checkbox';
import useUpdateDynamicValue from '@effects/useUpdateDynamicValue';
import useValidation from '@effects/useValidation';
import { useGlobalState } from '@state';
import { TRANSPORTER_DECLARED_BOX_QUESTION_ID } from '@utils/constants';
import { ConsignmentStatus } from '@utils/enum-transformers';
import { useGetDynamicAnswers } from '@utils/question-getter';
import { getIsRequired } from '@utils/question-validator';
import { isAnyQuestionAnsweredInCurrentSection } from '@utils/section-mapper';
import React, { useEffect } from 'react';

import { IDynamicQuestionProps } from './DynamicQuestion';

const DynamicBooleanQuestion: React.FC<IDynamicQuestionProps> = ({ question, index, allQuestionsFromSection }) => {
    const { isValid, checkIsValid, evaluated, errorMessage, resetIsValid } = useValidation(true);
    const [{ triggerValidatorCount, originalAnswers, meta, dynamicPendingAnswers, copyErrors }] = useGlobalState();
    const isRequired = getIsRequired(question.validators);
    const update = useUpdateDynamicValue();
    let isReadOnly = question.readOnly;
    const transporterCheckboxAnswer = originalAnswers.firstOrDefault((a) => a.questionId === TRANSPORTER_DECLARED_BOX_QUESTION_ID && a.index === index)?.value;

    if (meta.status === ConsignmentStatus.SUBMITTED && Boolean(transporterCheckboxAnswer)) {
        isReadOnly = true;
    }

    // Prefer pending if it exists, fallback to original if not
    const answers = useGetDynamicAnswers(question, index);
    const value = answers?.firstOrDefault((answer) => answer.questionId === question.id && answer.index === index)?.value ?? '';

    useEffect(() => {
        if (value === '' && dynamicPendingAnswers.answers.some((x) => allQuestionsFromSection?.some((y) => y?.id === x.questionId))) {
            if (question.validators.length === 0) {
                update(question, index, 'False');
            }
        }
    });

    useEffect(() => {
        if (value === '' && copyErrors.isCopy && isAnyQuestionAnsweredInCurrentSection(question, meta, originalAnswers)) {
            checkIsValid(question, value);
        }
    });

    useEffect(() => {
        if (!evaluated && value) {
            checkIsValid(question, value);
        }
    }, [evaluated, checkIsValid, question, value]);

    useEffect(() => {
        if (triggerValidatorCount > 0) {
            checkIsValid(question, value);
        }
        if (triggerValidatorCount === 0 && !copyErrors.isCopy) {
            resetIsValid();
        }
    }, [triggerValidatorCount, checkIsValid, resetIsValid, question, value, copyErrors.isCopy]);

    return (
        <Checkbox
            errorText={errorMessage}
            optionText={question.text}
            helperText={''}
            checked={value.toLocaleLowerCase() === 'true'}
            onChange={(e) => {
                update(question, index, e.target.checked ? 'True' : question.id === TRANSPORTER_DECLARED_BOX_QUESTION_ID ? '' : 'False');
            }}
            isValid={isValid}
            isReadOnly={isReadOnly}
            required={isRequired}
        />
    );
};

export default DynamicBooleanQuestion;
