/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PicSearchMode = "DEFAULT" | "DESTINATION" | "OWNER" | "%future added value";
export type PICSearchQueryVariables = {
    envdAccountId: string;
    searchText?: string | null;
    searchMode?: PicSearchMode | null;
};
export type PICSearchQueryResponse = {
    readonly pics: {
        readonly items: ReadonlyArray<{
            readonly id: string;
            readonly businessName: string | null;
            readonly firstName: string | null;
            readonly lastName: string | null;
            readonly town: string | null;
            readonly state: string | null;
            readonly address: string | null;
            readonly pic: string | null;
        } | null> | null;
    } | null;
};
export type PICSearchQuery = {
    readonly response: PICSearchQueryResponse;
    readonly variables: PICSearchQueryVariables;
};



/*
query PICSearchQuery(
  $envdAccountId: String!
  $searchText: String
  $searchMode: PicSearchMode
) {
  pics(envdAccountId: $envdAccountId, searchText: $searchText, searchMode: $searchMode) {
    items {
      id
      businessName
      firstName
      lastName
      town
      state
      address
      pic
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envdAccountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchMode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchText"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      },
      {
        "kind": "Variable",
        "name": "searchMode",
        "variableName": "searchMode"
      },
      {
        "kind": "Variable",
        "name": "searchText",
        "variableName": "searchText"
      }
    ],
    "concreteType": "PicConnection",
    "kind": "LinkedField",
    "name": "pics",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PIC",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "businessName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "town",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "state",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pic",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PICSearchQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PICSearchQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "4b474e17dae41a77b6b533afab67a777",
    "id": null,
    "metadata": {},
    "name": "PICSearchQuery",
    "operationKind": "query",
    "text": "query PICSearchQuery(\n  $envdAccountId: String!\n  $searchText: String\n  $searchMode: PicSearchMode\n) {\n  pics(envdAccountId: $envdAccountId, searchText: $searchText, searchMode: $searchMode) {\n    items {\n      id\n      businessName\n      firstName\n      lastName\n      town\n      state\n      address\n      pic\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '23deb18c64597f3dd4330cf0ac73c0ac';
export default node;
