import Dropdown from '@components/Form/Dropdown';
import useUpdateDynamicValue from '@effects/useUpdateDynamicValue';
import useValidation from '@effects/useValidation';
import { useGlobalState } from '@state';
import { getIsRequired } from '@utils/question-validator';
import { isAnyQuestionAnsweredInCurrentSection } from '@utils/section-mapper';
import React, { useEffect } from 'react';

import { IDynamicQuestionProps } from './DynamicQuestion';

const DynamicDropdownQuestion: React.FC<IDynamicQuestionProps> = ({ question, index }) => {
    const [{ dynamicPendingAnswers, originalAnswers, copyErrors, triggerValidatorCount, meta }] = useGlobalState();
    const { isValid, checkIsValid, errorMessage, resetIsValid } = useValidation();

    const update = useUpdateDynamicValue();

    const isReadOnly = question.readOnly;
    const isRequired = getIsRequired(question.validators);

    // If the data exists in pending, use that version otherwise fallback to the original
    const value =
        dynamicPendingAnswers.answers.firstOrDefault((answer) => answer.questionId === question.id && answer.index === index)?.value ??
        originalAnswers.firstOrDefault((answer) => answer.questionId === question.id && answer.index === index)?.value ??
        '';

    useEffect(() => {
        if (value === '' && copyErrors.isCopy && isAnyQuestionAnsweredInCurrentSection(question, meta, originalAnswers)) {
            checkIsValid(question, value);
        }
    });
    useEffect(() => {
        if (triggerValidatorCount > 0) {
            checkIsValid(question, value);
        }
        if (triggerValidatorCount === 0) {
            resetIsValid();
        }
    }, [triggerValidatorCount, checkIsValid, resetIsValid, question, value]);

    return (
        <Dropdown
            value={value}
            labelText={question.text}
            errorText={errorMessage}
            helperText={''}
            options={question.acceptableAnswers.map((answer) => ({
                id: answer.value,
                title: answer.displayName,
            }))}
            onChange={(v) => {
                checkIsValid(question, v.id, true);
                update(question, index, v.id);
            }}
            isValid={isValid}
            isReadOnly={isReadOnly}
            required={isRequired}
            autoFocus={false}
        />
    );
};

export default DynamicDropdownQuestion;
