// #region V4 Refactor

/**
 * Convert a dot notation string into a query for an object
 * @param field A dot notation string
 * @param obj Object in which the dot notation string will resolve the value from
 */
export function getValueForField<T>(field: string, obj: any): T | undefined {
    if (!obj) {
        return;
    }

    return field.split('.').reduce((o, i) => {
        if (!o) {
            // GQL normally returns null for an empty field. Just fake it til we make it here.
            return o;
        }
        return o[i];
    }, obj);
}

// TODO: Support nested arrays if required. This could be merged with above but if we add support for nested it'd be better to keep seperate
/**
 * Get the requested value from the field at the position
 * @param field Dot notation path to field
 * @param position Position in the array
 * @param obj The object containing the field
 */
export function getValueForArrayField<T>(field: string, position: number, obj: any): T | undefined {
    if (!obj) {
        return;
    }

    return field.split('.').reduce((o, i, idx) => {
        if (!o) {
            // GQL normally returns null for an empty field. Just fake it til we make it here.
            return o;
        }

        // Check if this is the array field
        if (i.endsWith('[]')) {
            const arrayField = i.slice(0, i.length - 2);

            // This is an array field. Grab it at the position specified
            return o[arrayField] && o[arrayField][position];
        }

        return o[i];
    }, obj);
}

/**
 * Convert a dot notation string into an object.
 * @param fields A list of paths that create a dot notation string
 * @param value The value to set the dot notation path
 * @param spread Whether to spread the value inside an array field
 */
export function setValueToField(fields: string, value: any, spread?: boolean) {
    const reducer = (acc: any, item: any, index: number, arr: any[]) => {
        if (typeof item === 'string' && item.endsWith('[]')) {
            return {
                // Drop the '[]' from the field name and save the value
                // If the value is null, we want to delete this array so keep null
                [item.slice(0, item.length - 2)]: value === null ? null : spread ? [index + 1 < arr.length ? acc : null, ...value].coalesce() : [index + 1 < arr.length ? acc : value],
            };
        }
        return {
            [item]: index + 1 < arr.length ? acc : value,
        };
    };
    return fields.split('.').reduceRight(reducer, {});
}

/**
 * Gets a field value from the consignment
 * @param query The relay query
 * @param field The field to extract from the consignment
 * @param defaultValue The default value if nothing found
 */
export function getField<T, R>(field: string, data: R, defaultValue: T): T {
    let foundField;

    if (data) {
        foundField = getValueForField<T>(field, data);
    }

    return foundField || defaultValue;
}
// #endregion
