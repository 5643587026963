import useKeyPress, { KeyCode, useLocalKeyPress } from '@common/effects/useKeyPress';
import DropdownOptions, { DropdownOption } from '@components/Form/DropdownOptions';
import MLALogger from '@utils/logger';
import classnames from 'classnames';
import React, { forwardRef, useCallback, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';

import Button, { ButtonSize, ButtonType } from '../Button';
import RelativePortal from '../RelativePortal';

export interface ContextMenuOption extends DropdownOption {
    onClick(): any;
}

interface Props {
    buttonType?: ButtonType;
    buttonSize?: ButtonSize;
    buttonText?: string;
    icon?: any;
    options: ContextMenuOption[];
    tabIndex?: number;
    fixedWidth?: string;
}
export interface ContextMenuRef {
    hide(): void;
}

const calculateActiveOptionIndex = (value: number, options: DropdownOption[], currentOptionIndex?: number): number => {
    let val = (currentOptionIndex ? currentOptionIndex : 0) + value;
    if (val < 0) {
        val = options.length - 1;
    }
    const optionIndex = val % options.length;
    MLALogger.Log(['ContextMenu'], { value, currentOptionIndex, optionIndex });
    return optionIndex;
};

const ContextMenu = forwardRef(
    ({ options, buttonType = 'tertiary', buttonSize = 'small', buttonText, icon, fixedWidth = '320px', tabIndex = 0 }: Props, ref: React.Ref<ContextMenuRef | undefined | null>) => {
        const node = useRef<HTMLDivElement>(null);
        const [show, setShow] = useState(false);
        const [activeOptionIndex, setActiveOptionIndex] = useState<number | undefined>();

        const downKeyPress = useLocalKeyPress(KeyCode.downArrow, node.current);
        const upKeyPress = useLocalKeyPress(KeyCode.upArrow, node.current);
        const enterKeyPress = useLocalKeyPress(KeyCode.enter, node.current);
        const escKeyPress = useKeyPress(KeyCode.escape);

        useImperativeHandle(ref, () => ({
            hide: () => {
                setShow(false);
            },
        }));
        const optionSelected = useCallback(
            (idx: any) => {
                if (idx !== null && idx !== undefined) {
                    const option = options[idx];
                    option.onClick();
                    setShow(false);
                }
            },
            [options]
        );

        useLayoutEffect(() => {
            if (downKeyPress) {
                setActiveOptionIndex((internalAOI) => calculateActiveOptionIndex(1, options, internalAOI));
            }
        }, [downKeyPress, options]);

        useLayoutEffect(() => {
            if (upKeyPress) {
                setActiveOptionIndex((internalAOI) => calculateActiveOptionIndex(-1, options, internalAOI));
            }
        }, [upKeyPress, options]);

        useLayoutEffect(() => {
            if (enterKeyPress) {
                optionSelected(activeOptionIndex);
            }
        }, [enterKeyPress, activeOptionIndex, optionSelected]);

        useLayoutEffect(() => {
            if (escKeyPress) {
                setShow(false);
            }
        }, [escKeyPress]);

        if (options.length === 0) {
            return null;
        }

        return (
            <>
                <style jsx>{`
                    @import 'vars';
                    @import 'utils';

                    .form-element {
                        padding: grid(3);
                        user-select: none;

                        &.with-success {
                            border-color: $color-success;
                        }

                        &.with-error {
                            border-color: $color-error;
                        }

                        &.active {
                            border-color: $color-primary;
                            background-color: $color-fade;
                        }
                    }
                `}</style>

                <div ref={node}>
                    <Button
                        buttonType={buttonType}
                        buttonSize={buttonSize}
                        className={classnames({
                            active: show,
                        })}
                        action={() => setShow(!show)}
                    >
                        {buttonText && <span className="m-r-8">{buttonText}</span>}
                        {icon}
                    </Button>
                    <RelativePortal
                        id={'context-menu-portal'}
                        fitToParentWidth={false}
                        show={show}
                        onParentClick={optionSelected}
                        onOutClick={() => {
                            // Callback variant of setting state will allow us to get the most up to date version of the state
                            setShow((s) => {
                                if (s) {
                                    return !s;
                                }
                                return s;
                            });
                        }}
                        parentElementRef={node}
                    >
                        <DropdownOptions options={options} selectedIndex={activeOptionIndex} fixedWidth={fixedWidth} />
                    </RelativePortal>
                </div>
            </>
        );
    }
);
export default ContextMenu;
