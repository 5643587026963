import 'react-nice-dates/build/style.css';

import { useUserState } from '@common/context/userContext';
import Alert, { AlertType } from '@components/Alert';
import FeedbackButton from '@components/FeedbackButton';
import EmptyList from '@components/Listing/EmptyList';
import Config from '@config';
import TemplateListFragment, { TemplateListFragmentQuery, TemplatesLoader } from '@containers/Consignments/components/fragments/TemplateListFragment';
import useWindowTitle from '@effects/useWindowTitle';
import React, { useCallback } from 'react';
import { useQuery } from 'relay-hooks';

const TemplateHeader = () => {
    return (
        <>
            <div className="flex-center-row flex-between">
                <h1 className="m-v-40">Templates</h1>
            </div>
            <Alert
                type={AlertType.Info}
                title="What is a template?"
                subtitle="A template allows you to create a new consignment quickly and easily using your answers from a selected consignment. These are great for when you are sending similar
                consignments on a regular basis whilst still allowing you to change key pieces of information if needed."
            />
        </>
    );
};

const TemplatesWrapper: React.FC = () => {
    console.count('[Count] Render TemplatesWrapper');
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;
    const { data, error } = useQuery(
        TemplateListFragmentQuery,
        {
            envdAccountId,
            count: Config.PAGINATION_PER_PAGE_LIMIT,
            species: undefined,
            status: undefined,
        },
        {}
    );

    const renderItem = useCallback(() => {
        console.count('[Count] renderItem');
        return error ? (
            <div className="tbl tbl-collapse">
                <EmptyList
                    emptyText={
                        <>
                            <p>Sorry we've had trouble loading this page</p>
                            <br />
                            <p>
                                Please try again in a few minutes or, if this problem continues, please <a href="mailto:envd@integritysystems.com.au">email</a> eNVD support or call 1800 683 111.
                            </p>
                        </>
                    }
                />
            </div>
        ) : data ? (
            <TemplateListFragment query={data as any} />
        ) : (
            <TemplatesLoader />
        );
    }, [error, data]);

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .SearchAndFilterContainer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    margin: grid(4) 0 grid(8);

                    @media (max-width: $sm-max) {
                        margin: grid(8) 0 grid(2);
                    }

                    :global(.FormRow) {
                        width: grid(75);
                    }
                }

                .FilterContainer {
                    display: flex;
                    align-items: center;

                    :global(.FilterMenu) {
                        margin-right: grid(2);
                    }

                    @media (max-width: $sm-max) {
                        padding: grid(4) 0 grid(8);
                        flex-wrap: wrap;

                        :global(> *) {
                            margin-top: grid(2);
                        }
                    }
                }

                h2 {
                    @media (max-width: $sm-max) {
                        margin-bottom: grid(2);
                    }
                }
            `}</style>
            <TemplateHeader />
            <FeedbackButton url={Config.ISC_CUSTOMERFEEDBACK_URL} />
            {renderItem()}
        </>
    );
};

const Templates: React.FC = () => {
    console.count('[Count] Render Templates');
    useWindowTitle('Templates List');

    return (
        <>
            <TemplatesWrapper />
        </>
    );
};

export default Templates;
