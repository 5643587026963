import * as React from 'react';
const SvgBgLpa = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" preserveAspectRatio="none" viewBox="0 0 363 400" {...props}>
        <path d="M81.712 323.714c-2.272 15.776-4.386 30.069-6.31 41.68-4.133 27.071-20.635 36.599-36.41 34.99-15.776-1.609-30.89-14.387-25.747-45.939 1.862-11.39 7.1-43.099 13.915-83.958 0 0 44.172 47.989 48.021 50.23a31.6 31.6 0 0 0 6.31 3.155M212.619 213.946c20.477 41.017 36.979 74.146 44.173 88.124 11.358 21.707 2.965 36.284-10.381 43.509s-31.551 7.162-43.005-14.261c-5.3-9.97-19.214-38.903-34.706-71.748 14.766-15.776 43.888-45.624 43.888-45.624M134.94 187.979c-15.019-31.993-26.472-56.54-26.472-56.54s-5.837 43.099-12.968 94.497v.599l-3.155 2.335-43.667-45.907s-.726-2.209-5.995-4.449c9.465-56.099 19.341-114.248 26.85-156.78 2.24-12.62 5.806-17.763 14.45-20.729 9.656-3.313 20.068 1.735 25.936 12.62 20.982 38.935 46.349 88.503 70.265 135.861z" />
        <path
            fill="#DAE0E8"
            fillOpacity={0.58}
            d="M6.746 194.195a21.8 21.8 0 0 1 27.639-2.745c9.78 7.32 43.951 47.075 58.118 63.829C143.837 192.365 295.41 74.899 345.23 48.522a12.12 12.12 0 0 1 16.407 4.228c2.398 4.417 1.893 12.336-3.628 15.491-76.986 43.636-217.706 196.787-248.91 235.154-10.349 12.778-29.028 5.174-33.792-2.493-7.92-12.81-54.237-61.304-69.413-76.575a21.08 21.08 0 0 1 .915-30.132"
        />
    </svg>
);
export default SvgBgLpa;
