/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ConsignmentSpecies = "BOBBY_CALVES" | "CATTLE" | "GOAT" | "SHEEP_LAMB" | "%future added value";
export type ConsignmentAddQueryVariables = {
    envdAccountId: string;
};
export type ConsignmentAddQueryResponse = {
    readonly viewer: {
        readonly forms: ReadonlyArray<{
            readonly name: string;
            readonly displayName: string;
            readonly species: ConsignmentSpecies | null;
            readonly logoUrl: string | null;
        }>;
    } | null;
};
export type ConsignmentAddQuery = {
    readonly response: ConsignmentAddQueryResponse;
    readonly variables: ConsignmentAddQueryVariables;
};



/*
query ConsignmentAddQuery(
  $envdAccountId: String!
) {
  viewer {
    forms(envdAccountId: $envdAccountId) {
      name
      displayName
      species
      logoUrl
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "envdAccountId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "envdAccountId",
    "variableName": "envdAccountId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "species",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConsignmentAddQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "FormModel",
            "kind": "LinkedField",
            "name": "forms",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConsignmentAddQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "FormModel",
            "kind": "LinkedField",
            "name": "forms",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9055b76415f66e757b064f808a9d6636",
    "id": null,
    "metadata": {},
    "name": "ConsignmentAddQuery",
    "operationKind": "query",
    "text": "query ConsignmentAddQuery(\n  $envdAccountId: String!\n) {\n  viewer {\n    forms(envdAccountId: $envdAccountId) {\n      name\n      displayName\n      species\n      logoUrl\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7b188583817fcd7a731e99cf94a6b02b';
export default node;
