import { useUserState } from '@common/context/userContext';
import { SelectableListItem } from '@components/Form/SelectableList';
import SelectableBlockView from '@components/SelectableBlockView';
import { actionTypes, useGlobalState } from '@state';
import { ALL_NFAS_FORMS } from '@utils/form-alias';
import { readOnlyWhenSubmittedV4 } from '@utils/question-editable';
import { useGetStaticAnswer } from '@utils/question-getter';
import React from 'react';

import { IStaticQuestionProps } from './StaticQuestion';

const StaticOptionalMultipleChoiceQuestion: React.FC<IStaticQuestionProps> = ({ question, data }) => {
    const [, dispatch] = useGlobalState();
    const [{ user }] = useUserState();

    // We don't want to pull from consignment response
    // Form might not exist yet since we haven't submitted a mutation, so make form.type nullable
    const answer = useGetStaticAnswer(question, data)?.map((form: any) => (typeof form === 'string' ? form : form?.type));

    const isReadOnly = readOnlyWhenSubmittedV4(data, user);

    return (
        <SelectableBlockView
            isReadOnly={isReadOnly}
            submitCount={0}
            initialRequiredItems={[]}
            requiredItemsProps={{
                labelText: '',
                // errorText: ConsignmentSpecies.CATTLE ? 'You can select only one (NVD or EU NVD required but not both)' : 'You must select at least one (1) NVD form',
                errorText: '',
            }}
            isRadioListReadOnly={isReadOnly}
            initialOptionalItems={
                question.options
                    ?.filter((option) => option.species === data?.species)
                    ?.filter((option) => {
                        if (option.program.startsWith('LPA')) {
                            return user?.accountDetails?.accreditations && user?.accountDetails?.accreditations['lPA'];
                        } else if (option.program.startsWith('EU')) {
                            return user?.accountDetails?.accreditations && user?.accountDetails?.accreditations['eUNVD'];
                        } else if (option.program.startsWith('NFAS') && !option.program.includes('EU')) {
                            return user?.accountDetails?.accreditations && user?.accountDetails?.accreditations['nFAS'];
                        } else if (option.program.startsWith('NFAS') && option.program.includes('EU')) {
                            return user?.accountDetails?.accreditations && user?.accountDetails?.accreditations['nFASEU'];
                        } else if (option.program.startsWith('MSA')) {
                            return user?.accountDetails?.accreditations && user?.accountDetails?.accreditations['mSA'];
                        }

                        // Default is not having permissions
                        return false;
                    })
                    // EPLAT-335 hide Cattle NVD and EU NVD excluded forms
                    .filter(({ program }) => {
                        if (program.startsWith('NFAS') && program.includes('EU')) {
                            return answer?.some((formId: string) => formId.startsWith('EUC'));
                        }
                        return true;
                    })
                    .map((option) => ({
                        id: option.program,
                        title: option.alias,
                        subtitle: option.subtitle,
                        image: option.image,
                        selected: answer?.some((form: any) => form === option.program.replace(/\./g, '')) ?? false,

                        // exclusive for ENVDR-933 backwards compatibility
                        exclusive: option.program.includes('NFAS') ? ALL_NFAS_FORMS.filter((nfas) => option.program !== nfas.program).map((nfas) => nfas.program) : [],
                    })) ?? []
            }
            optionalItemsProps={{
                labelText: isReadOnly ? 'Here are the additional forms you selected' : 'Select any additional forms you need',
                errorText: '',
            }}
            isCheckListReadOnly={isReadOnly}
            onSubmit={() => null}
            onChange={(updatedForms: SelectableListItem[]) => {
                // Only change the forms that are part of our options, leave the rest as is
                let forms = answer;

                updatedForms
                    .filter((x) => question.options?.map((o) => o.program === x.id))
                    .forEach((form) => {
                        // This form can be saved since it is a valid option
                        const value = form.id.replace(/\./g, '');
                        const selected = form.selected;

                        // If this option is selected, append it to the list, if it is not then remove it
                        forms = selected ? [...forms, value].distinct() : forms.filter((x: any) => x !== value);
                        forms = forms.coalesce();
                    });
                dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: { forms } });
            }}
        />
    );
};

export default StaticOptionalMultipleChoiceQuestion;
