import { ActionType } from '@state';
import { SectionName } from '@utils/enums';

interface IQuestionError {
    question: string;
    messages: string[];
    section: SectionName;
}

export interface ICopyErrorState {
    errors: IQuestionError[];
    invalidSections: SectionName[];
    isCopy: boolean;
}
export const CopyErrorActionTypes = {
    addError: 'addCopyError',
    clearErrors: 'clearCopyErrors',
    reset: 'resetCopyErrors',
    setInvalidSections: 'setCopyInvalidSections',
    setIsCopy: 'setCopyIsCopy',
};

export const CopyErrorsInitialState: ICopyErrorState = {
    errors: [],
    invalidSections: [],
    isCopy: false,
};

const reducer = (state: ICopyErrorState, action: ActionType): ICopyErrorState => {
    switch (action.type) {
        case CopyErrorActionTypes.addError:
            if (state?.errors?.some((error) => error.question === action.payload.question)) return state;
            return {
                ...state,
                errors: [...state.errors, action.payload],
            };
        case CopyErrorActionTypes.clearErrors:
            return {
                ...state,
                errors: CopyErrorsInitialState.errors,
            };
        case CopyErrorActionTypes.reset:
            return CopyErrorsInitialState;
        case CopyErrorActionTypes.setInvalidSections:
            return {
                ...state,
                invalidSections: action.payload,
            };
        case CopyErrorActionTypes.setIsCopy:
            return {
                ...state,
                isCopy: action.value,
            };
        default:
            return state;
    }
};

export default reducer;
