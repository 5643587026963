import { useUserState } from '@common/context/userContext';
import Dropdown from '@components/Form/Dropdown';
import useValidation from '@effects/useValidation';
import { actionTypes, useGlobalState } from '@state';
import { setValueToField } from '@utils/data-manipulator';
import { readOnlyWhenSubmittedV4 } from '@utils/question-editable';
import { useGetStaticAnswer } from '@utils/question-getter';
import { getIsRequired } from '@utils/question-validator';
import React, { useEffect } from 'react';

import { IStaticQuestionProps } from './StaticQuestion';

const StaticDropdownQuestion: React.FC<IStaticQuestionProps> = ({ question, data }) => {
    const answer = useGetStaticAnswer(question, data);
    const [{ copyErrors }, dispatch] = useGlobalState();
    const [{ user }] = useUserState();
    const isReadOnly = readOnlyWhenSubmittedV4(data, user);
    const isRequired = getIsRequired(question.validators);
    const { isValid, checkIsValid, evaluated } = useValidation();

    useEffect(() => {
        if (answer === '' && copyErrors.isCopy) {
            checkIsValid(question, answer);
        }
    });
    if (!evaluated) {
        checkIsValid(question, answer);
    }

    return (
        <Dropdown
            value={answer ?? ''}
            labelText={question.text}
            helperText={''}
            options={question.options?.map((option) => ({ id: option, title: option })) ?? []}
            onChange={(item) => {
                if (copyErrors.isCopy) {
                    // clear all the copyerrors so copyerrors list can be populated with all the latest errors after change
                    dispatch({ type: actionTypes.copyErrors.clearErrors });
                }
                checkIsValid(question, item.id);
                dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: setValueToField(question.field, item.id) });
            }}
            isValid={isValid}
            isReadOnly={isReadOnly}
            autoFocus={false}
            required={isRequired}
        />
    );
};

export default StaticDropdownQuestion;
