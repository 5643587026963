import usePortal from '@common/effects/usePortal';
import useLockBodyScroll from '@effects/useLockBodyScroll';
import { useWhyDidYouUpdate } from '@effects/useWhyDidYouUpdate';
import { useWindowWidth } from '@effects/useWindowWidth';
import MLALogger from '@utils/logger';
import classnames from 'classnames';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

type TOverflow = 'scroll' | 'auto' | 'unset' | undefined;

interface IRelativePortalStyle {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
    height?: number;
    overflowY?: TOverflow;
}

/*
 * fallbackAlignmentElementRef - Optional. When provided, indicates a DOM element that can be used for alignment as a fallback for cases
 *                               when the portal cannot be used, such as sometimes on first use / render.
 */

interface Props {
    id: string;
    show: boolean;
    fitToParentWidth: boolean;

    parentElementRef?: React.RefObject<HTMLElement>;
    fallbackAlignmentElementRef?: React.RefObject<HTMLElement>;

    onParentClick?: (data: any) => void;
    onOutClick?: () => void;

    footer?: React.ReactNode;
    disableScrollBodyLock?: boolean;

    headerHeight?: number;
    dropDownTopHeightAdjustment?: number;
    children?: React.ReactNode;
}

const EMPTY_STYLE = { top: 0, left: 0, right: 0 };
const HEADER_HEIGHT = 52; // grid(13) aka 13 * 4
const MAXIMUM_PADDING_BUFFER = 24;

const RelativePortal: React.FC<Props> = (props: Props & { children?: any }) => {
    const {
        id,
        children,
        show = true,
        onParentClick,
        onOutClick,
        footer,
        fitToParentWidth,
        parentElementRef,
        disableScrollBodyLock,
        fallbackAlignmentElementRef,
        headerHeight = HEADER_HEIGHT,
        dropDownTopHeightAdjustment = 0,
    } = props;
    const target = usePortal(id);
    const rootNodeRef = useRef<HTMLDivElement>(null);
    const portalNodeRef = useRef<HTMLDivElement>(null);

    useWhyDidYouUpdate('RelativePortal', props);

    // Account for the header, this is not fully generic as we are watching a known element (dynamic-repeatable-modal) but
    // it can be made generic when required (which just means looking for an element in the DOM that overlays the page with a scroll lock)
    const isRegularModalVisible = document.getElementById('dynamic-repeatable-modal') !== null;
    const parsedHeaderHeight = isRegularModalVisible ? 0 : headerHeight;

    const calculateStyle = useCallback(() => {
        if (!rootNodeRef.current || !portalNodeRef.current) {
            return EMPTY_STYLE;
        }

        // Determine the root element. Due to the fact that browsers are inconsistent (looking at Safari and IE11)
        // we check all of these and use the first one that is available
        const rootElement = document.documentElement || document.body.parentNode || document.body;
        const width = rootElement.clientWidth;

        const rect = rootNodeRef.current.getBoundingClientRect();

        // Similar story here, to support all browsers we need to use these fallbacks to determine
        // scroll position
        const pageOffset = {
            x: window.pageXOffset ? window.pageXOffset : rootElement.scrollLeft,
            y: window.pageYOffset ? window.pageYOffset : rootElement.scrollTop,
        };

        // Determine our base positioning values
        let top: number | undefined = pageOffset.y + rect.top;
        const left = pageOffset.x + rect.left;
        const right = width - rect.right - pageOffset.x;

        // Include this change to adjust the top height of the dropdown in Modal
        if (dropDownTopHeightAdjustment > 0) {
            top -= dropDownTopHeightAdjustment;
        }

        let height;
        let overflowY: TOverflow | undefined;

        let portalRect = portalNodeRef.current!!.getBoundingClientRect();
        let portalHeight = portalRect.height;

        const parentRect = parentElementRef?.current?.getBoundingClientRect();

        // ENVDR-951 fix dropdown options not accessible
        // On first render or use the portal's rect may not be in its correct location, at least for the purposes of calculations here.
        // This is likely due to the portal being created before we dynamically create the rest of the page, as best described by:
        // > "there is a delay in rendering [the] parent component due to dynamism" - Danny, 2020
        // In this case we need to use the fallback rect to calculate the portal's expected location
        const fallbackAlignmentRect = fallbackAlignmentElementRef?.current?.getBoundingClientRect();
        const fallbackAlignmentHeight = fallbackAlignmentRect?.height ?? 0;

        // Because the portal on first render can be incorrect, we need to determine where our base calculations
        // should be starting from.
        let adjustedPortalRect = {
            top: portalRect.top,
            bottom: portalRect.bottom,
        };

        // If we have a fallback, we use that when the portal is offscreen on render
        if (fallbackAlignmentRect) {
            // In theory it shouldn't bleed both top and bottom, but if it does prefer
            // the top aligning to the 0th pixel of the viewport
            if (portalRect.bottom <= 0) {
                adjustedPortalRect = {
                    top: fallbackAlignmentRect.bottom,
                    bottom: fallbackAlignmentRect.bottom + portalHeight,
                };
            }

            if (portalRect.top <= 0) {
                adjustedPortalRect = {
                    top: fallbackAlignmentRect.top + pageOffset.y - portalHeight,
                    bottom: fallbackAlignmentRect.top,
                };
            }
        }

        // We need to do multi-pass calculations on the portal because of the multiple elements in the watch list
        // and because the elements within the portal are dynamically rendered, the sizing can be different

        // Handle the first pass where we just check if our base positioning bleeds over the bottom of the viewport
        let portalExtendsBelowViewableArea = portalHeight > 0 && adjustedPortalRect.bottom > window.innerHeight;

        // Second pass calculation depends on a parent, this will account for nested modals or the like to determine scroll position
        // within the modal (checks the bleed within the viewport of the modal instead of the root element essentially)
        if (!portalExtendsBelowViewableArea && parentRect) {
            portalExtendsBelowViewableArea = parentRect.bottom + portalHeight > window.innerHeight;
        }

        // This lets us keep track of if we have flipped on the y-axis
        let flippedYAxis = false;

        //#region ENVDR-931
        // If the child element overflows the window size then we should have this display on top
        // If it overflows the top then we will need to limit the height for whichever direction has the most space
        if (portalExtendsBelowViewableArea) {
            const parentOffsetTop = parentElementRef?.current?.offsetTop ?? 0;

            const isNestedParent = parentOffsetTop % 4 === 0 && parentOffsetTop <= MAXIMUM_PADDING_BUFFER;

            // When we have a parent container, we need to do our calculations relative to the parent instead of
            // relative to the document
            if (parentRect) {
                const windowHeightToParent = parentRect.top;

                // Check to see if the portal is going to overflow the top of the parent (i.e. when
                // the top of the parent + portal height is larger than the available viewport ABOVE
                // the parent element
                const overflowsParentTop = windowHeightToParent < portalHeight;

                if (overflowsParentTop) {
                    // Set overflowY to auto to allow scrolling
                    overflowY = 'auto';

                    // Handle limited space scenario
                    // Here we have overflowed in both top and bottom (i.e. we bleed vertically in either direction) so the best course of action
                    // is to limit the height of the container to the maximum size in either direction
                    const maximumTopHeight = windowHeightToParent;
                    let maximumBottomHeightWithRoom = window.innerHeight - adjustedPortalRect.bottom;
                    if (maximumBottomHeightWithRoom + top - pageOffset.y >= window.innerHeight) {
                        // Offset the header that is pushing it over the edge
                        maximumBottomHeightWithRoom -= parsedHeaderHeight;
                    }

                    // Checks if we overflow bottom while inside a portal, if so find height to parent otherwise use height to node
                    const maximumBottomHeight = adjustedPortalRect.bottom > window.innerHeight ? window.innerHeight - parentRect.bottom - parsedHeaderHeight : maximumBottomHeightWithRoom;
                    const isTopBigger = maximumTopHeight > maximumBottomHeight;

                    if (isTopBigger) {
                        flippedYAxis = true;

                        if (isNestedParent) {
                            // When it's a nested parent, similar to the approach below we use a different calculation to find the top
                            // which in this case should just the the y offset position and offsetting header height too
                            // Offset is assumed as padding here so we divide it in half to avoid overlapping too much
                            top = pageOffset.y + parsedHeaderHeight + parentOffsetTop / 2;
                        } else {
                            // We aren't in a nested parent, so that means we can just flip the element as is with an adjusted height
                            top = parentOffsetTop - parentRect.top;
                        }

                        if (fallbackAlignmentHeight) {
                            // Move it down further
                            top += parentRect.height - fallbackAlignmentHeight;
                        }

                        // Aligned top with limited height
                        height = maximumTopHeight;
                    } else {
                        // Align bottom with limited height
                        height = maximumBottomHeight;
                    }
                } else {
                    // Handle the scenario where we have enough room on the top side of the parent element
                    // So its just about calculating where to insert the element
                    flippedYAxis = true;

                    // When the offset is 0 then we can just flip the portal as is since we know there is enough room above already
                    // But we account for the fallback element as an adjustment to try and position it just above it
                    if (parentOffsetTop === 0) {
                        // Move position to the top i.e. flip it
                        top = top - (fallbackAlignmentHeight ?? 0) - portalHeight;
                    } else if (isNestedParent) {
                        // When the offet isn't 0, it is either scrolled or has some padding which means we need to do some extra calculations
                        // Because we know our padding sizes are multiples of 4, if the offset is divisible by 4 and it is less than a bound
                        // then we use the top of the parent bounding box + window scroll instead of the offset
                        // The scrollY is necessary  because boundingBoxes are relative to viewport not whole page
                        top = pageOffset.y + parentRect.top - portalHeight;

                        // If this is true, then we are up higher than the viewport allows, so adjust the portal height to be smaller to fit within
                        // so basically just use the distance between the top of the viewport (pageOffset.y + parentRect.top) as the height with the top being
                        // 0 of the viewport
                        if (top > window.innerHeight) {
                            top = pageOffset.y + parsedHeaderHeight + (fallbackAlignmentHeight ?? 0);
                            height = parentRect.top - parsedHeaderHeight;
                        } else if (fallbackAlignmentRect) {
                            // When we have this we know its a nested parent, so we can do further adjustments to make this
                            // sit nicely above the intended element
                            // Offset is assumed as padding here so we divide it in half to avoid overlapping too much
                            top += parentRect.height - fallbackAlignmentRect.height + parentOffsetTop / 2;
                        }
                    } else {
                        top = parentOffsetTop - portalHeight;
                        if (fallbackAlignmentHeight) {
                            // Move it down further
                            top += parentRect.height - fallbackAlignmentHeight;
                        }
                    }
                }
            } else {
                flippedYAxis = true;
                // If no parent given, we will just flip it from where it is
                // Move position to the top i.e. flip it
                top = adjustedPortalRect.top - portalHeight;
            }
        }

        // Check if the child of the portal (if it only has 1 child) has a vastly different height.
        // If it does, set height to match since that means the portal height itself is too big
        if (flippedYAxis && portalNodeRef.current!.childElementCount === 1) {
            const childHeight = portalNodeRef.current!.children[0].getBoundingClientRect().height;
            const heightDiff = (height ?? portalRect.height) - childHeight;
            if (heightDiff >= MAXIMUM_PADDING_BUFFER) {
                // Reset the height to be the height of the child, which should be the true height
                height = childHeight;

                // Push top down by the difference as we have just reduced the height of the portal
                // by this amount
                top += heightDiff;
            }
        }

        if (fitToParentWidth) {
            // Align portal to the width of the parent element
            return { top, left, right, height, overflowY };
        } else if (left + portalRect.width > width) {
            // If the portal width is the same as the viewport width, this is probably the first render where the sizing is not yet determined
            // So we re-calculate to see if it should actually be flipped on the x-axis
            if (portalRect.width === width) {
                let foundLast = false;
                let keepLeftAligned = true;
                let child = portalNodeRef.current!.childElementCount === 1 ? portalNodeRef.current!.children[0] : null;

                // Double check width if there is only 1 child to bypass first render incorrect calculation issues
                // Find the lowest nested child which has no siblings as that should be the element that would have a real height associated
                while (!foundLast && child) {
                    foundLast = child.childElementCount > 1;
                    if (!foundLast) {
                        child = child.children[0];
                    }
                }

                // Once we have found it we can do our second pass to check if it should really be flipped
                if (foundLast && child) {
                    const cbb = child.getBoundingClientRect();
                    const childWidth = cbb.width;
                    if (portalRect.width - childWidth > MAXIMUM_PADDING_BUFFER) {
                        // The widths are too different, so do the check again
                        if (left + childWidth > width) {
                            // Then we are truely over, so flip to right alignment
                            keepLeftAligned = false;
                        }
                    }
                }

                if (keepLeftAligned) {
                    return { top, left, right: undefined, height, overflowY };
                }
            }

            // Return right aligned portal
            return { top, left: undefined, right, height, overflowY };
        }

        // Return left aligned portal by default
        return { top, left, right: undefined, height, overflowY };
    }, [fitToParentWidth, parentElementRef, fallbackAlignmentElementRef, parsedHeaderHeight, dropDownTopHeightAdjustment]);

    const [inlineStyle, setInlineStyle] = useState<IRelativePortalStyle>(calculateStyle());

    const windowWidth = useWindowWidth();

    useLayoutEffect(() => {
        // When toggled to not show, shrink the portal to 0 otherwise calculate positioning
        const newPosition = show ? calculateStyle() : EMPTY_STYLE;
        setInlineStyle(newPosition);
    }, [show, windowWidth, calculateStyle]);

    // Load a click handler on the root element safely for debugging
    useLayoutEffect(() => {
        const handler = () => MLALogger.Log(['Relative Portal'], 'Parent Div Clicked');
        let savedPtr: HTMLDivElement | null = null;
        if (rootNodeRef.current) {
            savedPtr = rootNodeRef.current;
            savedPtr.onclick = handler;
        }
        return () => {
            if (savedPtr) {
                savedPtr?.removeEventListener('click', handler);
            }
        };
    }, []);

    // Load a click handler for the portal element safely for dropdown item selection
    useLayoutEffect(() => {
        let savedPtr: HTMLDivElement | null = null;

        const handler = (e: any) => {
            MLALogger.Log(['Relative Portal'], { message: 'Portal Div Clicked', target: e.target });

            if (onParentClick && e.target) {
                // TODO: Make Generic
                // grab any attribute with data- and pass it to the callback
                const idAttribute = e.target.dataset.idx;
                if (idAttribute) {
                    onParentClick(idAttribute);
                    MLALogger.Log(['Portal'], { message: 'Sending...', idAttribute });
                }
            }
        };

        if (portalNodeRef.current) {
            savedPtr = portalNodeRef.current;
            savedPtr.addEventListener('click', handler, true);
        }
        return () => {
            if (savedPtr) {
                savedPtr?.removeEventListener('click', handler);
            }
        };
        // Ignore onParentClick since that shouldn't change per portal
    });

    // Load a click handler for the space around the portal to exit when a callback is registered
    useLayoutEffect(() => {
        const handler = (e: any) => {
            if (onOutClick && show) {
                // Skip when we are clicking anything within the portal
                if (portalNodeRef.current && portalNodeRef.current.contains(e.target)) {
                    MLALogger.Log(['Relative Portal'], { message: 'Failed on out click, it is within the portal', portalNodeRef, target: e.target, ct: e.currentTarget });
                    return;
                }

                // Skip when we are clicking anything within the parent if it exists
                if (parentElementRef && parentElementRef.current && parentElementRef.current.contains(e.target)) {
                    MLALogger.Log(['Relative Portal'], { message: 'Failed on out click, it is within the portal', parentElementRef, rootNodeRef, target: e.target, ct: e.currentTarget });
                    return;
                }

                MLALogger.Log(['Relative Portal'], { message: 'onOutClick Triggered', portalNodeRef, parentElementRef, target: e.target, ct: e.currentTarget });
                e.stopPropagation();
                onOutClick();
            }
        };

        document.addEventListener('click', handler);
        document.addEventListener('touchstart', handler);

        return () => {
            document.removeEventListener('click', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [show, onOutClick, parentElementRef]);

    // Enable body lock on open so we can use keyboard navigation
    useLockBodyScroll(!disableScrollBodyLock && show);

    return (
        <div ref={rootNodeRef}>
            {createPortal(
                <div ref={portalNodeRef} style={{ position: 'absolute', ...inlineStyle }} className={classnames('RelativePortal', { show })}>
                    {show && children}
                    {footer}
                </div>,
                target
            )}
        </div>
    );
};

export default RelativePortal;
