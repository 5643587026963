// ENVDB-896: Commemnting this as we know longer consider this as valid pics
// const PICS_IGNORED = [
//     // Ignore for EU Abbotoirs
//     'EUAB',
//     // Ignore for EU Saleyards
//     'EUSY',
//     // Ignore for Unknown PIC
//     'AAAAAAAA',
//     // Ignore for Unknown Destination PIC
//     'EEEEEEEE',
//     'COLES001',
// ];

const PIC_LENGTH = 8;
const PIC_NO_SPACE_OR_SYMBOLS_REGEX = new RegExp('^[\\w\\d]+$');
const PIC_FORMAT_REGEX_TWO_A_FOUR_D = new RegExp('^[A-Z]{2}[0-9]{4}$');
const PIC_FORMAT_REGEX_THREE_A_FOUR_D = new RegExp('^[A-Z]{3}[0-9]{4}$');
const PIC_FORMAT_REGEX_FOUR_A_THREE_D = new RegExp('^[A-Z]{4}[0-9]{3}$');
const PIC_FORMAT_REGEX_SIX_D = new RegExp('^[0-9]{6}$');

const validSecondCharNonVic = (char: string) => {
    const charCode = char.charCodeAt(0);
    // Between A and K
    const ak = charCode >= 65 && charCode <= 75;
    const z = charCode === 90;
    return ak || z;
};

function getCharValueVIC(char: string): number {
    const num = Number.parseInt(char, 10);

    // Numbers are worth their own value
    if (!Number.isNaN(num)) {
        return num;
    }

    // Letter is worth position with A=10, Z=35
    // As A is 65, just subtract 55 from all values
    return char.charCodeAt(0) - 55;
}

function getCharValueNSW(char: string, position: number): number {
    if (position === 0) {
        // First character is worth 12
        return 12;
    }

    const num = Number.parseInt(char, 10);

    // Numbers are worth their own value
    if (!Number.isNaN(num)) {
        return num;
    }

    // Letter is worth position with A=10, Z=35
    // As A is 65, just subtract 55 from all values
    return char.charCodeAt(0) - 55;
}

function getCharValueSA(char: string, position: number): number {
    if (position === 0) {
        // First character is worth 6
        return 6;
    }

    const num = Number.parseInt(char, 10);

    // Numbers are worth their own value
    if (!Number.isNaN(num)) {
        return num;
    }

    // Letters are worth ASCII value
    return char.charCodeAt(0);
}

function getCharValueWA(char: string, position: number): number {
    if (position === 0) {
        // First character is worth 87
        return 87;
    }

    // Ignore 4-7
    if (position >= 4) {
        return parseInt(char);
    }

    // There are a bunch of rules for 1-3.

    // 6. Number = their own value
    const num = Number.parseInt(char, 10);

    // Numbers are worth their own value
    if (!Number.isNaN(num)) {
        return num;
    }

    // 1. A = 10,
    if (char === 'A') {
        return 10;
    }

    const code = char.charCodeAt(0);

    // 2. C -> M = Index from B (C=1)
    if (code > 66 && code < 77) {
        return code - 66;
    }

    // 3. M -> X = Index from M (N=1)
    if (code > 77 && code < 88) {
        return code - 77;
    }

    // 4. X -> Z = Index from X (Y=1)
    if (code > 88 && code <= 90) {
        return code - 88;
    }

    // 5. B, M, X = 0
    return 0;
}

function getCharValueAtOneNT(char: string) {
    if (char === 'A') {
        return 90;
    }
    if (char === 'B') {
        return 0;
    }
    if (char === 'C') {
        return 9;
    }
    if (char === 'D') {
        return 18;
    }
    if (char === 'E') {
        return 27;
    }
    if (char === 'F') {
        return 36;
    }
    if (char === 'G') {
        return 45;
    }
    if (char === 'H') {
        return 54;
    }
    if (char === 'I') {
        return 63;
    }
    if (char === 'J') {
        return 72;
    }
    if (char === 'K') {
        return 81;
    }

    return null;
}

function getValueNT(pic: string): number | null {
    const char0 = 0;

    // Position 2
    const char1 = getCharValueAtOneNT(pic[1]);
    if (char1 === null) {
        return null;
    }

    // Position 3 and 4
    const char23 = pic.substr(2, 2);
    let char23value = 0;
    if (char23 === 'AS') {
        char23value = 179;
    }
    if (char23 === 'DG') {
        char23value = 94;
    }
    if (char23 === 'BT') {
        char23value = 84;
    }
    if (char23 === 'VR') {
        char23value = 164;
    }

    // Position 5-8 are always numbers
    let char4567value = 0;
    for (let index = 4; index < pic.length; index++) {
        const num = Number.parseInt(pic[index], 10);
        const multipler = Math.pow(2, pic.length - 1 - index);
        char4567value += num * multipler;
    }

    return char0 + char1 + char23value + char4567value;
}

function validatePICFormat(value: string): boolean {
    if (value.length !== PIC_LENGTH) {
        return false;
    }

    // ENVDB-896: Not validating the invlaid pics anymore
    // if (PICS_IGNORED.some((x) => value.startsWith(x))) {
    //     return true;
    // }

    // No space or symbols allowed
    if (!PIC_NO_SPACE_OR_SYMBOLS_REGEX.test(value)) {
        return false;
    }

    const char0 = value[0];
    const char1 = value[1];

    let tmp1 = '';

    switch (char0) {
        // Victoria (NEW) - 3
        case '3':
            tmp1 = value.substring(1);
            return PIC_FORMAT_REGEX_FOUR_A_THREE_D.test(tmp1);

        // Victoria (OLD) - V
        case 'V':
            tmp1 = value.substring(1);
            return PIC_FORMAT_REGEX_THREE_A_FOUR_D.test(tmp1);

        // NSW and SA use all digits from 2nd onwards
        case 'N':
        case 'S':
            if (!validSecondCharNonVic(char1)) {
                return false;
            }
            tmp1 = value.substring(2);
            return PIC_FORMAT_REGEX_SIX_D.test(tmp1);

        // QLD, TAS, WA, NT
        case 'Q':
        case 'M':
        case 'W':
        case 'T':
            if (!validSecondCharNonVic(char1)) {
                return false;
            }
            tmp1 = value.substring(2);
            return PIC_FORMAT_REGEX_TWO_A_FOUR_D.test(tmp1);

        default:
            break;
    }

    return false;
}

function getPICValue(value: string): { total: number; divided: number; valid: boolean } {
    const char0 = value[0];
    // Step 1. Convert characters to mapped value
    // Step 2. Multiply by position in big endian style e.g. x^n, x^n-1 etc
    // Step 3. Sum up values
    const valueLength = value.length;
    let sum = 0;
    if (char0 === 'N') {
        // NSW
        for (let index = 0; index < valueLength; index++) {
            const char = value[index];
            const charValue = getCharValueNSW(char, index);
            const pow = valueLength - index - 1;
            sum += charValue * Math.pow(2, pow);
        }
    }

    if (char0 === 'S') {
        // SA
        for (let index = 0; index < valueLength; index++) {
            const char = value[index];
            const charValue = getCharValueSA(char, index);
            const pow = valueLength - index - 1;
            sum += charValue * Math.pow(2, pow);
        }
    }

    if (char0 === 'W') {
        // WA
        for (let index = 0; index < valueLength; index++) {
            const char = value[index];
            const charValue = getCharValueWA(char, index);
            const pow = valueLength - index - 1;
            sum += charValue * Math.pow(2, pow);
        }
    }

    if (char0 === 'T') {
        // NT
        const ntValue = getValueNT(value);
        if (!ntValue) {
            return { total: 0, divided: 0, valid: false };
        }
        sum = ntValue;
    }

    if (char0 === 'Q' || char0 === 'M' || char0 === '3' || char0 === 'V') {
        // QLD, TAS, VIC, VIC
        for (let index = 0; index < valueLength; index++) {
            const char = value[index];
            const charValue = getCharValueVIC(char);
            const pow = valueLength - index - 1;
            sum += charValue * Math.pow(2, pow);
        }
    }

    // Step 4. Divide by 23 (When char0 is 3) or by 11 otherwise
    const divided = char0 === '3' ? sum / 23 : sum / 11;

    // Step 5. If value is whole number = true, else false
    const valid = divided % 1 === 0;

    return { total: sum, divided, valid };
}

function validatePIC(pic: string): boolean {
    const value = pic.toLocaleUpperCase();

    // Validate formatting first, if that fails then it is rejected
    const validFormat = validatePICFormat(value);

    if (!validFormat) {
        return false;
    }

    const { valid } = getPICValue(value);
    return valid;
}

export { getPICValue, validatePIC, validatePICFormat };
export default validatePIC;
