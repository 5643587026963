import 'react-responsive-carousel/lib/styles/carousel.min.css';

import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'react-responsive-carousel';

import TabBarItem from './TabBarItem';
import TabBarItemIcon from './TabBarItemIcon';

interface ITabBarItem {
    title: string;
    handler: () => void;
    icon?: 'success' | 'error' | 'loading';
    hidden?: boolean;
}

interface Props {
    items: ITabBarItem[];
    direction?: 'horizontal' | 'vertical';
    activeIndex: number;
}

const TabBarMobileHeader: React.FC<Omit<Props, 'direction'>> = ({ items, activeIndex }) => {
    return (
        <div className="TabBarMobileHeader">
            <style jsx>{`
                @import 'vars';
                @import 'mixins';
                @import 'utils';

                .TabBarMobileHeader {
                    width: 100%;

                    &--Active {
                        font-weight: bold;
                    }

                    &--Default {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    :global(.carousel) {
                        height: grid(12);

                        :global(.slider-wrapper) {
                            height: 100%;

                            :global(.slider) {
                                height: 100%;
                                align-items: center;
                            }
                        }

                        :global(.slide) {
                            cursor: pointer;
                            background: none;
                        }
                    }
                }
            `}</style>

            <Carousel showStatus={false} showThumbs={false} showIndicators={false} showArrows={false} emulateTouch centerMode={true} selectedItem={activeIndex} centerSlidePercentage={40}>
                {items.map((item, index) =>
                    // ENVDB-1050: if any section is disabled or have no questions in it, we ll not show that section in the tab bar
                    !item.hidden ? (
                        <span
                            key={item.title}
                            onClick={item.handler}
                            className={classnames('TabBarMobileHeader--Default', {
                                'TabBarMobileHeader--Active': index === activeIndex,
                                'TabBarMobileHeader--Sides': index === activeIndex - 1 || index === activeIndex + 1,
                            })}
                        >
                            <TabBarItemIcon icon={item.icon} />
                            {item.title}
                        </span>
                    ) : (
                        <></>
                    )
                )}
            </Carousel>
        </div>
    );
};

const TabBar: React.FunctionComponent<Props> = ({ items, direction = 'horizontal', activeIndex }) => {
    const [activeItem, setActiveItem] = useState<ITabBarItem | undefined>(items.length > 0 ? items[activeIndex] : undefined);

    useEffect(() => {
        setActiveItem(items.length > 0 ? items[activeIndex] : undefined);
    }, [activeIndex, items]);

    const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    const isDesktop = !isTabletOrMobile;

    return (
        <div className={`TabBar ${direction === 'horizontal' ? 'flex-row' : 'flex-column'}`}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .TabBar {
                    display: flex;
                    justify-content: space-between;

                    @media (max-width: $md-max) {
                        overflow-x: auto;
                        scroll-behavior: smooth;

                        position: sticky;
                        top: grid(13);
                        margin-left: grid(-4);
                        margin-right: grid(-4);
                        border-top: 1px solid $color-grey79;
                        border-bottom: 1px solid $color-grey79;

                        background-color: $color-white;

                        z-index: 1;

                        @media (prefers-color-scheme: dark) {
                            background-color: darken($color-grey96, 85%);
                        }
                    }

                    :global(.TabBarItem--Active) {
                        color: $color-primary;
                    }

                    :global(.TabBarItem--Symbol) {
                        font-size: grid(8);
                        line-height: 1rem;
                        cursor: pointer;
                    }
                }
            `}</style>
            {isDesktop &&
                items.map(
                    (item) =>
                        // ENVDB-1050: if any section is disabled or have no questions in it, we ll not show that section in the tab bar
                        !item.hidden && (
                            <TabBarItem
                                key={item.title}
                                title={item.title}
                                onClick={() => {
                                    // setActiveItem(item);
                                    item.handler();
                                }}
                                isActive={activeItem?.title === item.title}
                                icon={item.icon}
                            />
                        )
                )}
            {isTabletOrMobile && <TabBarMobileHeader items={items} activeIndex={activeIndex} />}
        </div>
    );
};

export default TabBar;
