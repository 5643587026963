import { IDynamicQuestion, IStaticQuestion } from '@typings';
import { SectionName } from '@utils/enums';
import { DynamicQuestions, HYBRID_SECTIONS, IHybridSectionDisplay, STATIC_SECTIONS, StaticQuestions } from '@utils/section-mapper';
import React, { useMemo } from 'react';

import { TAirtableConsignment } from '../../ConsignmentEdit';
import DynamicQuestion from './components/dynamic/DynamicQuestion';
import StaticQuestion from './components/static/StaticQuestion';

interface Props {
    name: SectionName;
    data: TAirtableConsignment;
}

const DynamicSection: React.FC<Props> = ({ name, data }) => {
    // any to dodge relay typing
    // We want to map the questions and answers together
    const questionOrders: number[] = DynamicQuestions[name];
    const questions: NonNullable<any> = questionOrders.map((order) => data?.questions?.find((q: any) => q?.order === order)).coalesce();

    return (
        <div>
            {questions.map((question: IDynamicQuestion) => (
                <div key={question.id}>{<DynamicQuestion key={question.id} question={question} index={null} allQuestionsFromSection={questions} />}</div>
            ))}
        </div>
    );
};

const StaticSection: React.FC<Props> = ({ name, data }) => {
    const questions = StaticQuestions[name] as IStaticQuestion[];
    return (
        <div>
            {questions?.map((question, idx) => (
                <StaticQuestion key={question.field + idx} question={question} data={data} />
            ))}
        </div>
    );
};

const HybridSection: React.FC<Props> = (props) => {
    const { name, data } = props;
    const hybridSectionMeta = HYBRID_SECTIONS.firstOrDefault((x: IHybridSectionDisplay) => x.name === name)!;
    const questionOrders: number[] = DynamicQuestions[name];
    const dynamicQuestions: NonNullable<any>[] = questionOrders.map((order) => data?.questions?.find((q: any) => q?.order === order)).coalesce();
    const questions = useMemo(() => {
        const staticQuestions = StaticQuestions[name] as IStaticQuestion[];

        // Strict check on undefined because of falsy equality
        let ret: (IDynamicQuestion | IStaticQuestion)[] = [];

        ret = [...dynamicQuestions];

        // Grab all the ordered static questions to push at position
        const orderedStaticQuestions = staticQuestions.filter((question: IStaticQuestion) => question.order !== undefined);

        for (const q of orderedStaticQuestions) {
            // Find where we should insert this question. Either AFTER the same order in dynamic or after the previous order if a matching order doesn't exist
            const idx = dynamicQuestions.findIndex((question: IDynamicQuestion) => question.order === q.order || question.order - 1 === q.order);

            ret.splice(idx + 1, 0, q);
        }

        // Append all the extra ones at position (either front or back)
        const unorderedStaticQuestions = staticQuestions.filter((question: IStaticQuestion) => question.order === undefined);
        ret = hybridSectionMeta.staticPosition === 'top' ? [...unorderedStaticQuestions, ...ret] : [...ret, ...unorderedStaticQuestions];

        return ret;
    }, [name, dynamicQuestions, hybridSectionMeta.staticPosition]);

    // With hybrid sections we can merge the questions together based on order if it exists.
    // If no order exists inside the static questions then we can just use top and bottom style rendering
    return (
        <div>
            {questions.map((question: IDynamicQuestion | IStaticQuestion, position: number) => {
                if ('id' in question) {
                    return <div key={`dynamic-${question.id}`}>{<DynamicQuestion key={question.id} question={question} index={null} allQuestionsFromSection={dynamicQuestions} />}</div>;
                } else {
                    return <StaticQuestion key={`static-${question.field + position}`} question={question} data={data} />;
                }
            })}
        </div>
    );
};

const Section: React.FC<Props> = (props) => {
    // We need to do custom mapping for movement, forms and declaration sections since they aren't question/answer pairings
    const { name } = props;

    // ADDITIONAL_INFO too, but not needed atm
    if (HYBRID_SECTIONS.some((x: IHybridSectionDisplay) => x.name === name)) {
        return <HybridSection {...props} />;
    }

    if (STATIC_SECTIONS.includes(name)) {
        return <StaticSection {...props} />;
    }

    return <DynamicSection {...props} />;
};

export default Section;
