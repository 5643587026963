import { ClearIcon, SortIcon } from '@assets/icons';
import Button, { ButtonSize, ButtonType } from '@components/Button';
import ClickableIcon from '@components/ClickableIcon';
import RelativePortal from '@components/RelativePortal';
import classnames from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import Popover from './Popover';
import RadioList from './RadioList';
import { SelectableListItem } from './SelectableList';

interface Props {
    title: string;
    items: SelectableListItem[];
    onChange: (item: SelectableListItem[]) => void;
    buttonType?: ButtonType;
    buttonSize?: ButtonSize;
    buttonText?: string;
    selectedItem?: string;
}

const SortMenu: React.FC<Props> = ({ title, items, onChange, buttonType = 'tertiary', buttonSize = 'normal', buttonText = 'Sort by' }) => {
    const node = useRef<HTMLDivElement>(null);
    const [show, setShow] = useState(false);

    const closeAction = useCallback(() => {
        setShow(false);
    }, []);

    const selectedTitle = useMemo(() => {
        return items.filter((x) => x?.selected)[0]?.title;
    }, [items]);

    const updateSortSelection = useCallback(
        (value: SelectableListItem) => {
            const updatedItemList: SelectableListItem[] = items.map((obj) => {
                if (value.id === obj.id) {
                    obj.selected = true;
                } else {
                    obj.selected = false;
                }
                return obj;
            });
            onChange(updatedItemList);
            setShow(false);
        },
        [items, onChange]
    );

    return (
        <div className="SortMenu" ref={node}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                :global(.Popover) {
                    :global(.sort-Consignment-popOver) {
                        width: 100% !important;
                    }
                    // Full screen when mobile
                    @media (max-width: $sm-max) {
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        position: fixed !important;
                        border: none !important;
                        margin: 0 !important;
                        border-radius: 0 !important;
                        padding: grid(8) !important;

                        :global(.sort-Consignment-popOver) {
                            height: 100% !important;
                            width: 100% !important;
                            max-height: 100% !important;
                        }
                    }
                }

                .form-element {
                    padding: grid(3);
                    user-select: none;

                    &.with-success {
                        border-color: $color-success;
                    }

                    &.with-error {
                        border-color: $color-error;
                    }

                    &.active {
                        border-color: $color-primary;
                        background-color: $color-fade;
                    }
                }

                .SortMenuPopoverHeader {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: grid(4);

                    position: sticky;
                    top: 0;
                    background: $color-white;
                    z-index: 99;

                    @media (prefers-color-scheme: dark) {
                        background: $color-black;
                        color: $color-white;
                    }
                }

                .SortMenuPopoverFooter {
                    display: none;

                    // Visible on mobile
                    @media (max-width: $sm-max) {
                        position: fixed;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        display: block;
                    }
                }
            `}</style>
            <Button
                buttonType={buttonType}
                buttonSize={buttonSize}
                className={classnames({
                    active: show,
                })}
                action={() => setShow(!show)}
            >
                <SortIcon />
                {buttonText && (
                    <span className="m-r-8">
                        {buttonText}: <span className="seleted-title">{selectedTitle}</span>
                    </span>
                )}
            </Button>
            <RelativePortal
                id={'sort-menu-portal'}
                fitToParentWidth={true}
                show={show}
                onParentClick={undefined}
                onOutClick={() => {
                    setShow((s) => {
                        return s ? !s : s;
                    });
                }}
                parentElementRef={node}
            >
                <Popover popOverContentClassname="sort-Consignment-popOver">
                    <div className="SortMenuPopoverHeader">
                        <h3>{title}</h3>
                        <ClickableIcon onClick={closeAction} alt="Close" icon={<ClearIcon />} />
                    </div>
                    <RadioList items={items} onChange={updateSortSelection} />
                    <div className="SortMenuPopoverFooter">
                        <Button buttonType="primary" fullWidth onClick={closeAction}>
                            Done
                        </Button>
                    </div>
                </Popover>
            </RelativePortal>
        </div>
    );
};

export default SortMenu;
