import { graphql } from 'relay-hooks';

// eslint-disable-next-line
export const ConsignmentUserMappingQuery = graphql`
    query ConsignmentUserMappingQuery($id: String!, $role: String!, $envdAccountId: String!) {
        consignmentUserMapping(consignmentNumber: $id, role: $role, envdAccountId: $envdAccountId) {
            email
            firstName
            lastName
            consignmentNumber
            role
            mappingId
            pic
            numOfHeads
        }
    }
`;
