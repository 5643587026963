import Button from '@components/Button';
import Input, { InputType } from '@components/Form/Input';
import Modal from '@components/Modal';
import UserSelect from '@components/UserSelect';
import useKeyPress, { KeyCode } from '@effects/useKeyPress';
import { GraphqlError, IPerson } from '@typings';
import { RoleTypeEnum } from '@utils/enums';
import { EMAIL_REGEX } from '@utils/question-validator';
import { isValidString } from '@utils/string-extensions';
import { isEmpty } from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useMutation } from 'relay-hooks';

import { ConsignmentUserMappingInput } from '../mutations/__generated__/createUserMappingMutation.graphql';
import createUserMapping, { CreateUserMappingMutation } from '../mutations/createUserMapping';
import { hasValidName } from './ConsignmentAddStep1';
import PICSearch from './fragments/PICSearch';

export interface AddBuyerModalRef {
    show(): void;
    hide(): void;
}

interface AddBuyerModalProps {
    consignmentNumber: string;
    buyersList: ConsignmentUserMappingInput[];
    setBuyers: (value: any) => void;
    envdAccountId: string;
}

interface BuyerAttribute {
    owner: IPerson | undefined;
    numOfHeads: string | undefined;
    emailId?: string;
}

interface AddBuyerModalErrorType {
    isValidOwner?: boolean;
    isValidNumOfHeads?: boolean;
    isValidEmail?: boolean;
    errorMessage?: string;
}

const samePICAndEmailErrorMessage = (emailId: string, pic: string) => {
    return `${emailId} with ${pic} was previously granted buyer access to this consignment.`;
};

const samePICErrorMessage = (pic: string) => {
    return `${pic} was previously granted buyer access to this consignment.`;
};
const initBuyerAttributes: BuyerAttribute = {
    owner: undefined,
    numOfHeads: '',
    emailId: '',
};

export const AddBuyerModal = forwardRef(({ consignmentNumber, buyersList, setBuyers, envdAccountId }: AddBuyerModalProps, ref: React.Ref<AddBuyerModalRef | undefined | null>) => {
    const [show, setShow] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 576 });
    const [isLoading, setLoading] = useState<boolean>(false);
    const escKeyPress = useKeyPress(KeyCode.escape);
    const [buyer, setBuyer] = useState<BuyerAttribute | undefined>(initBuyerAttributes);
    const [error, setError] = useState<AddBuyerModalErrorType | undefined>(undefined);
    const [editMode, setEditMode] = useState<boolean | undefined>(false);
    const [mutate] = useMutation(CreateUserMappingMutation);
    const resetState = () => {
        setBuyer(undefined);
        setError(undefined);
        setShow(false);
    };

    useEffect(() => {
        if (show) {
            setShow(false);
        }
        // eslint-disable-next-line
    }, [escKeyPress]);

    useImperativeHandle(ref, () => ({
        show: () => {
            setShow(true);
        },
        hide: () => {
            setShow(false);
        },
    }));

    if (!show) {
        return null;
    }

    const validateBuyerInputs = () => {
        if (isEmpty(buyer?.owner) || !checkValidNumOfHeads(buyer?.numOfHeads)) {
            setError({ ...error, isValidOwner: !isEmpty(buyer?.owner), isValidNumOfHeads: checkValidNumOfHeads(buyer?.numOfHeads) });
            return false;
        }

        if (buyer?.emailId && !EMAIL_REGEX.test(buyer?.emailId?.trim())) {
            setError({ ...error, isValidEmail: false });
            return false;
        }
        if (buyersList.length) {
            const samePICBuyerWithNoEmail = buyersList.find((x) => x.pic === buyer?.owner?.pic && isEmpty(buyer?.emailId) && isEmpty(x?.email));
            if (samePICBuyerWithNoEmail) {
                setError({ ...error, errorMessage: samePICErrorMessage(buyer?.owner?.pic || '') });
                return false;
            }
            const samePICBuyerWithEmail = buyersList.find((x) => x.pic === buyer?.owner?.pic && buyer?.emailId === x?.email);
            if (samePICBuyerWithEmail) {
                setError({ ...error, errorMessage: samePICAndEmailErrorMessage(buyer?.emailId || '', buyer?.owner?.pic || '') });
                return false;
            }
        }
        return true;
    };

    const checkValidNumOfHeads = (value: string | undefined) => {
        const numOfHeads = Number(value);
        return Number.isInteger(numOfHeads) && numOfHeads > 0;
    };

    const onSubmit = () => {
        const isValidBuyerDetails = validateBuyerInputs();
        if (!isValidBuyerDetails) {
            return;
        }
        const tempBuyers = buyersList?.map((val: ConsignmentUserMappingInput) => {
            return {
                mappingId: val.mappingId,
                email: val?.email,
                consignmentNumber: val.consignmentNumber,
                role: val.role,
                pic: val?.pic,
                numOfHeads: val?.numOfHeads,
            };
        });
        const mappings = [...tempBuyers, { consignmentNumber: consignmentNumber, email: buyer?.emailId, pic: buyer?.owner?.pic, numOfHeads: Number(buyer?.numOfHeads), role: RoleTypeEnum.BUYER }];
        setLoading(true);
        const response = createUserMapping(mutate, { consignmentNumber: consignmentNumber, role: RoleTypeEnum.BUYER, mappings: mappings || [] }, envdAccountId);
        response
            .then((mappings) => {
                setBuyers(mappings);
                resetState();
                setLoading(false);
            })
            .catch((e) => {
                var errors = e as GraphqlError[];
                let message = errors?.map((error) => error.message).join('\n');
                if (message?.indexOf('GraphQL.ExecutionError: ') > -1) {
                    message = message.substring('GraphQL.ExecutionError: '.length);
                }
                setError({ ...error, errorMessage: message });
                setLoading(false);
            });
    };

    const checkEdit = (value: boolean | undefined) => {
        setEditMode(value);
    };

    return (
        <>
            <Modal
                id={'buyer-modal'}
                show={true}
                modalHeader={
                    <>
                        <h2 className="buyer-modal-heading">Grant a buyer access to this consignment</h2>
                        <br />
                        <h4 className="small">Buyers will be able to view details about the consignment sold to them. They will not be able to edit or delete details.</h4>
                    </>
                }
                modalFooter={
                    <>
                        <Button
                            buttonType="secondary"
                            buttonSize="small"
                            onClick={() => {
                                resetState();
                            }}
                            className="m-h-8"
                        >
                            Cancel
                        </Button>
                        <Button buttonType="primary" className={editMode ? 'disabled' : ''} buttonSize="small" type={'submit'} onClick={onSubmit} disabled={editMode || isLoading} loading={isLoading}>
                            Add Buyer
                        </Button>
                    </>
                }
                onOutClick={() => {
                    resetState();
                }}
            >
                <div className="form-block">
                    {!buyer?.owner && (
                        <PICSearch
                            labelText="Destination PIC"
                            onSelected={(e) => {
                                setBuyer({ ...buyer, owner: e, numOfHeads: buyer?.numOfHeads });
                                setError({ ...error, isValidOwner: !isEmpty(e), errorMessage: '' });
                            }}
                            searchMode="DESTINATION"
                            dropDownTopHeightAdjustment={isMobile ? 50 : 76}
                            requiredText={error?.isValidOwner === undefined || error?.isValidOwner}
                            errorText="Required"
                            isValid={error?.isValidOwner}
                        />
                    )}
                    {buyer?.owner && (
                        <UserSelect
                            onChange={(owner) => {
                                if (isValidString(owner.pic)) {
                                    setBuyer({ ...buyer, owner: owner });
                                    setError({ ...error, errorMessage: '' });
                                } else {
                                    return false;
                                }
                            }}
                            person={buyer?.owner || ({} as IPerson)}
                            showEdit={{ title: 'Edit PIC', active: true, expandOnDefault: buyer?.owner?.pic === '' || !hasValidName(buyer?.owner || undefined) }}
                            showChange={{ title: 'Select a different PIC', active: true, onClicked: () => setBuyer({ ...buyer, owner: undefined }) }}
                            onCancel={() => setBuyer((s) => ({ ...buyer, owner: undefined }))}
                            displayNonPICField={false}
                            indent={false}
                            checkEdit={checkEdit}
                        />
                    )}
                    <div className="emailId">
                        <Input
                            labelText="Buyer email Address"
                            errorText="Please enter a Valid Email"
                            inputType={InputType.Text}
                            required={false}
                            isValid={error?.isValidEmail}
                            value={buyer?.emailId}
                            onChange={(e) => {
                                setBuyer({ emailId: e.target?.value, owner: buyer?.owner, numOfHeads: buyer?.numOfHeads });
                                setError({ ...error, errorMessage: '', isValidEmail: undefined });
                            }}
                        />
                    </div>
                    <div className="headSold">
                        <Input
                            isValid={error?.isValidNumOfHeads}
                            errorText={isEmpty(buyer?.numOfHeads) ? 'Required' : 'Please enter a valid number'}
                            labelText="No. of head sold"
                            inputType={InputType.Text}
                            required={true}
                            value={buyer?.numOfHeads}
                            onChange={(e) => {
                                setBuyer({ emailId: buyer?.emailId, owner: buyer?.owner, numOfHeads: e?.target?.value });
                                setError({ ...error, isValidNumOfHeads: checkValidNumOfHeads(e?.target?.value), errorMessage: '' });
                            }}
                        />
                    </div>
                    {error?.errorMessage ? <p className="error-message small">{error?.errorMessage}</p> : null}
                </div>
            </Modal>
            <style jsx>
                {`
                    @import 'vars';
                    @import 'utils';
                    @import 'mixins';

                    :global(#buyer-modal) {
                        :global(.Modal--Header) {
                            border: none;
                            background-color: $color-white;
                            margin-top: grid(8);
                            @media (prefers-color-scheme: dark) {
                                background-color: darken($color-white, 90%);
                            }
                        }
                        :global(.Modal--Footer) {
                            border: none;
                            margin-left: auto;
                            box-shadow: none;
                        }
                        :global(.Modal--ScrollContainer) {
                            overflow-y: auto;
                            :global(.UserSelect) {
                                padding: 0;
                            }
                        }
                        :global(.disabled) {
                            cursor: not-allowed;
                        }
                    }

                    .error-message {
                        color: $color-error;
                    }

                    .emailId,
                    .headSold {
                        margin-top: 40px;
                    }
                `}
            </style>
        </>
    );
});

export default AddBuyerModal;
