import { useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import FeedbackButton from '@components/FeedbackButton';
import Input from '@components/Form/Input';
import Loader from '@components/Loader';
import Tag from '@components/Tag';
import Config from '@config';
import useBrowserDetection from '@effects/useBrowserDetection';
import useWindowTitle from '@effects/useWindowTitle';
import { IDynamicQuestion } from '@typings';
import { MOBILE_OR_TABLET_WIDTH, UNKNOWN_PIC } from '@utils/constants';
import { consignmentEnumToColor, consignmentEnumToName, ConsignmentStatus } from '@utils/enum-transformers';
import { SectionName } from '@utils/enums';
import { getFormAlias } from '@utils/form-alias';
import MLALogger from '@utils/logger';
import { canCreateConsignment } from '@utils/question-editable';
import { getAllChildQuestions } from '@utils/question-getter';
import { sortByNumberDesc } from '@utils/question-sort';
import { EMAIL_REGEX } from '@utils/question-validator';
import classnames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router';
import { graphql, useQuery } from 'relay-hooks';

import { ConsignmentDetailQuery, ConsignmentDetailQueryResponse } from './__generated__/ConsignmentDetailQuery.graphql';
import { cleanConsignmentPIC } from './mutations/createOrSaveConsignment';

const stringToRelativeTime = (str?: string) => (str && new Date(str)?.relativeTime()) || 'never';

const query = graphql`
    query ConsignmentDetailQuery($consignmentNumber: String!, $envdAccountId: String!) {
        consignment(id: $consignmentNumber, envdAccountId: $envdAccountId) {
            ...ConsignmentEditConsignmentFragment @relay(mask: false)
        }
    }
`;

// HACK: Track these IDs as we need to use them specifically
const ID_QUESTION_DESCRIPTION = '1';
export const ID_QUESTION_DESCRIPTION_HEADS = '3';
export const ID_QUESTION_DESCRIPTION_BREED = '4';
export const ID_QUESTION_DESCRIPTION_SEX = '5';
const ID_QUESTION_TRANSPORTER = '157';
const ID_QUESTION_TRANSPORTER_NAME = '158';
const ID_QUESTION_TRANSPORTER_REGO = '159';

const ConsignmentSummaryPage: React.FC<{ consignment: ConsignmentDetailQueryResponse['consignment'] }> = ({ consignment }) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MOBILE_OR_TABLET_WIDTH });
    const isDesktop = !isTabletOrMobile;
    const [email, setEmail] = useState<string>('');
    const isIE = useBrowserDetection();

    const history = useHistory();

    // TODO: Remove this once email story has been done
    const showEmailSection = false;

    // const isIE = useBrowserDetection();
    const [{ user }] = useUserState();
    const canCreate = canCreateConsignment(user);
    const canEdit = consignment?.status !== ConsignmentStatus.LOCKED;

    const sendEmail = useCallback(
        (e: any) => {
            e.preventDefault();
            if (email && EMAIL_REGEX.test(email)) {
                MLALogger.Log(['ConsignmentDetail', 'sendEmail'], 'valid');
                // TODO: Send once the story has been done
            }
        },
        [email]
    );

    let consignmentPDFurl = consignment?.pdfUrl?.startsWith('http') ? consignment?.pdfUrl : Config.BASE_GRAPHQL_SERVER_URL + '/' + consignment?.pdfUrl;

    // PartB is Question 1, an
    let total = 0;
    let descriptionData: { brand: string; heads: number }[] = [];
    const descriptionQuestion: IDynamicQuestion = consignment?.questions?.firstOrDefault((question: any) => question.id === ID_QUESTION_DESCRIPTION) as any as IDynamicQuestion;
    if (descriptionQuestion) {
        const allDescriptionChildQuestions = getAllChildQuestions(descriptionQuestion);
        // Don't use dynamic, use original since we aren't doing any updates
        const descriptionAnswers = consignment?.answers?.coalesce().filter((answer) => allDescriptionChildQuestions.some((cq) => cq.id === answer.questionId)) ?? [];
        const descriptionHighestIndex = (descriptionAnswers?.map((x) => x.index)?.filter((x) => !!x) as NonNullable<number[]>)?.sort(sortByNumberDesc)?.last() ?? 0;
        descriptionData = new Array(descriptionHighestIndex + 1).fill({});

        // We only care about brand description and number of heads, so merge them into an object at index
        for (const answer of descriptionAnswers) {
            // Should only show the sex not brand
            if (answer.questionId === ID_QUESTION_DESCRIPTION_BREED) {
                descriptionData = descriptionData.map((x: any, index: number) => (index === answer.index! ? { ...x, breed: answer.value! } : x));
            }
            if (answer.questionId === ID_QUESTION_DESCRIPTION_SEX) {
                descriptionData = descriptionData.map((x: any, index: number) => (index === answer.index! ? { ...x, sex: answer.value! } : x));
            }
            if (answer.questionId === ID_QUESTION_DESCRIPTION_HEADS) {
                descriptionData = descriptionData.map((x: any, index: number) => (index === answer.index! ? { ...x, heads: Number.parseInt(answer.value!, 10) } : x));
            }
        }
        // Remake since we found some data
        const descriptionTotal = descriptionData?.reduce((p, c: any) => p + ('heads' in c ? (c.heads as number) : 0) ?? 0, 0) ?? 0;
        total = descriptionTotal;
    }

    let transporterData: { registration: string; name: string }[] = [];
    const transporterQuestion: IDynamicQuestion = consignment?.questions?.firstOrDefault((question: any) => question.id === ID_QUESTION_TRANSPORTER) as any as IDynamicQuestion;
    if (transporterQuestion) {
        const allTransporterQuestions = getAllChildQuestions(transporterQuestion);

        const transporterAnswers = consignment?.answers?.coalesce().filter((answer) => allTransporterQuestions.some((cq) => cq.id === answer.questionId)) ?? [];
        const transporterHighestIndex = (transporterAnswers?.map((x) => x.index)?.filter((x) => !!x) as NonNullable<number[]>)?.sort(sortByNumberDesc)?.last() ?? 0;
        // Remake since we found ata
        transporterData = new Array(transporterHighestIndex + 1).fill({});

        for (const answer of transporterAnswers) {
            if (answer.questionId === ID_QUESTION_TRANSPORTER_REGO) {
                transporterData = transporterData.map((x: any, index: number) => (index === answer.index! ? { ...x, registration: answer.value! } : x));
            }
            if (answer.questionId === ID_QUESTION_TRANSPORTER_NAME) {
                transporterData = transporterData.map((x: any, index: number) => (index === answer.index! ? { ...x, name: answer.value! } : x));
            }
        }
    }

    return (
        <div className="ConsignmentSummaryPage">
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                @media (max-width: $lg-max) {
                    .ConsignmentSummaryPage {
                        padding: grid(4) 0 0;
                    }
                }

                .ContentBox {
                    display: flex;
                    border: 1px solid $color-border;
                    border-radius: $border-radius;
                    background-color: $color-white;

                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }

                    @media (max-width: $md-max) {
                        background-color: transparent;
                        border: none;
                        flex-direction: column;
                        padding: 0;
                    }

                    h2 {
                        margin-bottom: grid(4);
                    }

                    &--IE {
                        @media (max-width: $md-max) {
                            display: block;
                        }
                    }
                }

                .Column {
                    flex: 1 0 33%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    padding: grid(8);

                    @media (max-width: $md-max) {
                        border: none;
                        border-radius: $border-radius;
                        background-color: transparent;
                        margin: grid(4) 0;
                        padding: grid(4) 0;

                        @media (prefers-color-scheme: dark) {
                            border: none;
                            border-radius: $border-radius;
                        }
                    }

                    section {
                        margin-top: grid(8);
                        width: 100%;
                    }
                }

                .Left {
                    @extend .Column;

                    flex: 2 0 66%;

                    border-right: 1px solid $color-border;

                    @media (max-width: $md-max) {
                        border-right: none;
                    }

                    &--Header {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &-ConsignmentDetails {
                            margin-bottom: grid(4);
                            display: flex;
                            align-items: center;

                            h2 {
                                margin-bottom: 0;
                                margin-right: grid(2);
                            }
                        }

                        &--Mobile {
                            position: fixed;
                            right: grid(2);
                            top: 0;
                            z-index: 8;

                            // Header height
                            height: 52px;
                            display: flex;
                            align-items: center;
                        }
                    }

                    &--IE {
                    }

                    .Subtitle {
                        @include text-xsmall;
                    }

                    .FormsSection {
                        @media (max-width: $md-max) {
                            margin: 0;
                        }
                        &--Forms {
                            > div {
                                display: inline-flex;
                                align-items: center;

                                :global(img) {
                                    width: grid(6);
                                    height: grid(6);

                                    margin-right: grid(3);
                                }

                                span {
                                    margin-right: grid(3);
                                }
                            }
                        }
                    }

                    .MovementOnSection {
                        > div {
                            display: flex;
                            > div {
                                flex: 1 0 auto;
                            }

                            @media (max-width: $md-max) {
                                flex-direction: column;

                                > div {
                                    &:not(:last-child) {
                                        margin-bottom: grid(6);
                                    }
                                }
                            }
                        }

                        &--Column {
                            p {
                                margin: 0;
                            }

                            &-Title {
                                margin-bottom: grid(2);
                                font-weight: 600;
                            }
                        }
                    }

                    .LivestockSection {
                        &--Table {
                            max-width: grid(100);

                            &-Row {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                &-Heads {
                                    margin-top: grid(4);
                                    p {
                                        font-weight: bold;
                                    }
                                }
                            }

                            @media (max-width: $md-max) {
                                max-width: 100%;
                            }
                        }
                    }
                }

                .Right {
                    @extend .Column;

                    :global(.tbl) {
                        margin-top: grid(8);
                    }

                    &--Footer {
                        margin-top: grid(6);
                        align-self: center;
                    }

                    &--IE {
                        width: 100%;
                        p {
                            width: 70%;
                        }
                        flex: 1 1 auto;
                    }

                    @media (max-width: $md-max) {
                        background-color: transparent;
                        border: none;
                        padding: grid(6) 0 0;

                        border-top: 1px solid $color-border;
                        border-radius: 0;

                        :global(.input-container) {
                            max-width: 100%;
                        }
                    }

                    section {
                        &:first-of-type {
                            margin-top: 0;
                        }

                        p {
                            margin-bottom: grid(4);
                        }
                    }
                }

                .Spacer {
                    flex: 1 1 auto;
                }
            `}</style>
            {isDesktop && (
                <div className="title-section">
                    <h1>Consignment summary</h1>
                </div>
            )}

            <FeedbackButton url={Config.ISC_CUSTOMERFEEDBACK_URL} />
            {isTabletOrMobile && (
                <div className="Left--Header--Mobile">
                    <Button
                        buttonType="primary"
                        fullWidth={isTabletOrMobile}
                        buttonSize={isTabletOrMobile ? 'xsmall' : 'normal'}
                        onClick={() => history.push(`/consignments/edit/${consignment?.number}/${SectionName.MOVEMENT}`)}
                    >
                        {canCreate && canEdit ? 'Update' : 'View'}
                    </Button>
                </div>
            )}
            <div className={classnames('ContentBox', { 'ContentBox--IE': isIE })}>
                <div className={classnames('Left', { 'Left--IE': isIE })} data-cy="consignment-summary">
                    {isDesktop && (
                        <>
                            <div className="Left--Header">
                                <div className="Left--Header-ConsignmentDetails">
                                    <h2>{consignment?.number}</h2>
                                    {consignment && <Tag tagType={consignmentEnumToColor(consignment?.status)} text={consignmentEnumToName(consignment?.status)} />}
                                </div>

                                <Button
                                    buttonType="primary"
                                    fullWidth={isTabletOrMobile}
                                    buttonSize={isTabletOrMobile ? 'small' : 'normal'}
                                    onClick={() => history.push(`/consignments/edit/${consignment?.number}/${SectionName.MOVEMENT}`)}
                                >
                                    {canCreate && canEdit ? 'Update' : 'View'}
                                </Button>
                            </div>
                            <p className="Subtitle">Last updated {stringToRelativeTime(consignment?.updatedAt as string)}</p>
                        </>
                    )}
                    <section className="FormsSection">
                        <h2>Included forms</h2>
                        <div className="FormsSection--Forms">
                            {consignment?.forms?.map((x: any) => {
                                const fa = getFormAlias(x.type);
                                if (fa) {
                                    return (
                                        <div key={fa.program}>
                                            <img src={fa.image} className="Documents--img" alt="Logo" />
                                            <span>
                                                {fa.alias} {x.serialNumber && <span>({x.serialNumber})</span>}
                                            </span>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </section>
                    <section className="MovementOnSection">
                        <h2>Movement on {consignment?.movementDate ? new Date(consignment.movementDate as string).toMLADateString() : ''}</h2>
                        <div>
                            <div className="MovementOnSection--Column">
                                <h4 className="MovementOnSection--Column-Title">Moving from</h4>
                                <div>
                                    <p>{consignment?.origin?.name}</p>
                                    <p>{consignment?.origin?.pic}</p>
                                </div>
                            </div>
                            <div className="MovementOnSection--Column">
                                <h4 className="MovementOnSection--Column-Title">Moving to</h4>
                                <div>
                                    <p>{consignment?.destination?.name}</p>
                                    <p>{consignment?.destination?.pic}</p>
                                </div>
                            </div>
                            <div className="MovementOnSection--Column">
                                <h4 className="MovementOnSection--Column-Title">Transported by</h4>
                                <div>
                                    {transporterData.map((transporter: any, index: number) => (
                                        <div key={index}>
                                            <p>{transporter.name}</p>
                                            <p>{transporter.registration}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="LivestockSection">
                        <h2>Livestock details</h2>
                        <div className="LivestockSection--Table">
                            {descriptionData.map((description: any, index: number) => (
                                <div className="LivestockSection--Table-Row" key={index}>
                                    <p>{description.breed}</p>
                                    <p>{description.sex}</p>
                                    <p>{description.heads}</p>
                                </div>
                            ))}
                            <div className="LivestockSection--Table-Row LivestockSection--Table-Row-Heads">
                                <p>Total</p>
                                <p>{total}</p>
                            </div>
                        </div>
                    </section>
                </div>
                <div className={classnames('Right', { 'Right--IE': isIE })}>
                    <section>
                        <h2>Print consignment</h2>
                        <p>Remember to make 3 copies of this consignment</p>
                        <Button buttonType="secondary" fullWidth={isTabletOrMobile} buttonSize={isTabletOrMobile ? 'small' : 'normal'} onClick={() => window.open(consignmentPDFurl, 'allForms')}>
                            Print
                        </Button>
                    </section>

                    {canCreate && showEmailSection && (
                        <section>
                            <h2>Share consignment</h2>
                            <form onSubmit={sendEmail}>
                                <Input labelText="Email address" value={email} onChange={(e) => setEmail(e.target.value)} regex={EMAIL_REGEX} type="email" required />
                                <Button buttonType="secondary" fullWidth={isTabletOrMobile} buttonSize={isTabletOrMobile ? 'small' : 'normal'} type="submit">
                                    Send email
                                </Button>
                            </form>
                        </section>
                    )}

                    {canCreate && (
                        <section>
                            <h2>Is this a frequent movement?</h2>
                            <p>Create a template and save all the important pieces of information you entered in this consignment, saving you time.</p>
                            <Button
                                buttonType="secondary"
                                fullWidth={isTabletOrMobile}
                                buttonSize={isTabletOrMobile ? 'small' : 'normal'}
                                action={() => history.push(`/templates/add/${consignment?.number}`)}
                            >
                                Create template
                            </Button>
                        </section>
                    )}
                </div>
            </div>
        </div>
    );
};

const ConsignmentDetail: React.FunctionComponent = () => {
    const { id } = useParams<any>();
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;
    const { data } = useQuery<ConsignmentDetailQuery>(query, { consignmentNumber: id, envdAccountId }, { fetchPolicy: 'network-only' });

    useWindowTitle('Consignment Summary');

    if (data) {
        const { consignment } = data;
        // ENVDB-896: Making sure that destination pic is not AAAAAAAA
        let cloneConsignment = _.clone(consignment);
        if (consignment?.destination?.pic === UNKNOWN_PIC) {
            cloneConsignment = cleanConsignmentPIC(cloneConsignment, 'destination', '');
        }
        return (
            <>
                <ConsignmentSummaryPage consignment={cloneConsignment} />
            </>
        );
    } else {
        return <Loader error={''} isLoading pastDelay={false} timedOut={false} retry={() => null} />;
    }
};

export default ConsignmentDetail;
