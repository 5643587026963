/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ConsignmentSpecies = "BOBBY_CALVES" | "CATTLE" | "GOAT" | "SHEEP_LAMB" | "%future added value";
export type ConsignmentAddSelectPagedQueryVariables = {
    envdAccountId: string;
    count: number;
    cursor?: string | null;
    searchText?: string | null;
    species?: ConsignmentSpecies | null;
};
export type ConsignmentAddSelectPagedQueryResponse = {
    readonly templates: {
        readonly pageInfo: {
            readonly hasNextPage: boolean;
            readonly endCursor: string | null;
        };
        readonly edges: ReadonlyArray<{
            readonly cursor: string;
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"ConsignmentAddSelectTemplateRow_template">;
            } | null;
        } | null> | null;
    } | null;
};
export type ConsignmentAddSelectPagedQuery = {
    readonly response: ConsignmentAddSelectPagedQueryResponse;
    readonly variables: ConsignmentAddSelectPagedQueryVariables;
};



/*
query ConsignmentAddSelectPagedQuery(
  $envdAccountId: String!
  $count: Int!
  $cursor: String
  $searchText: String
  $species: ConsignmentSpecies
) {
  templates(envdAccountId: $envdAccountId, first: $count, after: $cursor, searchText: $searchText, species: $species) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        ...ConsignmentAddSelectTemplateRow_template
        __typename
      }
    }
  }
}

fragment ConsignmentAddSelectTemplateRow_template on Template {
  id
  number
  status
  name
  createdBy
  forms {
    type
  }
  origin {
    name
  }
  destination {
    name
  }
  species
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envdAccountId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchText"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "species"
},
v5 = {
  "kind": "Variable",
  "name": "envdAccountId",
  "variableName": "envdAccountId"
},
v6 = {
  "kind": "Variable",
  "name": "searchText",
  "variableName": "searchText"
},
v7 = {
  "kind": "Variable",
  "name": "species",
  "variableName": "species"
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v5/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v6/*: any*/),
  (v7/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = [
  (v13/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConsignmentAddSelectPagedQuery",
    "selections": [
      {
        "alias": "templates",
        "args": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "concreteType": "TemplateConnection",
        "kind": "LinkedField",
        "name": "__ConsignmentAddSelect_templates_connection",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Template",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ConsignmentAddSelectTemplateRow_template"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "ConsignmentAddSelectPagedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "TemplateConnection",
        "kind": "LinkedField",
        "name": "templates",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Template",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdBy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FormType",
                    "kind": "LinkedField",
                    "name": "forms",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TemplateOrigin",
                    "kind": "LinkedField",
                    "name": "origin",
                    "plural": false,
                    "selections": (v14/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TemplateDestination",
                    "kind": "LinkedField",
                    "name": "destination",
                    "plural": false,
                    "selections": (v14/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "species",
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "filters": [
          "envdAccountId",
          "searchText",
          "species"
        ],
        "handle": "connection",
        "key": "ConsignmentAddSelect_templates",
        "kind": "LinkedHandle",
        "name": "templates"
      }
    ]
  },
  "params": {
    "cacheID": "36992d0a49100130b999ae5cce711642",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "count",
          "cursor": "cursor",
          "direction": "forward",
          "path": [
            "templates"
          ]
        }
      ]
    },
    "name": "ConsignmentAddSelectPagedQuery",
    "operationKind": "query",
    "text": "query ConsignmentAddSelectPagedQuery(\n  $envdAccountId: String!\n  $count: Int!\n  $cursor: String\n  $searchText: String\n  $species: ConsignmentSpecies\n) {\n  templates(envdAccountId: $envdAccountId, first: $count, after: $cursor, searchText: $searchText, species: $species) {\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    edges {\n      cursor\n      node {\n        id\n        ...ConsignmentAddSelectTemplateRow_template\n        __typename\n      }\n    }\n  }\n}\n\nfragment ConsignmentAddSelectTemplateRow_template on Template {\n  id\n  number\n  status\n  name\n  createdBy\n  forms {\n    type\n  }\n  origin {\n    name\n  }\n  destination {\n    name\n  }\n  species\n}\n"
  }
};
})();
(node as any).hash = 'f041ee1c567ae41e159adebdfd6d8aa6';
export default node;
