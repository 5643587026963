import { Auth0ProviderWithHistory } from '@common/context/auth0Context';
import { UserProvider } from '@common/context/userContext';
import { WarningsProvider } from '@common/context/warningsContext';
import { AdminProtectedRoute, ProtectedRoute } from '@components/ProtectedRoute';
import AdminPage from '@containers/Admin';
import Callback from '@containers/Callback';
import Consignments from '@containers/Consignments';
import ConsignmentAdd from '@containers/Consignments/ConsignmentAdd';
import ConsignmentAddSelect from '@containers/Consignments/ConsignmentAddSelect';
import ConsignmentCopy from '@containers/Consignments/ConsignmentCopy';
import ConsignmentCopySelectTemplate from '@containers/Consignments/ConsignmentCopySelectTemplate';
import ConsignmentEdit from '@containers/Consignments/ConsignmentEdit';
import ConsignmentSumary from '@containers/Consignments/ConsignmentSummary';
import Edecs from '@containers/Edecs';
import Logout from '@containers/Logout';
import Profile from '@containers/Profile';
import Register from '@containers/Register';
import Templates from '@containers/Templates';
import TemplateAdd from '@containers/Templates/TemplateAdd';
import TemplateEdit from '@containers/Templates/TemplateEdit';
import Welcome from '@containers/Welcome';
import { StateProvider } from '@state';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

const AppRouter: React.FC<{}> = () => {
    return (
        <>
            <WarningsProvider>
                <Router>
                    <Auth0ProviderWithHistory>
                        <UserProvider>
                            <Switch>
                                <Route path="/" exact>
                                    <Welcome />
                                </Route>
                                <Route path="/callback" exact>
                                    <Callback />
                                </Route>
                                <StateProvider>
                                    <Switch>
                                        <Route path="/logout" exact>
                                            <Logout />
                                        </Route>

                                        <ProtectedRoute path="/consignments/edit/:id/:section" component={ConsignmentEdit} />

                                        <ProtectedRoute path="/consignments/summary/:id" component={ConsignmentSumary} />

                                        <ProtectedRoute path="/consignments/add-select" exact component={ConsignmentAddSelect} />

                                        <ProtectedRoute path="/consignments/select-template" exact component={ConsignmentCopySelectTemplate} />

                                        <ProtectedRoute path="/consignments/copy/:id" exact component={ConsignmentCopy} />

                                        <ProtectedRoute path="/profile" exact component={Profile} />

                                        <ProtectedRoute path="/consignments/add" exact component={ConsignmentAdd} />

                                        <ProtectedRoute path="/consignments" component={Consignments} />

                                        <ProtectedRoute path="/templates/edit/:id/:section" component={TemplateEdit} />

                                        <ProtectedRoute path="/templates/add/:id" exact component={TemplateAdd} />

                                        <ProtectedRoute path="/templates" exact component={Templates} />

                                        <ProtectedRoute path="/edecs" exact component={Edecs} />

                                        <ProtectedRoute path="/register" exact component={Register} />

                                        <AdminProtectedRoute path="/admin" exact component={AdminPage} />

                                        <Route path="/*">
                                            <Redirect to="/" />
                                        </Route>
                                    </Switch>
                                </StateProvider>
                            </Switch>
                        </UserProvider>
                    </Auth0ProviderWithHistory>
                </Router>
            </WarningsProvider>
            <style jsx global>
                {`
                    @import 'index';

                    .container {
                        margin-top: 50px;
                    }

                    body {
                        background: $color-grey96;

                        @media (prefers-color-scheme: dark) {
                            background: darken($color-grey96, 85%);
                        }

                        &.sb-show-main {
                            margin-top: 0;
                        }

                        -webkit-overflow-scrolling: touch;
                    }
                `}
            </style>
        </>
    );
};

export default AppRouter;
