import { useGlobalState } from '@state';
import React from 'react';

import { IStaticQuestionProps } from './StaticQuestion';

const StaticCalculateQuestion: React.FC<IStaticQuestionProps> = ({ question, data }) => {
    const [{ dynamicPendingAnswers }] = useGlobalState();

    const calculationAnswerFromPending = dynamicPendingAnswers.answers?.filter((answer) => question.options?.some((option) => option.id === answer.questionId)) ?? [];
    const calculationAnswersFromData = data?.answers?.filter((answer) => question.options?.some((option) => option.id === answer?.questionId)) ?? [];

    const calculationAnswers = calculationAnswerFromPending.length === 0 ? calculationAnswersFromData : calculationAnswerFromPending;

    const calculationAnswer = calculationAnswers
        .distinct()
        .coalesce()
        .reduce((p, c) => {
            let parsedInt = Number.parseInt(c.value, 10);
            if (Number.isNaN(parsedInt)) {
                // HACK: Handle the case where an ON for a boolean value is 1
                parsedInt = Boolean(c.value) ? 1 : 0;
            }
            return p + parsedInt;
        }, 0);

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'mixins';
                @import 'utils';
                .Calculation {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 0 -1px 0;
                    border: 1px solid $color-border;

                    &--Question,
                    &--Answer {
                        padding: grid(2) grid(4);
                        margin: 0;
                    }

                    &--Question {
                        width: 30%;
                        background-color: $color-grey96;

                        @media (prefers-color-scheme: dark) {
                            background-color: darken($color-grey96, 80%);
                        }
                    }
                    &--Answer {
                        flex-grow: 1;
                    }
                }
            `}</style>
            {calculationAnswer > 0 && (
                <div className="Calculation">
                    <p className="Calculation--Question">{question.text}</p>
                    <p className="Calculation--Answer">{calculationAnswer}</p>
                </div>
            )}
        </>
    );
};

export default StaticCalculateQuestion;
