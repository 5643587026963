import classnames from 'classnames';
import React from 'react';

interface Props {
    error: boolean;
    warn: boolean;

    light?: boolean;
    dark?: boolean;
}

const Spinner: React.FC<Props> = ({ error, warn, light, dark }) => {
    return (
        <div className={classnames('Spinner')}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                $spinner-size: grid(6);
                $spinner-border-size: 2px;

                .Spinner {
                    &--Spin {
                        display: block;
                        width: $spinner-size;
                        height: $spinner-size;
                        color: $color-secondary;

                        &:after {
                            position: relative;
                            box-sizing: border-box;
                            content: '';
                            width: 100%;
                            height: 100%;
                            display: inline-block;
                            border: $spinner-border-size solid currentColor;
                            border-bottom-color: transparent;
                            border-radius: 100%;
                            background: transparent;
                            animation: ball-clip-rotate 0.75s linear infinite;
                        }
                        &.error {
                            background-color: $color-error;
                        }
                        &.warn {
                            background-color: $color-warn;
                        }
                        &.success {
                            background-color: $color-success;
                        }
                        &.light {
                            color: $color-grey79;
                        }
                        &.dark {
                            color: $color-secondary;
                        }
                    }

                    @keyframes ball-clip-rotate {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                }
            `}</style>
            <span className={classnames('Spinner--Spin', { error, warn, light, dark })} />
        </div>
    );
};

export default Spinner;
