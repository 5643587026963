import * as React from 'react';
const SvgPerson = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={37} height={37} fill="none" {...props} viewBox="0 0 37 37">
        <path
            fill="#848A94"
            d="M18.5 0C13.594 0 8.888 1.95 5.418 5.418a18.507 18.507 0 0 0 0 26.164 18.507 18.507 0 0 0 26.164 0A18.5 18.5 0 0 0 37 18.5 18.52 18.52 0 0 0 18.5 0m0 9.25a6.436 6.436 0 1 1 0 12.871 6.436 6.436 0 0 1 0-12.871m0 25.107a15.7 15.7 0 0 1-10.624-4.11 1 1 0 0 0 .053-.356 6.004 6.004 0 0 1 6-6h.713a8.97 8.97 0 0 0 7.717 0h.713a5.98 5.98 0 0 1 6 6q-.005.181.053.356a15.7 15.7 0 0 1-10.625 4.11"
        />
    </svg>
);
export default SvgPerson;
