import { GetConfigurationsQuery } from '@containers/Consignments/queries/__generated__/GetConfigurationsQuery.graphql';
import { GetConfigurations } from '@containers/Consignments/queries/GetConfigurations';
import { IConfiguration } from '@typings';
import { useQuery } from 'relay-hooks';

export default function GetDynamicConfiguration(): IConfiguration {
    const { data } = useQuery<GetConfigurationsQuery>(GetConfigurations, {}, {});
    let configurationItems = data?.configurations?.items;

    let response: IConfiguration = {
        showBanner: null,
        bannerTitle: null,
        bannerType: null,
        bannerContent: null,
    };

    if (configurationItems) {
        let keys = Object.keys(response);
        keys.map((k) => {
            let key = k as keyof IConfiguration;
            response[key] = configurationItems?.find((i) => i?.key === k)?.value as string;
            return true;
        });
    }
    return response;
}
