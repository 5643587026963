import { InfoIcon, PersonIcon } from '@assets/icons';
import Button from '@components/Button';
import Input from '@components/Form/Input';
import createComment, { AddCommentMutation } from '@containers/Consignments/mutations/createConsignmentComment';
import useRoleValidation from '@effects/useRoleValidation';
import { COMMENT_MAXLENGTH, CONSIGNMENT_COMMENT_DIALOG_MESSAGE, CONSIGNMENT_COMMENT_FACILITATOR_ONLY_MESSAGE } from '@utils/constants';
import { RoleTypeEnum } from '@utils/enums';
import { useState } from 'react';
import { useMutation } from 'relay-hooks';

import { CommentRowProps } from './ViewComment';
interface CommentInfoDialogBoxProps {
    message: string;
}

const CommentInfoDialogBox: React.FC<CommentInfoDialogBoxProps> = ({ message }) => {
    return (
        <>
            <div className="info-panel">
                <InfoIcon alt="Info" className="info-icon m-h-8 m-v-4" />
                <div className="m-v-8">
                    <h6>{message}</h6>
                </div>
            </div>
            <style jsx>
                {`
                    @import 'vars';

                    .info-panel {
                        display: flex;
                        flex: 2 0 65%;
                        border: 2px solid $grey-border;
                        border-radius: 5px;
                        height: auto;
                        flex-direction: row;
                        background-color: $pale-white;
                        margin-bottom: 10px;
                        @media (prefers-color-scheme: dark) {
                            background: darken($color-white, 90%);
                        }
                    }

                    .info-icon {
                        margin-top: 5px;
                    }
                `}
            </style>
        </>
    );
};

type CommentType = {
    comment: string;
    noOfCharacters: Number;
};

interface AddCommentProps {
    envdAccountId: string;
    consignmentNumber: string;
    updateComment: (value: CommentRowProps) => void;
}
const AddComment: React.FC<AddCommentProps> = ({ envdAccountId, consignmentNumber, updateComment }) => {
    const commentInitValue: CommentType = { comment: '', noOfCharacters: 0 };
    const [commentFields, setCommentFields] = useState<CommentType>(commentInitValue);
    const [showCommentButtons, setShowCommentButtons] = useState<Boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [mutate] = useMutation(AddCommentMutation);
    const { hasRole } = useRoleValidation();

    const cancelAction = () => {
        setShowCommentButtons(false);
        setCommentFields(commentInitValue);
    };

    const addCommentAction = async () => {
        if (!!commentFields?.comment?.trim()?.length) {
            try {
                const dataParam = {
                    consignmentNumber: consignmentNumber,
                    comment: commentFields?.comment,
                };
                setLoading(true);
                const response = await createComment(mutate, dataParam, envdAccountId);
                setLoading(false);
                setCommentFields(commentInitValue);
                setShowCommentButtons(false);
                if (response !== undefined)
                    updateComment({
                        firstName: response?.firstName || '',
                        lastName: response?.lastName || '',
                        createdAt: response?.createdAt || '',
                        commentText: response?.commentText || '',
                        role: response?.role || '',
                        commentId: response.commentId,
                    });
            } catch (error) {
                console.error('[AddComment]', error);
                setLoading(false);
            }
        }
    };

    return (
        <>
            <div className="input-section">
                <PersonIcon />
                <Input
                    value={commentFields.comment}
                    className="input-box"
                    data-cy="comment-box"
                    errorText={'This value is Required.'}
                    placeholder={'Write a comment....'}
                    maxLength={COMMENT_MAXLENGTH}
                    onChange={(e) => setCommentFields({ comment: e.target.value, noOfCharacters: e.target.value.length })}
                    onClick={() => setShowCommentButtons(true)}
                />
            </div>
            <div className="horizontal-divider">
                <hr />
                {showCommentButtons && <div className="comment-character">{`${commentFields.noOfCharacters}/${COMMENT_MAXLENGTH}`}</div>}
            </div>
            {showCommentButtons && <CommentInfoDialogBox message={CONSIGNMENT_COMMENT_DIALOG_MESSAGE} />}
            {hasRole(RoleTypeEnum.FACILITATOR) && showCommentButtons && <CommentInfoDialogBox message={CONSIGNMENT_COMMENT_FACILITATOR_ONLY_MESSAGE} />}
            {showCommentButtons && (
                <div className="comment-buttons m-v-24">
                    <Button data-cy="comment-cancel-btn" className="cancel-button m-h-16" buttonType="secondary" children="Cancel" action={cancelAction} />
                    <Button data-cy="comment-submit-btn" className="save-button" buttonType="primary" children={'Add comment'} action={addCommentAction} loading={loading} />
                </div>
            )}
            <style jsx>
                {`
                    @import 'vars';

                    .comment-buttons {
                        display: flex;
                        justify-content: flex-end;
                    }

                    .input-section {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 1vw;
                        :global(.input-container) {
                            max-width: 100% !important;
                        }
                        :global(input) {
                            border: none;
                        }
                        :global(.FormRow) {
                            margin-bottom: 0;
                        }
                        :global(svg) {
                            height: 34px;
                            width: 34px;
                        }
                    }

                    .comment-character {
                        display: flex;
                        justify-content: flex-end;
                        color: $color-grey60;
                        height: 5px;
                        font-size: small;
                    }

                    .horizontal-divider {
                        margin-top: 5px;
                        margin-bottom: 20px;
                        hr {
                            border: 1px solid $isc-blue;
                            margin: auto;
                        }
                    }
                `}
            </style>
        </>
    );
};

export default AddComment;
