/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateOrSaveUserInput = {
    clientMutationId?: string | null;
    firstName: string;
    lastName: string;
    defaultAccount: string;
    mobilePhone: string;
    receiveEmails?: boolean | null;
    isTransporter: boolean;
    rego?: string | null;
    signature?: string | null;
};
export type createOrSaveUserMutationVariables = {
    input: CreateOrSaveUserInput;
};
export type createOrSaveUserMutationResponse = {
    readonly createOrSaveUser: {
        readonly data: {
            readonly firstName: string;
            readonly lastName: string;
            readonly mobilePhone: string;
            readonly isNewUser: boolean;
            readonly email: string;
            readonly defaultAccount: string;
            readonly receiveEmails: boolean;
            readonly signature: string;
            readonly accounts: ReadonlyArray<{
                readonly envdAccountId: string | null;
                readonly accountType: string | null;
                readonly accountLabel: string | null;
                readonly accountId: string | null;
                readonly email: string | null;
                readonly properties: {
                    readonly nameId: string | null;
                    readonly nlisAccountId: string | null;
                    readonly nlisUserId: string | null;
                    readonly nlisAccountType: string | null;
                    readonly rego: string | null;
                    readonly firstName: string | null;
                    readonly lastName: string | null;
                    readonly entityName: string | null;
                    readonly propertyName: string | null;
                    readonly msaMemberNum: string | null;
                    readonly reAccreditationDate: string | null;
                    readonly address: {
                        readonly line1: string | null;
                        readonly town: string | null;
                        readonly state: string | null;
                        readonly postcode: string | null;
                    } | null;
                    readonly accreditations: {
                        readonly lPA: boolean;
                        readonly eU: boolean;
                        readonly mSA: boolean;
                        readonly eUGFHQB: boolean;
                        readonly nFAS: boolean;
                    } | null;
                } | null;
                readonly roles: ReadonlyArray<string | null> | null;
            } | null>;
        } | null;
    } | null;
};
export type createOrSaveUserMutation = {
    readonly response: createOrSaveUserMutationResponse;
    readonly variables: createOrSaveUserMutationVariables;
};



/*
mutation createOrSaveUserMutation(
  $input: CreateOrSaveUserInput!
) {
  createOrSaveUser(input: $input) {
    data {
      firstName
      lastName
      mobilePhone
      isNewUser
      email
      defaultAccount
      receiveEmails
      signature
      accounts {
        envdAccountId
        accountType
        accountLabel
        accountId
        email
        properties {
          nameId
          nlisAccountId
          nlisUserId
          nlisAccountType
          rego
          firstName
          lastName
          entityName
          propertyName
          msaMemberNum
          reAccreditationDate
          address {
            line1
            town
            state
            postcode
          }
          accreditations {
            lPA
            eU
            mSA
            eUGFHQB
            nFAS
          }
        }
        roles
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateOrSaveUser",
    "kind": "LinkedField",
    "name": "createOrSaveUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserDetailType",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mobilePhone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isNewUser",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultAccount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "receiveEmails",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "signature",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Account",
            "kind": "LinkedField",
            "name": "accounts",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "envdAccountId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountLabel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountId",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountProperties",
                "kind": "LinkedField",
                "name": "properties",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nlisAccountId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nlisUserId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nlisAccountType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rego",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "entityName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "propertyName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "msaMemberNum",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reAccreditationDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountAddress",
                    "kind": "LinkedField",
                    "name": "address",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "line1",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "town",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postcode",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClaimAccreditations",
                    "kind": "LinkedField",
                    "name": "accreditations",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lPA",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "eU",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "mSA",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "eUGFHQB",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "nFAS",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "roles",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createOrSaveUserMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createOrSaveUserMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "7a0eeee2845cb3cf6ea11890787cdd7f",
    "id": null,
    "metadata": {},
    "name": "createOrSaveUserMutation",
    "operationKind": "mutation",
    "text": "mutation createOrSaveUserMutation(\n  $input: CreateOrSaveUserInput!\n) {\n  createOrSaveUser(input: $input) {\n    data {\n      firstName\n      lastName\n      mobilePhone\n      isNewUser\n      email\n      defaultAccount\n      receiveEmails\n      signature\n      accounts {\n        envdAccountId\n        accountType\n        accountLabel\n        accountId\n        email\n        properties {\n          nameId\n          nlisAccountId\n          nlisUserId\n          nlisAccountType\n          rego\n          firstName\n          lastName\n          entityName\n          propertyName\n          msaMemberNum\n          reAccreditationDate\n          address {\n            line1\n            town\n            state\n            postcode\n          }\n          accreditations {\n            lPA\n            eU\n            mSA\n            eUGFHQB\n            nFAS\n          }\n        }\n        roles\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'dc2e8a6176faa7253a74cb27d79b669a';
export default node;
