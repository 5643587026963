import 'react-nice-dates/build/style.css';

import { useUserState } from '@common/context/userContext';
import Alert, { AlertType } from '@components/Alert';
import FeedbackButton from '@components/FeedbackButton';
import Input from '@components/Form/Input';
import EmptyList from '@components/Listing/EmptyList';
import Config from '@config';
import useDebounceValue from '@effects/useDebounce';
import useWindowTitle from '@effects/useWindowTitle';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'relay-hooks';

import EdecListFragment, { EdecListFragmentQuery, EdecsLoader } from './components/fragments/EdecListFragment';

const EdecsWrapper: React.FC = () => {
    console.count('[Count] Render EdecsWrapper');
    const [searchText, setSearchText] = useState<string>('');
    const debouncedSearchText = useDebounceValue(300, searchText);
    const [{ user }] = useUserState();

    const { data, error } = useQuery(
        EdecListFragmentQuery,
        {
            envdAccountId: user?.accountDetails?.id,
            count: Config.PAGINATION_PER_PAGE_LIMIT,
            searchText: debouncedSearchText,
        },
        {}
    );

    const renderItem = useMemo(() => {
        console.count('[Count] renderItem');
        return error ? (
            <div className="tbl tbl-collapse">
                <EmptyList
                    emptyText={
                        <>
                            <p>Sorry we've had trouble loading this page</p>
                            <br />
                            <p>
                                Please try again in a few minutes or, if this problem continues, please <a href="mailto:envd@integritysystems.com.au">email</a> eNVD support or call 1800 683 111.
                            </p>
                        </>
                    }
                />
            </div>
        ) : data ? (
            <EdecListFragment query={data as any} />
        ) : (
            <EdecsLoader />
        );
    }, [data, error]);

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .SearchEdecsContainer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    margin: grid(4) 0 grid(8);

                    @media (max-width: $sm-max) {
                        margin: grid(8) 0 grid(2);
                    }

                    :global(.FormRow) {
                        width: grid(75);
                    }
                }

                .FilterEdecsContainer {
                    display: flex;
                    align-items: center;

                    :global(.FilterMenu) {
                        margin-right: grid(2);
                    }

                    @media (max-width: $sm-max) {
                        padding: grid(4) 0 grid(8);
                        flex-wrap: wrap;

                        :global(> *) {
                            margin-top: grid(2);
                        }
                    }
                }

                .tbl-row {
                    .tbl-cell-30 {
                        div {
                            width: 100%;
                        }
                    }

                    @media (max-width: $md-max) {
                        border: 1px solid $color-line;
                        border-radius: $border-radius;
                        background: $color-white;
                        margin: grid(4) 0;

                        @media (prefers-color-scheme: dark) {
                            background: darken($color-white, 80%);
                        }
                    }
                }

                h2 {
                    @media (max-width: $sm-max) {
                        margin-bottom: grid(2);
                    }
                }
            `}</style>
            <>
                <div className="flex-center-row flex-between">
                    <h1 className="m-v-40">eDEC Forms</h1>
                </div>
                <Alert
                    type={AlertType.Info}
                    title="Using this page"
                    subtitle={
                        <>
                            The eDEC system was decommissioned on 31 December 2020. Any historical eDECs you completed have been moved here so you can access them for audits. There are 50 forms shown
                            below (in no particular order) and you can search for others using the serial number. You can view these eDEC forms by clicking on the link to PDF.
                        </>
                    }
                />
            </>
            <FeedbackButton url={Config.ISC_CUSTOMERFEEDBACK_URL} />
            <div className="SearchEdecsContainer">
                <div className="FilterEdecsContainer" />
                <Input value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Enter a serial number here" />
            </div>
            {renderItem}
        </>
    );
};

const Edecs: React.FC = () => {
    console.count('[Count] Render eDECs');

    useWindowTitle('eDECs List');

    return <EdecsWrapper />;
};

export default Edecs;
