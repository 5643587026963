import { Auth0Provider } from '@auth0/auth0-react';
import Config from '@config';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const Auth0ProviderWithHistory: React.FC<{ children?: any }> = ({ children }) => {
    const history = useHistory();

    const onRedirectCallback = (appState: any) => {
        history.replace({
            pathname: appState?.returnTo || window.location.pathname,
        });
    };

    return (
        <Auth0Provider
            domain={Config.AUTH0_DOMAIN}
            clientId={Config.AUTH0_CLIENTID}
            authorizationParams={{
                redirect_uri: window.location.origin + '/callback',
                audience: Config.AUTH0_AUDIENCE,
            }}
            useRefreshTokens={true}
            cacheLocation="localstorage"
            onRedirectCallback={onRedirectCallback}
            useRefreshTokensFallback={true}
        >
            {children}
        </Auth0Provider>
    );
};
