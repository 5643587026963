import 'react-nice-dates/build/style.css';

import { ClearIcon } from '@assets/icons';
import Button from '@common/components/Button';
import { useUserState } from '@common/context/userContext';
import Alert, { AlertType } from '@components/Alert';
import FeedbackButton from '@components/FeedbackButton';
import FilterMenu from '@components/Form/FilterMenu';
import { IFilterGroupMenuGroup, IFilterMenuOption } from '@components/Form/FilterMenuOptions';
import Input from '@components/Form/Input';
import { SelectableListItem } from '@components/Form/SelectableList';
import SortMenu from '@components/Form/SortMenu';
import EmptyList from '@components/Listing/EmptyList';
import Config from '@config';
import { AccreditationStatusAlert } from '@containers/Profile/fragments/AccreditationStatus';
import { ProfileCard } from '@containers/Profile/ProfileCard';
import useDebounceValue from '@effects/useDebounce';
import useRoleValidation from '@effects/useRoleValidation';
import useWindowTitle from '@effects/useWindowTitle';
import { useGlobalState } from '@state';
import { getAccreditationStatus } from '@utils/account';
import { RoleTypeEnum } from '@utils/enums';
import { format, subDays } from 'date-fns';
import { enAU } from 'date-fns/locale';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateRangePicker, END_DATE, START_DATE } from 'react-nice-dates';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import { useQuery } from 'relay-hooks';

import GetDynamicConfiguration from '../../utils/dynamic-configuration';
import { canCreateConsignment, hasRoles } from '../../utils/question-editable';
import { ElevatedViewerSearchResultsHeader } from './components/ElevatedViewerSearchPanel';
import ConsignmentListFragment, { ConsignmentListFragmentQuery, ConsignmentsLoader } from './components/fragments/ConsignmentListFragment';

const WelcomeHeader = () => {
    const [{ user }] = useUserState();

    const history = useHistory();

    const canCreate = canCreateConsignment(user);
    const accreditationStatus = getAccreditationStatus(user.accountDetails);
    const configurations = GetDynamicConfiguration();

    return (
        <>
            <div className="flex-center-row flex-between row">
                <style jsx>{`
                    @import 'vars';
                    @import 'utils';
                    .consignment-actions {
                        @media (max-width: $sm-min) {
                            margin-bottom: grid(8);
                        }

                        @media (max-width: $md-max) {
                            display: flex;
                        }
                    }

                    h1 {
                        margin: grid(10) 0;
                        color: $color-secondary;

                        @media (max-width: $sm-min) {
                            margin: grid(6) 0;
                        }
                    }
                `}</style>
                <h1 data-cy="welcome-heading">{`Welcome, ${user?.claims?.firstName}`}</h1>
                {canCreate && (
                    <div className="margin-v-center consignment-actions">
                        <Button buttonType="primary" children="Create new consignment" action={() => history.push('/consignments/add-select')} data-cy="consignment-create-new" />
                    </div>
                )}
            </div>
            {accreditationStatus && <AccreditationStatusAlert account={user.accountDetails} accreditationStatus={accreditationStatus} />}
            {configurations?.showBanner?.toLowerCase() === 'true' && (
                <Alert type={parseInt(configurations?.bannerType ?? '0') as AlertType} title={configurations?.bannerTitle} subtitle={configurations?.bannerContent} />
            )}
        </>
    );
};

const ConsignmentHeader = () => {
    return (
        <>
            <div className="flex-center-row flex-between row">
                <style jsx>{`
                    @import 'vars';
                    @import 'utils';

                    h1 {
                        margin: grid(10) 0;

                        @media (max-width: $sm-min) {
                            margin: grid(6) 0;
                        }
                    }
                `}</style>
                <h1 data-cy="consignment-heading">All Consignments</h1>
            </div>
        </>
    );
};

const ConsignmentsWrapper: React.FC = () => {
    console.count('[Count] Render ConsignmentsWrapper');
    const [searchText, setSearchText] = useState<string>('');
    const [species, setSpecies] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [{ user }] = useUserState();
    const [{ elevatedViewerSearchFields }] = useGlobalState();

    const hasValidRole = hasRoles(user);
    const { hasRole } = useRoleValidation();
    const debouncedSearchText = useDebounceValue(300, searchText || elevatedViewerSearchFields?.searchText);
    const debouncedStartDate = useDebounceValue(300, startDate);
    const debouncedEndDate = useDebounceValue(300, endDate);

    const [sortItems, setSortItems] = useState<SelectableListItem[]>([
        { id: 'UPDATED_AT_DESC', title: 'Last Updated', selected: true },
        { id: 'MOVEMENT_DATE_DESC', title: 'Movement Date', selected: false },
        { id: 'CREATED_AT_DESC', title: 'Created Date', selected: false },
    ]);

    const [filterGroups, setFilterGroups] = useState<IFilterGroupMenuGroup[]>([
        {
            title: 'Species',
            selectionType: 'single',
            options: [
                { id: 'CATTLE', title: 'Cattle', selected: false },
                { id: 'BOBBY_CALVES', title: 'Bobby Calves', selected: false },
                { id: 'GOAT', title: 'Goat', selected: false },
                { id: 'SHEEP_LAMB', title: 'Sheep and Lamb', selected: false },
            ],
        },
        {
            title: 'Status',
            selectionType: 'single',
            options: [
                { id: 'DRAFT', title: 'Draft', selected: false },
                { id: 'SUBMITTED', title: 'Submitted', selected: false },
                { id: 'LOCKED', title: 'Completed', selected: false },
            ],
        },
        {
            title: 'Within last',
            selectionType: 'single',
            options: [
                { id: 'WEEK', title: 'Week', selected: false },
                { id: 'MONTH', title: 'Month', selected: false },
                { id: 'QUARTER', title: 'Quarter', selected: false },
                {
                    id: 'custom-date-range',
                    title: 'Custom Date Range',
                    selected: false,
                    customRender: {
                        isConditional: true,
                        render: () => (
                            // EPLAT-353 when clicking on input, datepicker appears over input which changes click event target
                            // on release causing datepicker to close instantly. This div will prevent click events within datepicker
                            // from bubbling up to document and stopping outClickHandler from firing.
                            <div onClick={(evt) => evt.stopPropagation()}>
                                <DateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    onStartDateChange={(v: any) => setStartDate(v as Date)}
                                    onEndDateChange={(v: any) => setEndDate(v as Date)}
                                    format="dd MMM yyyy"
                                    locale={enAU}
                                >
                                    {({ startDateInputProps, endDateInputProps, focus }: any) => (
                                        <div className="date-range">
                                            <style jsx>{`
                                                @import 'vars';
                                                @import 'utils';
                                                @import 'mixins';

                                                .date-range {
                                                    display: flex;
                                                    input {
                                                        width: 50%;
                                                    }
                                                }
                                            `}</style>
                                            <input className={'input' + (focus === START_DATE ? ' -focused' : '')} {...startDateInputProps} placeholder="Start date" />
                                            <span className="date-range_arrow" />
                                            <input className={'input' + (focus === END_DATE ? ' -focused' : '')} {...endDateInputProps} placeholder="End date" />
                                        </div>
                                    )}
                                </DateRangePicker>
                            </div>
                        ),
                    },
                },
            ],
        },
    ]);

    useEffect(() => {
        const speciesGroup = filterGroups[0];
        if (speciesGroup.options.some((o) => o.selected)) {
            setSpecies(speciesGroup.options.filter((o) => o.selected)[0].id);
        } else {
            setSpecies('');
        }

        const statusGroup = filterGroups[1];
        if (statusGroup.options.some((o) => o.selected)) {
            setStatus(statusGroup.options.filter((o) => o.selected)[0].id);
        } else {
            setStatus('');
        }

        const dateRangeGroup = filterGroups[2];
        if (dateRangeGroup.options.some((o) => o.selected)) {
            // Date range is special since we need to calculate the date
            // Or use custom values
            const selectedOption = dateRangeGroup.options.firstOrDefault((x) => x.selected);
            if (selectedOption?.id === 'WEEK') {
                setStartDate(subDays(new Date(), 7));
                setEndDate(new Date());
            }
            if (selectedOption?.id === 'MONTH') {
                setStartDate(subDays(new Date(), 30));
                setEndDate(new Date());
            }
            if (selectedOption?.id === 'QUARTER') {
                setStartDate(subDays(new Date(), 90));
                setEndDate(new Date());
            }
        } else {
            // Clear them all
            setStartDate(undefined);
            setEndDate(undefined);
        }
    }, [filterGroups]);

    //TODO to be replace with envd-account-id
    // Account id might be the same for transporter and authViewer
    const accountSelected = `${user?.accountDetails?.accountLabel}:${user?.accountDetails?.id}`;
    const hasExpiredRole = user.accountDetails.roles.includes(RoleTypeEnum.EXPIRED);
    const { data, error } = useQuery(
        ConsignmentListFragmentQuery,
        {
            type: 'CONSIGNMENT',
            count: Config.PAGINATION_PER_PAGE_LIMIT,
            searchText: debouncedSearchText,
            species: hasRole(RoleTypeEnum.ELEVATEDVIEWER) ? (elevatedViewerSearchFields?.species ? elevatedViewerSearchFields?.species : undefined) : species ? species : undefined,
            status: hasRole(RoleTypeEnum.ELEVATEDVIEWER) ? (elevatedViewerSearchFields?.status ? elevatedViewerSearchFields?.status : undefined) : status ? status : undefined,
            fromDate: hasRole(RoleTypeEnum.ELEVATEDVIEWER) ? elevatedViewerSearchFields?.startDate?.toISODateString() : debouncedStartDate?.toISODateString(),
            toDate: hasRole(RoleTypeEnum.ELEVATEDVIEWER) ? elevatedViewerSearchFields?.endDate?.toISODateString() : debouncedEndDate?.toISODateString(),
            sortField: hasRole(RoleTypeEnum.ELEVATEDVIEWER) ? elevatedViewerSearchFields?.sortField : sortItems?.find((x) => x.selected)?.id,
            envdAccountId: user?.accountDetails?.id,
        },
        { fetchPolicy: 'network-only', fetchKey: accountSelected, skip: hasExpiredRole }
    );

    const isSearchOrFilter = useCallback(() => {
        return Boolean(debouncedSearchText || debouncedStartDate || debouncedEndDate || species || status);
    }, [debouncedSearchText, debouncedStartDate, debouncedEndDate, species, status]);

    const renderItem = useCallback(() => {
        // TODO: Render consignment vs template
        console.count('[Count] renderItem');
        return hasValidRole ? (
            error ? (
                <div className="tbl tbl-collapse">
                    <EmptyList
                        emptyText={
                            <>
                                <p>Sorry we've had trouble loading this page</p>
                                <br />
                                <p>
                                    Please try again in a few minutes or, if this problem continues, please <a href="mailto:envd@integritysystems.com.au">email</a> eNVD support or call 1800 683 111.
                                </p>
                            </>
                        }
                    />
                </div>
            ) : data ? (
                <ConsignmentListFragment query={data as any} isSearchOrFilter={isSearchOrFilter()} />
            ) : (
                <ConsignmentsLoader />
            )
        ) : (
            // Load consignment with empty list
            <ConsignmentListFragment query={data as any} isSearchOrFilter={isSearchOrFilter()} />
        );
    }, [hasValidRole, error, data, isSearchOrFilter]);

    const clearFilter = useCallback(
        (option: IFilterMenuOption) => {
            const updatedFg = filterGroups.map((fg) => ({ ...fg, options: fg.options.map((o) => (o.id === option.id ? { ...o, selected: false } : o)) }));
            setFilterGroups(updatedFg);
        },
        [filterGroups]
    );

    const renderSelectedFilters = useMemo(() => {
        const filters = filterGroups
            .flatMap((fg) => fg.options)
            .filter((o) => o.selected)
            .filter((o) => !o.id.includes('custom'))
            .map((o) => (
                <Button key={o.id} action={() => clearFilter(o)} buttonType={'tertiary'} buttonSize="small">
                    <ClearIcon />
                    {o.title}
                </Button>
            ));

        const customDateFilter = filterGroups[2].options[3];
        if (customDateFilter.selected) {
            filters.push(
                <Button key={customDateFilter.id} action={() => clearFilter(customDateFilter)} buttonType={'tertiary'}>
                    <ClearIcon />
                    {startDate && format(startDate, 'yyyy-MM-dd')} - {endDate && format(endDate, 'yyyy-MM-dd')}
                </Button>
            );
        }

        return filters;
    }, [filterGroups, clearFilter, startDate, endDate]);

    const resetFilters = useCallback(() => {
        const updatedFg = filterGroups.map((fg) => ({ ...fg, options: fg.options.map((o) => ({ ...o, selected: false })) }));
        setFilterGroups(updatedFg);
        setSearchText('');
        setSpecies('');
        setStatus('');
        setStartDate(undefined);
        setEndDate(undefined);
    }, [filterGroups]);

    const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
    const isDesktop = !isTabletOrMobile;

    // console.table('[Consignment', { isDesktop, isTabletOrMobile });

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .SearchAndFilterContainer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    margin: grid(4) 0 grid(8);

                    @media (max-width: $sm-max) {
                        margin: grid(8) 0 grid(2);
                    }

                    :global(.FormRow) {
                        width: grid(90);
                    }
                }

                .FilterAndSortContainer {
                    display: flex;
                    align-items: center;
                    gap: grid(4);

                    :global(.FilterMenu) {
                        margin-right: grid(2);
                    }
                    @media (min-width: $sm-max) and (max-width: $md-min) {
                        margin-bottom: grid(4);
                    }

                    @media (max-width: $sm-max) {
                        padding: grid(4) 0 grid(8);
                        flex-wrap: wrap;

                        :global(> *) {
                            margin-top: grid(2);
                        }
                    }
                }

                h2 {
                    @media (max-width: $sm-max) {
                        margin-bottom: grid(2);
                    }
                }
            `}</style>

            <FeedbackButton url={Config.ISC_CUSTOMERFEEDBACK_URL} />
            {hasRole(RoleTypeEnum.ELEVATEDVIEWER) ? (
                <ElevatedViewerSearchResultsHeader />
            ) : (
                <>
                    <ConsignmentHeader />
                    {searchText && <h2>Showing results for {searchText}</h2>}
                    {isDesktop && (
                        <>
                            <div className="SearchAndFilterContainer">
                                <div className="FilterAndSortContainer">
                                    <SortMenu title="Sort by" items={sortItems} onChange={(items) => setSortItems(items)} buttonType="tertiary" />
                                    <FilterMenu title="Filter by" groups={filterGroups} onUpdate={(groups) => setFilterGroups(groups)} buttonType="tertiary" />
                                    {renderSelectedFilters}
                                    {renderSelectedFilters.length > 0 && (
                                        <Button buttonType="link" onClick={resetFilters} buttonSize="small">
                                            Clear filter{renderSelectedFilters.length > 1 ? 's' : ''}
                                        </Button>
                                    )}
                                </div>
                                <Input value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search by PIC, name, serial number, or rego" />
                            </div>
                        </>
                    )}

                    {isTabletOrMobile && (
                        <>
                            <Input value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search by PIC, name, serial number, or rego" />
                            <div className="FilterAndSortContainer">
                                <SortMenu title="Sort by" items={sortItems} onChange={(items) => setSortItems(items)} buttonType="tertiary" />
                                <FilterMenu title="Filter by" groups={filterGroups} onUpdate={(groups) => setFilterGroups(groups)} buttonType="tertiary" />
                                {renderSelectedFilters}
                                {renderSelectedFilters.length > 0 && (
                                    <Button buttonType="link" onClick={resetFilters} buttonSize="small">
                                        Clear filter{renderSelectedFilters.length > 1 ? 's' : ''}
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}
            {renderItem()}
        </>
    );
};

const Consignments: React.FC = () => {
    console.count('[Count] Render Consignments');
    useWindowTitle('Consignments List');

    return (
        <>
            <WelcomeHeader />
            <ProfileCard />
            <ConsignmentsWrapper />
        </>
    );
};

export default Consignments;
