import classnames from 'classnames';
import React from 'react';

export enum Size {
    sm,
    md,
}

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    containerClass?: string;
    size?: Size;
}

const CheckboxInput: React.FunctionComponent<Props> = ({ containerClass, size = Size.sm, disabled, onChange, ...checkboxProps }) => {
    const sizeClass = size === Size.sm ? 'sm' : 'md';
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                $checkbox-sm: grid(4);
                $checkbox-md: grid(5);

                .chk {
                    cursor: pointer;
                    line-height: 16px;

                    &--disabled {
                        cursor: not-allowed;
                    }

                    &:hover input ~ .checkmark {
                        border-color: $color-secondary;
                    }

                    &:hover input[disabled] ~ .checkmark {
                        border-color: $color-border;
                        cursor: default;
                    }

                    input {
                        position: absolute;
                        opacity: 0;
                        height: 0;
                        width: 0;

                        &:checked ~ .checkmark {
                            border-color: $color-secondary;
                            &:after {
                                opacity: 1;
                            }
                        }
                        &:checked[disabled] ~ .checkmark {
                            border-color: $color-grey47;
                            background: $color-grey47;
                            cursor: not-allowed;
                            &:after {
                                border-color: $color-white;
                            }
                        }
                    }

                    .checkmark {
                        position: relative;
                        display: inline-block;
                        height: $checkbox-sm;
                        width: $checkbox-sm;
                        background-color: $color-white;
                        border: 1.5px solid $color-border;
                        border-radius: 1px;

                        @media (prefers-color-scheme: dark) {
                            background-color: darken($color-white, 90%);
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            opacity: 0;
                            left: 5px;
                            top: 1px;
                            width: 2px;
                            height: 6px;
                            border: solid $color-secondary;
                            border-width: 0 1.5px 1.5px 0;
                            transform: rotate(45deg);
                            transition: opacity 300ms ease-out;
                        }

                        &.md {
                            height: $checkbox-md;
                            width: $checkbox-md;

                            &:after {
                                left: 7px;
                                top: 1.5px;
                                width: 3px;
                                height: 9px;
                            }
                        }

                        &--disabled {
                            background: $color-fade;
                            cursor: not-allowed;
                            &:after {
                                border-color: $color-white;
                            }
                        }
                    }
                }
            `}</style>
            <span className={classnames('chk', containerClass, { 'chk--disabled': disabled })}>
                <input type="checkbox" {...checkboxProps} onChange={disabled ? undefined : onChange} />
                <span className={classnames('checkmark', sizeClass, { 'checkmark--disabled': disabled })} />
            </span>
        </>
    );
};

export default CheckboxInput;
