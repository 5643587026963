/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ConvertTemplateInput = {
    clientMutationId?: string | null;
    number: string;
    vendorTrackingCode?: string | null;
    referenceNumber?: string | null;
    origin?: EntityInput | null;
    destination?: EntityInput | null;
    consignee?: EntityInput | null;
    owner?: EntityInput | null;
    movementDate?: unknown | null;
    movementTime?: string | null;
    name?: string | null;
};
export type EntityInput = {
    name?: string | null;
    pic?: string | null;
    address?: PropertyAddressInput | null;
};
export type PropertyAddressInput = {
    line1?: string | null;
    town?: string | null;
    state?: string | null;
    postcode?: string | null;
};
export type convertTemplateMutationVariables = {
    input: ConvertTemplateInput;
    envdAccountId: string;
};
export type convertTemplateMutationResponse = {
    readonly convertTemplate: {
        readonly data: {
            readonly id: string;
            readonly name: string | null;
            readonly updatedAt: unknown | null;
        } | null;
    } | null;
};
export type convertTemplateMutation = {
    readonly response: convertTemplateMutationResponse;
    readonly variables: convertTemplateMutationVariables;
};



/*
mutation convertTemplateMutation(
  $input: ConvertTemplateInput!
  $envdAccountId: String!
) {
  convertTemplate(input: $input, envdAccountId: $envdAccountId) {
    data {
      id
      name
      updatedAt
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envdAccountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ConvertTemplate",
    "kind": "LinkedField",
    "name": "convertTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Template",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "convertTemplateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "convertTemplateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9b12e55b0576b16c307740ae3bb1c6ae",
    "id": null,
    "metadata": {},
    "name": "convertTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation convertTemplateMutation(\n  $input: ConvertTemplateInput!\n  $envdAccountId: String!\n) {\n  convertTemplate(input: $input, envdAccountId: $envdAccountId) {\n    data {\n      id\n      name\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'aadaea8d6aee765ba13f242213ed41d5';
export default node;
