import { useUserState } from '@common/context/userContext';
import RadioList from '@components/Form/RadioList';
import { accounts } from '@typings';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import Config from '../../config';
import { accountRadioButtonType } from '.';

interface Props {
    selectedPic: (value: accountRadioButtonType | undefined) => void;
}

export function extractLPAAccount(accounts: accounts[], defaultAccount: string) {
    const lpaAccounts = accounts?.filter((obj: accounts) => obj?.accountType === 'LPA');

    if (isEmpty(defaultAccount)) {
        if (!isEmpty(lpaAccounts)) {
            // if has LPA account, LPA account first
            defaultAccount = lpaAccounts[0].envdAccountId;
        } else if (!isEmpty(accounts)) {
            // If there is no LPA account but other accounts, consider the first one in the other accounts to be default
            defaultAccount = accounts[0].envdAccountId;
        }
    }
    return lpaAccounts
        .map((obj: accounts) => {
            const isDefault = obj?.envdAccountId === defaultAccount;
            return {
                id: obj?.envdAccountId,
                title: isDefault ? `${obj?.accountLabel}-${obj?.accountId}`.concat(' (Default)') : `${obj?.accountLabel}-${obj?.accountId}`,
                selected: isDefault,
            };
        })
        .sort((x: accountRadioButtonType, y: accountRadioButtonType) => {
            return x.id === defaultAccount ? -1 : y.id === defaultAccount ? 1 : 0;
        });
}

export function extractNLISAccount(accounts: accounts[]) {
    return accounts
        ?.filter((obj: accounts) => obj.accountType === 'NLIS')
        .map((obj: accounts) => {
            return {
                id: obj?.accountId,
                title: obj?.accountLabel,
            };
        });
}

export const LinkedAccount: React.FC<Props> = ({ selectedPic }) => {
    const [{ user }] = useUserState();

    const LPA_ACCOUNTS: { id: string; title: string; selected: boolean }[] = extractLPAAccount(user?.claims?.accounts, user?.claims?.defaultAccount);
    const NLIS_ACCOUNTS: { id: string; title: string }[] = extractNLISAccount(user?.claims?.accounts);

    const [picList, setPicList] = useState<{ id: string; title: string; selected: boolean }[]>(LPA_ACCOUNTS);

    useEffect(() => {
        selectedPic(LPA_ACCOUNTS?.find((obj: accountRadioButtonType) => obj?.selected === true));
        // Disabling the below rule to execute the useEffect on the mounting of this component.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changePICSelection = (value: accountRadioButtonType) => {
        const updatedPICList = picList.map((obj) => {
            if (obj?.selected && obj?.id !== value?.id) {
                obj.selected = false;
            }
            if (obj?.id === value?.id) {
                obj.selected = true;
            }
            return obj;
        });
        selectedPic(value);
        setPicList(updatedPICList);
    };

    return (
        <>
            {(!isEmpty(LPA_ACCOUNTS) || !isEmpty(NLIS_ACCOUNTS)) && (
                <h2 className="linked-account-text" data-cy="account-found">
                    Good News! We found your linked accounts.
                </h2>
            )}
            {!isEmpty(LPA_ACCOUNTS) && (
                <h6 className="linked-account-text--responsive">
                    Below are LPA accounts you can <b>create</b> and <b>update</b> consignments for. Please select your default account.
                </h6>
            )}
            {!isEmpty(LPA_ACCOUNTS) && <RadioList items={picList} onChange={changePICSelection} />}
            {!isEmpty(NLIS_ACCOUNTS) && (
                <h6 className="linked-account-text--responsive">
                    You will be able to <b>receive</b> and <b>view</b> consignments from the account(s) below.
                </h6>
            )}
            <div style={{ marginBottom: '15px' }}>
                {!isEmpty(NLIS_ACCOUNTS) &&
                    NLIS_ACCOUNTS.map((obj: { id: string; title: string }) => {
                        return (
                            <div data-cy="nlis-account" className="small" key={obj.id}>
                                {' '}
                                {obj.title}
                            </div>
                        );
                    })}
            </div>
            {(!isEmpty(LPA_ACCOUNTS) || !isEmpty(NLIS_ACCOUNTS)) && (
                <h6 className="linked-account-text--responsive">
                    Don’t see an account you’re looking for? Please ensure your LPA and NLIS accounts are <a href={Config.MYMLA_BASE_URL + '/link-accounts'}>linked to your myMLA profile.</a>
                </h6>
            )}
            {isEmpty(NLIS_ACCOUNTS) && isEmpty(LPA_ACCOUNTS) && (
                <>
                    <h2 className="linked-account-text">
                        We did not find any LPA or NLIS accounts linked to this myMLA
                        <br />
                        profile.
                    </h2>
                    <h6 className="linked-account-text" style={{ marginBottom: '20px' }}>
                        Visit the link below to link your accounts to{' '}
                        <span className="username-text">
                            <b>{user?.claims?.email}</b>
                        </span>
                        . Once you've <br />
                        linked your accounts, you will see your updated linked account here.
                    </h6>
                    <h6 className="linked-account-text">
                        <a href={Config.MYMLA_BASE_URL + '/link-accounts'}>Link accounts to myMLA</a>
                    </h6>
                </>
            )}

            <style jsx>{`
                @import 'mixins';
                @import 'vars';
                @import 'layout';

                :global(a) {
                    color: $isc-blue;
                }

                :global(.small) {
                    color: $isc-blue;
                    font-weight: bold;
                }

                .linked-account-text {
                    color: $generic-text;
                    margin-bottom: 2vh;
                    &--responsive {
                        width: 90%;
                        margin-bottom: 2vh;
                    }
                    @media (prefers-color-scheme: dark) {
                        color: $color-white;
                    }
                }

                .username-text {
                    color: $isc-blue;
                }
            `}</style>
        </>
    );
};
