import { InfoIcon } from '@assets/icons';
import { useUserState } from '@common/context/userContext';
import Button from '@components/Button';
// TODO Uncomment the below changes when the backend supports email notification preference.
import Checkbox from '@components/Form/Checkbox';
import Input, { InputType } from '@components/Form/Input';
import RadioList from '@components/Form/RadioList';
import ToggleSwitch from '@components/Form/ToggleSwitch';
import useWindowTitle from '@effects/useWindowTitle';
import { accounts, IUserProfileFields } from '@typings';
import { getCarrierRegNum } from '@utils/account';
import { INVALID_PHONE_NUMBER, PHONE_REGEX } from '@utils/constants';
import { SectionName } from '@utils/enums';
import { isEmpty, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';

import { accountRadioButtonType, checkValidField, TransportInfoDialogBox, UserConsentInfoBox } from '../Register/index';
import { extractLPAAccount } from '../Register/LinkedAccount';
import ProfileHeader from './ProfileHeader';

const Profile: React.FC = () => {
    const [{ user }] = useUserState();
    useWindowTitle('Profile');

    const profileFields: IUserProfileFields = {
        firstName: user?.claims?.firstName || '',
        lastName: user?.claims?.lastName || '',
        mobile: user?.claims?.mobilePhone || '',
        isCarrier: user?.claims?.accounts?.some((acc: accounts) => acc.accountType === SectionName.TRANSPORTER.toUpperCase()),
        reg_num: getCarrierRegNum(user),
        defaultAccount: user?.claims?.defaultAccount || '',
        receiveEmails: user?.claims?.receiveEmails,
        isValidFirstName: undefined,
        isValidLastName: undefined,
        isValidMobileNum: undefined,
        isValidRegNum: undefined,
    };
    const [showInfoContainer, setShowInfoContainer] = useState<Boolean>(false);
    const [userFields, setUserFields] = useState<IUserProfileFields>(profileFields);
    const PIC_LIST = extractLPAAccount(user?.claims?.accounts, user?.claims?.defaultAccount);
    const [isReadMode, setReadMode] = useState<boolean>(true);
    const [picList, setPicList] = useState<{ id: string; title: string; selected: boolean }[]>(PIC_LIST);
    const toggle = () => {
        setShowInfoContainer(!showInfoContainer);
    };

    const emailNotificationElement = () => {
        return <div>Disable daily email notifications for new and moving today consignments consigned to me.</div>;
    };

    const checkValidMobileNum = (value: string) => {
        return PHONE_REGEX.test(value);
    };

    const changePICSelection = (value: accountRadioButtonType) => {
        if (!isReadMode) {
            const updatedPICList = picList.map((obj) => {
                if (obj?.selected && obj?.id !== value?.id) {
                    obj.selected = false;
                }
                if (obj?.id === value?.id) {
                    obj.selected = true;
                }
                return obj;
            });
            setUserFields({ ...userFields, defaultAccount: value.id });
            setPicList(updatedPICList);
        }
        return;
    };

    useEffect(() => {
        /* Functionality of Cancel button - To check if there is any change
        in the user profile and revert back to the original values */
        if (isReadMode && !isEqual(userFields, profileFields)) {
            setUserFields({ ...profileFields });
            setPicList(PIC_LIST);
        }
        // To use this useEffect if there is any change in the isReadMode only
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReadMode]);

    return (
        <>
            <ProfileHeader isReadMode={isReadMode} setReadMode={setReadMode} userFields={userFields} setUserFields={setUserFields} />
            <div className="ContentBox">
                <div className="ProfileContent">
                    <h2 className="profile-user-details">User Details</h2>
                    <div className={'input-field'}>
                        <Input
                            labelText="First Name"
                            className="input-firstName"
                            data-cy="firstName"
                            errorText={'This value is Required.'}
                            isValid={userFields?.isValidFirstName}
                            inputType={InputType.Text}
                            required={!isReadMode}
                            disabled={isReadMode}
                            readOnly={isReadMode}
                            value={userFields?.firstName || ''}
                            onChange={(e) => setUserFields({ ...userFields, firstName: e.target.value, isValidFirstName: checkValidField(e.target.value) })}
                        />
                    </div>
                    <div className={'input-field'}>
                        <Input
                            data-cy="lastName"
                            labelText="Last Name"
                            errorText={'This value is Required.'}
                            isValid={userFields?.isValidLastName}
                            inputType={InputType.Text}
                            required={!isReadMode}
                            disabled={isReadMode}
                            readOnly={isReadMode}
                            value={userFields?.lastName || ''}
                            onChange={(e) => setUserFields({ ...userFields, lastName: e.target.value, isValidLastName: checkValidField(e.target.value) })}
                        />
                    </div>
                    <div style={{ marginBottom: '30px' }}>
                        <p className="label">Email Address</p>
                        <p className="email-value">{user?.claims?.email}</p>
                    </div>
                    <div className={'input-field'}>
                        <Input
                            labelText="Phone Number"
                            data-cy="mobile"
                            errorText={INVALID_PHONE_NUMBER}
                            isValid={userFields?.isValidMobileNum}
                            inputType={InputType.Text}
                            required={!isReadMode}
                            disabled={isReadMode}
                            readOnly={isReadMode}
                            value={userFields?.mobile || ''}
                            onChange={(e) => setUserFields({ ...userFields, mobile: e.target.value, isValidMobileNum: checkValidMobileNum(e.target.value) })}
                        />
                    </div>
                    <div className="carrier-display" style={{ marginBottom: userFields?.isCarrier ? '20px' : showInfoContainer ? '10px' : '30px' }}>
                        <h3 className="linked-account-text">Are you a Livestock Carrier?</h3>
                        <Button buttonType="link" buttonSize="xsmall" onClick={toggle}>
                            <InfoIcon alt="Info" style={{ marginLeft: '10px', marginRight: '10px', color: 'grey' }} />
                        </Button>
                        <div className="check-box">
                            <ToggleSwitch
                                className="vehicle-checkbox"
                                checked={userFields?.isCarrier}
                                onChange={() => setUserFields({ ...userFields, isCarrier: !userFields.isCarrier })}
                                disabled={isReadMode}
                            />
                        </div>
                    </div>
                    {showInfoContainer && (
                        <div className="info-panel">
                            <TransportInfoDialogBox />
                        </div>
                    )}
                    {userFields?.isCarrier && (
                        <div className={'input-field'}>
                            <Input
                                labelText="Transporter Vehicle Rego"
                                errorText={'This value is Required.'}
                                data-cy="rego"
                                isValid={userFields?.isValidRegNum}
                                inputType={InputType.Text}
                                required={!isReadMode}
                                disabled={isReadMode}
                                value={userFields?.reg_num || ''}
                                readOnly={isReadMode}
                                onChange={(e) => setUserFields({ ...userFields, reg_num: e.target.value, isValidRegNum: userFields.isCarrier && checkValidField(e.target.value) })}
                            />
                        </div>
                    )}

                    <div className="email-notification">
                        <p className="label-text">Email Notifications</p>
                        <Checkbox
                            optionElement={emailNotificationElement()}
                            optionText={''}
                            isValid={true}
                            helperText="Select to continue"
                            isReadOnly={isReadMode}
                            checked={!userFields?.receiveEmails}
                            onChange={() => setUserFields({ ...userFields, receiveEmails: !userFields.receiveEmails })}
                        />
                    </div>
                    {!isEmpty(picList) && (
                        <>
                            <p className="label-text">Default Account</p>
                            <RadioList onChange={changePICSelection} isValid items={picList} isReadOnly={isReadMode} />
                        </>
                    )}
                    <div className="info-panel bottom">
                        <UserConsentInfoBox />
                    </div>
                </div>
            </div>

            <style jsx>{`
                @import 'mixins';
                @import 'vars';
                @import 'utils';
                @import 'layout';

                .ContentBox {
                    border: 1px solid $color-border;
                    border-radius: $border-radius;
                    background-color: $color-white;

                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }
                }

                h2 {
                    color: $generic-text;
                    margin-bottom: 15px;
                }

                .carrier-display {
                    display: flex;
                }

                .email-notification {
                    margin-bottom: 30px;
                    pointer-events: ${isReadMode ? 'none' : 'auto'};
                }

                .info-panel {
                    display: flex;
                    border: 2px solid $grey-border;
                    border-radius: 5px;
                    height: auto;
                    flex-direction: row;
                    width: 50%;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    @media (max-width: $md-min) {
                        width: 90%;
                    }

                    &.bottom {
                        width: 70%;
                        margin-top: 40px;
                        margin-bottom: 30px;
                        @media (max-width: $md-min) {
                            width: 90%;
                        }
                    }
                }

                .infoText {
                    margin-top: '15px';
                    margin-bottom: '15px';
                }

                .ProfileContent {
                    margin-top: 20px;
                    margin-left: 20px;
                }

                .email-value {
                    color: $light-grey;
                }

                .check-box {
                    margin-top: -5px;
                    margin-left: 5px;
                }
                .profile-user-details {
                    margin-bottom: '20px';
                }

                .label-text {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 15px;
                }

                .icon-success {
                    visibility: hidden;
                }

                .input-field {
                    margin-bottom: 40px;
                }
                :global(.label) {
                    padding: 0 32px 0 0 !important;
                }
                :global(.required) {
                    margin-bottom: 5px !important;
                }
            `}</style>
        </>
    );
};

export default Profile;
