import { TAirtableConsignment } from '@containers/Consignments/ConsignmentEdit';
import { IDynamicDirtyAnswer, IDynamicQuestion, IStaticQuestion } from '@typings';
import { getField } from '@utils/data-manipulator';
import { SectionName } from '@utils/enums';
import { getIsRequired, validate, validateRepeatable } from '@utils/question-validator';
import { DynamicQuestions, isStaticSection, StaticQuestions } from '@utils/section-mapper';
import deepmerge from 'deepmerge';

import { IFormAlias } from './form-alias';

function extractFieldFromData<T>(data: any, staticPendingAnswers: any, field: string, defaultValue?: T): [T | null, boolean] {
    const existingField = getField(field, staticPendingAnswers.answers, defaultValue);

    const existsInPending = !!existingField;
    if (!existsInPending) {
        const extracted: T | null = getField<T | null, TAirtableConsignment>(field, data, defaultValue ?? null);
        return [extracted, existsInPending];
    }
    return [existingField ?? null, existsInPending];
}

const getStaticPendingAnswers = (consignment: any, staticPendingAnswers: any) => {
    const [forms, formExists] = extractFieldFromData(consignment, staticPendingAnswers, 'forms', []);
    let answers = {};

    if (!formExists) {
        answers = deepmerge(answers, { form: forms?.map((x: any) => x.type) });
    }

    const [origin] = extractFieldFromData(consignment, staticPendingAnswers, 'origin', null);
    answers = deepmerge(answers, { origin });

    const [owner] = extractFieldFromData(consignment, staticPendingAnswers, 'owner', null);
    answers = deepmerge(answers, { owner });

    const [destination] = extractFieldFromData(consignment, staticPendingAnswers, 'destination', null);
    answers = deepmerge(answers, { destination });

    const [consignee] = extractFieldFromData(consignment, staticPendingAnswers, 'consignee', null);
    answers = deepmerge(answers, { consignee });

    const [movementDate] = extractFieldFromData(consignment, staticPendingAnswers, 'movementDate', null);
    answers = deepmerge(answers, { movementDate });

    const [movementTime] = extractFieldFromData(consignment, staticPendingAnswers, 'movementDate', null);
    answers = deepmerge(answers, { movementTime });

    // Save the declaration fields to the reducer
    const [declaration] = extractFieldFromData(consignment, staticPendingAnswers, 'declaration', null);
    answers = deepmerge(answers, { declaration });

    // If this is a template, check the name
    const [name] = extractFieldFromData(consignment, staticPendingAnswers, 'name', undefined);
    if (name) {
        // We don't want to send name for consignments, so gate it
        answers = deepmerge(answers, { name });
    }
    return { dirty: false, answers };
};

const getDynamicPendingAnswers = (answers?: any) => {
    const indexedAnswers = answers?.filter((x: any) => x.index !== null) ?? [];
    return { answers: indexedAnswers, dirty: false };
};

/**
 * Check whether the dynamic parent question(including madatory and optional) has an answer
 * @param dq Dynamic questions
 * @param dynamicPendingAnswers
 * @param consignment
 */
const hasDyAnswerCheck = (dq: IDynamicQuestion, dynamicPendingAnswers: IDynamicDirtyAnswer, consignment: TAirtableConsignment) => {
    const originalAnswers = consignment?.answers;
    let hasAnswers: boolean | undefined;
    if (dq.type === 'REPEATABLE' && dq.childQuestions.length > 0) {
        const childQuestions = dq.childQuestions;
        hasAnswers = childQuestions.some((cq: any) => dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === cq.id) || originalAnswers?.some((oa) => oa?.questionId === cq.id));
    } else {
        hasAnswers = dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === dq.id) || originalAnswers?.some((oa) => oa?.questionId === dq.id);
    }
    return hasAnswers;
};

/**
 * Check the consignment to see if it has some forms.
 * @param consignmentForms
 * @param questionForms
 */
export const hasFormCheck = (consignmentForms: any, questionForms: IFormAlias[]): boolean => {
    const consignmentFormStrings = consignmentForms?.map((form: any) => form?.type);
    //In the questionForms it is HS.C.2, the consignmentForms value is HSC2
    const questionFormStrings = questionForms?.map((form) => form.program.replaceAll('.', ''));
    const hasForm = questionFormStrings.intersection(consignmentFormStrings as any).length > 0;
    return hasForm;
};

/**
    Validates a section

    sectionName: The name of the section
    deepScan: Whether to validate all child questions alongside the questions itself, mainly applies to repeatables
*/
const validateSection = (sectionName: SectionName, consignment: TAirtableConsignment) => {
    const questionOrders: number[] = DynamicQuestions[sectionName];
    const originalAnswers = consignment?.answers;
    const dynamicPendingAnswers: IDynamicDirtyAnswer = getDynamicPendingAnswers(consignment?.answers);
    const staticPendingAnswers: any = getStaticPendingAnswers(consignment, {
        dirty: false,
        answers: {},
    });
    // Get parent questions only
    const dynamicQuestions: NonNullable<IDynamicQuestion[]> = (questionOrders.map((order) => consignment?.questions?.find((q) => q?.order === order)).coalesce() as unknown[]) as IDynamicQuestion[];

    const repeatableDynamicQuestions = dynamicQuestions.filter((question) => question.type === 'REPEATABLE');

    // Check the updates in DynamicAnswers to make sure they're still all correct
    const isValidDynamicSection = dynamicQuestions.every((question: IDynamicQuestion) => {
        // If this isn't a 'visible' question, then skip it
        if (
            question.triggers !== null &&
            question.triggers.length > 0 &&
            question.triggers.every((trigger) => {
                return originalAnswers?.some((oa) => oa?.questionId === trigger.questionId && oa.value === trigger.value);
            }) === false
        ) {
            return true;
        }

        // If this is a child of a repeatable and the parent isn't valid, skip
        if (repeatableDynamicQuestions.some((rdq) => rdq.childQuestions.some((cq) => cq.id === question.id))) {
            const parentRepeatable = repeatableDynamicQuestions.firstOrDefault((rdq) => rdq.childQuestions.some((cq) => cq.id === question.id));

            if (parentRepeatable) {
                // Handle for triggers
                if (
                    parentRepeatable.triggers !== null &&
                    parentRepeatable.triggers.length > 0 &&
                    parentRepeatable.triggers.every((trigger) => {
                        const hasPendingAnswer = dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === trigger.questionId && dpa.value === trigger.value);
                        const hasOriginalAnswer = originalAnswers?.some((oa) => oa?.questionId === trigger.questionId && oa.value === trigger.value);
                        return hasPendingAnswer || hasOriginalAnswer;
                    }) === false
                ) {
                    return true;
                }

                // Handle for deleting repeatable nested inside a trigger
                if (
                    parentRepeatable.triggers !== null &&
                    parentRepeatable.triggers.length > 0 &&
                    parentRepeatable.triggers.every((trigger) => {
                        const hasParentTrigger = dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === trigger.questionId && dpa.value === trigger.value);
                        const isDeletedAnswer = dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === question.id && dpa.index === null);
                        return !hasParentTrigger && isDeletedAnswer;
                    })
                ) {
                    return true;
                }

                // Handle for empty repeatables
                if (parentRepeatable.triggers === null) {
                    // EPLAT-359: Handle acceptable answers for the checks due to older consignments using (now) invalid data
                    const pendingAnswer = dynamicPendingAnswers?.answers?.firstOrDefault((dpa) => dpa.questionId === question.id);
                    const originalAnswer = originalAnswers?.firstOrDefault((oa) => oa?.questionId === question.id);
                    const hasPendingAnswer = Boolean(pendingAnswer);
                    const hasOriginalAnswer = Boolean(originalAnswer);

                    if ((hasPendingAnswer || hasOriginalAnswer) === false) {
                        // If this has no answers, then we want to skip
                        return true;
                    }

                    const hasAcceptableAnswers = question.acceptableAnswers?.length > 0;
                    const hasValidAnswer = hasAcceptableAnswers && question.acceptableAnswers.some((aa) => aa.value === pendingAnswer?.value || aa.value === originalAnswer?.value);

                    if (hasAcceptableAnswers && !hasValidAnswer) {
                        return false;
                    }

                    // Skip when index === null since that is for deletion
                    const questionAnswers = dynamicPendingAnswers.answers.filter((answer) => answer.questionId === question.id);
                    if (hasPendingAnswer && questionAnswers.length === 1 && questionAnswers.every((answer) => answer.index === null)) {
                        return true;
                    }
                }
            }
        }

        const updated = dynamicPendingAnswers?.answers?.filter((dpa) => dpa.questionId === question.id);
        if (updated && updated.length > 0) {
            // If this is a deletion, just ignore
            if (updated.every((item: any) => item.index === null && item.value === null)) {
                return true;
            }
            return updated.every((item: any) => validate(question.validators, item.value));
        }

        const existing = originalAnswers?.filter((oa) => oa?.questionId === question.id);
        if (existing && existing.length > 0) {
            return existing.every((item: any) => validate(question.validators, item.value));
        }

        let validators = question.validators;
        const isRepeatable = question.type === 'REPEATABLE';
        if (isRepeatable) {
            // If it is a repeatable, we need to handle it differently
            // With this, required is only triggered if there are children, otherwise run through normal routes
            const isRepeatableRequired = getIsRequired(question.validators);
            if (isRepeatableRequired) {
                // Remove required since we handle it here
                validators = validators.filter((validator) => validator.type !== 'required');
                return validateRepeatable(question, dynamicPendingAnswers.answers, false);
            }
        }

        // If this isn't a required question, don't even bother with a fake validation
        if (validators.some((validator) => validator.type === 'required')) {
            return validate(validators, '');
        }

        // Default to valid
        return true;
    });

    // Check against the static side if there is one
    // Ignoring those of the Calculate type since they don't contain answers
    const staticQuestions: any[] = (StaticQuestions[sectionName] as any[]).filter((sq: IStaticQuestion) => {
        let hasForm = true;
        if (sq.forms) {
            hasForm = hasFormCheck(consignment?.forms, sq.forms);
        }

        // if the form is not included then filter the question out
        return sq.type !== 'Calculate' && hasForm;
    });
    let staticAnswerLength = 0;
    const isValidStaticSection = isStaticSection(sectionName)
        ? staticQuestions.every((question: IStaticQuestion) => {
              if (question.forms) {
                  const hasForm = hasFormCheck(consignment?.forms, question.forms);

                  if (!hasForm) {
                      // skip if we have it defined and its not valid
                      return true;
                  }
              }

              const currentAnswer = getField<any, TAirtableConsignment>(question.field, staticPendingAnswers.answers, null) ?? getField<any, TAirtableConsignment>(question.field, consignment, null);
              if (currentAnswer) staticAnswerLength++;

              const result = validate(question.validators, currentAnswer);
              return result;
          })
        : true;

    // Only use valid if there is ANY answers, if there are none, then return undefined (no icon)
    const hasDynamicAnswers = dynamicQuestions.length > 0 && dynamicQuestions.some((dq: any) => hasDyAnswerCheck(dq, dynamicPendingAnswers, consignment));
    const hasStaticAnswers = staticQuestions.length > 0;

    const valid = isValidDynamicSection && isValidStaticSection;
    return {
        valid: hasDynamicAnswers || hasStaticAnswers ? valid : undefined,
        numOfQues: dynamicQuestions.length + staticQuestions.length,
        numOfAns: staticAnswerLength + dynamicQuestions.filter((dq: any) => hasDyAnswerCheck(dq, dynamicPendingAnswers, consignment)).length,
    };
};

export const validateConsignment = (consignment: TAirtableConsignment, sectionName: SectionName): { valid: boolean | undefined; numOfQues: number; numOfAns: number } => {
    return validateSection(sectionName, consignment);
};
