import { ClearIcon, Lpa } from '@assets/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserState } from '@common/context/userContext';
import ClickableIcon from '@components/ClickableIcon';
import Tag from '@components/Tag';
import Config from '@config';
import useResetConsignment from '@effects/useResetConsignment';
import { actionTypes, useGlobalState } from '@state';
import { DESKTOP_WIDTH } from '@utils/constants';
import { consignmentEnumToColor, consignmentEnumToName } from '@utils/enum-transformers';
import { canViewTemplate, IsValidUser } from '@utils/question-editable';
import classnames from 'classnames';
import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router';
import { graphql, useQuery } from 'relay-hooks';

import useUserDetails from '../../effects/useUserdetails';
import LogoutButton from './LogoutButton';
import NavItem from './NavItem';
import UserCard from './UserCard';
import UserMenu from './UserMenu';

const query = graphql`
    query NavigationBarQuery($number: String!, $envdAccountId: String!) {
        consignment(id: $number, envdAccountId: $envdAccountId) {
            id
            number
            updatedAt
            status
        }
    }
`;

const stringToRelativeTime = (str?: string) => (str && new Date(str)?.relativeTime()) || 'never';

interface Props {
    menuOpen: boolean;
    closeAction: () => void;
}

const NavigationBar: React.FC<Props> = ({ closeAction, menuOpen }) => {
    const location = useLocation();
    const history = useHistory();

    const { isAuthenticated } = useAuth0();
    const { isLoading } = useUserDetails();
    // We can't access params here because we aren't matching? So we have to extract it from location
    let number = null;
    const pathSplit = location.pathname.split('/');
    if (pathSplit.length > 3) {
        number = pathSplit[3];
    }
    const [{ user }] = useUserState();
    const isValidUser = IsValidUser(user);
    const envdAccountId = user?.accountDetails?.id;
    const { data } = useQuery<any>(query, { number: number!, envdAccountId }, { skip: !number });
    const isTabletOrMobile = useMediaQuery({ maxWidth: DESKTOP_WIDTH });
    const [{ generic }, dispatch] = useGlobalState();
    const isLoggedIn = Boolean(isAuthenticated && !isLoading);
    const canView = canViewTemplate(user);

    const resetGlobalConsignment = useResetConsignment();

    const saveOnExit = useCallback(
        (toPath: string, newTab?: boolean) => {
            if (!generic?.exitingNavigation && location.pathname.startsWith('/consignments/edit')) {
                dispatch({ type: actionTypes.generic.setNavigation, value: { payload: { toPath, exitingNavigation: true } } });
            } else {
                if (toPath.startsWith('http')) {
                    if (newTab) window.open(toPath, '_blank');
                    else window.location.href = toPath;
                } else {
                    // Reset all state here
                    resetGlobalConsignment();
                    history.push(toPath);
                }
            }
        },
        [generic, dispatch, history, location.pathname, resetGlobalConsignment]
    );

    const navItems = (
        <>
            {isLoggedIn && (
                <NavItem
                    type="internal"
                    path="/consignments"
                    title="Consignments"
                    internal={{ isActive: location.pathname.startsWith('/consignments') }}
                    preAction={() => saveOnExit('/consignments')}
                />
            )}
            {isLoggedIn && canView && (
                <NavItem type="internal" path="/templates" title="Templates" internal={{ isActive: location.pathname.startsWith('/templates') }} preAction={() => saveOnExit('/templates')} />
            )}
            {isLoggedIn && <NavItem type="internal" path="/edecs" title="eDECs" internal={{ isActive: location.pathname.startsWith('/edecs') }} preAction={() => saveOnExit('/edecs')} />}
            {isLoggedIn && isValidUser && (
                <NavItem type="internal" path="/profile" title="eNVD Profile" internal={{ isActive: location.pathname.startsWith('/profile') }} preAction={() => saveOnExit('/profile')} />
            )}
            <NavItem type="external" title="Help" path={Config.SERVICE_DESK_URL} external={{ newTab: true, showIcon: false }} />
            {isLoggedIn && user && (
                <NavItem
                    type="external"
                    title="Take me to myMLA"
                    path={Config.MYMLA_BASE_URL}
                    external={{ newTab: false, showIcon: true }}
                    preAction={() => saveOnExit(Config.MYMLA_BASE_URL, false)}
                />
            )}
        </>
    );

    const tabletOrMobileConsignmentHeader = isTabletOrMobile && data?.consignment && (
        <div className="title">
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .title {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .top-row {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .consignment-number {
                            font-weight: bold;
                        }
                    }

                    .bottom-row {
                        color: $color-subtitle;
                    }
                }
            `}</style>
            <div className="top-row">
                <Tag tagType={consignmentEnumToColor(data.consignment.status)} text={consignmentEnumToName(data.consignment.status)} />
                &nbsp;
                <span className="h6 consignment-number">{number}</span>
            </div>
            <div>
                <div className="small muted">Updated {stringToRelativeTime(data.consignment.updatedAt as string)}</div>
            </div>
        </div>
    );

    const desktopConsignmentHeader = !isTabletOrMobile && data?.consignment && (
        <div className="ConsignmentStateViewHeader--Desktop" data-cy="menu-header">
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .ConsignmentStateViewHeader {
                    &--Desktop {
                        display: flex;
                        align-items: center;

                        .bold {
                            font-weight: bold;
                        }

                        div {
                            margin: 0 grid(3);
                        }

                        .Spacer {
                            margin: 0 grid(3);
                        }
                    }
                }
            `}</style>
            <NavItem
                type="internal"
                title="Back to Consignments"
                path="/consignments"
                internal={{ isActive: location.pathname.startsWith('/consignments') }}
                preAction={() => saveOnExit('/consignments')}
            />
            <div className="Spacer" />
            <Tag tagType={consignmentEnumToColor(data.consignment.status)} text={consignmentEnumToName(data.consignment.status)} />
            <div className="small ">
                Consignment&nbsp;<span className="bold">{data.consignment.number}</span>
            </div>
            <div className="small ">
                Updated&nbsp;<span className="bold">{stringToRelativeTime(data.consignment.updatedAt as string)}</span>
            </div>
            <div className="Spacer" />
        </div>
    );

    const isInConsignmentEditRoute = location.pathname.startsWith('/consignments');

    const customPrefix = isInConsignmentEditRoute ? (isTabletOrMobile ? tabletOrMobileConsignmentHeader : desktopConsignmentHeader) : undefined;
    const showNavItems = !Boolean(customPrefix);

    return (
        <div className="NavigationBar">
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .NavigationBar {
                    display: flex;
                    flex: 1;
                    @media (max-width: $lg-max+1) {
                        .title {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;

                            :global(svg) {
                                text-align: center;
                                margin-left: grid(-1);
                            }
                        }

                        .menu-icon-padding {
                            padding-right: grid(6);
                        }
                    }
                }

                .nav-container {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    max-height: grid(13);
                    flex-grow: 1;
                    margin-left: grid(4);

                    @media (max-width: $lg-max + 1) {
                        margin-left: 0;
                        max-height: 100%;
                        width: 85%;
                        background-color: $color-mobile-menu;
                        position: fixed;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        flex-direction: column;
                        justify-content: flex-start;
                        overflow: hidden;
                        overflow-y: auto;
                        transition: width 0.3s;
                        z-index: 10;
                        box-shadow: 0 grid(1) grid(1) rgba(0, 0, 0, 0.25);

                        @media (prefers-color-scheme: dark) {
                            background-color: darken($color-mobile-menu, 95%);
                        }
                    }
                    &.collapsed {
                        width: 0;
                    }

                    .mobile-menu {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                        width: 100%;
                        padding: grid(6);

                        .top-section {
                            .top-row {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                                padding-bottom: grid(10);
                            }
                        }
                    }
                }
            `}</style>
            {customPrefix && <span className={classnames('title', { 'menu-icon-padding': isLoggedIn })}>{customPrefix}</span>}
            <div className={classnames('nav-container flex-between', { collapsed: !menuOpen, 'flex-center-row': !isTabletOrMobile })} onClick={closeAction}>
                {/* Use base level path for prefix e.g. Consignment instead of Consignments which then covers active for ConsignmentEdit, ConsignmentDetail etc */}
                {!isTabletOrMobile && (
                    <>
                        {showNavItems && navItems}
                        <span className="flex-grow" />
                        {isLoggedIn && <UserMenu />}
                    </>
                )}
                {isTabletOrMobile && (
                    <>
                        <div className="mobile-menu">
                            <div className="top-section">
                                <div className="top-row">
                                    <Lpa />
                                    <ClickableIcon onClick={closeAction} alt="Close" icon={<ClearIcon />} />
                                </div>
                                {navItems}
                            </div>
                            {isLoggedIn && (
                                <div className="bottom-section">
                                    {user && user.accountDetails && <UserCard />}
                                    <LogoutButton />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default NavigationBar;
