import { TAirtableConsignment } from '@containers/Consignments/ConsignmentEdit';
import { IUser } from '@typings';

import { ConsignmentStatus } from './enum-transformers';
import { RoleTypeEnum } from './enums';

export function isMyConsignment({ createdBy }: { createdBy: string | null }, user: IUser) {
    if (!createdBy) {
        return false;
    }
    return !!user.accountDetails.id && createdBy.endsWith(user.accountDetails.accountId);
}

export function canCreateConsignment(user: IUser | undefined): boolean {
    // NLIS users can't create consignments
    // Admin users also cannot create
    const indexOfProducer = user?.accountDetails?.roles?.indexOf('PRODUCER');
    return (indexOfProducer ?? -1) > -1;
}

export function hasRoles(user: IUser | undefined): boolean {
    // User have no role don't have any access to consignment
    // Same as expired LPA account
    const expired = user?.accountDetails?.roles.includes(RoleTypeEnum.EXPIRED);
    return Boolean(user?.accountDetails && user?.accountDetails.roles.length > 0 && !expired);
}

export function IsValidUser(user: IUser | undefined): boolean {
    return (user?.claims?.email ?? '').length > 0;
}

export function canViewTemplate(user: IUser | undefined): boolean {
    // NLIS users can't view templates
    return canCreateConsignment(user);
}

export function readOnlyWhenSubmittedV4(consignment: TAirtableConsignment, user: IUser): boolean {
    if (!consignment) {
        return false;
    }

    // Limit to owner only
    if (!isMyConsignment(consignment, user)) {
        return true;
    }

    if (consignment.status === 'DRAFT' && isMyConsignment(consignment, user)) {
        return false;
    }

    return true;
}

export function readOnlyWhenLockedV4(query: TAirtableConsignment, user: IUser): boolean {
    if (!query) {
        return false;
    }

    // Limit to owner only
    if (!isMyConsignment(query, user)) {
        return true;
    }

    // Once locked there should be no changes made.
    if (query.status === ConsignmentStatus.LOCKED.toString()) {
        return true;
    }

    if (user.accountDetails.isAdmin) {
        return true;
    }

    return false;
}

const EDITABLE_AND_DELETABLE_IDS_V4 = ['1', '157'];
export const isEditableAndDeletableDuplicateV4 = (user: IUser, isOwner: boolean, status: ConsignmentStatus, id: string) => {
    if (!isOwner) {
        return false;
    }
    // Admin users are read-only
    if (user?.accountDetails?.isAdmin) {
        return false;
    }

    if (status === ConsignmentStatus.DRAFT) {
        return true;
    }

    if (status === ConsignmentStatus.SUBMITTED && EDITABLE_AND_DELETABLE_IDS_V4.includes(id)) {
        return true;
    }

    if (status === ConsignmentStatus.LOCKED) {
        return false;
    }

    return false;
};
