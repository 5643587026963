import { useAuth0 } from '@auth0/auth0-react';
import { actionTypes, useUserDispatch } from '@common/context/userContext';
import useWindowTitle from '@effects/useWindowTitle';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

const Logout: React.FC = () => {
    const dispatch = useUserDispatch();
    const { logout } = useAuth0();
    const history = useHistory();
    useWindowTitle('Logging you out...');

    useEffect(() => {
        const timeout = setTimeout(() => {
            // Log the user out by clearing state
            dispatch({
                type: actionTypes.user.resetUserParam,
                value: null,
            });

            // We now need to clear the token in session storage
            // Need to wrap to ensure it runs and isn't discarded
            sessionStorage.clear();
            history.push('/');
        }, 0);

        return () => clearTimeout(timeout);
    }, [dispatch, history, logout]);

    return null;
};

export default Logout;
