import { DeepPartial, IUser } from '@typings';
import React, { createContext, Dispatch, useContext, useReducer } from 'react';

import userReducer, { UserActionTypes, UserInitialState } from '../../State/Reducers/userReducer';

export const initialState: IUserState = {
    user: UserInitialState(),
};

export const actionTypes = {
    user: UserActionTypes,
};

const reducers = ({ user }: IUserState, action: ActionType) => ({
    user: userReducer(user!!, action),
});

const StateContext = createContext<[IUserState, Dispatch<any>]>([{} as any, () => null]);
const DispatchContext = createContext<Dispatch<any>>(() => null);

interface Props {
    children: any;
    mockState?: DeepPartial<IUserState>;
}

export const UserProvider: React.FunctionComponent<Props> = ({ children }) => {
    const state: IUserState = initialState;
    const value = useReducer(reducers, state);
    return (
        <StateContext.Provider value={value}>
            <DispatchContext.Provider value={value[1]}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
};

export const useUserState = () => {
    const stateContext = useContext(StateContext);
    return stateContext;
};

export const useUserDispatch = () => {
    const dispatchContext = useContext(DispatchContext);
    return dispatchContext;
};

export interface ActionType {
    type: string;
    value: any;
    payload: any;
}

interface IUserState {
    user: IUser;
}
