import EmptyList from '@components/Listing/EmptyList';
import Loader from '@components/Loader';
import Config from '@config';
import MLALogger from '@utils/logger';
import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ConnectionConfig } from 'react-relay';
import { graphql, usePagination } from 'relay-hooks';

import EdecRow from '../EdecRow';

export const EdecListFragmentQuery = graphql`
    query EdecListFragmentPagedQuery($envdAccountId: String!, $count: Int!, $cursor: String, $searchText: String) {
        ...EdecListFragment_query
    }
`;

export const EdecListPaginationSpec = graphql`
    fragment EdecListFragment_query on Query @refetchable(queryName: "EdecListRefetchQuery") {
        edecs(envdAccountId: $envdAccountId, first: $count, after: $cursor, searchText: $searchText) @connection(key: "EdecListFragment_edecs") {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    ...EdecRow_edec
                }
            }
        }
    }
`;

const Header = () => (
    <div className="tbl-row row-header">
        <div className="tbl-cell-60 column-heading">Form</div>
        <div className="tbl-cell-20 column-heading">Serial No</div>
        <div className="tbl-cell-20 column-heading">Link to PDF</div>
    </div>
);

const Empty: React.FC<{ error: boolean }> = ({ error = false }) =>
    error ? (
        <EmptyList
            emptyText={
                <>
                    <p>No results found</p>
                    <br />
                    <p>We couldn’t find the eDEC form you were looking for.</p>
                    <p>Update your search and try again.</p>
                </>
            }
        />
    ) : (
        <EmptyList emptyText="You have no eDEC forms." />
    );

export const EdecsLoader = () => (
    <div className="tbl tbl-collapse">
        <Header />
        <Loader isLoading={true} error={false} retry={() => null} timedOut={false} pastDelay={false} />
    </div>
);

export const EdecsListConnectionConfig: ConnectionConfig = {
    direction: 'forward',
    query: EdecListFragmentQuery as any,
    getConnectionFromProps(props: any) {
        return props?.edecs;
    },
    getVariables(props: any, paginationInfo: any, fragmentVariables: any) {
        return {
            count: paginationInfo.count,
            cursor: props.edecs.pageInfo.endCursor,
            searchText: fragmentVariables.searchText,
        };
    },
};

interface Props {
    // query: EdecsListFragment_query;
    query: any;
}

const EdecListFragment: React.FC<Props> = ({ query }) => {
    const { data, isLoading, hasNext, loadNext } = usePagination(EdecListPaginationSpec, query as any);
    const edecsList = data?.edecs?.edges?.map((x: any) => x && x.node).coalesce() ?? [];

    const _loadMore = useCallback(() => {
        if (!hasNext || isLoading) {
            MLALogger.Log(['EdecList'], 'Skipped loading');
        }
        loadNext(Config.PAGINATION_PER_PAGE_LIMIT);
    }, [hasNext, isLoading, loadNext]);

    return (
        <>
            <style jsx>{``}</style>
            <div className="tbl tbl-collapse">
                <Header />
                <InfiniteScroll
                    className="tbl-body"
                    data-cy="edec-records"
                    pageStart={0}
                    initialLoad={false}
                    loadMore={() => {
                        if (!hasNext || isLoading) {
                            console.log('[EdecList] Relay has no more or is currently loading', { hasMore: hasNext, isLoading });
                            return;
                        }

                        console.log('[EdecList] Relay is attempting to load more', { hasMore: hasNext, isLoading });

                        _loadMore();
                    }}
                    hasMore={hasNext}
                    loader={
                        <div className="tbl-row" key={0}>
                            <Loader isLoading={true} error={''} retry={() => null} timedOut={false} pastDelay={false} />
                        </div>
                    }
                >
                    {!isLoading && edecsList.length > 0 ? edecsList.map((x: any) => <EdecRow key={x.id} edec={x} />) : <Empty error />}
                </InfiniteScroll>
            </div>
        </>
    );
};

export default EdecListFragment;
