import { ClearIcon, WarningIcon } from '@assets/icons';
import Alert, { AlertType } from '@components/Alert';
import Button from '@components/Button';
import ClickableIcon from '@components/ClickableIcon';
import Modal from '@components/Modal';
import Config from '@config';
import { accounts, IAccountDetails } from '@typings';
import { getReAccreditationDate } from '@utils/account';
import { AccreditationStatusEnum } from '@utils/enums';
import { format } from 'date-fns';
import React, { useState } from 'react';

interface AccreditationStatusProps {
    account: accounts | IAccountDetails;
    accreditationStatus: AccreditationStatusEnum;
}

function getAccreditationMessage(account: accounts | IAccountDetails, accreditationStatus: AccreditationStatusEnum | null, setShowModal?: any) {
    const reAccreditationDate = getReAccreditationDate(account);
    let message, cardText, title, body, modalFooter;

    function getCardTextAndAction(message: string) {
        return (
            <>
                <style jsx>{`
                    @import 'vars';

                    .card-button {
                        cursor: pointer;
                        font-size: grid(4);
                        line-height: grid(6);
                        border: none;
                        text-align: left;
                        appearance: none;
                        background: none;
                        padding: 0;
                        color: $color-error;
                        text-decoration: underline;
                    }
                `}</style>
                <button onClick={() => setShowModal(true)} className="card-button">
                    {message}
                </button>
            </>
        );
    }

    function getLPALink(message: string) {
        return (
            <>
                {' '}
                <a href={Config.LPA_URL} target="_blank" rel="noreferrer">
                    {message}
                </a>{' '}
            </>
        );
    }

    function getFooter(closeButtonMessage: string, primaryButtonMessage: string) {
        return (
            <>
                <Button buttonType="redirect" buttonSize="medium" onClick={() => setShowModal(false)}>
                    {closeButtonMessage}
                </Button>
                <Button buttonType="primary" url={Config.LPA_URL} openNewTab buttonSize="medium">
                    {primaryButtonMessage}
                </Button>
            </>
        );
    }

    switch (accreditationStatus) {
        case AccreditationStatusEnum.VALID:
            cardText = 'Your accreditation is up to date';
            break;
        case AccreditationStatusEnum.WITHIN_2MONTHS:
            message = 'Your LPA accreditation is about to expire';
            title = message;
            cardText = getCardTextAndAction(`${message}`);
            const reAccreditationStandardDateFormat = reAccreditationDate && format(new Date(reAccreditationDate), 'dd-MM-yyyy');
            let msaAccreditated = false;
            if ('accreditations' in account) {
                msaAccreditated = account?.accreditations?.mSA || false;
            }
            body = (
                <>
                    <p>
                        Your LPA {msaAccreditated && ' and MSA'} {msaAccreditated ? ' accreditations' : 'accreditation'} for PIC {account.accountLabel} will expire on{' '}
                        {reAccreditationStandardDateFormat}. You must renew your accreditation by the due date to remain LPA accredited and continue to use LPA NVDs.
                    </p>
                    <p>Please log in to the {getLPALink('LPA Service Centre')} to renew your accreditation.</p>
                </>
            );
            modalFooter = getFooter('Complete later', 'Complete Accreditation now');
            break;
        case AccreditationStatusEnum.EXPIRED_ACCESS:
            message = 'Your LPA accreditation for this PIC has expired.';
            title = message;
            cardText = getCardTextAndAction(`${message}`);
            body = (
                <>
                    <p>You currently have temporary access to LPA. Soon you will no longer be able to create a new LPA NVD.</p>
                    <p>Please log in to the {getLPALink('LPA Service Centre')} to complete your accreditation.</p>
                </>
            );
            modalFooter = getFooter('Cancel', 'Complete LPA Accreditation now');
            break;
        case AccreditationStatusEnum.INVALID:
            message = 'Your PIC is not LPA accredited';
            title = message;
            cardText = getCardTextAndAction(`${message}`);
            body = (
                <>
                    <p>Your PIC {account.accountLabel} is not LPA accredited. LPA NVDs cannot be created for this PIC until LPA accreditation is completed.</p>
                    <p>Please log in to the {getLPALink('LPA Service Centre')} to complete your accreditation.</p>
                </>
            );
            modalFooter = getFooter('Cancel', 'Complete LPA Accreditation now');
            break;
        default:
            break;
    }

    return { cardText, title, body, modalFooter };
}

const AccreditationStatusModal: React.FC<AccreditationStatusProps> = ({ account, accreditationStatus }) => {
    const [showModal, setShowModal] = useState(false);
    const { cardText, title, body, modalFooter } = getAccreditationMessage(account, accreditationStatus, setShowModal);

    return (
        <>
            <div>{cardText}</div>
            <Modal id={'accreditation-status-modal'} show={showModal} onOutClick={() => setShowModal(false)} modalFooter={false} zIndex={9} ModalInnerClassName="accreditation-status-inner-modal">
                <>
                    <div className="modal-header">
                        <ClickableIcon icon={<ClearIcon />} alt="Remove" onClick={() => setShowModal(false)} classNames="close-modal-icon" />
                        <div className="modal-header-title">
                            <div className="warning-icon">
                                <WarningIcon />
                            </div>
                            <span className="title-text">{title}</span>
                        </div>
                    </div>
                    <div className="modal-body">{body}</div>
                    <div className="modal-footer">{modalFooter}</div>
                </>
            </Modal>
            <style jsx>{`
                @import 'vars';

                :global(.accreditation-status-inner-modal) {
                    display: flex;
                    flex-direction: column;
                    background-color: $color-white;
                    z-index: 2;
                    max-width: 607px;
                    overflow-scrolling: unset !important;
                    @media (max-width: $xl-min) {
                        width: 100% !important;
                    }
                    @media (min-width: 1800px) {
                        width: 50% !important;
                    }
                    border-radius: 4px;
                    border: 2px solid $isc-blue;
                    overflow: hidden !important; /* Hide scrollbars */
                    :global(.Modal--ScrollContainer) {
                        overflow: hidden !important; /* Hide scrollbars */
                    }
                }

                .modal-header {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    margin-bottom: 14px;
                }

                :global(.close-modal-icon) {
                    color: $isc-blue;
                    align-self: flex-end;
                    margin-bottom: 22px;

                    :global(svg) {
                        width: $icon-xl;
                        height: $icon-xl;
                    }
                }

                .warning-icon {
                    :global(svg) {
                        width: $icon-xl;
                        height: $icon-xl;
                        color: $color-error;
                    }
                }

                .modal-header-title {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    font-size: 26px;
                    font-weight: 590;
                }

                .modal-body {
                    font-size: 18px !important;
                    font-weight: 400;
                    margin-bottom: 64px;
                }

                .modal-body p {
                    padding-bottom: 20px;
                }

                .modal-footer {
                    display: flex;
                    flex-direction: row;
                    align-items: start;
                    gap: 27px;
                }
            `}</style>
        </>
    );
};

const AccreditationStatusAlert: React.FC<AccreditationStatusProps> = ({ account, accreditationStatus }) => {
    switch (accreditationStatus) {
        case AccreditationStatusEnum.VALID:
            return null;
        case AccreditationStatusEnum.WITHIN_2MONTHS:
        case AccreditationStatusEnum.EXPIRED_ACCESS:
        case AccreditationStatusEnum.INVALID:
            const { title, body } = getAccreditationMessage(account, accreditationStatus);
            const alertType = accreditationStatus === AccreditationStatusEnum.INVALID ? AlertType.Error : AlertType.Warn;

            return <Alert type={alertType} title={title} subtitle={body} />;
        default:
            throw new Error(`Invalid accreditation status: ${accreditationStatus}`);
    }
};

export { AccreditationStatusAlert, AccreditationStatusModal };
