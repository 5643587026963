import { graphql } from 'relay-hooks';

export const userDetailsQuery = graphql`
    query userDetailsQuery {
        userDetails {
            firstName
            lastName
            mobilePhone
            isNewUser
            email
            defaultAccount
            receiveEmails
            signature
            accounts {
                envdAccountId
                accountType
                accountLabel
                email
                phone
                accountId
                properties {
                    nameId
                    nlisAccountId
                    nlisUserId
                    nlisAccountType
                    rego
                    firstName
                    lastName
                    entityName
                    propertyName
                    msaMemberNum
                    reAccreditationDate
                    address {
                        line1
                        town
                        state
                        postcode
                    }
                    accreditations {
                        lPA
                        eU
                        mSA
                        eUGFHQB
                        nFAS
                        hRG
                    }
                }
                roles
            }
        }
    }
`;
