import { IGlobalState } from '@typings';
import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { DeepPartial } from 'ts-essentials';

import reducers, { actionTypes as allActionTypes, initialState } from './Reducers';

export const actionTypes = allActionTypes;

const StateContext = createContext<[IGlobalState, Dispatch<any>]>([{} as IGlobalState, () => null]);
const DispatchContext = createContext<Dispatch<any>>(() => null);

interface Props {
    children: any;
    mockState?: DeepPartial<IGlobalState>;
}

export const StateProvider: React.FunctionComponent<Props> = ({ children, mockState }) => {
    const state: IGlobalState = (mockState as IGlobalState) || initialState;
    const value = useReducer(reducers, state);
    return (
        <StateContext.Provider value={value}>
            <DispatchContext.Provider value={value[1]}>{children}</DispatchContext.Provider>
        </StateContext.Provider>
    );
};

export const useGlobalState = () => {
    const stateContext = useContext(StateContext);
    return stateContext;
};

export const useGlobalDispatch = () => {
    const dispatchContext = useContext(DispatchContext);
    return dispatchContext;
};

export interface ActionType {
    type: string;
    value: any;
    payload: any;
}
