import FilterMenuOptions from '@components/Form/FilterMenuOptions';
import { IFilterGroupMenuGroup } from '@components/Form/FilterMenuOptions';
import RadioList from '@components/Form/RadioList';
import { SelectableListItem } from '@components/Form/SelectableList';
import { actionTypes, useGlobalState } from '@state';
import { isAfter, isBefore, subDays } from 'date-fns';
import { enAU } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { DateRangePicker, END_DATE, START_DATE } from 'react-nice-dates';

const CustomDateRange = () => {
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [{ elevatedViewerSearchFields }, dispatch] = useGlobalState();

    useEffect(() => {
        if (startDate || endDate) {
            dispatch({
                type: actionTypes.elevatedViewerSearch.setElevatedViewerSearchField,
                payload: { ...elevatedViewerSearchFields, startDate: startDate, endDate: endDate },
            });
        }
        // Disabling the below rule since the above update on global store is based only on the parameter
        // of start date and end date.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    return (
        <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={(v: any) => {
                if ((endDate && isBefore(v, endDate)) || !endDate) {
                    setStartDate(v as Date);
                }
            }}
            onEndDateChange={(v: Date) => {
                if ((startDate && isAfter(v, startDate)) || !startDate) {
                    setEndDate(v as Date);
                }
            }}
            format="dd MMM yyyy"
            locale={enAU}
        >
            {({ startDateInputProps, endDateInputProps, focus }: any) => (
                <div className="date-range">
                    <style jsx>{`
                        @import 'vars';
                        @import 'utils';
                        @import 'mixins';

                        .date-range {
                            display: flex;
                            input {
                                width: 50%;
                            }
                        }
                    `}</style>
                    <input className={'input' + (focus === START_DATE ? ' -focused' : '')} {...startDateInputProps} placeholder="Start date" />
                    <span className="date-range_arrow" />
                    <input className={'input' + (focus === END_DATE ? ' -focused' : '')} {...endDateInputProps} placeholder="End date" />
                </div>
            )}
        </DateRangePicker>
    );
};

const ElevatedViewerSearchOptions = () => {
    const [{ elevatedViewerSearchFields }, dispatch] = useGlobalState();

    const [sortItems, setSortItems] = useState<SelectableListItem[]>([
        { id: 'UPDATED_AT_DESC', title: 'Last Updated', selected: true },
        { id: 'MOVEMENT_DATE_DESC', title: 'Movement Date', selected: false },
        { id: 'CREATED_AT_DESC', title: 'Created Date', selected: false },
    ]);
    const [filterGroups, setFilterGroups] = useState<IFilterGroupMenuGroup[]>([
        {
            title: 'Species',
            selectionType: 'single',
            options: [
                { id: 'CATTLE', title: 'Cattle', selected: false },
                { id: 'SHEEP_LAMB', title: 'Sheep & Lamb', selected: false },
                { id: 'GOAT', title: 'Goat', selected: false },
                { id: 'BOBBY_CALVES', title: 'Bobby Calves', selected: false },
            ],
        },
        {
            title: 'Status',
            selectionType: 'single',
            options: [
                { id: 'DRAFT', title: 'Draft', selected: false },
                { id: 'SUBMITTED', title: 'Submitted', selected: false },
                { id: 'LOCKED', title: 'Completed', selected: false },
            ],
        },
        {
            title: 'Within last',
            selectionType: 'single',
            options: [
                { id: 'WEEK', title: 'Week', selected: false },
                { id: 'MONTH', title: 'Month', selected: false },
                { id: 'QUARTER', title: 'Quarter', selected: false },
                {
                    id: 'custom-date-range',
                    title: 'Custom date range',
                    selected: false,
                    customRender: {
                        isConditional: true,
                        render: () => <CustomDateRange />,
                    },
                },
            ],
        },
    ]);

    const calculateDate = (dateIdentifier: string) => {
        let startDate = elevatedViewerSearchFields?.startDate;
        let endDate = elevatedViewerSearchFields?.endDate;
        if (dateIdentifier === 'WEEK') {
            startDate = subDays(new Date(), 7);
            endDate = new Date();
        }
        if (dateIdentifier === 'MONTH') {
            startDate = subDays(new Date(), 30);
            endDate = new Date();
        }
        if (dateIdentifier === 'QUARTER') {
            startDate = subDays(new Date(), 90);
            endDate = new Date();
        }
        if (dateIdentifier === '') {
            startDate = null;
            endDate = null;
        }
        return { startDate, endDate };
    };

    // Using the below useEffect to reset the value in global store upon component unmount.
    useEffect(() => {
        return () => {
            dispatch({
                type: actionTypes.elevatedViewerSearch.resetElevatedViewerSearchField,
            });
        };
    }, [dispatch]);

    const updateFilterGroup = (group: IFilterGroupMenuGroup[]) => {
        setFilterGroups(group);
        const speciesGroup = group[0];
        const statusGroup = group[1];
        const dateRangeGroup = group[2];
        const selectedDateOption = dateRangeGroup?.options?.firstOrDefault((x) => x.selected)?.id || '';

        const { startDate, endDate } = calculateDate(selectedDateOption);
        const species = speciesGroup?.options?.filter((o) => o.selected)[0]?.id || null;
        const status = statusGroup?.options?.filter((o) => o.selected)[0]?.id || null;
        dispatch({
            type: actionTypes.elevatedViewerSearch.setElevatedViewerSearchField,
            payload: { ...elevatedViewerSearchFields, species: species, status: status, startDate: startDate, endDate: endDate },
        });
    };

    const updateSortBy = (value: SelectableListItem) => {
        const updatedItemList: SelectableListItem[] = sortItems.map((obj) => {
            if (value.id === obj.id) {
                obj.selected = true;
            } else {
                obj.selected = false;
            }
            return obj;
        });
        setSortItems(updatedItemList);
        dispatch({ type: actionTypes.elevatedViewerSearch.setElevatedViewerSearchField, payload: { ...elevatedViewerSearchFields, sortField: updatedItemList?.find((x) => x.selected)?.id || null } });
    };

    return (
        <>
            <div className="search-options">
                <div className="sort-by">
                    <h4 className="sort-by-heading m-v-4">Sort by</h4>
                    <div className="divider"></div>
                    <div className="sort-by-radioList m-v-12">
                        <RadioList
                            items={sortItems}
                            onChange={(item) => {
                                updateSortBy(item);
                            }}
                        />
                    </div>
                </div>
                <div className="filter-by">
                    <h4 className="m-h-16 m-v-4">Filter by</h4>
                    <div className="divider"></div>
                    <div className="m-h-16 m-v-12">
                        <FilterMenuOptions groups={filterGroups} onUpdate={(groups) => updateFilterGroup(groups)} />
                    </div>
                </div>
            </div>
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                .search-options {
                    display: flex;
                    @media (max-width: $md-min) {
                        display: block;
                    }
                }

                .sort-by-heading,
                .sort-by-radioList {
                    margin-left: 40px;
                    @media (max-width: $md-min) {
                        margin-left: 16px;
                    }
                }

                .sort-by {
                    flex: 1;
                }

                .filter-by {
                    flex: 4;
                }

                :global(.filter-group) {
                    :global(.FormRow),
                    :global(h4) {
                        margin-bottom: 8px;
                    }
                }
                :global(.filter-group):last-of-type {
                    flex: 3;
                }
                .divider {
                    border-top: 1px solid $color-border;
                }
            `}</style>
        </>
    );
};

export default ElevatedViewerSearchOptions;
