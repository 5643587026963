import { DropDown, DropUp, NewWindow } from '@assets/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import Popover from '@components/Form/Popover';
import RelativePortal from '@components/RelativePortal';
import { IsValidUser } from '@utils/question-editable';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';

import Config from '../../config';
import UserCard from './UserCard';

const UserMenu: React.FC = () => {
    const [{ user }] = useUserState();
    const isValidUser = IsValidUser(user);
    const userMenuRef = useRef<any>();
    const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
    const { logout } = useAuth0();
    const history = useHistory();

    const logoutAction = () => {
        // We now need to clear the token in session storage
        // Need to wrap to ensure it runs and isn't discarded
        sessionStorage.clear();
        // Log the user out by clearing state
        // dispatch({
        //     type: actionTypes.user.resetUserParam,
        //     value: null,
        // });
        // Call logout Auth0 functionality to redirect to the origin page.
        logout({ logoutParams: { returnTo: window.location.origin + '/logout' } });
    };

    return (
        <div className="UserMenu" ref={userMenuRef}>
            <style jsx>{`
                :global(.UserMenuButton) {
                    :global(svg) {
                        pointer-events: none;
                    }
                }
            `}</style>
            <Button active={userMenuOpen} buttonType={'tertiary'} buttonSize={'small'} action={() => setUserMenuOpen(!userMenuOpen)} className="UserMenuButton">
                <span className="m-r-8">
                    {user?.claims?.firstName || ''}&nbsp;{user?.claims?.lastName || ''}
                    {/* {user.details.entityName ?? user.details.nameid ?? user.details.nlisUserId}&nbsp;{user.details.pic && <>{`(${user.details.pic})`}</>} */}
                </span>
                {userMenuOpen ? <DropUp /> : <DropDown />}
            </Button>
            <RelativePortal
                id={'user-menu-portal'}
                fitToParentWidth={false}
                show={userMenuOpen}
                onParentClick={undefined}
                onOutClick={() => setUserMenuOpen(!userMenuOpen)}
                parentElementRef={userMenuRef}
                disableScrollBodyLock
            >
                <Popover
                    size="medium"
                    footer={
                        <>
                            {/* only showing the edit profile if we have user details */}
                            {isValidUser && (
                                <div>
                                    <Button buttonType={'redirect'} className={'button1'} fullWidth style={{ marginBottom: '20px' }} action={() => history.push('/profile')}>
                                        Edit eNVD Profile
                                    </Button>
                                </div>
                            )}
                            <div>
                                <Button buttonType={'redirect'} fullWidth style={{ marginBottom: '20px' }} action={() => (window.location.href = Config.MYMLA_BASE_URL)}>
                                    Redirect to MyMLA
                                    <NewWindow />
                                </Button>
                            </div>
                            <div>
                                <Button fullWidth buttonType="delete" children={'Logout from eNVD'} action={logoutAction} />
                            </div>
                        </>
                    }
                >
                    <UserCard />
                </Popover>
            </RelativePortal>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';
            `}</style>
        </div>
    );
};

export default UserMenu;
