import Config from '@config';
import { IAccountDetails } from '@typings';
import {
    ACCEPTED_FILE_EXTENSION_LABEL,
    ACCEPTED_FILE_EXTENSIONS,
    ACCEPTED_FILESIZE,
    ACCEPTED_IMAGE_EXTENSION_LABEL,
    ACCEPTED_MIMETYPES_IMAGES,
    BLANK_IMAGE,
    FILE_REGEX,
    ONE_MB_BYTES,
} from '@utils/constants';
import axios from 'axios';

import MLALogger from './logger';
import { splitByLastOccurrence } from './string-extensions';

export async function upload(
    files: FileList | null,
    mimeTypes: string[],
    sizeLimit: number = 50,
    consignmentNumber: string = '',
    index?: number | null,
    questionId?: string,
    account?: IAccountDetails,
    preKey?: string
): Promise<{ url?: string; key?: string; error?: string } | null> {
    if (!files || files?.length === 0) {
        return null;
    }

    const isImageUploadFn: boolean = mimeTypes.toString() === ACCEPTED_MIMETYPES_IMAGES.toString();

    const file = files![0];

    if (!mimeTypes.includes(file.type)) {
        const extensionLabel = isImageUploadFn ? ACCEPTED_IMAGE_EXTENSION_LABEL : ACCEPTED_FILE_EXTENSION_LABEL;
        return {
            error: 'The file you are trying to upload is not in a supported format. Please ensure that your attachment is in one of the following formats: ' + extensionLabel + '.',
        };
    }

    if (file.size > sizeLimit * ONE_MB_BYTES) {
        // Disallow anything over allowed size
        return { error: 'The file you are trying to upload exceeds the maximum allowed file size. Please ensure that your attachment is less than ' + ACCEPTED_FILESIZE + 'MB in size.' };
    }

    let uploadUrl = Config.GRAPHQL_SERVER_API_UPLOAD_URL;
    let section = '';
    if (consignmentNumber) {
        if (questionId) {
            // if question id is 62, then section is 'foodsafety' or is 'healthdeclaration'
            section = questionId === '62' ? '/fs' : '/hd';
        }
        let idRoute = '/' + index;
        uploadUrl = Config.BASE_GRAPHQL_SERVER_URL + '/api/consignments/' + consignmentNumber + section + idRoute + '/upload';
    }

    try {
        // Upload to endpoint and save the url
        const formData = new FormData();
        formData.append('file', file);
        if (preKey) {
            const keyOnlyName = preKey.split('key=').last() ?? null;
            if (keyOnlyName) formData.append('preKey', keyOnlyName);
        }
        account && formData.append('envdAccountId', account.id);

        const response = await axios.post(uploadUrl, formData);
        const json = response.data;
        const url = json.url;
        const key = json.key;

        return { url, key };
    } catch (error) {
        console.error(error);
        MLALogger.Log(['file-ops', 'upload'], { error });
    }

    return null;
}

export function convertFromUrlToFilename(url: string): string {
    // In order to convert to the filename, we need to check the file extension against our known list
    // of acceptable file types
    const acceptedFileExtensions = ACCEPTED_FILE_EXTENSIONS.split(', ');
    MLALogger.Log(['file-ops', 'convertFromUrlToFilename'], { url, acceptedFileExtensions });
    const fileExtension = url?.split('.')?.pop() || '';
    if (acceptedFileExtensions.includes('.' + fileExtension.toLowerCase())) {
        const splittedFileURL = splitByLastOccurrence(url, '.' + fileExtension);
        const fileName = splittedFileURL?.split('/')?.last();
        MLALogger.Log(['file-ops', 'convertFromUrlToFilename'], { url, fileExtension, fileName });
        return fileName + '.' + fileExtension ?? '';
    }

    return '';
}

export async function fetchFileUrl(key: string): Promise<string | null> {
    if (!key) {
        return null;
    }

    // Already a url, assume it is fine
    if (key.startsWith('http')) {
        return key;
    }

    try {
        const response = await axios.get(`${Config.BASE_GRAPHQL_SERVER_URL}/api/fetch?key=${key}`);
        const json = response.data;

        return json.url;
    } catch (error) {
        console.error(error);
        MLALogger.Log(['file-ops', 'fetch'], { error });
    }

    return null;
}

export function isBase64Image(value: string) {
    if (!value) {
        return false;
    }

    return FILE_REGEX.test(value) || value === BLANK_IMAGE;
}

export function getImgSrcForProgram(accreditationCode: string) {
    const imgSrc = `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.${accreditationCode}.jpg`;
    return imgSrc;
}
