import { ClearIcon } from '@assets/icons';
import Button from '@components/Button';
import ClickableIcon from '@components/ClickableIcon';
import Modal from '@components/Modal';
import { IUser } from '@typings';
import { format } from 'date-fns';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Header } from './fragments/ConsignmentListFragment';
import { TodayConsignmentContent } from './TodayConsignment';
interface Props {
    count: Number;
    isLoading: boolean;
    consignmentsList: Object[];
    user: IUser;
    isScrollable: Boolean;
}

const TodayConsignmentHeadingContent = ({ count, showDescription }: { count: Number; showDescription: boolean }) => {
    const date = new Date();
    return (
        <>
            <div>
                <div className="content">
                    <h2>
                        Moving Today: {format(date, 'd')}
                        <sup>{format(date, 'do')?.slice(-2)}</sup> {format(date, 'LLLL')} {format(date, 'y')}
                    </h2>
                    {count?.valueOf() >= 0 && <span className="badge"> {count as React.ReactNode} </span>}
                </div>
                {showDescription && (
                    <div className="content--text">
                        <p>You are viewing the full list of consignments that are moving today.</p>
                    </div>
                )}
            </div>
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                h2 {
                    margin: grid(2) 0;

                    @media (max-width: $sm-min) {
                        margin: grid(6) 0;
                    }
                }

                .break {
                    flex-basis: 100%;
                    height: 0;
                }
                .badge {
                    background-color: $isc-blue;
                    border-radius: 40px;
                    color: white;
                    min-width: 40px;
                    min-height: 30px;
                    padding: 4px 3px;
                    font-size: 15px;
                    text-align: center;
                }
                .content {
                    display: flex;
                    align-items: center;
                    gap: 1vw;
                    &--text {
                        margin-top: 15px;
                    }
                }
            `}</style>
        </>
    );
};

const TodayConsignmentHeader = ({ count, isLoading, consignmentsList, user, isScrollable }: Props) => {
    const [showModal, setShowModal] = useState(false);

    const isDesktop = useMediaQuery({ minWidth: 1150 });
    return (
        <div className="todayConsignment">
            <div className="content">
                <TodayConsignmentHeadingContent count={count} showDescription={false} />
                {isScrollable && (
                    <div className="link-button">
                        <Button buttonType="link" onClick={() => setShowModal(true)}>
                            {isDesktop ? <p>View all consignments moving today</p> : <p>Today Consignments</p>}
                        </Button>
                    </div>
                )}
            </div>
            <div className="sample">
                <Modal id={'consignment-today-modal'} show={showModal} onOutClick={() => setShowModal(false)} modalFooter={false} zIndex={9} ModalInnerClassName="Today-Modal-Consignment">
                    <>
                        <div className="ModalConsignment--Header">
                            <TodayConsignmentHeadingContent count={count} showDescription={true} />
                            <ClickableIcon icon={<ClearIcon />} alt="Remove" onClick={() => setShowModal(false)} classNames="ConsignmentModalClose" />
                        </div>
                        <div className="tbl tbl-collapse">
                            <Header user={user} showMovementDate={false} headerClassName="tbl-row consignment-header" />
                            <div className="scrollableDiv">
                                <TodayConsignmentContent isLoading={isLoading} consignmentsList={consignmentsList} checkScrollBarPresent={() => {}} isModal />
                            </div>
                        </div>
                    </>
                </Modal>
            </div>
            <style jsx>{`
                @import 'vars';

                .tbl {
                    @media (max-width: $md-max) {
                        border: none;
                        background: transparent;
                    }
                }

                .todayConsignment {
                    margin-bottom: 10px;
                }

                .link-button {
                    margin-bottom: -15px;
                    margin-left: auto;
                    margin-right: 5%;
                    p {
                        color: $isc-blue;
                        text-decoration: underline;
                    }
                }

                .content {
                    display: flex;
                    align-items: center;
                    gap: 1vw;
                }

                .scrollableDiv {
                    width: 100%;
                }

                :global(.tbl .tbl-row.consignment-header) {
                    position: inherit;
                    @media (max-width: $sm-max) {
                        display: none;
                    }
                }

                :global(.ModalConsignment--Header) {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex: 1 1 auto;
                    margin-bottom: 35px;
                }

                :global(.Today-Modal-Consignment) {
                    display: flex;
                    flex-direction: column;
                    background-color: $color-white;
                    z-index: 2;
                    width: 60% !important;
                    height: 90% !important;
                    max-width: 1400px !important;
                    max-height: 90vh !important;
                    @media (max-width: $xl-min) {
                        width: 100% !important;
                    }
                    @media (min-width: 1800px) {
                        width: 50% !important;
                    }
                    @media (prefers-color-scheme: dark) {
                        background-color: darken($color-white, 80%);
                    }
                }
            `}</style>
        </div>
    );
};

export default TodayConsignmentHeader;
