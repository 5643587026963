import { AccreditationCodeEnum, FormNameDisplayEnum } from '@utils/enums';
import { getImgSrcForProgram } from '@utils/file-ops';

const ProgramIcon: React.FC<{ program: AccreditationCodeEnum }> = ({ program }) => {
    let accreditationCode;
    let alias: string = program;
    switch (program) {
        case AccreditationCodeEnum.LPA:
            accreditationCode = AccreditationCodeEnum.LPA.toLowerCase();
            break;
        case AccreditationCodeEnum.EU:
            accreditationCode = AccreditationCodeEnum.LPA.toLowerCase();
            alias = FormNameDisplayEnum.EUNVD;
            break;
        case AccreditationCodeEnum.MSA:
            accreditationCode = AccreditationCodeEnum.MSA.toLowerCase();
            break;
        case AccreditationCodeEnum.NFAS:
        case AccreditationCodeEnum.EUGFHQB:
            accreditationCode = AccreditationCodeEnum.NFAS.toLowerCase();
            break;
        case AccreditationCodeEnum.HRG:
            accreditationCode = AccreditationCodeEnum.LPA.toLowerCase();
            alias = FormNameDisplayEnum.HRG;
            break;
        default:
            accreditationCode = null;
            break;
    }
    const imgSrc = accreditationCode ? getImgSrcForProgram(accreditationCode) : null;
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                .program-wrapper {
                    margin: grid(2);
                    @media (max-width: $lg-min) {
                        margin: grid(1);
                    }
                    @media (max-width: $md-min) {
                        margin: grid(0);
                    }
                    @media (max-width: $sm-min) {
                        margin: grid(2);
                    }
                    img {
                        height: grid(6);
                        width: grid(6);
                        @media (max-width: $lg-min) {
                            height: grid(4);
                            width: grid(4);
                        }
                        @media (max-width: $sm-min) {
                            height: grid(6);
                            width: grid(6);
                        }
                    }
                }
            `}</style>
            <div className="flex-center-row program-wrapper" key={alias}>
                {imgSrc && <img src={imgSrc} alt={alias} className={alias === AccreditationCodeEnum.MSA ? 'm-r-4' : ''}></img>}
                <div>{alias.toUpperCase()}</div>
            </div>
        </>
    );
};
export default ProgramIcon;
