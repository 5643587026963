import MLALogger from '@utils/logger';
import { graphql } from 'relay-hooks';

export const createAdminMutation = graphql`
    mutation createAdminMutation($envdAccountId: String!, $input: CreateAdminInput!) {
        createAdmin(envdAccountId: $envdAccountId, input: $input) {
            data
        }
    }
`;

const commitAsync = async (mutate: any, inputData: any, envdAccountId: String): Promise<Partial<any | undefined>> => {
    MLALogger.Log(['CreateAdmin', 'commitAsync', 'Sending'], { inputData });
    try {
        const data = await mutate({
            variables: { input: inputData, envdAccountId },
            onCompleted: (response: any, error: any) => {
                MLALogger.Log(['CreateAdmin', 'commitAsync'], { response, error });
                if (error) {
                    MLALogger.Log(['CreateAdmin', 'commitAsync'], { error }, 'error');
                } else {
                    MLALogger.Log(['CreateAdmin', 'commitAsync'], { response, data: response.createAdmin.data, request: inputData });
                }
            },
        });
        return data;
    } catch (error) {
        MLALogger.Log(['CreateAdmin'], error, 'error');
        throw error;
    }
};

export default commitAsync;
