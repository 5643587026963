import { IAnswer } from '@typings';
import deepmerge from 'deepmerge';

export const overwriteMerge = (destinationArray: any[], sourceArray: any[], options: any) => sourceArray;
export const combineMerge = (target: any, source: any, options: any) => {
    const destination = target.slice();

    for (let index = 0; index < source.length; index++) {
        const item = source[index];
        if (typeof destination[index] === 'undefined') {
            destination[index] = options.cloneUnlessOtherwiseSpecified(item, options);
        } else if (options.isMergeableObject(item)) {
            destination[index] = deepmerge(target[index], item, options);
        } else if (target.indexOf(item) === -1) {
            destination.push(item);
        }
    }
    return destination;
};

export const answerArrayMerge = (original: IAnswer[], appending: IAnswer[], _: any) => {
    let destination: IAnswer[] = [];
    const used = new Set();

    // First loop over original to find if there are any matches, if so replace with the one from appending
    // Keep track of what is unused and then append all those for the final output
    for (const item of original) {
        if (destination.some((x) => x.questionId === item.questionId && x.index === item.index)) {
            // Already have a copy, skip
            continue;
        }

        const matchingAppendingQuestions = appending.filter((x) => x.questionId === item.questionId);

        // It doesn't already exist, lets check the incoming payload to see if we need to use an update or not
        const matchingAtIndex = matchingAppendingQuestions.firstOrDefault((x) => x.index === item.index);
        if (matchingAtIndex) {
            // Store the fact that we used this so we can avoid appending it again later
            if (!used.has(matchingAtIndex)) {
                destination.push(matchingAtIndex!);
                used.add(matchingAtIndex);
            }
        } else {
            // If there are any index: null being appended, then we need to ignore anything existing for that questionId
            const matchingAtNull = matchingAppendingQuestions.firstOrDefault((x) => x.index === null);
            if (matchingAtNull) {
                // Store the fact that we used this so we can avoid appending it again later
                if (!used.has(matchingAtNull)) {
                    destination.push(matchingAtNull!);
                    used.add(matchingAtNull);
                }
            } else if (item.index === null && appending.some((x) => x.questionId === item.questionId)) {
                // If there are any index: null already existing, we should skip that and keep the one coming from appending
                const newItems = appending.filter((x) => x.questionId === item.questionId);
                for (const newItem of newItems) {
                    if (!used.has(newItem)) {
                        destination.push(newItem!);
                        used.add(newItem);
                    }
                }
            } else {
                // Keep this one
                destination.push(item);
            }
        }
    }

    const unused = appending.diffLeft([...used] as IAnswer[]);

    destination = [...destination, ...unused];

    return Array.from(destination);
};

export const combineIdMerge = (destination: any[], source: any[], options: any) => {
    // destination = consignment.forms
    // source = mutation forms
    const combined = [];
    for (let i = 0; i < source.length; i++) {
        combined.push({
            ...destination[i],
            ...source[i],
        });
    }
    return combined;
};
