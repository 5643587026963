import { ActionType } from '@state';
import { IStaticDirtyAnswer } from '@typings';
import { overwriteMerge } from '@utils/deepmerge-strategies';
import MLALogger from '@utils/logger';
import deepmerge from 'deepmerge';

export const StaticPendingAnswersActionTypes = {
    setStatic: 'setStatic',
    removeStatic: 'removeStatic',
    updateStatic: 'updateStatic',
    resetStatic: 'resetStatic',
};

export const StaticPendingAnswersInitialState: IStaticDirtyAnswer = {
    dirty: false,
    answers: {},
};

const reducer = (state: IStaticDirtyAnswer, action: ActionType) => {
    switch (action.type) {
        case StaticPendingAnswersActionTypes.setStatic:
            MLALogger.Log(['Store'], { message: 'Setting Static', state, action });
            return action.payload;
        case StaticPendingAnswersActionTypes.updateStatic:
            MLALogger.Log(['Store'], { message: 'Updating Static', state, action });
            return { answers: deepmerge(state.answers, action.payload, { arrayMerge: overwriteMerge }), dirty: action.payload.dirty ?? true };
        case StaticPendingAnswersActionTypes.resetStatic:
            MLALogger.Log(['Store'], { message: 'Resetting Static', state, action });
            return StaticPendingAnswersInitialState;
        default:
            return state;
    }
};

export default reducer;
