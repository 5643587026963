import * as React from 'react';
const SvgChevronForward = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="none" {...props} viewBox="0 0 30 30">
        <g clipPath="url(#chevron-forward_svg__a)">
            <path fill="#F8F8F8" d="M0 0h30v30H0z" />
            <path fill="#004976" d="m8.112 25.163 2.213 2.212L22.7 15 10.325 2.625 8.112 4.838 18.275 15z" />
        </g>
        <defs>
            <clipPath id="chevron-forward_svg__a">
                <path fill="#fff" d="M0 0h30v30H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SvgChevronForward;
