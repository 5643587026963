import Input, { InputType } from '@components/Form/Input';
import useUpdateDynamicValue from '@effects/useUpdateDynamicValue';
import useValidation from '@effects/useValidation';
import { useGlobalState } from '@state';
import { useGetDynamicAnswers } from '@utils/question-getter';
import { getIsRequired } from '@utils/question-validator';
import classnames from 'classnames';
import React, { useEffect } from 'react';

import { IDynamicQuestionProps } from './DynamicQuestion';

const DynamicDateMonthQuestion: React.FC<IDynamicQuestionProps> = ({ question, index }) => {
    const { isValid, checkIsValid, evaluated, errorMessage, resetIsValid } = useValidation();
    const [{ triggerValidatorCount }] = useGlobalState();
    const update = useUpdateDynamicValue();

    const isReadOnly = question.readOnly;
    const isRequired = getIsRequired(question.validators);

    // Prefer pending if it exists, fallback to original if not
    const answers = useGetDynamicAnswers(question, index);
    const value = answers?.firstOrDefault((answer) => answer.questionId === question.id && answer.index === index)?.value ?? '';

    useEffect(() => {
        if (!evaluated && value) {
            checkIsValid(question, value);
        }
    }, [evaluated, checkIsValid, question, value]);

    useEffect(() => {
        if (triggerValidatorCount > 0) {
            checkIsValid(question, value);
        }
        if (triggerValidatorCount === 0) {
            resetIsValid();
        }
    }, [triggerValidatorCount, checkIsValid, resetIsValid, question, value]);

    useEffect(() => {
        const resize = setTimeout(() => {
            // Send resize for auto resizing components
            // IE11
            let event = null;
            if (typeof Event === 'function') {
                event = new Event('resize');
            } else {
                event = document.createEvent('Event');
                event.initEvent('resize', true, true);
            }
            window.dispatchEvent(event);
        }, 100);
        return () => clearTimeout(resize);
    }, []);

    const customClassName = question.text.replace(/[ \\/ ]/gm, '_').toLocaleLowerCase();
    return (
        <>
            <style jsx>{`
                @import 'vars';
                .DateMonthField {
                    :global(.${customClassName}) {
                    }
                }
            `}</style>

            <Input
                errorText={errorMessage}
                labelText={question.text}
                className={classnames('DateMonthField', customClassName)}
                inputType={InputType.DateTimeNumber}
                value={value}
                onChange={(e) => {
                    checkIsValid(question, e.target.value);
                    update(question, index, e.target.value);
                }}
                isValid={isValid}
                isReadOnly={isReadOnly}
                autoFocus={false}
                required={isRequired}
            />
        </>
    );
};

export default DynamicDateMonthQuestion;
