export const currentPropertiesInString = Object.getOwnPropertyNames(String.prototype);

export const isValidString = (str: string | undefined) => (str ? str.trim() !== '' : false);

// write a function that will check if the message present a pair of double curly braces,
// if it does then replace the pair of double curly braces togeter with the string quoted with the value of the parameter
export function replaceCurlyBracesWithParameter(message: string, term: string, parameter: string | undefined) {
    let newMessage = message;
    if (message.includes('{{') && message.includes('}}') && parameter !== undefined) {
        newMessage = message.replace('{{' + term, parameter);
        newMessage = newMessage.replace('}}', '');
    }
    return newMessage;
}

export function singleLineString(strings: TemplateStringsArray, ...values: any) {
    // Interweave the strings with the
    // substitution vars first.
    let output = '';
    for (let i = 0; i < values.length; i++) {
        output += strings[i] + values[i];
    }
    output += strings[values.length];

    // Split on newlines.
    const lines = output.split(/(?:\r\n|\n|\r)/);

    // Rip out the leading whitespace.
    return lines
        .map((line) => {
            return line.replace(/^\s+/gm, '');
        })
        .join(' ')
        .trim();
}

export function multiLineString(strings: TemplateStringsArray, ...values: any) {
    // Interweave the strings with the
    // substitution vars first.
    let output = '';
    for (let i = 0; i < values.length; i++) {
        output += strings[i] + values[i];
    }
    output += strings[values.length];

    // Split on newlines.
    const lines = output.split(/(?:\r\n|\n|\r)/);

    // Rip out the leading whitespace.
    return lines
        .map((line) => {
            return line.replace(/^\s+/gm, '');
        })
        .join('\n')
        .trim();
}

export function noWhiteSpace(strings: TemplateStringsArray, ...placeholders: any) {
    const withSpace = strings.reduce((result, s, i) => result + placeholders[i - 1] + s);
    const withoutSpace = withSpace.replace(/$\n^\s*/gm, ' ');
    return withoutSpace;
}

if (!currentPropertiesInString.includes('splitOnArray')) {
    Object.defineProperty(String.prototype, 'splitOnArray', {
        value() {
            const splitProp = this.split('.');
            const arrIndex = splitProp.findIndex((sp: string) => sp.endsWith('[]'));

            if (arrIndex === -1) {
                return [this, null];
            }

            const propUpToArray = splitProp.slice(0, arrIndex + 1).join('.');
            const propEndOfArray = splitProp.slice(arrIndex + 1).join('.');
            return [propUpToArray, propEndOfArray];
        },
    });
}

if (!currentPropertiesInString.includes('upperCaseFirst')) {
    Object.defineProperty(String.prototype, 'upperCaseFirst', {
        value() {
            return this[0].toUpperCase() + this.slice(1);
        },
    });
}

if (!currentPropertiesInString.includes('lowerCaseFirst')) {
    Object.defineProperty(String.prototype, 'lowerCaseFirst', {
        value() {
            return this[0].toLowerCase() + this.slice(1);
        },
    });
}

if (!currentPropertiesInString.includes('convertToAMPM')) {
    Object.defineProperty(String.prototype, 'convertToAMPM', {
        value() {
            // Splitting the input time into hours and minutes
            const [hours, minutes]: [string, number] = this.split(':');

            // Converting hours to a number
            const hoursNum = parseInt(hours, 10);

            // Determining if it's AM or PM
            const period = hoursNum >= 12 ? 'PM' : 'AM';

            // Converting hours to 12-hour format
            const hours12 = hoursNum > 12 ? hoursNum - 12 : hoursNum === 0 ? 12 : hoursNum;

            // Adding leading zero if needed
            const hours12Formatted = hours12 < 10 ? `0${hours12}` : hours12;

            // Combining all parts into AM/PM format
            return `${hours12Formatted}:${minutes} ${period}`;
        },
    });
}

export function splitByLastOccurrence(str: string, substring: string) {
    const lastIndex = str?.lastIndexOf(substring);

    const beforeString = str?.slice(0, lastIndex);

    return beforeString;
}
