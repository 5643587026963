/* eslint-disable no-useless-escape */

import { IAnswer, IDynamicQuestion, IDynamicQuestionValidator, ITrigger } from '@typings';

import { compareArray } from './array-extensions';
import { COMBO_PARAMETERS, REQUIRED_WHEN_VALIDATOR_TYPE, UNIQUE_WHEN_VALIDATOR_TYPE } from './constants';
import MLALogger from './logger';
import { getAllChildQuestions } from './question-getter';
import { replaceCurlyBracesWithParameter } from './string-extensions';

export const EMAIL_REGEX = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

const BOOLEAN_VALUE = ['True', 'False', 'Yes', 'No'];

export function getIsRequired(validators: IDynamicQuestionValidator[]): boolean {
    return validators.some((validator) => validator.type === 'required');
}

export function validateTrigger(triggers: ITrigger[] | null, pendingAnswers: IAnswer[] | null, originalAnswers: IAnswer[] | null): boolean {
    if (triggers) {
        return triggers.every((trigger) => {
            let answer = pendingAnswers?.find((ans) => ans.questionId === trigger.questionId) ?? originalAnswers?.find((ans) => ans.questionId === trigger.questionId);
            if (answer) {
                return answer.value === trigger.value;
            }
            return false;
        });
    }
    return true;
}

export function getErrorMessage(validators: IDynamicQuestionValidator[], value: string, selectedIndex: number = -1, pendingAnswers?: IAnswer[]): string[] {
    const logTags = ['QuestionValidator'];
    MLALogger.Log(logTags, { validators, value });

    const errors: string[] = [];

    validators.every((validator) => {
        if (validator.type === 'required') {
            if (BOOLEAN_VALUE.includes(value)) {
                // It has a value? Is there is a distinction because saying yes vs not having a value
                return true;
            }
            const requiredIsValid = Boolean(value);

            if (!requiredIsValid) {
                errors.push(validator.errorMessage ?? 'Required field');
            }

            return requiredIsValid;
        }

        if (validator.type === UNIQUE_WHEN_VALIDATOR_TYPE) {
            const metaData = JSON.parse(validator?.parameter);
            let arrayofIAnswer: IAnswer[][] = [];
            metaData.uniqueComboId.forEach((id: string) => {
                const answer = pendingAnswers?.firstOrDefault((a) => a?.questionId === id && a.index === selectedIndex)?.value;
                const arrayFilterdByAnswer = pendingAnswers?.filter((a) => a?.questionId === id && a.index !== selectedIndex && a?.value === answer);
                if (arrayFilterdByAnswer !== undefined) arrayofIAnswer.push(arrayFilterdByAnswer);
            });

            let flag = !compareArray(arrayofIAnswer);
            if (!flag) {
                // here we assume this is validator.errormessge A transporter with email {{email}} and vehicle rego {{rego}} has already been added.
                let errmsg = validator.errorMessage ? validator.errorMessage : 'Must unqiue';
                for (let key in COMBO_PARAMETERS) {
                    errmsg = replaceCurlyBracesWithParameter(errmsg, COMBO_PARAMETERS[key], pendingAnswers?.firstOrDefault((a) => a?.questionId === key && a.index === selectedIndex)?.value);
                }
                errors.push(errmsg ?? ' Must unique');
            }
            return flag;
        }
        if (validator.type === REQUIRED_WHEN_VALIDATOR_TYPE) {
            const declarionQuestionId = JSON.parse(validator?.parameter).questionId;
            if (pendingAnswers?.firstOrDefault((a: any) => a?.questionId === declarionQuestionId && a?.index === selectedIndex)?.value === 'True') {
                const requiredWhenIsValid = Boolean(value);
                if (!requiredWhenIsValid) {
                    errors.push(validator.errorMessage ?? 'Required field');
                }
                return requiredWhenIsValid;
            }
        }

        if (validator.type === 'min') {
            // Try to convert to a number, then check the value
            try {
                const convertedValue = Number.parseInt(value, 10);
                const convertedParameter = Number.parseInt(validator.parameter, 10);
                const isValid = convertedValue >= convertedParameter;
                if (!isValid) {
                    errors.push(validator.errorMessage ?? `Must be at least ${convertedParameter}`);
                }
                return isValid;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'max') {
            // Try to convert to a number, then check the value
            try {
                const convertedValue = Number.parseInt(value, 10);
                const convertedParameter = Number.parseInt(validator.parameter, 10);
                const isValid = convertedValue <= convertedParameter;
                if (!isValid) {
                    errors.push(validator.errorMessage ?? `Must be at most ${convertedParameter}`);
                }
                return isValid;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'minLength') {
            // Try to convert to a number, then check the value
            try {
                const convertedParameter = Number.parseInt(validator.parameter, 10);
                const isValid = value?.length >= convertedParameter;
                if (!isValid) {
                    errors.push(validator.errorMessage ?? `Must be at least ${convertedParameter}`);
                }
                return isValid;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'maxLength') {
            // Try to convert to a number, then check the value
            try {
                const convertedParameter = Number.parseInt(validator.parameter, 10);
                const isValid = value?.length <= convertedParameter;
                if (!isValid) {
                    errors.push(validator.errorMessage ?? `Must be at most ${convertedParameter}`);
                }
                return isValid;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'minDate') {
            // Try to convert to a number, then check the value
            try {
                const convertedValue = new Date(value);

                // Param is pre-converted to a ISO string
                const parameterDate = new Date(validator.parameter);

                const isValid = convertedValue >= parameterDate;
                if (!isValid) {
                    errors.push(validator.errorMessage ?? `Must be at least ${parameterDate.toDateString()}`);
                }
                return isValid;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error, validator }, 'error');
                return false;
            }
        }

        if (validator.type === 'maxDate') {
            try {
                const convertedValue = new Date(value);
                // Param is pre-converted to a ISO string
                const parameterDate = new Date(validator.parameter);

                const isValid = convertedValue <= parameterDate;
                if (!isValid) {
                    errors.push(validator.errorMessage ?? `Must be at most ${parameterDate.toDateString()}`);
                }
                return isValid;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'minYear') {
            try {
                const convertedValue = Number.parseInt(value.split('-').first()!, 10);
                const convertedParameter = Number.parseInt(validator.parameter, 10);
                const isValid = convertedValue >= convertedParameter;
                if (!isValid) {
                    errors.push(validator.errorMessage ?? `Must be at least ${convertedParameter}`);
                }
                return isValid;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'maxYear') {
            try {
                const convertedValue = Number.parseInt(value.split('-').first()!, 10);
                let convertedParameter: number = -1;
                // maxYear also can contain 'now'
                if (validator.parameter === 'now') {
                    convertedParameter = new Date().getUTCFullYear();
                } else {
                    convertedParameter = Number.parseInt(validator.parameter, 10);
                }
                const isValid = convertedValue <= convertedParameter;
                if (!isValid) {
                    errors.push(validator.errorMessage ?? `Must be at most ${convertedParameter}`);
                }
                return isValid;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'regex') {
            try {
                // Replace any stray `/` since it conflicts with the built in version in RegExp
                const convertedParameter = new RegExp(validator.parameter?.replace(/\//g, ''));
                const isValid = convertedParameter.test(value);
                if (!isValid) {
                    errors.push(validator.errorMessage ?? `Must match ${convertedParameter}`);
                }
                return isValid;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'maxArrayCount') {
            // MLALogger.Log(logTags, { message: `${validator.type} error`, value });

            // HACK: Clean this up later
            if (value === 'invalid') {
                // errors.push(validator.errorMessage ?? `If the livestock are still within a WHP or ESI, you must provide details`);
                errors.push(`If the livestock are still within a WHP or ESI, you must provide details`);
                return false;
            }

            return true;
        }

        return true;
    });

    MLALogger.Log(logTags, { message: `Errored with these error messages`, errors });
    return errors;
}

export function validate(validators: IDynamicQuestionValidator[], value: string, question?: any, selectedIndex: number = -1, pendingAnswers?: IAnswer[]) {
    const logTags = ['QuestionValidator'];
    const valid = validators.every((validator) => {
        if (validator.type === 'required') {
            if (BOOLEAN_VALUE.includes(value)) {
                // It has a value? Is there is a distinction because saying yes vs not having a value
                return true;
            }
            return Boolean(value);
        }

        if (validator.type === REQUIRED_WHEN_VALIDATOR_TYPE) {
            const declarionQuestionId = JSON.parse(validator?.parameter).questionId;
            if (pendingAnswers?.firstOrDefault((a: any) => a?.questionId === declarionQuestionId && a.index === selectedIndex)?.value === 'True') {
                return Boolean(value);
            }
        }
        if (validator.type === UNIQUE_WHEN_VALIDATOR_TYPE) {
            const metaData = JSON.parse(validator?.parameter);
            let arrayofIAnswer: IAnswer[][] = [];
            metaData.uniqueComboId.forEach((id: string) => {
                const answer = pendingAnswers?.firstOrDefault((a) => a?.questionId === id && a.index === selectedIndex)?.value;
                const arrayFilterdByAnswer = pendingAnswers?.filter((a) => a?.questionId === id && a.index !== selectedIndex && a?.value === answer);
                if (arrayFilterdByAnswer !== undefined) arrayofIAnswer.push(arrayFilterdByAnswer);
            });
            let flag = !compareArray(arrayofIAnswer);
            return flag;
        }

        if (validator.type === 'min') {
            // Try to convert to a number, then check the value
            try {
                const convertedValue = Number.parseInt(value, 10);
                const convertedParameter = Number.parseInt(validator.parameter, 10);
                return convertedValue >= convertedParameter;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'max') {
            // Try to convert to a number, then check the value
            try {
                const convertedValue = Number.parseInt(value, 10);
                const convertedParameter = Number.parseInt(validator.parameter, 10);
                return convertedValue <= convertedParameter;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'minLength') {
            // Try to convert to a number, then check the value
            try {
                const convertedParameter = Number.parseInt(validator.parameter, 10);
                return value?.length >= convertedParameter;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'maxLength') {
            // Try to convert to a number, then check the value
            try {
                const convertedParameter = Number.parseInt(validator.parameter, 10);
                return value?.length <= convertedParameter;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'minDate') {
            // Try to convert to a number, then check the value
            try {
                const convertedValue = new Date(value);

                // Param is pre-converted to a ISO string
                const parameterDate = new Date(validator.parameter);

                return convertedValue >= parameterDate;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error, validator }, 'error');
                return false;
            }
        }

        if (validator.type === 'maxDate') {
            try {
                const convertedValue = new Date(value);
                // Param is pre-converted to a ISO string
                const parameterDate = new Date(validator.parameter);

                return convertedValue <= parameterDate;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'minYear') {
            try {
                const convertedValue = Number.parseInt(value.split('-').first()!, 10);
                const convertedParameter = Number.parseInt(validator.parameter, 10);
                return convertedValue >= convertedParameter;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'maxYear') {
            try {
                const convertedValue = Number.parseInt(value.split('-').first()!, 10);
                let convertedParameter: number = -1;
                // maxYear also can contain 'now'
                if (validator.parameter === 'now') {
                    convertedParameter = new Date().getUTCFullYear();
                } else {
                    convertedParameter = Number.parseInt(validator.parameter, 10);
                }
                return convertedValue <= convertedParameter;
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'regex') {
            try {
                // Replace any stray `/` since it conflicts with the built in version in RegExp
                const convertedParameter = new RegExp(validator.parameter?.replace(/\//g, ''));
                return convertedParameter.test(value);
            } catch (error) {
                MLALogger.Log(logTags, { message: `${validator.type} error`, error }, 'error');
                return false;
            }
        }

        if (validator.type === 'maxArrayCount') {
            MLALogger.Log(logTags, { message: `${validator.type} error`, value });
            // Return true for now while we haven't handled it
            return true;
        }

        return true;
    });

    MLALogger.Log(logTags, { validators, value, valid });

    return valid;
}

export function validateRepeatable(question: IDynamicQuestion, answers: IAnswer[], deepScan: boolean): boolean {
    if (question.type !== 'REPEATABLE') {
        return false;
    }

    const isRepeatableRequired = getIsRequired(question.validators);

    // Needs to have 1 child + parent is a valid trigger
    // If it had a parent, then we HAVE to have at least 1 answer
    const hasAParent = Boolean(question.triggers);
    const hasAtLeastOneChildAnswer = question.childQuestions.some((cq) => answers.some((dpa) => dpa.questionId === cq.id && dpa.value !== null));

    // Deep scan means that we can exit early if this is a trigger question
    if (hasAParent && isRepeatableRequired) {
        return hasAtLeastOneChildAnswer;
    }

    // Now we can check against the children
    if (deepScan) {
        const allChildQuestions = getAllChildQuestions(question);
        const hasAllRequiredAnswers = allChildQuestions
            .filter((cq) => getIsRequired(cq.validators))
            .every((cq) => {
                // Not fully accurate as we aren't using index tracking but should be ok
                // because anything invalid would be wrong anyway
                const answer = answers.firstOrDefault((dpa) => dpa.questionId === cq.id);

                // Check acceptable answers if they exist, otherwise just use value in answers
                if (cq.acceptableAnswers?.length > 0 && answer) {
                    return cq.acceptableAnswers.some((aa) => aa.value === answer.value);
                }

                return Boolean(answer);
            });

        return hasAllRequiredAnswers;
    }

    // Fallback to true if nothing was given or correct
    return true;
}

export const filterByRequired = (q: IDynamicQuestion) => getIsRequired(q.validators);

export const filterByAnswered = (answers: IAnswer[]) => (q: IDynamicQuestion) => {
    const answer = answers.firstOrDefault((dpa) => dpa.questionId === q.id);

    if (q.acceptableAnswers?.length > 0 && answer) {
        return q.acceptableAnswers.some((aa) => aa.value === answer.value);
    }

    return Boolean(answer);
};

export const filterByUnanswered = (answers: IAnswer[]) => (q: IDynamicQuestion) => {
    const answer = answers.firstOrDefault((dpa) => dpa.questionId === q.id);

    if (q.acceptableAnswers?.length > 0 && answer) {
        return !q.acceptableAnswers.some((aa) => aa.value === answer.value);
    }

    return !Boolean(answer);
};

/* eslint-enable no-useless-escape */
