import { useUserState } from '@common/context/userContext';
import Alert, { AlertType } from '@components/Alert';
import Button from '@components/Button';
import ConfirmModal, { ConfirmModalRef } from '@containers/Consignments/components/ConfirmModal';
import useUserDetails from '@effects/useUserdetails';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';

import AddAdmin from './AddAdmin';
import LinkAccount from './LinkAccount';

export interface ResponseContentType {
    title: string;
    successMessage: string | undefined;
    warningMessage: string | undefined;
}
const AdminPage = () => {
    const confirmSubmitRef = useRef<ConfirmModalRef>();
    const [{ user }] = useUserState();
    const { fetchUserDetails } = useUserDetails();
    const [activeTab, setActiveTab] = useState(0);
    const history = useHistory();
    const defaultResponseContent: ResponseContentType = {
        title: '',
        successMessage: '',
        warningMessage: '',
    };
    const [responseContent, setResponseContent] = useState<ResponseContentType>(defaultResponseContent);
    const onSuccess = () => {
        confirmSubmitRef.current?.show();
    };

    const onTabSwitch = (index: number) => {
        setResponseContent(defaultResponseContent);
        setActiveTab(index);
    };

    return (
        <>
            <style jsx>
                {`
                    @import 'vars';
                    @import 'utils';
                    .parentContainer {
                        padding: 28px 60px;
                    }

                    .mainContainer {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        margin-top: 50px;
                    }

                    .child-container {
                        width: 600px;
                    }

                    .child-container-content {
                        background-color: white;
                        padding: 40px;
                        border: 1px solid $color-border;
                        border-top: 2px solid $color-secondary;
                    }

                    .tabs-div {
                        display: flex;
                        gap: 10px;
                    }

                    .child-container-tab {
                        background-color: #f2f2f2;
                        border: 2px solid $color-border;
                        border-bottom: 0px;
                        padding: 15px;
                        border-radius: 5px 5px 0px 0px;
                        cursor: pointer;

                        .tabs-label {
                            color: #848a94;
                        }

                        &.selected {
                            border: 2px solid $color-secondary;
                            background-color: white;
                            border-bottom: 0px;
                            .tabs-label {
                                color: $color-secondary;
                            }
                        }
                    }

                    h1 {
                        margin: grid(10) 0;
                        color: $color-secondary;
                        margin: 10px 0px;
                        @media (max-width: $sm-min) {
                            margin: grid(6) 0;
                        }
                    }

                    @media (max-width: $sm-min) {
                        .child-container {
                            min-width: 300px;
                        }
                        .child-container-content {
                            background-color: white;
                            padding: 30px;
                            border: 1px solid $color-border;
                            border-top: 1px solid $color-secondary;
                        }

                        .parentContainer {
                            padding: 28px 0;
                        }
                    }
                `}
            </style>
            <div className="parentContainer">
                <div className="flex-row flex-between">
                    <div className="flex-column flex-between">
                        <h1 data-cy="welcome-heading">{`Welcome, ${user?.claims?.firstName}`}</h1>
                    </div>
                    <div className="flex-center-row flex-between row">
                        <Button buttonType="primary" onClick={() => history.push('/consignments')}>
                            Back to Consignments
                        </Button>
                    </div>
                </div>
                <div className="mainContainer">
                    <div className="child-container">
                        <div className="tabs-div">
                            <div
                                className={classNames('child-container-tab', {
                                    selected: activeTab === 0,
                                })}
                                onClick={() => onTabSwitch(0)}
                            >
                                <h3 className="tabs-label">Link Accounts</h3>
                            </div>
                            <div
                                className={classNames('child-container-tab', {
                                    selected: activeTab === 1,
                                })}
                                onClick={() => onTabSwitch(1)}
                            >
                                <h3 className="tabs-label">Add eNVD Admin</h3>
                            </div>
                        </div>
                        <div className="child-container-content">
                            {responseContent.warningMessage && <Alert type={AlertType.Error} title={responseContent.warningMessage} />}
                            {responseContent.successMessage && <Alert type={AlertType.Success} title={responseContent.successMessage} />}
                            {activeTab === 0 ? (
                                <LinkAccount user={user} setResponseContent={setResponseContent} onSuccess={onSuccess} />
                            ) : (
                                <AddAdmin user={user} setResponseContent={setResponseContent} onSuccess={onSuccess} />
                            )}
                        </div>
                    </div>
                    <ConfirmModal
                        actions={[
                            {
                                style: 'secondary',
                                text: 'Stay on this page',
                                action: async () => {
                                    await fetchUserDetails(user.token);
                                    confirmSubmitRef.current?.hide();
                                },
                            },
                            {
                                style: 'primary',
                                text: 'Back to Consignments',
                                action: async () => {
                                    await fetchUserDetails(user.token);
                                    history.push('/consignments');
                                },
                            },
                        ]}
                        ref={confirmSubmitRef}
                        modalId={`confirm-modal`}
                        title={responseContent.title}
                    >
                        <p>{responseContent.successMessage}</p>
                    </ConfirmModal>
                </div>
            </div>
        </>
    );
};

export default AdminPage;
