import * as React from 'react';
const SvgList = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={39} height={44} {...props} viewBox="0 0 39 44">
        <g fill="currentColor">
            <path d="M33.418 2.602c1.735 0 3.14 1.374 3.14 3.069v29.567l-6.201 6.312H6.214c-1.731 0-3.138-1.381-3.138-3.075V2.602zM.984.552v37.922c0 2.826 2.343 5.125 5.23 5.125h25.033l.309-.314 6.801-6.922.293-.298V5.671c0-2.828-2.343-5.119-5.232-5.119z" />
            <path d="M29.237 30.275c-1.737 0-3.143 1.369-3.143 3.072v6.151c0 .566.469 1.025 1.046 1.025s1.047-.459 1.047-1.025v-6.151c0-.568.466-1.022 1.05-1.022h4.705c.577 0 1.046-.459 1.046-1.024 0-.566-.469-1.025-1.046-1.025h-4.705zM30.88 9.777c.578 0 1.047-.459 1.047-1.025s-.469-1.025-1.047-1.025H9.354c-.578 0-1.047.459-1.047 1.025s.469 1.025 1.047 1.025zm0 6.149c.578 0 1.047-.458 1.047-1.024s-.469-1.025-1.047-1.025H9.354c-.578 0-1.047.458-1.047 1.025s.469 1.024 1.047 1.024zm0 7.175c.578 0 1.047-.459 1.047-1.025s-.469-1.024-1.047-1.024H9.354c-.578 0-1.047.459-1.047 1.024 0 .566.469 1.025 1.047 1.025z" />
        </g>
    </svg>
);
export default SvgList;
