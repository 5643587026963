import { useEffect } from 'react';

const prefix = 'eNVD - ';

const useWindowTitle = (title: string) => {
    useEffect(() => {
        document.title = `${prefix}${title}`;
    });
};

export { useWindowTitle };
export default useWindowTitle;
