import { useEffect, useState } from 'react';

export default function useDebounceValue<T>(delay: number, value?: T, defaultValue?: T): T | undefined {
    const [debouncedValue, setDebouncedValue] = useState<T | undefined>(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue ?? defaultValue;
}

export function useDebounceFn(delay: number, fn: () => void, ...values: any): void {
    useEffect(() => {
        console.count('[EffectCount][AutoSave] useDebounceFn');
        const handler = setTimeout(() => fn(), delay);
        return () => clearTimeout(handler);
        // eslint-disable-next-line
    }, [delay, fn, ...values]);
}
