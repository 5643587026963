/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateOrSaveUserMappingInput = {
    clientMutationId?: string | null;
    consignmentNumber?: string | null;
    role?: string | null;
    mappings?: Array<ConsignmentUserMappingInput | null> | null;
};
export type ConsignmentUserMappingInput = {
    mappingId?: number | null;
    email?: string | null;
    consignmentNumber: string;
    role: string;
    pic?: string | null;
    numOfHeads?: number | null;
};
export type createUserMappingMutationVariables = {
    input: CreateOrSaveUserMappingInput;
    envdAccountId: string;
};
export type createUserMappingMutationResponse = {
    readonly createOrSaveUserMapping: {
        readonly data: ReadonlyArray<{
            readonly email: string | null;
            readonly firstName: string | null;
            readonly lastName: string | null;
            readonly consignmentNumber: string;
            readonly role: string;
            readonly mappingId: number | null;
            readonly pic: string | null;
            readonly numOfHeads: number | null;
        } | null> | null;
    } | null;
};
export type createUserMappingMutation = {
    readonly response: createUserMappingMutationResponse;
    readonly variables: createUserMappingMutationVariables;
};



/*
mutation createUserMappingMutation(
  $input: CreateOrSaveUserMappingInput!
  $envdAccountId: String!
) {
  createOrSaveUserMapping(input: $input, envdAccountId: $envdAccountId) {
    data {
      email
      firstName
      lastName
      consignmentNumber
      role
      mappingId
      pic
      numOfHeads
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envdAccountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateOrSaveUserMapping",
    "kind": "LinkedField",
    "name": "createOrSaveUserMapping",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ConsignmentUserMappingType",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "consignmentNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mappingId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pic",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numOfHeads",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createUserMappingMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createUserMappingMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "525551522542c60b1601644add3f3695",
    "id": null,
    "metadata": {},
    "name": "createUserMappingMutation",
    "operationKind": "mutation",
    "text": "mutation createUserMappingMutation(\n  $input: CreateOrSaveUserMappingInput!\n  $envdAccountId: String!\n) {\n  createOrSaveUserMapping(input: $input, envdAccountId: $envdAccountId) {\n    data {\n      email\n      firstName\n      lastName\n      consignmentNumber\n      role\n      mappingId\n      pic\n      numOfHeads\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5c19d41c091b835b21c507a7a392fb39';
export default node;
