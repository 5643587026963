import { ActionType } from '@state';

export const TVCActionTypes = {
    increaseTriggerValidatorCount: 'increaseTriggerValidatorCount',
    resetTriggerValidatorCount: 'resetTriggerValidatorCount',
};

export const TriggerValidatorCountInitialState: number = 0;

const reducer = (state: number, action: ActionType) => {
    switch (action.type) {
        case TVCActionTypes.increaseTriggerValidatorCount:
            return state + 1;
        case TVCActionTypes.resetTriggerValidatorCount:
            return TriggerValidatorCountInitialState;
        default:
            return state;
    }
};

export default reducer;
