import { SourceReceiver } from '@assets/icons';
import Button from '@components/Button';
import useRoleValidation from '@effects/useRoleValidation';
import { actionTypes, useGlobalState } from '@state';
import { IBuyerFields } from '@typings';
import { MOBILE_OR_TABLET_WIDTH } from '@utils/constants';
import { ConsignmentStatus } from '@utils/enum-transformers';
import { RoleTypeEnum } from '@utils/enums';
import { isEmpty } from 'lodash';
import { MutableRefObject, useEffect, useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useMutation, useQuery } from 'relay-hooks';

import createUserMapping, { CreateUserMappingMutation } from '../mutations/createUserMapping';
import { ConsignmentUserMappingQuery } from '../queries/ConsignmentUserMappingQuery';
import AddBuyerModal, { AddBuyerModalRef } from './AddBuyerModal';

interface BuyerPanelProps {
    consignmentNumber: string;
    buyerModalRef: MutableRefObject<AddBuyerModalRef | undefined>;
    status: string | null;
    envdAccountId: string;
}

const BuyerPanel = ({ consignmentNumber, buyerModalRef, status, envdAccountId }: BuyerPanelProps) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: MOBILE_OR_TABLET_WIDTH });
    const [{ optionsState }, dispatch] = useGlobalState();
    const { hasRole } = useRoleValidation();
    const buyersData = useQuery<any>(ConsignmentUserMappingQuery, { id: consignmentNumber, role: RoleTypeEnum.BUYER, envdAccountId }, { fetchPolicy: 'network-only' });
    const [mutate] = useMutation(CreateUserMappingMutation);

    const [buyers, setBuyers] = useState<IBuyerFields[]>([]);

    useEffect(() => {
        setBuyers(buyersData?.data?.consignmentUserMapping || []);
    }, [buyersData]);

    useLayoutEffect(() => {
        if (optionsState.isOpenBuyerModal) {
            buyerModalRef.current?.show();
            dispatch({ type: actionTypes.generic.resetOptionStateAction });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsState, dispatch]);

    const deleteBuyer = (mappingId: number | undefined | null) => {
        let tempBuyers = [...buyers];
        if (mappingId) {
            tempBuyers = tempBuyers
                .filter((buyer) => Number(buyer.mappingId) !== mappingId)
                .map((val: IBuyerFields) => {
                    return {
                        mappingId: val.mappingId,
                        pic: val.pic,
                        numOfHeads: val.numOfHeads,
                        email: val?.email || '',
                        consignmentNumber: val.consignmentNumber,
                        role: val.role,
                    };
                });
            const response = createUserMapping(mutate, { consignmentNumber: consignmentNumber, role: RoleTypeEnum.BUYER, mappings: tempBuyers || [] }, envdAccountId);
            response.then((mappings) => {
                setBuyers(mappings);
            });
        }
    };

    return (
        <>
            <div className="buyer-panel box">
                <div className="panel-heading flex-row flex-between p-v-16 p-h-16">
                    <div className="flex-center-row">{status === ConsignmentStatus.SUBMITTED ? <h2>Buyer Details</h2> : <h2>Your Buyer Details</h2>}</div>

                    {status === ConsignmentStatus.SUBMITTED && hasRole(RoleTypeEnum.FACILITATOR) && (
                        <>
                            <Button className="print-button" buttonType="secondary" buttonSize={isTabletOrMobile ? 'small' : 'normal'} onClick={() => buyerModalRef.current?.show()}>
                                Add Buyer
                            </Button>
                            <AddBuyerModal ref={buyerModalRef} buyersList={buyers ?? []} setBuyers={setBuyers} consignmentNumber={consignmentNumber} envdAccountId={envdAccountId} />
                        </>
                    )}
                </div>
                <div className=" flex-column">
                    {buyers?.map((buyer: IBuyerFields) => {
                        return (
                            <div key={buyer.mappingId} className="buyer-details flex-row p-v-16">
                                <div className="buyer-icon flex-center-row p-v-16 p-h-16">
                                    <SourceReceiver className="buyer-icon" />
                                </div>
                                <div className="flex-column buyer-name">
                                    {!isEmpty(buyer.firstName) && !isEmpty(buyer.lastName) && (
                                        <h4 className="name">
                                            {buyer?.firstName}&nbsp;{buyer?.lastName}
                                        </h4>
                                    )}
                                    <div className="pic-text">{buyer?.pic}</div>
                                    {!isEmpty(buyer.email) && (
                                        <div className="flex-column email">
                                            <div className="small grey-text m-t-4">{buyer?.email}</div>
                                        </div>
                                    )}
                                </div>
                                <div className="flex-column sold grey-text">{`${buyer.numOfHeads} head sold`}</div>

                                {status === ConsignmentStatus.SUBMITTED && hasRole(RoleTypeEnum.FACILITATOR) && (
                                    <div className="flex-row p-h-16">
                                        <Button buttonType="link" onClick={() => deleteBuyer(buyer?.mappingId)}>
                                            <label className="small remove-text m-r-8">Remove</label>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <style jsx>
                {`
                    @import 'mixins';
                    @import 'vars';
                    @import 'layout';

                    :global(.print-button) {
                        border-color: $isc-blue !important;
                        &:hover {
                            background-color: $isc-blue !important;
                        }
                    }

                    .box {
                        box-sizing: border-box;
                        background-color: $color-white;
                        border: 1px solid $grey-border;
                        border-radius: 4px;
                        @media (prefers-color-scheme: dark) {
                            background-color: darken($color-white, 80%);
                        }
                    }

                    .buyer-panel {
                        margin-bottom: 20px;
                        .buyer-details {
                            border-top: 1px solid $grey-border;
                        }
                        .buyer-name {
                            flex: auto;
                            justify-content: center;
                            flex: 4;
                        }
                        .sold {
                            flex: auto;
                            justify-content: center;
                            flex: 3;
                        }
                        .name,
                        .email {
                            word-break: break-word;
                        }
                    }

                    .grey-text {
                        color: $color-subtitle;
                        cursor: inherit;
                    }

                    .buyer-icon {
                        :global(svg) {
                            width: $icon-xl;
                            height: $icon-xl;
                        }
                    }

                    .pic-text {
                        color: $isc-blue;
                    }

                    .remove-text {
                        color: $color-error;
                        cursor: inherit;
                    }
                `}
            </style>
        </>
    );
};

export default BuyerPanel;
