import { Lpa, Menu } from '@assets/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { WarningsContext } from '@common/context/warningsContext';
import ClickableIcon from '@components/ClickableIcon';
import Config from '@config';
import useUserDetails from '@effects/useUserdetails';
import { DESKTOP_WIDTH } from '@utils/constants';
import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import { Offline } from 'react-detect-offline';
import { useMediaQuery } from 'react-responsive';

import NavigationBar from './NavigationBar';

interface Props {
    sticky?: boolean;
}

const Header: React.FC<Props> = ({ sticky = true }) => {
    const stickyClass = sticky ? 'sticky' : '';
    const [menuOpen, setMenuOpen] = useState(false);
    const { isAuthenticated } = useAuth0();
    const { isLoading } = useUserDetails();
    const [showWarning, setShowWarning] = useContext(WarningsContext);
    const isTabletOrMobile = useMediaQuery({ maxWidth: DESKTOP_WIDTH });

    const isLoggedIn = Boolean(isAuthenticated && !isLoading);

    if (showWarning) {
        setTimeout(() => setShowWarning(false), 2500);
    }

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                $headerHeight: grid(13);

                .Header {
                    background-color: $color-white;
                    color: $color-copy;
                    min-height: $headerHeight;
                    padding: 0 grid(4);
                    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);

                    @media (prefers-color-scheme: dark) {
                        background-color: $color-black;
                        color: $color-white;
                    }

                    &.sticky {
                        position: fixed;
                        width: 100%;
                        top: 0;
                        z-index: 8;
                    }

                    &--Inner {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: $headerHeight;

                        @media (min-width: $sm-max) {
                            max-width: $container-maxwidth;
                            margin: 0 auto;
                        }
                    }

                    :global(.nav-toggle) {
                        width: grid(6);
                        cursor: pointer;
                        z-index: 1;
                        color: $color-copy;

                        @media (prefers-color-scheme: dark) {
                            color: $color-white;
                        }
                    }

                    .col-hidden-sm {
                        display: flex;
                        flex-direction: column;
                        padding-right: grid(4);
                    }

                    .user-container {
                        display: flex;
                        flex-direction: row;
                        flex-grow: 2;
                        justify-content: flex-end;
                        align-items: center;
                    }

                    @media (max-width: $lg-max+1) {
                        .col-hidden-sm {
                            display: none;
                        }

                        .offline {
                            position: fixed;
                            right: grid(2);
                        }

                        .title {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: center;

                            :global(svg) {
                                text-align: center;
                                margin-left: grid(-1);
                            }
                        }

                        .menu-icon-padding {
                            padding-right: grid(6);
                        }
                    }
                }

                .Header-Warning {
                    top: $headerHeight;
                    width: 100%;
                    background: $color-error;
                    color: $color-white;
                    padding: grid(1) 0;
                    text-align: center;
                    position: fixed;
                    z-index: 10;
                }
            `}</style>
            <header
                className={classnames('Header flex-center-row flex-between', stickyClass, {
                    'Header--Desktop': !isTabletOrMobile,
                    'Header--Mobile': isTabletOrMobile,
                })}
            >
                <div className="Header--Inner">
                    {isLoggedIn && isTabletOrMobile && <ClickableIcon alt="Menu" onClick={() => setMenuOpen(true)} icon={<Menu />} classNames={'nav-toggle'} />}
                    {isLoggedIn && !isTabletOrMobile && (
                        <span className="title menu-icon-padding">
                            <Lpa />
                        </span>
                    )}
                    <NavigationBar menuOpen={menuOpen} closeAction={() => setMenuOpen(false)} />
                </div>
            </header>
            {Config.FLAG_OFFLINE_CAPABLE && (
                <Offline polling={{ url: Config.POLLING_URL, interval: Config.POLLING_INTERVAL, enabled: true }}>
                    <div className="Header-Warning h6 midbold">You are currently offline, changes may not be saved</div>
                </Offline>
            )}
            {showWarning && <div className="Header-Warning h6 midbold">An error occurred, changes may not be saved.</div>}
        </>
    );
};

export default Header;
