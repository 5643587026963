import { getDefaultAccount } from '@effects/useUserdetails';
import { ActionType } from '@state';
import { accounts, IAccountDetails, IUser, IUserClaims } from '@typings';
import { getFilteredUserDetails } from '@utils/account';
import { AccountTypeEnum, RoleTypeEnum } from '@utils/enums';

export const UserActionTypes = {
    setUser: 'setUser',
    setUserToken: 'setUserToken',
    setUserClaims: 'setUserClaims',
    resetUserParam: 'resetUserParam',
    setAccount: 'setAccount',
};

export function UserInitialState(): IUser {
    let token: string | null = null;
    let claims: IUserClaims | null = null;
    let accountDetails: IAccountDetails | null = null;

    return {
        token: token!!,
        claims: claims!!,
        accountDetails: accountDetails!!,
    };
}

export const mapToAccountDetails = (account: accounts): IAccountDetails => {
    let ret: IAccountDetails = {} as IAccountDetails;
    ret.accountLabel = account?.accountLabel;
    ret.accountType = account?.accountType;
    ret.id = account?.envdAccountId;
    ret.accountId = account?.accountId;
    if (account?.accountType === AccountTypeEnum.LPA) {
        ret.pic = account.accountLabel;
    }
    ret.isPicBased = false;
    if (account?.accountType === AccountTypeEnum.LPA || account?.accountType === AccountTypeEnum.NLIS) ret.isPicBased = true;
    let roles = account?.roles || [];
    roles = roles.map((val) => val.toUpperCase());
    ret.roles = roles ?? [];
    ret.isAdmin = (roles ?? []).indexOf(RoleTypeEnum.ELEVATEDVIEWER) > -1;
    if (account?.properties) {
        ret.entityName = account.properties.entityName;
        ret.firstName = account.properties.firstName;
        ret.lastName = account.properties.lastName;
        ret.locationAddress = account.properties.address?.line1;
        ret.locationTown = account.properties.address?.town;
        ret.locationState = account.properties.address?.state;
        ret.locationPostcode = account.properties.address?.postcode;
        ret.accreditations = account.properties.accreditations;
        ret.reAccreditationDate = account.properties.reAccreditationDate;
        ret.nlisAccountType = account.properties.nlisAccountType;
    }
    return ret;
};

const base64EncodeClaims = (account: accounts): string => {
    var accountLabel = account?.accountLabel;
    var id = account?.envdAccountId;
    var accountType = account?.accountType;
    var accountId = account?.accountId;
    const jsonString = `{"accountLabel": "${accountLabel}", "id": "${id}", "accountType": "${accountType}","accountId":"${accountId}"}`;

    const base64EncodedString = btoa(jsonString);
    return base64EncodedString;
};

const setAccount = (account: accounts): IAccountDetails => {
    const accountDetails = mapToAccountDetails(account);
    sessionStorage.setItem('_xenvd', base64EncodeClaims(account));

    return accountDetails;
};

const reducer = (state: IUser, action: ActionType) => {
    switch (action.type) {
        case UserActionTypes.setUser:
            const { claims, accountDetails: account } = action.value;
            const accountDetails = setAccount(account);
            const filteredClaims = getFilteredUserDetails(claims);
            return { ...action.value, claims: filteredClaims, accountDetails };
        case UserActionTypes.setUserToken: {
            const token = action.value;
            return {
                token,
            };
        }
        case UserActionTypes.setUserClaims: {
            const claims: IUserClaims = action.value;
            const filteredClaims = getFilteredUserDetails(claims);
            const account = getDefaultAccount(filteredClaims);
            const accountDetails = setAccount(account);
            return { ...state, claims: filteredClaims, accountDetails };
        }
        case UserActionTypes.resetUserParam: {
            return {
                token: null,
                accountDetails: null,
                claims: null,
            };
        }
        case UserActionTypes.setAccount: {
            const accountDetails = setAccount(action.value);
            return {
                ...state,
                accountDetails: accountDetails,
            };
        }
        default:
            return state;
    }
};

export default reducer;
