import { TAirtableConsignment } from '@containers/Consignments/ConsignmentEdit';
import { useGlobalState } from '@state';
import { IDynamicQuestion, IStaticQuestion } from '@typings';

import { getField } from './data-manipulator';

export const useGetDynamicAnswers = (question: IDynamicQuestion, index: number | null) => {
    const [{ originalAnswers, dynamicPendingAnswers, meta }] = useGlobalState();
    const parent = findParent(meta.questions, question.id);
    const grandParent = findParent(meta.questions, parent?.id);
    // Also account for the fact that we could have deleted the answer/modal for a repeatable. Therefore we also need to check against index === null too
    return dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === question.id && (dpa.index === index || dpa.index === null))
        ? dynamicPendingAnswers.answers
        : parent?.type === 'REPEATABLE' || grandParent?.type === 'REPEATABLE'
        ? null
        : originalAnswers;
};

export const findParent = (meta: any, value: string) => {
    var parent: any = null;
    if (meta instanceof Array) {
        for (var i = 0; i < meta.length; i++) {
            parent = findParent(meta[i], value);
            if (parent) {
                break;
            }
        }
    } else {
        for (var key in meta) {
            if (key === 'id') {
                if (meta.childQuestions?.some((cq: any) => cq.id === value)) {
                    return meta;
                }
            }
            if (meta[key] instanceof Object || meta[key] instanceof Array) {
                parent = findParent(meta[key], value);
                if (parent) {
                    break;
                }
            }
        }
    }
    return parent;
};

export const useGetStaticAnswer = (question: IStaticQuestion, data: TAirtableConsignment, defaultValue: any = null) => {
    const [{ staticPendingAnswers }] = useGlobalState();
    return staticPendingAnswers.dirty
        ? getField<any, TAirtableConsignment>(question.field, staticPendingAnswers.answers, defaultValue)
        : getField<any, TAirtableConsignment>(question.field, data, defaultValue);
};

export const getAllChildQuestions = (question: IDynamicQuestion) => {
    let allChildQuestions = question.childQuestions ?? [];
    let hasNestedChild = allChildQuestions?.some((x) => x.childQuestions?.length ?? false) ?? false;

    while (hasNestedChild) {
        const newChildQuestions = allChildQuestions.flatMap((x) => x.childQuestions);
        allChildQuestions = [...allChildQuestions, ...newChildQuestions].coalesce().distinct();
        hasNestedChild = newChildQuestions.some((x) => x.childQuestions?.length ?? false);
    }

    return allChildQuestions;
};
