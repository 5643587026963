import { ClearIcon } from '@assets/icons';
import useKeyPress, { KeyCode } from '@effects/useKeyPress';
import usePortal from '@effects/usePortal';
import { useWhyDidYouUpdate } from '@effects/useWhyDidYouUpdate';
import MLALogger from '@utils/logger';
import React, { useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';

interface Props {
    id: string;
    show: boolean;
    modalHeader?: React.ReactNode;
    modalFooter?: React.ReactNode;
    modalSubText?: React.ReactNode;
    modalWarning?: React.ReactNode;
    parentElementRef?: React.RefObject<HTMLElement>;
    onOutClick?: () => void;
    zIndex?: number;
    children?: React.ReactNode;
}

const AddUserModal: React.FC<Props> = (props) => {
    const { id, modalHeader, modalFooter, modalSubText, children, show, onOutClick, modalWarning, zIndex } = props;
    MLALogger.LogCount(`[RenderCount] Modal - ${id}`);
    useWhyDidYouUpdate(`Modal - ${id}`, props);

    const target = usePortal(id);
    const rootNodeRef = useRef<HTMLDivElement>(null);
    const portalNodeRef = useRef<HTMLDivElement>(null);

    const escKey = useKeyPress(KeyCode.escape);

    useLayoutEffect(() => {
        if (onOutClick && escKey) {
            MLALogger.Log(['Modal'], 'Out Click Triggered via escKey');
            onOutClick();
        }
    }, [escKey, onOutClick]);

    useLayoutEffect(() => {
        const handler = (e: any) => {
            if (onOutClick) {
                if (e.target && typeof e.target.className === 'string' && e.target.className.split(' ').some((x: any) => x === 'Modal')) {
                    MLALogger.Log(['Modal'], { message: 'Out Click Triggered', target: e.target, id, show });
                    if (show) {
                        onOutClick();
                        e.stopPropagation();
                    }
                }
            }
        };

        document.addEventListener('click', handler);
        document.addEventListener('touchstart', handler);

        return () => {
            document.removeEventListener('click', handler);
            document.removeEventListener('touchstart', handler);
        };
    }, [onOutClick, show, id]);

    return (
        <div ref={rootNodeRef}>
            {createPortal(
                <div ref={portalNodeRef}>
                    <style jsx>{`
                        @import 'vars';
                        @import 'utils';
                        @import 'mixins';

                        .Modal {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: rgba($color-black, 0.9);

                            width: 100vw;
                            height: 100vh;
                            position: fixed;
                            top: 0;

                            // IE-FIX
                            flex-direction: column;

                            @media (max-width: $sm-max) {
                                align-items: start;
                            }

                            :global(&--ScrollContainer) {
                                flex: 1 1 auto;
                                overflow-y: scroll;
                                padding: grid(6);

                                border-radius: $border-radius;
                                @media (max-width: $sm-max) {
                                    max-width: 100vw;
                                    max-height: 100vh;
                                    padding-bottom: calc(70px + 56px + 50px); // Height of footer + header + Possible bottom bar (iPhone / Android)
                                }
                            }

                            &--Inner {
                                display: flex;
                                flex-direction: column;
                                max-height: 90vh;
                                background-color: $color-white;
                                z-index: 2;

                                @media (max-width: $sm-max) {
                                    width: 100vw;
                                    height: 100vh;
                                    max-height: 100vh;
                                }

                                @media (min-width: $sm-max) {
                                    max-width: $form-input-maxwidth;
                                }

                                @media (min-width: $md-max) {
                                    min-width: 400px;
                                }

                                @media (prefers-color-scheme: dark) {
                                    background-color: darken($color-white, 80%);
                                }

                                :global(.close-icon) {
                                    color: $isc-blue;
                                    width: 30px;
                                    height: 30px;
                                    margin-left: auto;
                                    margin-right: 10px;
                                    cursor: pointer;
                                }

                                :global(.error) {
                                    color: $color-error;
                                }

                                :global(.disabled) {
                                    cursor: not-allowed;
                                }
                            }

                            z-index: ${zIndex ?? 9};

                            &--Header {
                                display: flex;
                                flex-direction: column;
                                padding: grid(4) grid(6);
                                flex: 0 0 auto;
                                @include text-midbold();

                                &:empty {
                                    display: none;
                                }

                                .link {
                                    @include text-label();
                                }
                            }

                            &--SubText {
                                display: flex;
                                flex-direction: column;
                                padding: grid(2) grid(6);
                                flex: 0 0 auto;
                                @include text-midbold();

                                &:empty {
                                    display: none;
                                }

                                .link {
                                    @include text-label();
                                }
                            }

                            &--Warning {
                                width: 100%;
                                background-color: $color-error;
                                color: $color-white;
                                padding: grid(1) 0;
                                text-align: center;
                            }

                            &--Content {
                                .error {
                                    color: $color-error;
                                }
                            }

                            &--Footer {
                                display: flex;
                                flex: 0 0 auto;
                                padding: grid(4) grid(6);
                                margin-left: auto;
                                // Mobile Safari fix
                                @media (max-width: $sm-max) {
                                    position: fixed;
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    background-color: $color-white;

                                    @media (prefers-color-scheme: dark) {
                                        background-color: darken($color-white, 80%);
                                    }
                                }
                            }
                        }
                    `}</style>
                    {show && (
                        <div className="Modal">
                            <div className="Modal--Inner">
                                <div className="close-div m-v-16 m-h-16">
                                    <ClearIcon className="close-icon icon-primary" onClick={onOutClick} />
                                </div>
                                {modalWarning && <div className="Modal--Warning">{modalWarning}</div>}
                                {modalHeader && <div className="Modal--Header">{modalHeader}</div>}
                                {modalSubText && <div className="Modal--SubText">{modalSubText}</div>}
                                <RemoveScroll className="Modal--ScrollContainer">{React.Children.count(children) > 0 && <div className="Modal--Content">{children}</div>}</RemoveScroll>
                                {modalFooter && <div className="Modal--Footer">{modalFooter}</div>}
                            </div>
                        </div>
                    )}
                </div>,
                target
            )}
        </div>
    );
};

export default AddUserModal;
