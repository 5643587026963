import { ErrorIcon, SpinnerIcon, SuccessIcon } from '@assets/icons';
import React from 'react';

export type TTabBarItemIcon = 'success' | 'error' | 'loading';

interface Props {
    icon?: TTabBarItemIcon;
}

const TabBarItemIcon: React.FC<Props> = ({ icon }) => {
    if (icon === 'success') {
        return (
            <span className="SuccessIcon">
                <style jsx>{`
                    @import 'vars';
                    @import 'mixins';
                    @import 'utils';
                    :global(.SuccessIcon) {
                        margin-right: grid(1);
                        color: $color-success;
                        height: $icon-sm;
                        :global(svg) {
                            width: $icon-sm;
                            height: $icon-sm;
                        }
                    }
                `}</style>
                <SuccessIcon />
            </span>
        );
    } else if (icon === 'error') {
        return (
            <span className="ErrorIcon">
                <style jsx>{`
                    @import 'vars';
                    @import 'mixins';
                    @import 'utils';
                    :global(.ErrorIcon) {
                        margin-right: grid(1);
                        color: $color-error;
                        height: $icon-sm;
                        :global(svg) {
                            width: $icon-sm;
                            height: $icon-sm;
                        }
                    }
                `}</style>
                <ErrorIcon />
            </span>
        );
    } else if (icon === 'loading') {
        return (
            <span className="SpinnerIcon">
                <style jsx>{`
                    @import 'vars';
                    @import 'mixins';
                    @import 'utils';
                    :global(.SpinnerIcon) {
                        margin-right: grid(1);
                        font-size: 1rem;
                        line-height: 1rem;
                        height: $icon-sm;
                        :global(svg) {
                            width: $icon-sm;
                            height: $icon-sm;
                            animation: ball-clip-rotate 0.75s linear infinite;
                        }
                    }
                    @keyframes ball-clip-rotate {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                `}</style>
                <SpinnerIcon />
            </span>
        );
    }

    return null;
};

export default TabBarItemIcon;
