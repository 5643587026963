/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ConsignmentCommentsQueryVariables = {
    envdAccountId: string;
    id: string;
};
export type ConsignmentCommentsQueryResponse = {
    readonly comments: ReadonlyArray<{
        readonly commentId: number;
        readonly consignmentNumber: string;
        readonly role: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly commentText: string;
        readonly createdAt: unknown;
    } | null> | null;
};
export type ConsignmentCommentsQuery = {
    readonly response: ConsignmentCommentsQueryResponse;
    readonly variables: ConsignmentCommentsQueryVariables;
};



/*
query ConsignmentCommentsQuery(
  $envdAccountId: String!
  $id: String!
) {
  comments(envdAccountId: $envdAccountId, consignmentNumber: $id) {
    commentId
    consignmentNumber
    role
    firstName
    lastName
    commentText
    createdAt
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "envdAccountId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "consignmentNumber",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      }
    ],
    "concreteType": "CommentType",
    "kind": "LinkedField",
    "name": "comments",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "commentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "consignmentNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "commentText",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConsignmentCommentsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConsignmentCommentsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6d3d41ed121f2d884dd5520b92baeb21",
    "id": null,
    "metadata": {},
    "name": "ConsignmentCommentsQuery",
    "operationKind": "query",
    "text": "query ConsignmentCommentsQuery(\n  $envdAccountId: String!\n  $id: String!\n) {\n  comments(envdAccountId: $envdAccountId, consignmentNumber: $id) {\n    commentId\n    consignmentNumber\n    role\n    firstName\n    lastName\n    commentText\n    createdAt\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e5f5000db1007f99b308d802d6831058';
export default node;
