/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateAdminInput = {
    clientMutationId?: string | null;
    email: string;
};
export type createAdminMutationVariables = {
    envdAccountId: string;
    input: CreateAdminInput;
};
export type createAdminMutationResponse = {
    readonly createAdmin: {
        readonly data: string | null;
    } | null;
};
export type createAdminMutation = {
    readonly response: createAdminMutationResponse;
    readonly variables: createAdminMutationVariables;
};



/*
mutation createAdminMutation(
  $envdAccountId: String!
  $input: CreateAdminInput!
) {
  createAdmin(envdAccountId: $envdAccountId, input: $input) {
    data
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "envdAccountId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateAdmin",
    "kind": "LinkedField",
    "name": "createAdmin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createAdminMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createAdminMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "79593174a9ae6c858cfe5c6b53680b9c",
    "id": null,
    "metadata": {},
    "name": "createAdminMutation",
    "operationKind": "mutation",
    "text": "mutation createAdminMutation(\n  $envdAccountId: String!\n  $input: CreateAdminInput!\n) {\n  createAdmin(envdAccountId: $envdAccountId, input: $input) {\n    data\n  }\n}\n"
  }
};
})();
(node as any).hash = '6ceb9604443dc709d50b14fe4071cce8';
export default node;
