import { actionTypes, useUserState } from '@common/context/userContext';
import environment from '@common/relay/environment';
import createOrSaveUser, { CreateOrSaveUserMutation } from '@containers/Consignments/mutations/createOrSaveUserMutation';
import { userDetailsQuery } from '@containers/Consignments/queries/userDetailsQuery';
import { accounts, IUserClaims } from '@typings';
// import axios from 'axios';
import { useState } from 'react';
import { useMutation } from 'relay-hooks';
import { fetchQuery } from 'relay-runtime';
import { RelayObservable } from 'relay-runtime/lib/network/RelayObservable';

interface UserClaimsDetails {
    auth0Id: string;
    email: string;
    firstName: string;
    lastName: string;
    mobilePhone: string;
    defaultAccount: string;
    accounts: {
        accountId: string;
        accountLabel: string;
        accountType: string;
        roles: [];
    }[];
}
// Initialize a empty user details with no role for user to see the main page
// when they are able to login but failed when get userDetails
const handleSignInError = (): RelayObservable<{ userDetails: UserClaimsDetails }> => {
    const emptyUserClaims = {
        auth0Id: '',
        email: '',
        firstName: 'User',
        lastName: '',
        mobilePhone: '',
        defaultAccount: '',
        signature: '',
        accounts: [
            {
                accountId: '',
                accountLabel: '',
                accountType: '',
                roles: [],
            },
        ],
    };

    const userDetails = emptyUserClaims;
    return RelayObservable.create((observer: any) => {
        observer.next({ userDetails: userDetails });
        observer.complete();
    });
};

const useUserDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);
    const [, dispatch] = useUserState();
    const [error, setError] = useState(null);
    const [mutate] = useMutation(CreateOrSaveUserMutation);

    const fetchUserDetails = async (accessToken: string) => {
        setIsLoading(true);
        setError(null);
        try {
            // response.data;
            let userDetailsResponse: any = await fetchQuery(environment, userDetailsQuery, {}).catch(() => handleSignInError());
            if (!userDetailsResponse.userDetails) {
                userDetailsResponse = handleSignInError();
            }

            setIsNewUser(userDetailsResponse.userDetails.isNewUser);
            if (userDetailsResponse) {
                const account = getDefaultAccount(userDetailsResponse.userDetails);
                dispatch({
                    type: actionTypes.user.setUser,
                    value: {
                        token: accessToken,
                        claims: userDetailsResponse.userDetails,
                        accountDetails: account,
                    },
                });
            }
            //   getDetails(userDetails);
        } catch (error: any) {
            setError(error.message || 'Something went wrong!');
        }
        setIsLoading(false);
    };

    const setUserSignature = async (userData: any, signature: string) => {
        const response = await createOrSaveUser(mutate, { ...userData, signature: signature });
        dispatch({
            type: actionTypes.user.setUserClaims,
            value: response,
        });
    };

    return {
        isLoading,
        error,
        fetchUserDetails,
        isNewUser,
        setUserSignature,
    };
};

export function getDefaultAccount(claims: IUserClaims): accounts {
    let account: accounts = {} as accounts;
    if (claims && claims.accounts && claims.accounts[0]) {
        account = claims.accounts[0];
    }
    const selectedAccountBase = sessionStorage.getItem('_xenvd');
    //If user has selected an account, that must still be selected on reload
    let selectedAccount = getSelectedAccount(claims?.accounts, selectedAccountBase);
    if (selectedAccount) return selectedAccount;

    if (claims?.defaultAccount) {
        const defaultAccount = claims.accounts.find((acc) => acc.envdAccountId === claims.defaultAccount);
        if (defaultAccount) account = defaultAccount;
    }
    return account;
}

function getSelectedAccount(accounts: accounts[], selectedAccountBase: string | null) {
    if (selectedAccountBase && accounts) {
        const selectedAccount = JSON.parse(atob(selectedAccountBase ?? '{}')).id;
        if (selectedAccount) {
            return accounts.find((acc: any) => acc?.envdAccountId === selectedAccount);
        }
    }
    return null;
}

export default useUserDetails;
