import { IUserClaims } from '@typings';
import MLALogger from '@utils/logger';
import { graphql } from 'relay-hooks';

import { CreateOrSaveUserInput } from './__generated__/createOrSaveUserMutation.graphql';

const mutation = graphql`
    mutation createOrSaveUserMutation($input: CreateOrSaveUserInput!) {
        createOrSaveUser(input: $input) {
            data {
                firstName
                lastName
                mobilePhone
                isNewUser
                email
                defaultAccount
                receiveEmails
                signature
                accounts {
                    envdAccountId
                    accountType
                    accountLabel
                    accountId
                    email
                    properties {
                        nameId
                        nlisAccountId
                        nlisUserId
                        nlisAccountType
                        rego
                        firstName
                        lastName
                        entityName
                        propertyName
                        msaMemberNum
                        reAccreditationDate
                        address {
                            line1
                            town
                            state
                            postcode
                        }
                        accreditations {
                            lPA
                            eU
                            mSA
                            eUGFHQB
                            nFAS
                        }
                    }
                    roles
                }
            }
        }
    }
`;

const commitAsync = async (mutate: any, registerInput: CreateOrSaveUserInput): Promise<IUserClaims | undefined> => {
    MLALogger.Log(['register', 'commitAsync', 'Sending'], { registerInput });

    try {
        const data = await mutate({
            variables: { input: registerInput },
            onCompleted: (response: any, error: any) => {
                MLALogger.Log(['register', 'commitAsync', 'onComplete'], { response, error });
                if (error) {
                    MLALogger.Log(['register', 'commitAsync'], { registerInput, error }, 'error');
                } else {
                    MLALogger.Log(['register', 'commitAsync', 'success'], { response, request: registerInput });
                }
            },
        });

        return data?.createOrSaveUser?.data;
    } catch (error) {
        MLALogger.Log(['register'], error, 'error');
        return;
    }
};

export { mutation as CreateOrSaveUserMutation };
export default commitAsync;
