import ElevatedViewerSearchPanel from '@containers/Consignments/components/ElevatedViewerSearchPanel';
import TodayConsignmentComponent from '@containers/Consignments/components/TodayConsignment';
import useRoleValidation from '@effects/useRoleValidation';
import { RoleTypeEnum } from '@utils/enums';

import AccountSelector from './AccountSelector';

export const ProfileCard = () => {
    const { hasRole } = useRoleValidation();

    return (
        <>
            <div>
                <style jsx>{`
                    @import 'vars';

                    .account-panel {
                        display: flex;
                        @media (max-width: $lg-min) {
                            flex-direction: column;
                        }
                    }
                    .account-selector {
                        flex: 1;
                    }

                    .moving-today,
                    .search-panel {
                        flex: 3;
                    }
                `}</style>
                <div className="account-panel">
                    <div className="account-selector">
                        <AccountSelector />
                    </div>
                    {hasRole(RoleTypeEnum.ELEVATEDVIEWER) ? (
                        <div className="search-panel">
                            <ElevatedViewerSearchPanel />
                        </div>
                    ) : (
                        <div className="moving-today">
                            <TodayConsignmentComponent />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
