import CheckboxInput from '@components/Form/CheckboxInput';
import FormRow from '@components/Form/FormRow';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    errorText?: string;
    labelText?: string;
    optionText: string;
    helperText?: string;
    isValid?: boolean;
    isReadOnly?: boolean;
    optionElement?: JSX.Element;
}

const Checkbox: React.FunctionComponent<Props> = ({ errorText, labelText, optionText, helperText, isValid, isReadOnly, required, optionElement, ...inputProps }) => {
    const isError = isValid === false;
    const isSuccess = isValid === true;

    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                .input-container {
                    user-select: none;
                    display: flex;
                    align-items: center;

                    &.with-error {
                        color: $color-error;

                        :global(.chk span.checkmark) {
                            border-color: $color-error;
                        }
                    }
                    &.with-disabled {
                        :global(.chk span.checkmark) {
                            border-color: $color-grey79;
                            background: $color-grey79;
                            cursor: default;
                            &:after {
                                border-color: $color-white;
                            }
                        }
                    }
                }
            `}</style>

            <FormRow errorText={errorText} isValid={isValid} helperText={helperText} labelText={labelText} readOnly={isReadOnly} required={required}>
                <label
                    className={classnames('input-container', {
                        'with-success': isSuccess,
                        'with-error': isError,
                        'with-disabled': isReadOnly,
                    })}
                >
                    <CheckboxInput {...inputProps} containerClass={'m-r-8'} disabled={isReadOnly} />
                    {isEmpty(optionText) ? optionElement : <span>{optionText}</span>}
                </label>
            </FormRow>
        </>
    );
};

export default Checkbox;
