import { CommentIcon } from '@assets/icons';
import { useUserState } from '@common/context/userContext';
import useRoleValidation from '@effects/useRoleValidation';
import useWindowTitle from '@effects/useWindowTitle';
import { ConsignmentStatus } from '@utils/enum-transformers';
import { RoleTypeEnum } from '@utils/enums';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from 'relay-hooks';
import { graphql } from 'relay-runtime';

import { ConsignmentCommentsQuery } from './__generated__/ConsignmentCommentsQuery.graphql';
import AddComment from './AddComment';
import ViewComment, { CommentRowProps } from './ViewComment';

interface ConsignmentCommentProps {
    status: string;
}
const query = graphql`
    query ConsignmentCommentsQuery($envdAccountId: String!, $id: String!) {
        comments(envdAccountId: $envdAccountId, consignmentNumber: $id) {
            commentId
            consignmentNumber
            role
            firstName
            lastName
            commentText
            createdAt
        }
    }
`;
const ConsignmentComment: React.FC<ConsignmentCommentProps> = ({ status }) => {
    useWindowTitle('Consignment Comments');
    const [{ user }] = useUserState();
    const { id } = useParams<any>();
    const envdAccountId = user?.accountDetails?.id;
    const { data } = useQuery<ConsignmentCommentsQuery>(query, { envdAccountId, id }, { fetchPolicy: 'network-only' });

    const { hasRole } = useRoleValidation();
    const [commentData, setCommentData] = useState<CommentRowProps[]>([]);

    useEffect(() => {
        setCommentData(
            data?.comments
                ?.map((comment) => {
                    return {
                        firstName: comment?.firstName || '',
                        lastName: comment?.lastName || '',
                        role: comment?.role || '',
                        commentText: comment?.commentText || '',
                        createdAt: String(comment?.createdAt) || '',
                        commentId: Number(comment?.commentId),
                    };
                })
                .reverse() || []
        );
    }, [data]);

    const canAddComments = status === ConsignmentStatus.SUBMITTED && !hasRole(RoleTypeEnum.ELEVATEDVIEWER);

    const updateComment = (value: CommentRowProps) => {
        setCommentData([...commentData, value]);
    };

    return (
        <>
            <div className="box m-v-16">
                <div className="p-v-16 p-h-16">
                    <div className="comment-count">
                        <CommentIcon />
                        <div className="comment-heading">Comments {commentData?.length > 0 ? `(${commentData?.length})` : status === ConsignmentStatus.LOCKED && '(0)'}</div>
                    </div>
                    <ViewComment comments={commentData} />
                    {canAddComments && <AddComment envdAccountId={envdAccountId} consignmentNumber={id} updateComment={updateComment} />}
                </div>
            </div>
            <style jsx>
                {`
                    @import 'vars';

                    .box {
                        box-sizing: border-box;
                        background: $color-white;
                        border: 1px solid $grey-border;
                        border-radius: 4px;
                        @media (prefers-color-scheme: dark) {
                            background: darken($color-white, 90%);
                        }
                    }

                    hr {
                        border: 1px solid $isc-blue;
                        margin: auto;
                    }

                    .comment-count {
                        display: flex;
                        gap: 1vw;
                    }
                `}
            </style>
        </>
    );
};

export default ConsignmentComment;
