import { useUserState } from '@common/context/userContext';
import { accounts, IAccountDetails } from '@typings';
import { isIAccountDetails } from '@utils/account';
import { RoleTypeEnum } from '@utils/enums';

const useRoleValidation = () => {
    const [{ user }] = useUserState();

    /**
     * Check whether current account has this role
     * @param role
     */
    const hasRole = (role: RoleTypeEnum): boolean => {
        const userRoles = user?.accountDetails?.roles;
        return (userRoles?.indexOf(role) ?? -1) > -1;
    };

    /**
     * Check whether account has any of the passed roles
     * @param role
     */
    const hasAnyOfRoles = (roles: RoleTypeEnum[]): boolean => {
        const userRoles = user?.accountDetails?.roles ?? [];
        const intersection = roles.filter((element) => userRoles.includes(element));
        return intersection.length > 0;
    };

    /**
     * Check whether a account has this role
     * @param role
     */
    const hasRoleInAccount = (account: accounts | IAccountDetails, role: RoleTypeEnum): boolean => {
        let userRoles;
        if (isIAccountDetails(account)) {
            userRoles = account.roles;
        } else {
            const accountRoles = account?.roles || [];
            userRoles = accountRoles.map((role) => role.toUpperCase());
        }
        return (userRoles?.indexOf(role) ?? -1) > -1;
    };

    /**
     * Check whether a account has this role on this consignment
     * @param role
     */
    const hasRoleWithConsignment = (account: accounts | IAccountDetails, role: RoleTypeEnum, consignment: { owner: any; origin: any; consignee: any; destination: any } | null): boolean => {
        let userRoles;
        if (isIAccountDetails(account)) {
            userRoles = account.roles;
        } else {
            const accountRoles = account?.roles || [];
            userRoles = accountRoles.map((role) => role.toUpperCase());
        }
        const hasAccountRole = (userRoles?.indexOf(role) ?? -1) > -1;
        if (hasAccountRole) {
            if (user?.accountDetails?.isPicBased) {
                if (user?.accountDetails?.accountType === 'NLIS') return role === RoleTypeEnum.RECEIVER;
                const pic = user?.accountDetails?.pic;
                if (consignment?.origin?.pic === pic) return role === RoleTypeEnum.PRODUCER;
                if (consignment?.owner?.pic === pic || consignment?.consignee?.pic === pic || consignment?.destination?.pic === pic) return role === RoleTypeEnum.RECEIVER;
            }
            return false;
        }
        return false;
    };

    return { hasRole, hasRoleInAccount, hasAnyOfRoles, hasRoleWithConsignment };
};

export default useRoleValidation;
