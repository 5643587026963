import { useWhyDidYouUpdate } from '@effects/useWhyDidYouUpdate';
import { BLANK_IMAGE } from '@utils/constants';
import MLALogger from '@utils/logger';
import React from 'react';

import SignatureWriteable from './SignatureWriteable';

export interface ISignatureQuestionProps {
    isReadOnly: boolean;
    title?: string;
    value?: string;

    onUpdate?: (value?: string) => void;
    hideClear?: boolean;
}

const Signature: React.FC<ISignatureQuestionProps> = (props) => {
    MLALogger.LogCount('Signature');
    useWhyDidYouUpdate('Signature', props);

    const { isReadOnly, title, value } = props;

    // Readonly is shown as an image to avoid an array of issues with multiple canvases
    if (isReadOnly) {
        return (
            <div className="SignaturePadWrapper ReadOnly">
                <style jsx>{`
                    @import 'vars';
                    @import 'utils';
                    @import 'mixins';

                    label {
                        display: block;
                        margin-bottom: grid(2);
                        &.has-link {
                            :global(.Button--Link) {
                                float: right;
                            }
                        }
                    }

                    .SignaturePadWrapper {
                        margin-bottom: grid(5);
                        max-width: $form-input-maxwidth;
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        width: 100%;
                        height: 100%;

                        img {
                            background-color: white;
                            width: 100%;
                        }

                        &.ReadOnly {
                            max-width: none;
                            margin: 0;
                        }

                        .ReadOnly {
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;

                            .Question,
                            .Answer {
                                padding: grid(2) grid(4);
                                margin: 0;
                            }

                            .Question {
                                width: 30%;
                                @include text-regular();
                                font-size: 14px;
                                font-weight: 600;
                            }
                            .Answer {
                                width: 70%;
                            }

                            img {
                                max-width: 200px;
                            }
                        }

                        @media not all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                            &--Parent {
                                flex: 1;
                            }
                        }
                    }
                `}</style>

                <div className="ReadOnly">
                    <div className="Question label">{title}</div>
                    <div className="Answer">
                        <img src={value ?? BLANK_IMAGE} alt={title} />
                    </div>
                </div>
            </div>
        );
    } else {
        return <SignatureWriteable {...props} />;
    }
};

export default Signature;
