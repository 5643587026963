import { endOfDay, format, formatDistance } from 'date-fns';

export const currentPropertiesInDate = Object.getOwnPropertyNames(Date.prototype);

if (!currentPropertiesInDate.includes('toISODateString')) {
    Object.defineProperty(Date.prototype, 'toISODateString', {
        value() {
            return endOfDay(this as Date)
                .toISOString()
                .slice(0, 10);
        },
    });
}

if (!currentPropertiesInDate.includes('relativeTime')) {
    Object.defineProperty(Date.prototype, 'relativeTime', {
        value() {
            // formatDistance(subDays(new Date(), 3), new Date())
            return `${formatDistance(new Date(), this as Date)} ago`;
        },
    });
}

if (!currentPropertiesInDate.includes('toMLADateString')) {
    Object.defineProperty(Date.prototype, 'toMLADateString', {
        value() {
            // return format(this as Date, 'do MMM YYYY hh:mm a', { awareOfUnicodeTokens: true });
            return format(this as Date, 'do MMM yyyy');
        },
    });
}

if (!currentPropertiesInDate.includes('toMLATimeString')) {
    Object.defineProperty(Date.prototype, 'toMLATimeString', {
        value() {
            return format(this as Date, 'hh:mm a');
        },
    });
}
