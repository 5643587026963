import { SearchIcon } from '@assets/icons';
import debounce from '@common/effects/useDebounce';
import useKeyPress, { KeyCode, useLocalKeyPress } from '@common/effects/useKeyPress';
import { useWhyDidYouUpdate } from '@common/effects/useWhyDidYouUpdate';
import DropdownOptions, { DropdownOption } from '@components/Form/DropdownOptions';
import FormRow from '@components/Form/FormRow';
import MLALogger from '@utils/logger';
import classnames from 'classnames';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import RelativePortal from '../RelativePortal';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    onSelected: (item: DropdownOption) => void;
    search: (q: string) => void;
    searchResults?: DropdownOption[];
    errorText?: string;
    labelText?: string;
    helperText?: string;
    isValid?: boolean;
    isError?: boolean;
    initialValue?: string;
    heading?: string;
    placeholder?: string;
    emptyText?: string;
    errorSlot?: React.ReactNode;
    dropDownTopHeightAdjustment?: number;
}

const calculateActiveOptionIndex = (value: number, options: DropdownOption[], currentOptionIndex?: number): number => {
    let val = (currentOptionIndex ? currentOptionIndex : 0) + value;
    if (val < 0) {
        val = options.length - 1;
    }
    const optionIndex = val % options.length;
    MLALogger.Log(['Autocomplete'], { message: 'Calculated new Active Option Index', value, currentOptionIndex, optionIndex });
    return optionIndex;
};

const Autocomplete: React.FunctionComponent<Props> = (props: Props) => {
    const {
        initialValue,
        errorText,
        labelText,
        helperText,
        isValid,
        search,
        onSelected,
        heading,
        emptyText,
        placeholder,
        searchResults,
        isError,
        errorSlot,
        dropDownTopHeightAdjustment,
        ...inputProps
    } = props;

    useWhyDidYouUpdate('Autocomplete', props);
    const node = useRef<HTMLDivElement>(null);
    const input = useRef<HTMLInputElement>(null);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(initialValue);
    const debouncedSearchTerm = debounce(300, searchTerm, '');
    const [show, setShow] = useState<boolean>(false);
    const isSuccess = searchTerm === undefined || searchTerm === '' ? undefined : isValid === true;
    const iconStyle = isError ? 'icon-error' : isSuccess ? 'icon-success' : show ? 'icon-primary' : 'icon-dark';
    const [activeOptionIndex, setActiveOptionIndex] = useState<number | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);

    MLALogger.LogCount('[RenderCount] Autocomplete');

    const reset = useCallback(() => {
        MLALogger.Log(['Autocomplete'], 'reset');
        setShow((s) => (s ? !s : s));
        setIsLoading((s) => (s ? !s : s));
        setSearchTerm(undefined);

        if (input.current) {
            input.current.blur();
        }
    }, []);

    useEffect(() => {
        search(debouncedSearchTerm ?? '');
        MLALogger.Log(['Autocomplete'], { message: 'debounce changed', debouncedSearchTerm });
    }, [search, debouncedSearchTerm]);

    useEffect(() => {
        if (searchTerm && searchTerm.length > 0) {
            setIsLoading(true);
            MLALogger.Log(['Autocomplete'], { message: 'search changed', searchTerm });
        }
    }, [searchTerm]);

    const optionSelected = (option: any) => {
        MLALogger.Log(['Autocomplete'], { message: 'option selected', option });

        if (option === -1) {
            setSearchTerm('');
            // setSelectedIndex(option);
        } else {
            setSearchTerm(option.title);
            setSelectedIndex(parseInt(option, 10));
        }

        reset();
    };

    useEffect(() => {
        if (selectedIndex !== undefined) {
            if (searchResults && searchResults.length > selectedIndex) {
                MLALogger.Log(['Autocomplete'], { message: 'sending', searchResults, selectedIndex });

                onSelected(searchResults[selectedIndex]);
            }
        }
    }, [selectedIndex, searchResults, onSelected]);

    useEffect(() => {
        if (!!searchResults) {
            setActiveOptionIndex(0);
            MLALogger.Log(['Autocomplete'], { message: 'search changed?', searchResults });
            setIsLoading(false);
        }
    }, [searchResults]);

    const downKeyPress = useLocalKeyPress(KeyCode.downArrow, node.current);
    const upKeyPress = useLocalKeyPress(KeyCode.upArrow, node.current);
    const deleteKeyPress = useLocalKeyPress(KeyCode.delete, node.current);
    const enterKeyPress = useLocalKeyPress(KeyCode.enter, node.current);
    const escKeyPress = useKeyPress(KeyCode.escape);

    useLayoutEffect(() => {
        if (downKeyPress && searchResults) {
            setActiveOptionIndex((internalAOI) => calculateActiveOptionIndex(1, show ? searchResults : [], internalAOI));
        }
    }, [downKeyPress, searchResults, show]);

    useLayoutEffect(() => {
        if (upKeyPress && searchResults) {
            setActiveOptionIndex((internalAOI) => calculateActiveOptionIndex(-1, show ? searchResults : [], internalAOI));
        }
    }, [upKeyPress, searchResults, show]);

    useLayoutEffect(() => {
        if (deleteKeyPress) {
            setActiveOptionIndex(0);
        }
    }, [deleteKeyPress]);

    useLayoutEffect(() => {
        if (enterKeyPress && searchResults) {
            if (show) {
                onSelected(searchResults[activeOptionIndex || 0]);
            }
        }
    }, [enterKeyPress, searchResults, show, activeOptionIndex, onSelected]);

    useLayoutEffect(() => {
        if (escKeyPress) {
            setShow(false);
        }
    }, [escKeyPress]);

    return (
        <div className="Autocomplete">
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                .Autocomplete {
                    input {
                        padding: grid(3);
                    }

                    .with-loading {
                        background-color: lightgreen;
                    }

                    .input-container {
                        margin-bottom: grid(0.5);

                        &--wrapper {
                            position: relative;
                        }

                        &.with-icon {
                            :global(svg) {
                                position: absolute;
                                right: 7px;
                                top: 12px;
                                width: $icon-sm;
                                height: $icon-sm;
                            }
                        }

                        &.with-success {
                            input {
                                border-color: $color-success;
                            }
                        }

                        &.with-error {
                            input {
                                border-color: $color-error;
                            }
                        }
                    }

                    :global(.FormRow),
                    :global(.FormRow--readOnly) {
                        margin-bottom: grid(2);
                    }
                }
            `}</style>

            <FormRow errorText={errorText} isValid={isValid} helperText={helperText} labelText={labelText} small>
                <div
                    className={classnames('input-container with-icon', {
                        'with-success': isSuccess,
                        'with-error': isError,
                        'with-loading': isLoading,
                    })}
                >
                    <div className="input-container--wrapper" ref={node}>
                        <input
                            ref={input}
                            type="text"
                            value={searchTerm || ''}
                            placeholder={placeholder}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onFocus={() => setShow(true)}
                            {...inputProps}
                        />

                        <SearchIcon className={iconStyle} alt="Search" />

                        <RelativePortal
                            id={'autocomplete-portal'}
                            show={show}
                            onParentClick={optionSelected}
                            footer={show && <DropdownOptions options={[]} selectedIndex={!show ? activeOptionIndex : undefined} className="Dropdown--Footer" />}
                            onOutClick={reset}
                            fitToParentWidth
                            parentElementRef={node}
                            dropDownTopHeightAdjustment={dropDownTopHeightAdjustment}
                        >
                            <DropdownOptions
                                loading={isLoading}
                                error={isError}
                                errorSlot={errorSlot}
                                options={searchResults || []}
                                selectedIndex={activeOptionIndex}
                                heading={heading}
                                emptyText={emptyText}
                            />
                        </RelativePortal>
                    </div>
                </div>
            </FormRow>
        </div>
    );
};

export default Autocomplete;
