import { IAnswer } from '@typings';
/* eslint-disable no-extend-native */
export const currentPropertiesInArray = Object.getOwnPropertyNames(Array.prototype);

if (!currentPropertiesInArray.includes('tail')) {
    Object.defineProperty(Array.prototype, 'tail', {
        value<T>(n: number) {
            return (this as T[])[(this as T[]).length - (1 + n)];
        },
    });
}

if (!currentPropertiesInArray.includes('coalesce')) {
    Object.defineProperty(Array.prototype, 'coalesce', {
        value<T>() {
            if (!(this as T[])) {
                return this as T[];
            }
            return (this as T[]).filter((e) => !!e);
        },
    });
}

if (!currentPropertiesInArray.includes('distinct')) {
    Object.defineProperty(Array.prototype, 'distinct', {
        value<T>() {
            const seen = new Set<T>();
            return (this as T[]).filter((element) => {
                if (seen.has(element)) {
                    return false;
                }

                seen.add(element);
                return true;
            });
        },
    });
}

if (!currentPropertiesInArray.includes('lastIndex')) {
    Object.defineProperty(Array.prototype, 'lastIndex', {
        value<T>(fn: (item: T) => boolean) {
            for (let i = (this as T[]).length - 1; i >= 0; i--) {
                const element = (this as T[])[i];

                if (fn(element)) {
                    return i;
                }
            }

            return -1;
        },
    });
}

if (!currentPropertiesInArray.includes('firstIndex')) {
    Object.defineProperty(Array.prototype, 'firstIndex', {
        value<T>(fn: (item: T) => boolean) {
            for (let i = 0; i < (this as T[]).length; i++) {
                const element = (this as T[])[i];

                if (fn(element)) {
                    return i;
                }
            }

            return -1;
        },
    });
}

if (!currentPropertiesInArray.includes('firstOrDefault')) {
    Object.defineProperty(Array.prototype, 'firstOrDefault', {
        value<T>(fn: (item: T) => boolean, notFoundValue?: T) {
            const index = (this as T[]).firstIndex(fn);
            return index < 0 ? notFoundValue : (this as T[])[index];
        },
    });
}

if (!currentPropertiesInArray.includes('first')) {
    Object.defineProperty(Array.prototype, 'first', {
        value<T>() {
            return (this as T[]).slice(0, 1)[0];
        },
    });
}

if (!currentPropertiesInArray.includes('last')) {
    Object.defineProperty(Array.prototype, 'last', {
        value<T>() {
            return (this as T[]).slice(-1)[0];
        },
    });
}

if (!currentPropertiesInArray.includes('toHashMap')) {
    Object.defineProperty(Array.prototype, 'toHashMap', {
        value<T>(options?: { internalKey?: string; externalKey?: string; keyGetter?: (item: T) => string | number; valueGetter?: (item: T) => any }) {
            const { internalKey, externalKey } = options || { internalKey: '', externalKey: '' };
            const flattenedArray: { [key: string]: any } = {};

            (this as T[]).forEach((a, i) => {
                let key = internalKey ? (a as any)[internalKey] : externalKey || i;
                let value = a;

                if (options && options.keyGetter) {
                    // There is a key getter defined so we will use that to grab the key instead
                    key = options.keyGetter(a);
                }

                if (options && options.valueGetter) {
                    value = options.valueGetter(a);
                }

                flattenedArray[key] = value;
            });

            return flattenedArray;
        },
    });
}

if (!currentPropertiesInArray.includes('diff')) {
    Object.defineProperty(Array.prototype, 'diff', {
        value<T>(other: T[]) {
            const arr = this as T[];
            const firstItem = arr.first();
            if (typeof firstItem === 'object') {
                const a = arr.map((x) => JSON.stringify(x));
                const b = (other as T[]).map((x) => JSON.stringify(x));

                const difference = [...a.filter((x) => !b.includes(x)).map((x) => JSON.parse(x)), ...b.filter((x) => !a.includes(x)).map((x) => JSON.parse(x))];
                return difference;
            } else {
                const difference = [...arr.filter((x) => !other.includes(x)), ...other.filter((x) => !arr.includes(x))];
                return difference;
            }
        },
    });
}

if (!currentPropertiesInArray.includes('diffLeft')) {
    Object.defineProperty(Array.prototype, 'diffLeft', {
        value<T>(other: T[]) {
            const arr = this as T[];
            const firstItem = arr.first();
            if (typeof firstItem === 'object') {
                const a = arr.map((x) => JSON.stringify(x));
                const b = (other as T[]).map((x) => JSON.stringify(x));

                const difference = a.filter((x) => !b.includes(x)).map((x) => JSON.parse(x));
                return difference;
            } else {
                const difference = arr.filter((x) => !other.includes(x));
                return difference;
            }
        },
    });
}

if (!currentPropertiesInArray.includes('diffRight')) {
    Object.defineProperty(Array.prototype, 'diffRight', {
        value<T>(other: T[]) {
            const arr = this as T[];
            const firstItem = arr.first();
            if (typeof firstItem === 'object') {
                const a = arr.map((x) => JSON.stringify(x));
                const b = (other as T[]).map((x) => JSON.stringify(x));

                const difference = b.filter((x) => !a.includes(x)).map((x) => JSON.parse(x));
                return difference;
            } else {
                const difference = other.filter((x) => !arr.includes(x));
                return difference;
            }
        },
    });
}

if (!currentPropertiesInArray.includes('groupBy')) {
    Object.defineProperty(Array.prototype, 'groupBy', {
        value<T>(k: string) {
            const arr = this as T[];
            const m = new Map();

            for (const v of arr) {
                const kv = k.split('.').reduce((o, i) => (o as any)[i], v);
                const existing = m.get(kv);
                if (!existing) {
                    m.set(kv, [v]);
                } else {
                    m.set(kv, [...existing, v]);
                }
            }

            return Array.from(m.values()).flat();
        },
    });
}

if (!currentPropertiesInArray.includes('intersection')) {
    Object.defineProperty(Array.prototype, 'intersection', {
        value<T>(other: T[]) {
            const aSet = new Set(this as T[]);
            const bSet = new Set(other as T[]);
            return [...new Set([...aSet].filter((x) => bSet.has(x)))];
        },
    });
}

export function compareArray(arrays: IAnswer[][]): boolean {
    var result = false;
    for (let i = 0; i < arrays.length; i++) {
        if (arrays[i] === undefined) return false;
    }
    if (arrays.length < 2) return false;
    for (let i = 0; i < arrays.length - 1; i++) {
        // eslint-disable-next-line no-loop-func
        arrays[i].forEach((item1) => {
            arrays[i + 1].forEach((item2) => {
                if (item1.index === item2.index) {
                    result = true;
                }
            });
        });
    }
    return result;
}
