/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type userDetailsQueryVariables = {};
export type userDetailsQueryResponse = {
    readonly userDetails: {
        readonly firstName: string;
        readonly lastName: string;
        readonly mobilePhone: string;
        readonly isNewUser: boolean;
        readonly email: string;
        readonly defaultAccount: string;
        readonly receiveEmails: boolean;
        readonly signature: string;
        readonly accounts: ReadonlyArray<{
            readonly envdAccountId: string | null;
            readonly accountType: string | null;
            readonly accountLabel: string | null;
            readonly email: string | null;
            readonly phone: string | null;
            readonly accountId: string | null;
            readonly properties: {
                readonly nameId: string | null;
                readonly nlisAccountId: string | null;
                readonly nlisUserId: string | null;
                readonly nlisAccountType: string | null;
                readonly rego: string | null;
                readonly firstName: string | null;
                readonly lastName: string | null;
                readonly entityName: string | null;
                readonly propertyName: string | null;
                readonly msaMemberNum: string | null;
                readonly reAccreditationDate: string | null;
                readonly address: {
                    readonly line1: string | null;
                    readonly town: string | null;
                    readonly state: string | null;
                    readonly postcode: string | null;
                } | null;
                readonly accreditations: {
                    readonly lPA: boolean;
                    readonly eU: boolean;
                    readonly mSA: boolean;
                    readonly eUGFHQB: boolean;
                    readonly nFAS: boolean;
                    readonly hRG: boolean;
                } | null;
            } | null;
            readonly roles: ReadonlyArray<string | null> | null;
        } | null>;
    } | null;
};
export type userDetailsQuery = {
    readonly response: userDetailsQueryResponse;
    readonly variables: userDetailsQueryVariables;
};



/*
query userDetailsQuery {
  userDetails {
    firstName
    lastName
    mobilePhone
    isNewUser
    email
    defaultAccount
    receiveEmails
    signature
    accounts {
      envdAccountId
      accountType
      accountLabel
      email
      phone
      accountId
      properties {
        nameId
        nlisAccountId
        nlisUserId
        nlisAccountType
        rego
        firstName
        lastName
        entityName
        propertyName
        msaMemberNum
        reAccreditationDate
        address {
          line1
          town
          state
          postcode
        }
        accreditations {
          lPA
          eU
          mSA
          eUGFHQB
          nFAS
          hRG
        }
      }
      roles
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserDetailType",
    "kind": "LinkedField",
    "name": "userDetails",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mobilePhone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isNewUser",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "defaultAccount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "receiveEmails",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signature",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "accounts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "envdAccountId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountLabel",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accountId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AccountProperties",
            "kind": "LinkedField",
            "name": "properties",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nameId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nlisAccountId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nlisUserId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nlisAccountType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rego",
                "storageKey": null
              },
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entityName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "propertyName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "msaMemberNum",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reAccreditationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountAddress",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "line1",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "town",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postcode",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ClaimAccreditations",
                "kind": "LinkedField",
                "name": "accreditations",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lPA",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "eU",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mSA",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "eUGFHQB",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nFAS",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hRG",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "roles",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "userDetailsQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "userDetailsQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "5b64172b019c87e6aeeb00242f341628",
    "id": null,
    "metadata": {},
    "name": "userDetailsQuery",
    "operationKind": "query",
    "text": "query userDetailsQuery {\n  userDetails {\n    firstName\n    lastName\n    mobilePhone\n    isNewUser\n    email\n    defaultAccount\n    receiveEmails\n    signature\n    accounts {\n      envdAccountId\n      accountType\n      accountLabel\n      email\n      phone\n      accountId\n      properties {\n        nameId\n        nlisAccountId\n        nlisUserId\n        nlisAccountType\n        rego\n        firstName\n        lastName\n        entityName\n        propertyName\n        msaMemberNum\n        reAccreditationDate\n        address {\n          line1\n          town\n          state\n          postcode\n        }\n        accreditations {\n          lPA\n          eU\n          mSA\n          eUGFHQB\n          nFAS\n          hRG\n        }\n      }\n      roles\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '80981cb88cda9994a1981922470b9688';
export default node;
