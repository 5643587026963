import { ActionType } from '@state';
import { IConsignmentMeta } from '@typings';
import { ConsignmentStatus } from '@utils/enum-transformers';

export const MetaActionTypes = {
    setConsignmentMeta: 'setConsignmentMeta',
    resetConsignmentMeta: 'resetConsignmentMeta',
    setTransporterEdit: 'setTransporterEdit',
};

export const MetaInitialState: IConsignmentMeta = {
    number: '',
    createdBy: '',
    questions: [],
    status: ConsignmentStatus.DRAFT,
    isTransporterEdit: false,
    transporterIndex: 0,
    selectedIndex: -1,
};

const reducer = (state: IConsignmentMeta, action: ActionType) => {
    switch (action.type) {
        case MetaActionTypes.setConsignmentMeta:
            return action.payload;
        case MetaActionTypes.setTransporterEdit:
            return action.payload;
        case MetaActionTypes.resetConsignmentMeta:
            return MetaInitialState;
        default:
            return state;
    }
};

export default reducer;
