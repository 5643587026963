import Input, { InputType } from '@components/Form/Input';
import useUpdateDynamicValue from '@effects/useUpdateDynamicValue';
import useValidation from '@effects/useValidation';
import { useGlobalState } from '@state';
import { useGetDynamicAnswers } from '@utils/question-getter';
import { getIsRequired } from '@utils/question-validator';
import { isAnyQuestionAnsweredInCurrentSection } from '@utils/section-mapper';
import React, { useEffect } from 'react';

import { IDynamicQuestionProps } from './DynamicQuestion';

const DynamicNumberQuestion: React.FC<IDynamicQuestionProps> = ({ question, index }) => {
    const update = useUpdateDynamicValue();
    const [{ triggerValidatorCount, copyErrors }] = useGlobalState();
    const { isValid, checkIsValid, evaluated, errorMessage, resetIsValid } = useValidation();
    const isReadOnly = question.readOnly;
    const isRequired = getIsRequired(question.validators);
    const [{ originalAnswers, meta }] = useGlobalState();
    // Prefer pending if it exists, fallback to original if not
    const answers = useGetDynamicAnswers(question, index);
    // for the answers with indexes it will take the value from Dynamic pending answers
    // for answers with null indexes it will take value from original answers
    const value =
        answers?.firstOrDefault((answer) => answer.questionId === question.id && answer.index === index)?.value ??
        originalAnswers?.firstOrDefault((answer) => answer.questionId === question.id && index === null)?.value;

    if (!evaluated && value) {
        checkIsValid(question, value);
    }
    useEffect(() => {
        if ((value === null || value === undefined) && copyErrors.isCopy && isAnyQuestionAnsweredInCurrentSection(question, meta, originalAnswers)) {
            checkIsValid(question, value);
        }
    });
    useEffect(() => {
        if (triggerValidatorCount > 0) {
            checkIsValid(question, value);
        }
        if (triggerValidatorCount === 0 && !copyErrors.isCopy) {
            resetIsValid();
        }
    }, [triggerValidatorCount, checkIsValid, resetIsValid, question, value, copyErrors.isCopy]);

    return (
        <Input
            inputType={InputType.Text}
            labelText={question.text}
            errorText={errorMessage}
            helperText={''}
            className="class-for-input"
            value={value}
            onChange={(e) => {
                const parsedValue = Number.parseInt(e.target.value, 10);
                const isEmptyInput = e.target.value === '';
                checkIsValid(question, e.target.value, true);

                if (Number.isNaN(parsedValue)) {
                    if (isEmptyInput && Boolean(value)) {
                        // Reset value
                        update(question, index, '');
                    }
                    return;
                }

                update(question, index, parsedValue.toString());
            }}
            max={9999}
            isValid={isValid}
            isReadOnly={isReadOnly}
            autoFocus={false}
            required={isRequired}
        />
    );
};

export default DynamicNumberQuestion;
