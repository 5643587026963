import React from 'react';

interface Props {
    text: string;
}

const FormErrorText: React.FC<Props> = ({ text }) => {
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .text-error {
                    margin: 0 0 grid(4) 0;

                    label,
                    label * {
                        @include text-error();
                    }
                }
            `}</style>
            {<div className="text-error helper">{text}</div>}
        </>
    );
};

export default FormErrorText;
