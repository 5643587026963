import Button from '@components/Button';
import TabBar from '@components/TabBar';
import { TTabBarItemIcon } from '@components/TabBar/TabBarItemIcon';
import useResetConsignment from '@effects/useResetConsignment';
import { SectionName } from '@utils/enums';
import MLALogger from '@utils/logger';
import React, { useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router';

interface Props {
    headers: {
        title: string;
        section: SectionName;
        handler: (sectionName: SectionName, previousSectionName: SectionName) => void;
        getIcon: (sectionName: SectionName) => TTabBarItemIcon | undefined;
        hidden?: boolean;
    }[];
    footer?: {
        onPrev?: (args: any) => Promise<boolean>;
        onNext?: (args: any) => Promise<boolean>;
        onCancel?: () => void;
        onSubmit?: () => void;
        showSubmit?: boolean;
    };
    children: React.ReactNode;
}

export interface IWizardReducerState {
    activeIndex: number;
}

type TActionType = 'nextPage' | 'prevPage' | 'set';
export interface IWizardReducerAction {
    type: TActionType;
    key?: keyof IWizardReducerState;
    payload?: any;
}

function reducer(state: IWizardReducerState, action: IWizardReducerAction) {
    switch (action.type) {
        case 'set':
            MLALogger.Log(['Wizard'], { action });
            return {
                ...state,
                [action.key!]: action.payload,
            };
        default:
            throw new Error('Unable to find matching action');
    }
}

const Wizard: React.FC<Props> = ({ headers, children, footer }) => {
    const { section: sectionRoute } = useParams<any>();
    const history = useHistory();

    const [state, dispatch] = useReducer(reducer, { activeIndex: headers.findIndex((x) => x.section === sectionRoute) });

    // EPLAT-294 Need to reset the store before leaving the wizard, otherwise
    // the state gets carried to other consignments and could cause saving issues.
    // Same functionality as saveOnExit() in NavigationBar.tsx (Back to Consignments button)
    const resetGlobalConsignment = useResetConsignment();

    useEffect(() => {
        MLALogger.Log(['Wizard'], { message: 'sectionRoute or headers changed', sectionRoute, headers });
        dispatch({ type: 'set', key: 'activeIndex', payload: headers.findIndex((x) => x.section === sectionRoute) });
        // eslint-disable-next-line
    }, [sectionRoute]);

    useEffect(() => {
        MLALogger.Log(['Wizard'], { message: 'Active Index has changed', activeIndex: state.activeIndex, sectionRoute });
        // currentIndex is where we currently are, state.activeIndex is where we want to be
        const currentIndex = headers.findIndex((x) => x.section === sectionRoute);
        if (currentIndex !== state.activeIndex) {
            const header = headers[state.activeIndex];
            const previousHeader = headers[currentIndex].section;
            header.handler(header.section, previousHeader);
        }
        const resize = setTimeout(() => {
            // Send resize for auto resizing components
            // IE11
            let event = null;
            if (typeof Event === 'function') {
                event = new Event('resize');
            } else {
                event = document.createEvent('Event');
                event.initEvent('resize', true, true);
            }

            window.dispatchEvent(event);
        }, 100);
        return () => clearTimeout(resize);
        // eslint-disable-next-line
    }, [state.activeIndex]);

    // We are assuming children are all of type WizardStep for now. Inject state and dispatch to it

    const numberOfChildren = React.Children.count(children);

    // ENVDB-1050: function to get index of next available section
    const getAvailableSectionIndex = (index: number, direction: 'Next' | 'Prev') => {
        let isSectionDisable = headers[index].hidden;
        while (isSectionDisable) {
            direction === 'Next' ? index++ : index--;
            isSectionDisable = headers[index].hidden;
        }
        return index;
    };
    return (
        <div className="Wizard">
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .Wizard {
                    @media (min-width: $md-min) {
                        background: $color-white;
                        border: 1px solid $color-grey79;
                        padding: grid(2) grid(4) grid(8);
                        border-radius: 4px;

                        @media (prefers-color-scheme: dark) {
                            background: $color-black;
                        }
                    }

                    &--Content {
                        @media (min-width: $md-min) {
                            border-top: 1px solid $color-grey79;
                        }
                        padding: grid(10) 0;
                    }

                    &--Footer {
                        display: flex;
                        flex-direction: column;
                        .button-row {
                            display: flex;
                            gap: 1vw;
                        }

                        @media (max-width: $md-max) {
                            position: fixed;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            padding: grid(2);
                            z-index: 3;

                            background: $color-grey79;
                            @media (prefers-color-scheme: dark) {
                                background: darken($color-grey79, 80%);
                            }

                            :global(button) {
                                flex: 1;
                            }
                        }
                    }
                }
            `}</style>
            <TabBar
                activeIndex={state.activeIndex}
                items={headers.map((header) => ({
                    title: header.title,
                    handler: () => header.handler(header.section, headers[state.activeIndex].section),
                    icon: header.getIcon(header.section),
                    hidden: header.hidden ?? false,
                }))}
            />
            <div className="Wizard--Content">
                {React.Children.map(children, (child, index) => React.cloneElement(child as any, { ...(child as any)?.props, state, dispatch, hidden: state.activeIndex !== index }))}
            </div>
            {numberOfChildren > 1 && (
                <div className="Wizard--Footer">
                    <div className="button-row">
                        {state.activeIndex === 0 && (
                            <Button
                                buttonType="secondary"
                                onClick={() => {
                                    resetGlobalConsignment();
                                    footer?.onCancel?.();
                                }}
                            >
                                Back to Summary Page
                            </Button>
                        )}
                        {state.activeIndex > 0 && (
                            <Button
                                buttonType="secondary"
                                onClick={async () => {
                                    const index = getAvailableSectionIndex(state.activeIndex > 0 ? state.activeIndex - 1 : 0, 'Prev');
                                    const goPrev = footer?.onPrev ? await footer.onPrev(state.activeIndex) : true;

                                    if (goPrev) {
                                        MLALogger.Log(['Wizard'], { message: 'Previous', index });
                                        dispatch({ type: 'set', key: 'activeIndex', payload: index });
                                    }
                                }}
                            >
                                Previous
                            </Button>
                        )}
                        {state.activeIndex < numberOfChildren - 1 ? (
                            <Button
                                buttonType="primary"
                                buttonSize="medium"
                                onClick={async () => {
                                    const index = getAvailableSectionIndex((state.activeIndex + 1) % numberOfChildren, 'Next');
                                    const goNext = footer?.onNext ? await footer.onNext(state.activeIndex) : true;
                                    if (goNext) {
                                        MLALogger.Log(['Wizard'], { message: 'Next', index });
                                        dispatch({ type: 'set', key: 'activeIndex', payload: index });
                                    }
                                }}
                            >
                                Next
                            </Button>
                        ) : null}
                        {state.activeIndex === numberOfChildren - 1 && footer?.showSubmit ? (
                            <>
                                <Button
                                    buttonType="primary"
                                    onClick={async () => {
                                        MLALogger.Log(['Wizard'], 'Save draft');
                                        if (footer?.onPrev) {
                                            const valid = await footer.onPrev(state.activeIndex);
                                            if (valid) {
                                                resetGlobalConsignment();
                                                history.replace('/consignments');
                                            }
                                        }
                                    }}
                                >
                                    Save draft
                                </Button>
                                <Button
                                    buttonType="primary"
                                    onClick={async () => {
                                        MLALogger.Log(['Wizard'], 'Submit');
                                        if (footer?.onSubmit) {
                                            footer.onSubmit();
                                        }
                                    }}
                                >
                                    Submit Consignment
                                </Button>
                            </>
                        ) : null}
                    </div>
                    {state.activeIndex > 0 && (
                        <div className="button-row m-v-20">
                            <Button
                                buttonType="secondary"
                                onClick={() => {
                                    resetGlobalConsignment();
                                    footer?.onCancel?.();
                                }}
                            >
                                Back to Summary Page
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Wizard;
