import { actionTypes, useGlobalDispatch } from '@state';
import { useCallback } from 'react';

const useResetConsignment = () => {
    const globalDispatch = useGlobalDispatch();

    const resetConsignment = useCallback(() => {
        globalDispatch({ type: actionTypes.dynamicPendingAnswers.resetDynamic });
        globalDispatch({ type: actionTypes.staticPendingAnswers.resetStatic });
        globalDispatch({ type: actionTypes.originalAnswers.resetOriginal });
        globalDispatch({ type: actionTypes.meta.resetConsignmentMeta });
        globalDispatch({ type: actionTypes.copyErrors.reset });
    }, [globalDispatch]);

    return resetConsignment;
};

export default useResetConsignment;
