import { useAuth0 } from '@auth0/auth0-react';
import Button from '@components/Button';
import MLALogger from '@utils/logger';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import Navbar from './navbar';

// const MLALogo = require('@assets/images/mymla-logo.svg').default;

const Welcome: React.FC = () => {
    const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const history = useHistory();

    useEffect(() => {
        async function fetchAccessToken() {
            try {
                await getAccessTokenSilently();
            } catch (error) {
                MLALogger.Log(['Silent Authentication'], error, 'error');
            }
        }

        if (!isAuthenticated) {
            fetchAccessToken();
        } else {
            history.push('/consignments');
        }
    }, [isAuthenticated, getAccessTokenSilently, history]);

    return (
        <>
            <Navbar />
            <div className="welcome-container flex-column">
                <div className="welcome-banner">
                    <div className="welcome-banner-section flex-column">
                        <div className="content">
                            <h1>WELCOME</h1>
                        </div>
                        <div className="content">
                            <h1>TO eNVD</h1>
                        </div>
                        <div className="content welcome-description">AUSTRALIA'S ELECTRONIC NATIONAL VENDOR DECLARATION</div>
                    </div>
                    <div className="welcome-banner-section flex-column">
                        <div className="login-register-container flex-center-column flex-center">
                            <div className="action-btns flex-column">
                                <Button
                                    buttonType="primary"
                                    className="action-btns--login-btn"
                                    onClick={() => {
                                        loginWithRedirect({
                                            appState: {
                                                returnTo: '/consignments',
                                            },
                                        });
                                    }}
                                >
                                    LOG IN VIA
                                    <img className="mymla-logo" src={'/img/myMLA_logo.png'} alt="my MLA" />
                                </Button>
                                <div className="btn-divider flex-row">
                                    <hr />
                                    <label>OR</label>
                                    <hr />
                                </div>
                                <Button
                                    buttonType="primary"
                                    className="action-btns--register-btn"
                                    onClick={() => {
                                        loginWithRedirect({
                                            authorizationParams: {
                                                screen_hint: 'signup',
                                            },
                                        });
                                    }}
                                >
                                    SIGN UP TO
                                    <img className="mymla-logo" src={'/img/myMLA_logo_transparent.png'} alt="my MLA" />
                                </Button>
                                <div className="help-text">
                                    <p>
                                        Need help setting up a myMLA account?
                                        <br></br>
                                        Visit{' '}
                                        <a
                                            href="https://www.integritysystems.com.au/on-farm-assurance/how-to-guides/how-to-create-a-new-mymla-account-and-link-your-integrity-system-accounts/"
                                            className="help-link"
                                        >
                                            How to guide.
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="login-reg-btn-mob flex-center-column">
                    <div className="action-btns flex-column">
                        <Button
                            buttonType="primary"
                            className="action-btns--login-btn"
                            onClick={() => {
                                loginWithRedirect({
                                    appState: {
                                        returnTo: '/consignments',
                                    },
                                });
                            }}
                        >
                            LOG IN VIA
                            <img className="mymla-logo" src={'/img/myMLA_logo.png'} alt="my MLA" />
                        </Button>
                        <div className="btn-divider flex-row">
                            <hr />
                            <label>OR</label>
                            <hr />
                        </div>
                        <Button
                            buttonType="primary"
                            className="action-btns--register-btn"
                            onClick={() => {
                                loginWithRedirect({
                                    authorizationParams: {
                                        screen_hint: 'signup',
                                    },
                                });
                            }}
                        >
                            SIGN UP TO
                            <img className="mymla-logo" src={'/img/myMLA_logo_transparent.png'} alt="my MLA" />
                        </Button>
                        <div className="help-text">
                            <p>
                                Need help setting up a myMLA account?
                                <br></br>
                                Visit{' '}
                                <a
                                    href="https://www.integritysystems.com.au/on-farm-assurance/how-to-guides/how-to-create-a-new-mymla-account-and-link-your-integrity-system-accounts/"
                                    className="help-link"
                                >
                                    How to guide.
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="welcome-body flex-column flex-center">
                    <h2>Access your electronic National Vendor Declaration account</h2>
                    <p>Welcome to the electronic National Vendor Declaration (eNVD) platform - a faster, easier way to complete livestock consignments.</p>
                    <p>
                        Create and link an eNVD account in your personalised and secure myMLA dashboard. You can access all your integrity accounts from myMLA, including eNVD, Livestock Production
                        Assurance (LPA) and the National Livestock Identification System (NLIS) with a single sign-on.
                    </p>
                    <p>Login or register now to access digital consignments.</p>
                </div>
            </div>
            <div className="footer-row-container">
                <div className="footer-row">
                    <div className="footer-useful-links-text">
                        <h2>eNVD</h2>
                    </div>
                    <div className="footer-useful-links-text">
                        <h2>SUPPORT</h2>
                    </div>
                    <div className="footer-useful-links-text">
                        <h2>CONTACT US</h2>
                    </div>
                </div>
                <div className="footer-row">
                    <div className="footer-useful-links-text">
                        <a href="https://www.integritysystems.com.au/on-farm-assurance/national-vendor-declaration-nvd/">About eNVD</a>
                    </div>
                    <div className="footer-useful-links-text">
                        <a href="https://www.integritysystems.com.au/on-farm-assurance/how-to-guides/how-to-create-a-new-mymla-account-and-link-your-integrity-system-accounts/">myMLA How to guide </a>
                    </div>
                    <div className="footer-useful-links-text">
                        <a href="mailto:envd@integritysystems.com.au">envd@integritysystems.com.au</a>
                    </div>
                </div>
                <div className="footer-row">
                    <div className="footer-useful-links-text">
                        <a href="https://www.integritysystems.com.au/on-farm-assurance/how-to-guides/how-to-create-eNVD/">Starting with eNVD</a>
                    </div>
                    <div className="footer-useful-links-text">
                        <a href="https://www.integritysystems.com.au/help/faqs/">FAQs</a>
                    </div>
                    <div className="footer-useful-links-text">
                        <p>PO Box 1961, North Sydney NSW 2059</p>
                        <a href="tel:1800 683 111">1800 683 111</a>
                    </div>
                </div>
            </div>
            <div className="footer-terms-policies-container">
                <div className="footer-terms-policies">
                    <a href="https://www.integritysystems.com.au/on-farm-assurance/national-vendor-declaration-nvd/envd-app/terms-of-use/">eNVD Terms of Use</a>
                    <a href="https://www.integritysystems.com.au/terms-and-conditions/">Website Terms and Conditions</a>
                    <a href="https://www.integritysystems.com.au/privacy-website/">Privacy Policy</a>
                    <div>
                        <p>Integrity Systems Company Limited (ISC Ltd)</p>
                        <p>ABN 34 134 745 038 © 2023</p>
                        <img className="footer-logo" src={'/img/ISO.svg'} alt="my MLA" />
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                    @import 'mixins';
                    @import 'vars';
                    @import 'layout';

                    .welcome-container {
                        width: 100vw;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        overflow: auto;
                        position: absolute;
                        bottom: 213px;
                        top: 76px;
                    }

                    .welcome-banner {
                        display: flex;
                        height: 581px;
                        align-items: center;
                        justify-content: space-between;
                        background-image: url('/img/eNVD-landing-page-bg.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: cover;
                    }

                    .welcome-banner-section {
                        gap: 30px;
                        padding: 85px;
                    }

                    .login-reg-btn-mob {
                        display: none;
                    }

                    .content {
                        color: #ffffff;
                    }

                    .content > h1 {
                        font-size: 66px;
                        font-weight: 400;
                        filter: drop-shadow(0px 4px 4px 0px #00000040);
                    }

                    .login-register-container {
                        background-color: #fafcfd8c;
                        border-radius: 8px;
                        padding: 2rem 2rem 1rem 2rem;
                    }

                    .action-btns {
                        justify-content: space-evenly;
                        align-items: center;
                        gap: 6px;
                        :global(&--login-btn) {
                            background-color: $color-white !important;
                            color: $isc-blue !important;
                            width: calc(min(60vw, 300px));
                            height: calc(min(9.5vw, 50px));
                            gap: 12px;
                            &:hover {
                                background-color: $color-fade !important;
                            }
                        }

                        :global(&--register-btn) {
                            width: calc(min(60vw, 300px));
                            height: calc(min(9.5vw, 50px));
                            gap: 12px;
                        }
                    }

                    .mymla-logo {
                        width: 60px !important;
                    }

                    .footer-logo {
                        height: calc(min(8.5vw, 50px));
                    }

                    .btn-divider {
                        align-items: center;
                        width: calc(min(62vw, 328px));
                        height: calc(min(9.5vw, 58px));
                    }

                    .btn-divider hr {
                        background: $color-black;
                        border: 1px solid $color-black;
                        width: 11vw;
                        margin-top: 10px;
                    }

                    .btn-divider label {
                        color: $color-black;
                        padding: 8px;
                    }

                    .help-link {
                        color: rgba(0, 73, 118, 1);
                    }

                    .help-text {
                        text-align: center;
                        color: $generic-text;
                    }

                    .help-text > p {
                        font-size: 11px;
                    }

                    .welcome-body {
                        background-color: $color-grey79;
                        text-align: center;
                        gap: 5px;
                        height: 40vh;
                        padding: 10px 15rem;
                    }

                    .footer-row-container {
                        position: absolute;
                        bottom: 47px;
                        overflow: hidden;
                        width: 100%;
                        background-color: $isc-blue;
                    }

                    .footer-row {
                        width: 70%;
                        margin: auto;
                    }

                    .footer-row:after {
                        content: '';
                        display: table;
                        clear: both;
                    }

                    .footer-useful-links-text {
                        float: left;
                        width: 33.33%;
                        padding: 0px 20px;
                        color: $color-white;
                    }

                    .footer-useful-links-text > a,
                    .footer-useful-links-text > p {
                        color: $color-white;
                        border-bottom: 1px solid $light-grey;
                        padding: 10px 0px;
                        font-size: 11px;
                        width: 100%;
                        text-decoration: none;
                    }

                    .footer-useful-links-text > a:hover {
                        text-decoration: underline;
                    }

                    .footer-useful-links-text > a:nth-child(3) {
                        border-bottom: none;
                    }

                    .footer-useful-links-text > h2 {
                        padding: 10px 0px;
                        color: $color-white;
                        border-bottom: 1px solid $color-white;
                    }

                    .footer-terms-policies-container {
                        position: absolute;
                        bottom: 0;
                        overflow: hidden;
                        width: 100%;
                        background-color: $color-black;
                        display: flex;
                        justify-content: center;
                    }

                    .footer-terms-policies {
                        width: 70%;
                        display: flex;
                        padding: 0px 10px;
                        justify-content: space-between;
                    }

                    .footer-terms-policies div {
                        display: flex;
                        gap: 3px;
                    }

                    .footer-logo {
                        height: 100%;
                    }

                    .footer-terms-policies a,
                    .footer-terms-policies p {
                        color: $color-white;
                        font-size: 11px;
                        text-decoration: none;
                        display: flex;
                        margin: 0px;
                        align-items: center;
                    }

                    .footer-terms-policies a:hover {
                        text-decoration: underline;
                    }

                    @media only screen and (max-width: $xl-min) {
                        .footer-row-container {
                            position: absolute;
                            bottom: 49px;
                            overflow: hidden;
                            width: 100%;
                            background-color: $isc-blue;
                        }

                        .footer-row {
                            width: 85%;
                            margin: auto;
                        }

                        .footer-terms-policies {
                            width: 85%;
                            display: flex;
                        }

                        .footer-terms-policies a,
                        .footer-terms-policies p {
                            font-size: 8px;
                            text-decoration: none;
                        }

                        .footer-logo {
                            height: calc(min(8.5vw, 50px));
                        }

                        .welcome-body {
                            padding: 10px 5rem;
                        }

                        .content > h1 {
                            font-size: 66px;
                        }

                        .welcome-banner-section {
                            gap: 20px;
                            padding: 30px;
                        }
                    }

                    @media only screen and (min-width: 601px) and (max-width: 1280px) {
                        .welcome-container {
                            bottom: 224px;
                            top: 78px;
                        }

                        .welcome-banner {
                            display: flex;
                            height: 100vh;
                            align-items: center;
                            justify-content: space-between;
                            background-image: url('/img/eNVD-landing-page-bg.svg');
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                        }

                        .action-btns {
                            gap: 0px;

                            font-size: 10px;
                            :global(&--login-btn) {
                                width: calc(min(40vw, 200px));
                                height: calc(min(9.5vw, 40px));
                            }

                            :global(&--register-btn) {
                                width: calc(min(40vw, 200px));
                                height: calc(min(9.5vw, 40px));
                            }
                        }

                        .btn-divider {
                            align-items: center;
                            width: calc(min(30vw, 228px));
                            height: calc(min(9.5vw, 58px));
                        }

                        .welcome-body {
                            display: none;
                        }

                        .footer-row {
                            width: 100%;
                        }

                        .footer-terms-policies {
                            width: 100%;
                        }
                    }

                    @media screen and (max-width: 600px) {
                        .welcome-container {
                            top: 75px;
                        }
                        .welcome-banner {
                            display: block;
                            padding-top: 20px;
                            padding-bottom: 10px;
                        }

                        .footer-row-container {
                            display: none;
                        }

                        .welcome-body {
                            display: none;
                        }

                        .login-register-container {
                            display: none;
                        }

                        .login-reg-btn-mob {
                            display: flex;
                            justify-content: center;
                            padding: 30px 0px;
                        }

                        .action-btns {
                            justify-content: center;
                            align-items: center;
                            gap: 18px;
                            :global(&--login-btn) {
                                background-color: $color-white !important;
                                color: $isc-blue !important;
                                width: 75vw;
                                height: calc(min(13.5vw, 70px));
                                gap: 12px;
                                &:hover {
                                    background-color: $color-fade !important;
                                }
                            }

                            :global(&--register-btn) {
                                width: 75vw;
                                height: calc(min(13.5vw, 70px));
                                gap: 12px;
                            }
                        }

                        .btn-divider {
                            width: calc(min(92vw, 328px));
                        }

                        .btn-divider hr {
                            width: 75vw;
                        }

                        .welcome-banner {
                            height: 281px;
                            text-align: center;
                        }

                        .welcome-banner-section {
                            gap: 15px;
                            padding: 0px;
                        }

                        .content > h1 {
                            font-size: 36px;
                        }

                        .footer-useful-links-text {
                            width: 100%;
                        }

                        .footer-terms-policies-container {
                            flex-direction: column;
                        }

                        .footer-terms-policies {
                            width: 100%;
                            flex-direction: column;
                        }

                        .footer-terms-policies div {
                            display: flex;
                            flex-direction: column;
                        }

                        .footer-terms-policies a,
                        .footer-terms-policies p,
                        .footer-terms-policies img {
                            justify-content: center;
                            padding: 0px;
                        }
                    }
                `}
            </style>
        </>
    );
};

export default Welcome;
