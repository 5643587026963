import classnames from 'classnames';
import React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    containerClass?: string;
}

const RadioInput: React.FunctionComponent<Props> = ({ containerClass, ...radioProps }) => {
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .rad {
                    display: flex;
                    cursor: pointer;
                    user-select: none;
                    margin: 0 grid(2) 0 0;

                    /* IE11 fix */
                    min-width: 20px;
                    min-height: grid(2);

                    /* Hide the browser's default radio button */
                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }

                    /* Create a custom radio button */
                    .radio {
                        border-radius: 50%;
                        overflow: hidden;
                        position: relative;
                        display: inline-block;
                        height: grid(5);
                        width: grid(5);
                        background-color: $color-white;
                        border: 1.5px solid $color-secondary;

                        @media (prefers-color-scheme: dark) {
                            background: darken($color-white, 90%);
                        }
                    }

                    /* Style the indicator (dot/circle) */
                    .radio:after {
                        content: '';
                        position: absolute;
                        display: none;
                        top: 0px;
                        bottom: 0px;
                        left: 0px;
                        right: 0px;
                        border-radius: 50%;
                        background: $color-secondary;
                        margin: 2.5px;
                    }
                }

                /* Show the indicator (dot/circle) when checked */
                .rad input:checked ~ .radio:after {
                    display: block;
                }
            `}</style>
            <span className={classnames('rad', containerClass)}>
                <input type="radio" {...radioProps} />
                <span className={'radio'} />
            </span>
        </>
    );
};

export default RadioInput;
