import Button from '@common/components/Button';
import Dropdown from '@common/components/Form/Dropdown';
import { ALL_SPECIES, speciesEnumToName } from '@common/utils/enum-transformers';
import { ALL_NFAS_FORMS, getFormAlias } from '@common/utils/form-alias';
import React, { useState } from 'react';

interface LocalState {
    species: string;
}

interface Props {
    state: LocalState;
    setState: React.Dispatch<React.SetStateAction<Partial<LocalState>>>;
    onPrevious(): void;
    onSubmit(): void;
    forms: ReadonlyArray<any>;
}

const ConsignmentAddStep2: React.FunctionComponent<Props> = ({ state, setState, onPrevious, onSubmit, forms }: Props) => {
    const [hasChanged, setHasChanged] = useState<boolean>(false);
    return (
        <>
            <div className="title-section">
                <h1>Select species</h1>
            </div>
            <div className="wizard-step">
                <Dropdown
                    value={state.species}
                    onChange={(x) => {
                        setState((s) => ({
                            ...s,
                            species: x.id,
                            forms: forms
                                .filter((f) => f.species === x.id)
                                .map((f) => getFormAlias(f.name))
                                .coalesce()
                                .map((f) => ({
                                    id: f.program,
                                    title: f.alias,
                                    subtitle: f.subtitle,
                                    image: f.image,
                                    exclusive: f.program.startsWith('NFAS') ? ALL_NFAS_FORMS.filter((nfas) => f.program !== nfas.program).map((nfas) => nfas.program) : [],
                                })),
                        }));
                        setHasChanged(true);
                    }}
                    options={ALL_SPECIES.map((x) => ({ id: x.toString(), title: speciesEnumToName(x) }))}
                />

                <Button buttonType="secondary" className="m-r-12" children="Previous step" action={onPrevious} />
                <Button
                    buttonType="primary"
                    children="Next step"
                    action={() => {
                        // Check to see if the user has selected any species first, otherwise assume cattle
                        if (!hasChanged) {
                            setState((s) => ({
                                ...s,
                                species: state.species,
                                forms: forms
                                    .filter((f) => f.species === state.species)
                                    .map((f) => getFormAlias(f.name))
                                    .coalesce()
                                    .map((f) => ({
                                        id: f.program,
                                        title: f.alias,
                                        subtitle: f.subtitle,
                                        image: f.image,
                                        exclusive: f.program.startsWith('NFAS') ? ALL_NFAS_FORMS.filter((nfas) => f.program !== nfas.program).map((nfas) => nfas.program) : [],
                                    })),
                            }));
                        }

                        onSubmit();
                    }}
                />
            </div>
        </>
    );
};

export default ConsignmentAddStep2;
