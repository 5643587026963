import { IConsignment } from '@typings';
import MLALogger from '@utils/logger';
import { graphql } from 'relay-hooks';

import { CreateOrSaveTemplateInput } from './__generated__/createOrSaveTemplateMutation.graphql';

export const createOrSaveTemplateMutation = graphql`
    mutation createOrSaveTemplateMutation($input: CreateOrSaveTemplateInput!, $envdAccountId: String!) {
        createOrSaveTemplate(input: $input, envdAccountId: $envdAccountId) {
            data {
                name
                basedOn
                ...TemplateEditTemplateFragment @relay(mask: false)
            }
        }
    }
`;

const commitAsync = async (mutate: any, template: CreateOrSaveTemplateInput, envdAccountId: String): Promise<Partial<IConsignment>> => {
    MLALogger.Log(['createConsignment', 'commitAsync', 'Sending'], { template });
    try {
        const data = await mutate({
            variables: { input: template, envdAccountId },
            onCompleted: (response: any, error: any) => {
                MLALogger.Log(['createOrSaveTemplate', 'commitAsync'], { response, error });
                if (error) {
                    MLALogger.Log(['createOrSaveTemplate', 'commitAsync'], { error }, 'error');
                } else {
                    MLALogger.Log(['createOrSaveTemplate', 'commitAsync'], { response, data: response.createOrSaveTemplate.data, request: template });
                }
            },
        });

        return data.createOrSaveTemplate.data;
    } catch (error) {
        MLALogger.Log(['createOrSaveTemplate'], error, 'error');
        throw error;
    }
};

export default commitAsync;
