import { DeleteIcon } from '@assets/icons';
import Button from '@components/Button';
import ClickableIcon from '@components/ClickableIcon';
import { actionTypes, useGlobalState } from '@state';
import { IAnswer } from '@typings';
import { getAllChildQuestions } from '@utils/question-getter';
import { sortByIndex, sortByNumberDesc } from '@utils/question-sort';
import deepmerge from 'deepmerge';
import React from 'react';

import DynamicQuestion, { IDynamicQuestionProps } from './DynamicQuestion';

const DynamicTableQuestion: React.FC<IDynamicQuestionProps> = ({ question, index }) => {
    const [{ dynamicPendingAnswers, originalAnswers }, dispatch] = useGlobalState();

    // This is a special case where we want to re-use the questions up to X amount of times.
    // When reading, this is based on the highest index for this question being a parent
    // But this question itself is only asked once, the children are the ones that are repeated
    const allChildQuestions = getAllChildQuestions(question);

    let childAnswers = dynamicPendingAnswers?.answers.filter((answer) => allChildQuestions.some((cq) => cq.id === answer.questionId)) ?? [];
    if (childAnswers.length === 0 || childAnswers.length < question.childQuestions.length) {
        childAnswers = deepmerge(
            originalAnswers?.filter((answer) => question.childQuestions.some((cq) => cq.id === answer.questionId)),
            childAnswers
            // { arrayMerge: overwriteMerge }
        );
    }
    childAnswers = childAnswers.sort(sortByIndex);

    // If we don't have any, then make sure not to insert at the incorrect index
    const highestIndex = ((childAnswers?.map((x) => x.index)?.filter((x) => !!x) as NonNullable<number[]>)?.sort(sortByNumberDesc)?.last() ?? 0) + 1;

    const isReadOnly = question.readOnly;
    const numberOfRows = allChildQuestions.length > 0 ? Math.ceil(childAnswers.length / allChildQuestions.length) : 0;

    const hideTableQuestions = childAnswers.every((ca) => ca.index === null && ca.value === null);

    return (
        <section className="TableQuestions" key={`${question.id}-${index}`}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                .TableQuestions {
                    margin: grid(4) 0;
                    &--Footer {
                        padding: grid(4);
                        border: 1px solid $color-line;
                        margin-top: -1px;
                    }

                    :global(.tbl-row.row-header) {
                        position: relative;
                        top: 0;
                    }
                    :global(.tbl-row .form-row) {
                        margin-bottom: 0;
                    }

                    .tbl-cell-err {
                        justify-content: center;
                    }
                }

                @media all and (max-width: $sm-max) {
                    .tbl-row {
                        :not(:last-child) {
                            border-bottom: 1px solid $color-line;
                            margin-bottom: 8px;
                        }
                        > * {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            .cell-title {
                                margin-bottom: 4px;
                            }
                        }
                        > .tbl-cell-err {
                            align-items: center;
                        }
                    }
                }
            `}</style>
            <div className="tbl tbl-collapse">
                <div className="tbl-row row-header">
                    {question.childQuestions
                        .map((cq) => cq.text)
                        .map((header) => (
                            <div key={header} className={`tbl-cell-${Math.floor(90 / question.childQuestions.length)} column-heading`}>
                                {header}
                            </div>
                        ))}
                    <div className={`tbl-cell-10 column-heading`} />
                </div>

                {!hideTableQuestions &&
                    new Array(numberOfRows).fill(0).map((_, rowIndex) => (
                        <div key={rowIndex} className="tbl-row">
                            {allChildQuestions.map((cq) => (
                                <div key={`${cq.id}-${rowIndex}`} className={`tbl-cell-${Math.floor(90 / question.childQuestions.length)}`}>
                                    <div className="cell-title">{cq.text}</div>
                                    <div className="cell-content">
                                        <DynamicQuestion question={cq} index={rowIndex} showTitle={false} />
                                    </div>
                                </div>
                            ))}

                            <div className="tbl-cell-10 tbl-cell-err">
                                {!isReadOnly && (
                                    <ClickableIcon
                                        icon={<DeleteIcon />}
                                        alt="Remove"
                                        onClick={() => {
                                            // Similar logic to normal repeatables, delete all the questions at this index then shuffle the higher indexes down
                                            // For deleting, we need to send the entire question set to pending because of the index changes
                                            const deletedAnswers: IAnswer[] = [];

                                            for (const cq of allChildQuestions) {
                                                childAnswers = childAnswers
                                                    .map((x) => {
                                                        let retVal: IAnswer | null = x;

                                                        if (x.questionId === cq.id && x.index === rowIndex) {
                                                            retVal = null;
                                                            deletedAnswers.push(x);
                                                        }

                                                        return retVal;
                                                    })
                                                    .coalesce();
                                            }

                                            if (rowIndex === 0 && highestIndex === 1) {
                                                // This was the last one, so we want to delete everything
                                                // To do this, we need to send up just one value with null for the index
                                                dispatch({
                                                    type: actionTypes.dynamicPendingAnswers.removeBatchDynamic,
                                                    payload: [...deletedAnswers],
                                                });

                                                const deleteChildAnswers = allChildQuestions.map((x) => x.id).map((x) => ({ questionId: x, index: null, value: null }));
                                                dispatch({ type: actionTypes.dynamicPendingAnswers.updateBatchDynamic, payload: deleteChildAnswers });
                                            } else if (rowIndex < highestIndex) {
                                                // Shuffle down the indexes that are higher by -1
                                                const amountToAdjust = highestIndex - rowIndex;
                                                for (let i = 1; i <= amountToAdjust; i++) {
                                                    const updatedIndex = rowIndex + i;

                                                    // TODO: Use functional method
                                                    for (const cq of allChildQuestions) {
                                                        // In-place update everything with this index to itself - 1, start at the lowest digit and move right for no conflicts
                                                        childAnswers = childAnswers.map((x) => (x.questionId === cq.id && x.index === updatedIndex ? { ...x, index: x.index - 1 } : x));
                                                    }
                                                }

                                                // Since we are sending the entire stack again, we should be deleting all indexes
                                                dispatch({
                                                    type: actionTypes.dynamicPendingAnswers.removeBatchDynamic,
                                                    payload: [...deletedAnswers, ...allChildQuestions.map((x) => x.id).map((x) => ({ questionId: x, index: highestIndex - 1 }))],
                                                });

                                                // Now that all adjustments have been made, we can save the newly updated answers
                                                dispatch({
                                                    type: actionTypes.dynamicPendingAnswers.updateBatchDynamic,
                                                    payload: childAnswers,
                                                    // payload: [...childAnswers, ...deletedAnswers.map(x => ({ ...x, value: null, index: highestIndex-1}))],
                                                });
                                            } else {
                                                dispatch({
                                                    type: actionTypes.dynamicPendingAnswers.removeBatchDynamic,
                                                    payload: [...allChildQuestions.map((x) => x.id).map((x) => ({ questionId: x, index: highestIndex - 1 }))],
                                                });
                                            }
                                        }}
                                        classNames={'link'}
                                    />
                                )}
                            </div>
                        </div>
                    ))}
            </div>
            {!isReadOnly && (
                <div className="TableQuestions--Footer">
                    <Button
                        buttonType="secondary"
                        buttonSize="xsmall"
                        action={() => {
                            // Insert a new empty row at the highest index
                            // Only if it wasn't previously a deletion (check the state, if it is, set at highestIndex-1) and delete the null indexed ones
                            if (hideTableQuestions) {
                                dispatch({
                                    type: actionTypes.dynamicPendingAnswers.removeBatchDynamic,
                                    payload: childAnswers,
                                });
                            }
                            dispatch({
                                type: actionTypes.dynamicPendingAnswers.updateBatchDynamic,
                                payload: allChildQuestions.map((cq) => ({ questionId: cq.id, index: hideTableQuestions ? highestIndex - 1 : highestIndex, value: '' })),
                            });
                        }}
                    >
                        {question.tip}
                    </Button>
                </div>
            )}
        </section>
    );
};

export default DynamicTableQuestion;
