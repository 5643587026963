import React from 'react';

import TabBarItemIcon, { TTabBarItemIcon } from './TabBarItemIcon';

interface Props {
    icon?: TTabBarItemIcon;
    title: string;
    isActive: boolean;
    onClick: () => void;
}

const TabBarItem: React.FunctionComponent<Props> = ({ title, isActive, onClick, icon }) => {
    const enableDataTestId = process.env.NODE_ENV === 'test' || Boolean(process.env.CI);

    return (
        <button className={`tabbar-item ${isActive ? 'active' : ''}`} onClick={onClick} data-test-id={enableDataTestId ? `tabbar-item-${title.replace(/[ ]/g, '-')}` : undefined}>
            <TabBarItemIcon icon={icon} />
            <span>{title}</span>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .tabbar-item {
                    background: none;
                    border: none;
                    text-decoration: none;
                    display: block;
                    cursor: pointer;
                    padding: grid(2) grid(2) grid(4);
                    color: $color-copy;
                    border-bottom: 2px solid transparent;

                    margin: 0 0 -1px;

                    @media (prefers-color-scheme: dark) {
                        color: lighten($color-copy, 85%);
                    }

                    &.active {
                        color: $color-link;
                        border-color: $color-link;

                        @media (prefers-color-scheme: dark) {
                            color: lighten($color-link, 40%);
                        }
                    }

                    &:hover {
                        color: $color-link-hover;

                        @media (prefers-color-scheme: dark) {
                            color: lighten($color-link-hover, 40%);
                        }
                    }

                    @include text-midbold();

                    display: flex;
                    align-items: center;

                    :global(svg) {
                        display: inline-block;
                    }
                }
            `}</style>
        </button>
    );
};
export default TabBarItem;
