import FormRow, { HelperLocation } from '@components/Form/FormRow';
import RadioInput from '@components/Form/RadioInput';
import { SelectableListItemProps } from '@components/Form/SelectableList';
import classnames from 'classnames';
import React from 'react';

const RadioList: React.FunctionComponent<SelectableListItemProps> = ({ items, errorText, labelText, helperText, isValid, isReadOnly, horizontal = false, required, onChange }) => {
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .Radio-Item {
                    cursor: pointer;
                    display: flex;
                    margin-bottom: grid(2);
                    align-items: start;
                    user-select: none;

                    &--Active {
                        border-color: $color-primary;
                    }

                    .Content {
                        display: flex;
                        flex-direction: column;
                        overflow: auto;
                    }

                    > img {
                        width: grid(6);
                        height: grid(6);
                        margin: 0 grid(2) 0 grid(2);
                    }

                    &--ReadOnly {
                        cursor: not-allowed;
                    }
                }

                .horizontal {
                    display: flex;
                    flex-wrap: wrap;
                    .Radio-Item {
                        margin-right: grid(8);
                    }
                }
            `}</style>

            <FormRow errorTextAtTop errorText={errorText} isValid={isValid} helperText={helperText} labelText={labelText} helperLocation={HelperLocation.Top} readOnly={isReadOnly} required={required}>
                <div className={classnames({ horizontal })}>
                    {items
                        .filter((i) => !isReadOnly || i.selected)
                        .map((item) => {
                            const isExclusive = Boolean(item.exclusive && items.some((x) => item.exclusive?.includes(x.id)));

                            return (
                                <label
                                    className={classnames('Radio-Item', {
                                        'Radio-Item--Active': item.selected,
                                        'Radio-Item--ReadOnly': isReadOnly,
                                    })}
                                    key={item.id}
                                >
                                    {!isReadOnly && !isExclusive && <RadioInput checked={item.selected || false} onChange={() => onChange(item)} />}
                                    {isExclusive && <RadioInput checked={item.selected || false} disabled />}

                                    {item.image && <img src={item.image} alt="Form" />}
                                    <div className="Content">
                                        <div className="small">{item.title}</div>
                                        {item.subtitle && <div className="helper muted m-t-4">{item.subtitle}</div>}
                                    </div>
                                </label>
                            );
                        })}

                    {isReadOnly && items.length > 0 && !items.some((i) => i.selected) && <p>Not answered</p>}
                </div>
            </FormRow>
        </>
    );
};

export default RadioList;
