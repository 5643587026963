import Config from '@config';
import { ALL_DEPRECATED_FORMS, mapFAToForm } from '@utils/form-alias';

interface Consignment {
    number: string;
    forms?: {
        type: string;
    }[];
}

export function fromPriorSystem(consignment: Consignment) {
    const numStr = consignment.number.substring(consignment.number.indexOf('-') + 1);
    const num = parseInt(numStr, 10);
    return num < Config.FIRST_CONSIGNMENT_NUMBER;
}

const deprecatedForms = ALL_DEPRECATED_FORMS.map(mapFAToForm);

export function containsDeprecatedForms(consignment: Consignment) {
    if (consignment.forms) {
        const types = consignment.forms.map((x) => x.type);
        if (types.some((x) => deprecatedForms.includes(x))) {
            return true;
        }
    }

    return false;
}
