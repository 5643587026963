import { IConsignment } from '@typings';
import { UNKNOWN_PIC } from '@utils/constants';
import MLALogger from '@utils/logger';
import { graphql } from 'relay-hooks';

import { ConsignmentEditQueryResponse } from '../queries/__generated__/ConsignmentEditQuery.graphql';
import { CopyConsignmentInput } from './__generated__/copyConsignmentMutation.graphql';
import { cleanConsignmentPIC } from './createOrSaveConsignment';

export const mutation = graphql`
    mutation copyConsignmentMutation($input: CopyConsignmentInput!, $envdAccountId: String!) {
        copyConsignment(input: $input, envdAccountId: $envdAccountId) {
            data {
                id
                number
            }
        }
    }
`;

const commitAsync = async (mutate: any, consignment: CopyConsignmentInput, envdAccountId: String, dataId?: string, query?: ConsignmentEditQueryResponse): Promise<Partial<IConsignment>> => {
    MLALogger.Log(['copyConsignment', 'commitAsync', 'Sending'], { consignment });
    // ENVDB-896: Adding AAAAAAAA to destination pic if destination pic is empty
    // as graphql doesnt allow empty destination pic
    if (consignment?.destination?.pic === '') {
        consignment = cleanConsignmentPIC(consignment, 'destination', UNKNOWN_PIC);
    }
    try {
        const data = await mutate({
            variables: { input: consignment, envdAccountId },
            onCompleted: (response: any, error: any) => {
                if (error) {
                    MLALogger.Log(['copyConsignment', 'commitAsync'], { error }, 'error');
                } else {
                    MLALogger.Log(['copyConsignment', 'commitAsync'], { response, data: response.copyConsignment.data, request: consignment });
                }
            },
        });

        return data.copyConsignment.data;
    } catch (error) {
        MLALogger.Log(['copyConsignment'], error, 'error');
        throw error;
    }
};

export default commitAsync;
export { mutation as CopyConsignmentMutation };
