import { numberToMonthString } from '@components/Calendar/MonthPicker';
import FormRow from '@components/Form/FormRow';
import { useGlobalState } from '@state';
import { answerArrayMerge } from '@utils/deepmerge-strategies';
import deepmerge from 'deepmerge';
import React, { useEffect, useState } from 'react';

import { IDynamicQuestionProps } from './DynamicQuestion';

const DEFAULT_NA_TEXT = 'Not answered';

const DynamicReadOnlyQuestion: React.FC<IDynamicQuestionProps & { vertical?: boolean }> = ({ question, index, vertical = true, showTitle = true }) => {
    const [{ dynamicPendingAnswers, originalAnswers }] = useGlobalState();
    // We need to keep track of both instances of answers (with current edit overwriting original) as we do not copy over all the
    // data into a pending state
    const [answer, setAnswer] = useState<string>('');

    useEffect(() => {
        const updatedAnswers = deepmerge(originalAnswers, dynamicPendingAnswers.answers, { arrayMerge: answerArrayMerge });

        const updatedAnswer =
            question.type === 'MULTIPLE_CHOICE'
                ? updatedAnswers
                      .filter((x) => x.questionId === question.id)
                      .map((m) => m.value)
                      .sort((a, b) => a - b)
                      .join(', ')
                : updatedAnswers.firstOrDefault((x) => x.questionId === question.id && x.index === index)?.value ?? '';

        // HACK: Handle for DateTimeMonth
        if (question.type === 'DATE_MONTH') {
            setAnswer(numberToMonthString(Number.parseInt(updatedAnswer, 10)));
        } else {
            setAnswer(updatedAnswer);
        }
    }, [dynamicPendingAnswers.answers, originalAnswers, question.id, question.type, index]);

    if (vertical === true) {
        return (
            <FormRow readOnly labelText={showTitle ? question.text : ''}>
                {answer || DEFAULT_NA_TEXT}
            </FormRow>
        );
    }

    return (
        <div className="ReadOnly">
            <style jsx>{`
                @import 'vars';
                @import 'mixins';
                @import 'utils';
                .ReadOnly {
                    display: flex;
                    flex-wrap: wrap;

                    :global(& + .form-row) {
                        margin-top: grid(6);
                    }

                    :global(p) {
                        font-size: 14px;
                    }

                    &--Question,
                    &--Answer {
                        padding: grid(2) grid(4);
                        margin: 0;
                    }

                    &--Question {
                        width: 30%;
                        font-weight: 600;
                    }
                    &--Answer {
                        word-break: break-word;
                        flex-grow: 1;
                    }
                }
            `}</style>
            {showTitle && <p className="ReadOnly--Question">{question.text}</p>}
            <p className="ReadOnly--Answer">{answer || DEFAULT_NA_TEXT}</p>
        </div>
    );
};

export default DynamicReadOnlyQuestion;
