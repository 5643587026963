import Button from '@components/Button';
import useLogoutRedirect from '@effects/useLogoutRedirect';

const LogoutButton = () => {
    const logoutRedirect = useLogoutRedirect();

    return (
        <div className="delete-button-container">
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                .delete-button-container {
                    margin-top: grid(10);

                    :global(.Button) {
                        @include text-label;
                        width: 100%;
                        padding: grid(4) 0;
                    }
                }
            `}</style>
            <Button buttonType="delete" buttonSize="xsmall" children={logoutRedirect.text} action={logoutRedirect.action} />
        </div>
    );
};

export default LogoutButton;
