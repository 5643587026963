import { useUserState } from '@common/context/userContext';
import EmptyList from '@components/Listing/EmptyList';
import Loader from '@components/Loader';
import { RoleTypeEnum } from '@utils/enums';
import { hasRoles } from '@utils/question-editable';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { usePagination, useQuery } from 'relay-hooks';

import { ConsignmentListPaginationSpec, Header } from './fragments/ConsignmentListFragment';
import { ConsignmentListFragmentQuery } from './fragments/ConsignmentListFragment';
import ConsignmentRow from './fragments/ConsignmentRow';
import TodayConsignmentHeader from './TodayConsignmentHeader';

interface ContentProps {
    isLoading: boolean;
    consignmentsList: Object[];
    checkScrollBarPresent: (value: number) => void;
    isModal: Boolean;
}

export const TODAY_CONSIGINMENT_PANEL_HEIGHT = 330;
export const MAX_TODAY_COUNT = 10000;

export const EmptyTodayConsignment = () => {
    const [{ user }] = useUserState();
    const hasRole = hasRoles(user);
    if (hasRole) {
        return <EmptyList emptyText={<div className="h3">There are no consignments associated with this profile moving today.</div>}></EmptyList>;
    } else {
        return <EmptyList emptyText={`\n`}></EmptyList>;
    }
};

export const TodayConsignmentContent = ({ isLoading, consignmentsList, checkScrollBarPresent, isModal }: ContentProps) => {
    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const value: number = ref?.current?.scrollHeight || 0;
        !isModal && checkScrollBarPresent(value);
    });

    return (
        <>
            <InfiniteScroll className="tbl-body" pageStart={0} initialLoad={false} loadMore={() => {}} hasMore={isLoading}>
                {isLoading ? (
                    <div>
                        <Loader isLoading={true} error={''} retry={() => null} timedOut={false} pastDelay={false} />
                    </div>
                ) : isEmpty(consignmentsList) ? (
                    <div>
                        <EmptyTodayConsignment />
                    </div>
                ) : (
                    <div ref={ref}>
                        {consignmentsList?.map((c: any) => {
                            return <ConsignmentRow key={c!.id} consignment={c!} showMovementDate={false} />;
                        })}
                    </div>
                )}
            </InfiniteScroll>
        </>
    );
};

const TodayConsignmentComponent: React.FC = () => {
    const [isScrollable, SetScrollable] = useState<Boolean>(false);
    const [{ user }] = useUserState();
    const ref = useRef<HTMLDivElement>(null);
    // Account id might be the same for transporter and authViewer
    const accountSelected = `${user?.accountDetails?.accountLabel}:${user?.accountDetails?.id}`;
    let toDate = new Date();
    toDate.setDate(toDate.getDate() + 1);
    const hasExpiredRole = user.accountDetails.roles.includes(RoleTypeEnum.EXPIRED);
    const { data, isLoading } = useQuery(
        ConsignmentListFragmentQuery,
        {
            type: 'CONSIGNMENT',
            envdAccountId: user?.accountDetails?.id,
            count: MAX_TODAY_COUNT,
            searchText: null,
            species: null,
            status: null,
            fromDate: format(new Date(), 'yyyy-MM-dd'),
            toDate: format(toDate, 'yyyy-MM-dd'),
            sortField: 'UPDATED_AT_DESC',
        },
        { fetchPolicy: 'network-only', fetchKey: accountSelected, skip: hasExpiredRole }
    );
    const consignmentData = usePagination(ConsignmentListPaginationSpec, data as any);

    const consignmentsList = useMemo(() => {
        return consignmentData?.data?.consignments?.edges?.map((c: any) => c && c.node).coalesce();
    }, [consignmentData]);

    const checkScrollBarPresent = (value: number) => {
        const scrollable = value - TODAY_CONSIGINMENT_PANEL_HEIGHT > 0 ? true : false;
        SetScrollable(scrollable);
    };

    return (
        <div className="today-consignment-panel">
            <div>
                <TodayConsignmentHeader count={consignmentsList?.length ?? 0} isLoading={isLoading} consignmentsList={consignmentsList} user={user} isScrollable={isScrollable} />
                <div className="tbl tbl-collapse">
                    <Header user={user} showMovementDate={false} />
                    <div ref={ref} className={`${isLoading || isEmpty(consignmentsList) ? 'no-consignment' : 'has-consignment'}`}>
                        <TodayConsignmentContent isLoading={isLoading} consignmentsList={consignmentsList} checkScrollBarPresent={checkScrollBarPresent} isModal={false} />
                    </div>
                </div>
            </div>
            <style jsx>{`
                @import 'vars';

                .tbl {
                    @media (max-width: $md-max) {
                        background: ${consignmentsList?.length > 0 ? 'transparent' : 'white'};
                    }
                    :global(.row-header) {
                        height: 73px;
                    }
                }
                .no-consignment {
                    height: ${TODAY_CONSIGINMENT_PANEL_HEIGHT}px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                }
                .has-consignment {
                    width: 100%;
                    overflow-y: auto;
                    display: flex;
                    overflow-x: hidden;
                    height: ${TODAY_CONSIGINMENT_PANEL_HEIGHT}px;
                    /* To show the scroll bar*/
                    ::-webkit-scrollbar {
                        -webkit-appearance: none;
                        width: 7px;
                    }
                    ::-webkit-scrollbar-thumb {
                        border-radius: 4px;
                        background-color: $color-grey60;
                    }
                }

                .today-consignment-panel {
                    @media (min-width: $lg-min) {
                        max-width: 948px;
                    }
                }
            `}</style>
        </div>
    );
};

export default TodayConsignmentComponent;
