import { useUserState } from '@common/context/userContext';
import { WarningsContext } from '@common/context/warningsContext';
import FeedbackButton from '@components/FeedbackButton';
import Loader from '@components/Loader';
import Config from '@config';
import useWindowTitle from '@effects/useWindowTitle';
import { IPerson } from '@typings';
import { UNKNOWN_PIC } from '@utils/constants';
import { SectionName } from '@utils/enums';
import { mapPersonToEntity, mapPicToIPerson } from '@utils/person';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { graphql, useMutation, useQuery } from 'relay-hooks';

import { ConsignmentCopyQuery } from './__generated__/ConsignmentCopyQuery.graphql';
import ConsignmentAddStep1 from './components/ConsignmentAddStep1';
import { CopyConsignmentInput } from './mutations/__generated__/copyConsignmentMutation.graphql';
import copyConsignmentAsync, { mutation } from './mutations/copyConsignment';
import { cleanConsignmentPIC } from './mutations/createOrSaveConsignment';

const query = graphql`
    query ConsignmentCopyQuery($consignmentNumber: String!, $envdAccountId: String!) {
        consignment(id: $consignmentNumber, envdAccountId: $envdAccountId) {
            ...ConsignmentEditConsignmentFragment @relay(mask: false)
        }
    }
`;

const ConsignmentCopyPage: React.FC<{ templateConsignment: any }> = ({ templateConsignment }) => {
    const [mutate] = useMutation(mutation);
    const [{ user }] = useUserState();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [, setShowWarning] = useContext(WarningsContext);

    const consignor = mapPicToIPerson(templateConsignment.origin, 'CONSIGNOR');
    const owner = mapPicToIPerson(templateConsignment.owner, 'OWNER');
    const consignee = mapPicToIPerson(templateConsignment.consignee, 'CONSIGNEE');
    const destination = mapPicToIPerson(templateConsignment.destination, 'DESTINATION');

    const [state, setState] = useState<{
        ownedByUser: boolean;
        owner: IPerson | null;
        consignor: IPerson | null;
        sameConsigneeAsDestination: boolean;
        destination: IPerson | null;
        consignee: IPerson | null;
        movementDate: Date | null;
        movementTime: string | null;
    }>({
        consignor,
        ownedByUser: owner?.pic === user?.accountDetails?.pic ?? false,
        owner,
        consignee,
        sameConsigneeAsDestination: (destination?.pic === consignee?.pic && destination?.businessName === consignee?.businessName) ?? false,
        destination,
        movementDate: templateConsignment.movementDate,
        movementTime: templateConsignment.movementTime,
    });

    const history = useHistory();

    if (isLoading) {
        return <Loader isLoading={true} error={false} retry={() => null} timedOut={false} pastDelay={false} />;
    }

    return (
        <>
            <FeedbackButton url={Config.ISC_CUSTOMERFEEDBACK_URL} />

            <ConsignmentAddStep1
                title={<h1 className="m-b-16">Create consignment</h1>}
                subtitle={
                    <h2 className="m-b-32">
                        {templateConsignment.name ? (
                            <>
                                From template <em>{templateConsignment.name}</em>
                            </>
                        ) : (
                            <>
                                Copied from consignment <em>{templateConsignment.number}</em>
                            </>
                        )}
                    </h2>
                }
                state={state}
                setState={setState as any}
                onSubmit={async () => {
                    try {
                        const picTo = state.destination;
                        if (picTo) {
                            setIsLoading(true);
                            const payload: CopyConsignmentInput = {
                                number: templateConsignment.number,
                                movementDate: state.movementDate,
                                movementTime: state.movementTime,
                                origin: state.consignor ? mapPersonToEntity(state.consignor) : undefined,
                                destination: state.destination ? mapPersonToEntity(state.destination) : undefined,
                                owner: state.owner ? mapPersonToEntity(state.owner) : undefined,
                                consignee: state.consignee ? mapPersonToEntity(state.consignee) : undefined,
                                type: 'CONSIGNMENT',
                            };
                            const envdAccountId = user?.accountDetails?.id;
                            const newConsignment = await copyConsignmentAsync(mutate, payload, envdAccountId);

                            history.push(`/consignments/edit/${newConsignment.number}/${SectionName.LIVESTOCK_DESCRIPTION}`);
                        }
                    } catch (error) {
                        console.error('[ConsignmentCopyFragment] error on save', error);
                        setShowWarning(true);
                        setIsLoading(false);
                    }
                }}
            />
        </>
    );
};

const ConsignmentCopy: React.FC = () => {
    const { id } = useParams<any>();
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;
    const { data } = useQuery<ConsignmentCopyQuery>(query, { consignmentNumber: id, envdAccountId }, { fetchPolicy: 'network-only' });

    useWindowTitle('Copy Consignment');

    if (data) {
        const { consignment } = data;
        // ENVDB-896: Making sure that destination pic is not AAAAAAAA
        let cloneConsignment = _.clone(consignment);
        if (consignment?.destination?.pic === UNKNOWN_PIC) {
            cloneConsignment = cleanConsignmentPIC(cloneConsignment, 'destination', '');
        }
        return <ConsignmentCopyPage templateConsignment={cloneConsignment} />;
    } else {
        return <Loader error={''} isLoading pastDelay={false} timedOut={false} retry={() => null} />;
    }
};

export default ConsignmentCopy;
