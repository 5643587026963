import { ActionType } from '@state';

export interface IElevatedViewerSearchFields {
    searchText: string | null;
    species: string;
    status: string;
    sortField: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
}

export const ElevatedViewerSearchActionTypes = {
    setElevatedViewerSearchField: 'setElevatedViewerSearchField',
    resetElevatedViewerSearchField: 'resetElevatedViewerSearchField',
};

export const ElevatedViewerSearchFields: IElevatedViewerSearchFields = {
    searchText: null,
    species: '',
    status: '',
    sortField: 'UPDATED_AT_DESC',
    startDate: undefined,
    endDate: undefined,
};

export const elevatedSearchReducer = (state: IElevatedViewerSearchFields, action: ActionType) => {
    switch (action.type) {
        case ElevatedViewerSearchActionTypes.setElevatedViewerSearchField:
            return action.payload;
        case ElevatedViewerSearchActionTypes.resetElevatedViewerSearchField:
            return state;
    }
};
