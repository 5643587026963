import RadioList from '@components/Form/RadioList';
import useUpdateDynamicValue from '@effects/useUpdateDynamicValue';
import useValidation from '@effects/useValidation';
import { useGlobalState } from '@state';
import { useGetDynamicAnswers } from '@utils/question-getter';
import { getIsRequired } from '@utils/question-validator';
import { isAnyQuestionAnsweredInCurrentSection } from '@utils/section-mapper';
import React, { useEffect } from 'react';

import DynamicDropdownQuestion from './DynamicDropdownQuestion';
import { IDynamicQuestionProps } from './DynamicQuestion';

const DynamicSingleChoiceQuestion: React.FC<IDynamicQuestionProps> = ({ question, index }) => {
    const { isValid, checkIsValid, errorMessage, resetIsValid } = useValidation();
    const [{ triggerValidatorCount, copyErrors, originalAnswers, meta }] = useGlobalState();
    const isReadOnly = question.readOnly;
    const isRequired = getIsRequired(question.validators);

    const update = useUpdateDynamicValue();
    // Prefer pending if it exists, fallback to original if not
    const answers = useGetDynamicAnswers(question, index);
    const value = answers?.firstOrDefault((x) => x.questionId === question.id && question.acceptableAnswers.some((aa) => aa.value === x.value))?.value;

    useEffect(() => {
        if ((value === null || value === undefined) && copyErrors.isCopy && isAnyQuestionAnsweredInCurrentSection(question, meta, originalAnswers)) {
            checkIsValid(question, value);
        }
    });

    useEffect(() => {
        if (triggerValidatorCount > 0) {
            checkIsValid(question, value);
        }
        if (triggerValidatorCount === 0 && !copyErrors.isCopy) {
            resetIsValid();
        }
    }, [triggerValidatorCount, checkIsValid, resetIsValid, question, answers, copyErrors.isCopy, value]);

    // When there are more than 2 acceptable answers, we want to display it as a dropdown instead
    if (question.acceptableAnswers.length > 2) {
        return <DynamicDropdownQuestion question={question} index={index} />;
    }

    return (
        <RadioList
            horizontal
            helperText={''}
            errorText={errorMessage}
            items={question.acceptableAnswers.map((acceptableAnswer) => ({
                id: acceptableAnswer.value,
                title: acceptableAnswer.displayName,
                selected: answers?.some((answer) => answer.questionId === question.id && answer.value === acceptableAnswer.value && answer.index === index),
            }))}
            onChange={(radioItem) => {
                checkIsValid(question, radioItem.id, true);
                update(question, index, radioItem.id);
            }}
            labelText={question.text}
            isValid={isValid}
            isReadOnly={isReadOnly}
            required={isRequired}
        />
    );
};

export default DynamicSingleChoiceQuestion;
