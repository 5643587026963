import { useUserState } from '@common/context/userContext';
import Input, { InputType } from '@components/Form/Input';
import { actionTypes, useGlobalState } from '@state';
import { setValueToField } from '@utils/data-manipulator';
import { readOnlyWhenLockedV4, readOnlyWhenSubmittedV4 } from '@utils/question-editable';
import { useGetStaticAnswer } from '@utils/question-getter';
import { getIsRequired } from '@utils/question-validator';
import classnames from 'classnames';
import React from 'react';

import { IStaticQuestionProps } from './StaticQuestion';

const StaticDateTimeQuestion: React.FC<IStaticQuestionProps> = ({ question, data }) => {
    const [{ user }] = useUserState();
    const [, dispatch] = useGlobalState();
    const answer = useGetStaticAnswer(question, data);
    const isReadOnly = question.field === 'declaration.date' ? readOnlyWhenSubmittedV4(data, user) : readOnlyWhenLockedV4(data, user);
    const isRequired = getIsRequired(question.validators);
    const inputType = answer?.includes('-') ? InputType.Date : InputType.Time;

    return (
        <Input
            labelText={question.text}
            className={classnames('DateField')}
            inputType={inputType}
            value={answer ? (isReadOnly ? (inputType === InputType.Date ? new Date(answer).toMLADateString() : answer.length === 5 ? answer : new Date(answer).toMLATimeString()) : answer) : ''}
            onChange={(e) => {
                dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: setValueToField(question.field, e.target.value) });
            }}
            // One year in the past
            minDate={new Date(new Date().setDate(new Date().getDate() - 365))}
            // max date cannot be more than current date + 28 days
            maxDate={new Date(new Date().setDate(new Date().getDate() + 28))}
            isValid={true}
            isReadOnly={isReadOnly}
            autoFocus={false}
            required={isRequired}
        />
    );
};

export default StaticDateTimeQuestion;
