import { useUserState } from '@common/context/userContext';
import AddUserModal from '@components/AddUserModal';
import Button from '@components/Button';
import Input, { InputType } from '@components/Form/Input';
import useKeyPress, { KeyCode } from '@effects/useKeyPress';
import { GraphqlError } from '@typings';
import { UserRole } from '@utils/enum-transformers';
import MLALogger from '@utils/logger';
import { EMAIL_REGEX } from '@utils/question-validator';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useMutation } from 'relay-hooks';

import { ConsignmentUserMappingInput } from '../mutations/__generated__/createUserMappingMutation.graphql';
import createUserMapping, { CreateUserMappingMutation } from '../mutations/createUserMapping';

export interface AddViewerModalRef {
    show(): void;
    hide(): void;
}

interface AddViewerModalProps {
    consignmentNumber?: string | null;
    viewers: ConsignmentUserMappingInput[];
    updateViewers: any;
}

export const AddViewerModal = forwardRef(({ consignmentNumber, viewers, updateViewers }: AddViewerModalProps, ref: React.Ref<AddViewerModalRef | undefined | null>) => {
    const [show, setShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [viewer, setViewer] = useState('');
    const [isLoading, setIsLoading] = useState<number | null>(null);
    const escKeyPress = useKeyPress(KeyCode.escape);
    const [mutate] = useMutation(CreateUserMappingMutation);
    const [{ user }] = useUserState();
    const envdAccountId = user?.accountDetails?.id;

    useEffect(() => {
        setViewer('');
        setErrorMessage('');
        if (show) {
            setShow(false);
        }
        // eslint-disable-next-line
    }, [escKeyPress]);

    useImperativeHandle(ref, () => ({
        show: () => {
            setShow(true);
            setIsLoading(null);
        },
        hide: () => {
            setShow(false);
            setIsLoading(null);
        },
    }));

    if (!show) {
        return null;
    }

    const isDisabled = () => {
        return viewer === null || viewer === '' || !EMAIL_REGEX.test(viewer.trim());
    };

    const createMapping = () => {
        const tempViewers = viewers?.map((val: ConsignmentUserMappingInput) => {
            return {
                mappingId: val.mappingId,
                email: val.email,
                consignmentNumber: val.consignmentNumber,
                role: val.role,
            };
        });
        const mappings: any = [...tempViewers, { consignmentNumber: consignmentNumber ?? '', email: viewer.trim(), role: UserRole.AUTHVIEWER }];
        const response = createUserMapping(mutate, { consignmentNumber: consignmentNumber ?? '', role: UserRole.AUTHVIEWER, mappings: mappings ?? [] }, envdAccountId);
        response
            .then((mappings) => {
                updateViewers(mappings);
                setViewer('');
                setShow(false);
            })
            .catch((e) => {
                var errors = e as GraphqlError[];
                let message = errors.map((error) => error.message).join('\n');
                MLALogger.Log(['ConsignmentUserMapping'], { message: 'Save was unsuccessful', e }, 'error');
                if (message.indexOf('GraphQL.ExecutionError: ') > -1) {
                    message = message.substring('GraphQL.ExecutionError: '.length);
                }
                setErrorMessage(message);
            });
    };

    return (
        <AddUserModal
            id={'modalId'}
            show={true}
            modalHeader={<h2>Grant an eNVD user view access to this consignment</h2>}
            modalSubText={<p className="small">Viewers will be able to read details about this consignment. They will not be able to edit or delete details.</p>}
            modalFooter={
                <>
                    <Button
                        buttonType="secondary"
                        buttonSize="small"
                        disabled={isLoading !== null}
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button buttonType="primary" className={isDisabled() ? 'disabled' : ''} buttonSize="small" type={'submit'} onClick={() => createMapping()} disabled={isDisabled()}>
                        Add Viewer
                    </Button>
                </>
            }
            onOutClick={() => {
                setViewer('');
                setErrorMessage('');
                setShow(false);
            }}
        >
            <div className="form-block">
                <Input
                    labelText="Email Address"
                    className="class-for-input"
                    inputType={InputType.Text}
                    required={true}
                    value={viewer}
                    onChange={(e) => {
                        setErrorMessage('');
                        const d = e.target.value;
                        setViewer(d);
                    }}
                />
                {errorMessage ? <p className="small error">{errorMessage}</p> : null}
            </div>
        </AddUserModal>
    );
});

export default AddViewerModal;
