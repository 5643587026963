import { useUserState } from '@common/context/userContext';
import RadioList from '@components/Form/RadioList';
import useValidation from '@effects/useValidation';
import { actionTypes, useGlobalState } from '@state';
import { setValueToField } from '@utils/data-manipulator';
import { readOnlyWhenSubmittedV4 } from '@utils/question-editable';
import { useGetStaticAnswer } from '@utils/question-getter';
import { getIsRequired } from '@utils/question-validator';
import React, { useEffect } from 'react';

import StaticDropdownQuestion from './StaticDropdownQuestion';
import { IStaticQuestionProps } from './StaticQuestion';

const StaticSingleChoiceQuestion: React.FC<IStaticQuestionProps> = ({ question, data }) => {
    const { isValid, checkIsValid, evaluated, resetIsValid, errorMessage } = useValidation();
    const answer = useGetStaticAnswer(question, data);
    const [{ triggerValidatorCount, copyErrors }, dispatch] = useGlobalState();
    const [{ user }] = useUserState();
    useEffect(() => {
        if (answer === '' && copyErrors.isCopy) {
            checkIsValid(question, answer);
        }
    });

    useEffect(() => {
        if (!evaluated && answer) {
            checkIsValid(question, answer);
        }
    }, [evaluated, answer, checkIsValid, question]);

    useEffect(() => {
        if (triggerValidatorCount > 0) {
            checkIsValid(question, answer);
        }
        if (triggerValidatorCount === 0) {
            resetIsValid();
        }
    }, [triggerValidatorCount, checkIsValid, resetIsValid, question, answer]);

    if (question.options?.length ?? 0 > 2) {
        return <StaticDropdownQuestion question={question} data={data} />;
    }

    const isReadOnly = readOnlyWhenSubmittedV4(data, user);
    const isRequired = getIsRequired(question.validators);

    return (
        <RadioList
            horizontal
            helperText={''}
            items={
                question.options?.map((option) => ({
                    id: option,
                    title: option,
                    selected: answer ?? '' === option,
                })) ?? []
            }
            onChange={(radioItem) => {
                if (copyErrors.isCopy) {
                    // clear all the copyerrors so copyerrors list can be populated with all the latest errors after change
                    dispatch({ type: actionTypes.copyErrors.clearErrors });
                }
                checkIsValid(question, radioItem.id);
                dispatch({ type: actionTypes.staticPendingAnswers.updateStatic, payload: setValueToField(question.field, radioItem.id) });
            }}
            labelText={question.text}
            isValid={isValid}
            errorText={errorMessage}
            isReadOnly={isReadOnly}
            required={isRequired}
        />
    );
};

export default StaticSingleChoiceQuestion;
