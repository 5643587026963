import Config from '@config';

import { ConsignmentSpecies } from './enums';

// Map Form Name to Known Name
// 'EU.C.0': 'FormEUC', 'European Union Vendor Declaration (Cattle) and Waybill
// 'HS.C.1': 'FormHSC1', 'National Cattle Health Declaration
// 'LPA.C.1`: 'FormLPC1', 'National Vendor Declaration (Cattle) and Waybill'
// 'MSA.C.1: 'FormMSAC1: 'Meat Standards Australia
// 'NFAS.C.0' National Feedlot Accreditation Scheme Delivery Docket - Form B
// "NFASDD.C.1 National Feedlot Accreditation Scheme Delivery Docket
// NFASEU.C.1 National Feedlot Accreditation Scheme Delivery Docket EU GF HQB

// Phase 1 is NVD, MSA, NCHD, NFAS DD, NFAS EU GF HQB, NFAS FORM B
// LPA.C.1, MSA.C.1, HS.C.1, NFASDD.C.1, NFASEU.C.1, NFAS.C.0

// Difference between NVD and EU NVD = Chemical -> HGP Question (Not in EU NVD)
// Difference between NFASDD, NFAS EU = nothing
// Difference between NFASDD, NFASEU vs NFAS Form B = Minimum days on feed vs Days on feedlot. Form B doesnt have Describe ID method

export interface IFormAlias {
    alias: string;
    subtitle: string;
    program: string;
    image: string;
    species: ConsignmentSpecies;
}

export const FA_NVD_CATTLE: IFormAlias = {
    program: 'LPA.C.1',
    alias: 'NVD',
    subtitle: 'National Vendor Declaration (Cattle) and Waybill',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.lpa.jpg`,
    species: ConsignmentSpecies.CATTLE,
};

export const FA_EU_NVD_CATTLE: IFormAlias = {
    program: 'EU.C.1',
    alias: 'EU NVD',
    subtitle: 'European Union Vendor Declaration (Cattle) and Waybill',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.lpa.jpg`,
    species: ConsignmentSpecies.CATTLE,
};

export const FA_MSA_CATTLE: IFormAlias = {
    program: 'MSA.C.1',
    alias: 'MSA',
    subtitle: 'Meat Standards Australia',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.msa.jpg`,
    species: ConsignmentSpecies.CATTLE,
};

export const FA_MSA_CATTLE_V1: IFormAlias = {
    program: 'MSA.C.0',
    alias: 'MSA',
    subtitle: 'Meat Standards Australia',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.msa.jpg`,
    species: ConsignmentSpecies.CATTLE,
};

export const FA_NCHD_CATTLE_V1: IFormAlias = {
    program: 'HS.C.1',
    alias: 'NCHD',
    subtitle: 'Health Declaration',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.hs.png`,
    species: ConsignmentSpecies.CATTLE,
};

export const FA_NCHD_CATTLE: IFormAlias = {
    program: 'HS.C.2',
    alias: 'NCHD',
    subtitle: 'Health Declaration',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.hs.png`,
    species: ConsignmentSpecies.CATTLE,
};

export const FA_NFASDD_CATTLE: IFormAlias = {
    program: 'NFASDD.C.1',
    alias: 'NFAS DD',
    subtitle: 'National Feedlot Accreditation Scheme Delivery Docket',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.nfas.jpg`,
    species: ConsignmentSpecies.CATTLE,
};

export const FA_NFASEU_CATTLE: IFormAlias = {
    program: 'NFASEU.C.1',
    alias: 'NFAS EU GF HQB',
    subtitle: 'National Feedlot Accreditation Scheme Delivery Docket EU GF HQB',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.nfas.jpg`,
    species: ConsignmentSpecies.CATTLE,
};

export const FA_NFASFB_CATTLE: IFormAlias = {
    program: 'NFAS.C.0',
    alias: 'NFAS Form B',
    subtitle: 'National Feedlot Accreditation Scheme Delivery Docket - Form B',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.nfas.jpg`,
    species: ConsignmentSpecies.CATTLE,
};

export const FA_NVD_BC: IFormAlias = {
    program: 'LPA.BC.1',
    alias: 'NVD',
    subtitle: 'National Vendor Declaration (Bobby Calves) and Waybill',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.lpa.jpg`,
    species: ConsignmentSpecies.BOBBY_CALVES,
};

export const FA_NVD_SHEEP: IFormAlias = {
    program: 'LPA.SL.1',
    alias: 'NVD',
    subtitle: 'National Vendor Declaration (Sheep) and Waybill',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.lpa.jpg`,
    species: ConsignmentSpecies.SHEEP_LAMB,
};

export const FA_NSHD_SHEEP: IFormAlias = {
    program: 'HS.SL.1',
    alias: 'Health Declaration',
    subtitle: 'National Sheep Health Declaration',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.lpa.jpg`,
    species: ConsignmentSpecies.SHEEP_LAMB,
};

export const FA_NVD_GOAT: IFormAlias = {
    program: 'LPA.G.2',
    alias: 'NVD',
    subtitle: 'National Vendor Declaration (Goat) and Waybill',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.lpa.jpg`,
    species: ConsignmentSpecies.GOAT,
};

export const FA_NVD_HRG_GOAT: IFormAlias = {
    program: 'LPAHR.G.0',
    alias: 'HRG NVD',
    subtitle: 'National Vendor Declaration (Harvested Rangeland Goat) and Waybill',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.lpa.jpg`,
    species: ConsignmentSpecies.GOAT,
};

export const FA_NGHD_GOAT: IFormAlias = {
    program: 'HS.G.0',
    alias: 'Health Declaration',
    subtitle: 'National Goat Health Declaration',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.lpa.jpg`,
    species: ConsignmentSpecies.GOAT,
};

export const ALL_CATTLE_NVD_FORMS: IFormAlias[] = [FA_NVD_CATTLE, FA_EU_NVD_CATTLE];
export const ALL_NFAS_FORMS: IFormAlias[] = [FA_NFASDD_CATTLE, FA_NFASFB_CATTLE, FA_NFASEU_CATTLE];
export const ALL_NHD_FORMS: IFormAlias[] = [FA_NCHD_CATTLE, FA_NSHD_SHEEP, FA_NGHD_GOAT];

export const ALL_CATTLE_FORMS: IFormAlias[] = [...ALL_CATTLE_NVD_FORMS, ...ALL_NFAS_FORMS, FA_NCHD_CATTLE, FA_MSA_CATTLE];
export const ALL_BC_FORMS: IFormAlias[] = [FA_NVD_BC];
export const ALL_GOAT_FORMS: IFormAlias[] = [FA_NVD_GOAT, FA_NGHD_GOAT, FA_NVD_HRG_GOAT];

export const ALL_NVD_FORMS: IFormAlias[] = [...ALL_CATTLE_NVD_FORMS, FA_NVD_BC, FA_NVD_SHEEP, FA_NVD_GOAT, FA_NVD_HRG_GOAT];
export const ALL_FORMS: IFormAlias[] = [...ALL_NVD_FORMS, ...ALL_NFAS_FORMS, FA_MSA_CATTLE, ...ALL_NHD_FORMS];

export const ALL_OPTIONAL_FORMS: IFormAlias[] = [...ALL_NFAS_FORMS, ...ALL_NHD_FORMS, FA_MSA_CATTLE];

export const FA_NVD_CATTLE_V1: IFormAlias = {
    program: 'LPA.C.0',
    alias: 'NVD',
    subtitle: 'National Vendor Declaration (Cattle) and Waybill',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.lpa.jpg`,
    species: ConsignmentSpecies.CATTLE,
};

export const FA_EU_NVD_CATTLE_V1: IFormAlias = {
    program: 'EU.C.0',
    alias: 'EU NVD',
    subtitle: 'European Union Vendor Declaration (Cattle) and Waybill',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.lpa.jpg`,
    species: ConsignmentSpecies.CATTLE,
};

export const FA_NVD_BC_V1: IFormAlias = {
    program: 'LPA.BC.0',
    alias: 'NVD',
    subtitle: 'National Vendor Declaration (Bobby Calves) and Waybill',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.lpa.jpg`,
    species: ConsignmentSpecies.BOBBY_CALVES,
};

export const FA_NVD_SHEEP_V1: IFormAlias = {
    program: 'LPA.SL.0',
    alias: 'NVD',
    subtitle: 'National Vendor Declaration (Sheep) and Waybill',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.lpa.jpg`,
    species: ConsignmentSpecies.SHEEP_LAMB,
};

export const FA_NVD_GOAT_V1: IFormAlias = {
    program: 'G0517',
    alias: 'NVD',
    subtitle: 'National Vendor Declaration (Goat) and Waybill',
    image: `${Config.BASE_GRAPHQL_SERVER_URL}/declaration/accredited-programs-logos/logo.lpa.jpg`,
    species: ConsignmentSpecies.GOAT,
};

export const ALL_DEPRECATED_FORMS: IFormAlias[] = [FA_NVD_CATTLE_V1, FA_EU_NVD_CATTLE_V1, FA_NVD_BC_V1, FA_NVD_SHEEP_V1, FA_NVD_GOAT_V1, FA_MSA_CATTLE_V1, FA_NCHD_CATTLE_V1];
export const ALL_DEPRECATED_NVD_FORMS: IFormAlias[] = [FA_NVD_CATTLE_V1, FA_EU_NVD_CATTLE_V1, FA_NVD_BC_V1, FA_NVD_SHEEP_V1, FA_NVD_GOAT_V1];
export const ALL_FORMS_PLUS_DEPRECATED: IFormAlias[] = [...ALL_FORMS, ...ALL_DEPRECATED_FORMS];

export const mapFAToForm = (fa: IFormAlias) => mapProgramToForm(fa.program);
export const mapProgramToForm = (program: string) => program.replace(/\./g, '');
export const getFormAlias = (s: string) => [...ALL_FORMS, ...ALL_DEPRECATED_FORMS].filter((x) => x.program === s || x.program.replace(/\./g, '') === s).first();

export const CONSIGNMENT_ROOT_NAME = 'consignment';
