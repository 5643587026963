import * as React from 'react';
const SvgReview = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={21} height={14} fill="none" {...props} viewBox="0 0 21 14">
        <path
            fill="#848A94"
            d="M10.5 2.25a8.55 8.55 0 0 1 7.718 4.813 8.55 8.55 0 0 1-7.718 4.812 8.55 8.55 0 0 1-7.717-4.812A8.55 8.55 0 0 1 10.5 2.25m0-1.75C6.125.5 2.389 3.221.875 7.063c1.514 3.84 5.25 6.562 9.625 6.562s8.111-2.721 9.625-6.562C18.611 3.22 14.875.5 10.5.5m0 4.375a2.188 2.188 0 1 1-.002 4.377 2.188 2.188 0 0 1 .002-4.377m0-1.75a3.943 3.943 0 0 0-3.937 3.938A3.943 3.943 0 0 0 10.5 11a3.943 3.943 0 0 0 3.938-3.937A3.943 3.943 0 0 0 10.5 3.125"
        />
    </svg>
);
export default SvgReview;
