import Button from '@common/components/Button';
import { isValidString } from '@common/utils/string-extensions';
import Input, { InputType } from '@components/Form/Input';
import validatePIC from '@services/pic';
import { IPerson, IUserSelectField } from '@typings';
import React, { useState } from 'react';

import Dropdown from '../Form/Dropdown';

interface EditProps {
    person: IPerson;
    onChange: (p: IPerson) => void;
    onCancel: () => void;

    additionalFields?: IUserSelectField[];
    displayNonPICField?: boolean;
}

const UserEdit: React.FunctionComponent<EditProps> = ({ person, onChange, onCancel, additionalFields, displayNonPICField }) => {
    const [localPerson, setLocalPerson] = useState<IPerson>(person);
    const states = ['NSW', 'NT', 'WA', 'QLD', 'TAS', 'SA', 'VIC', 'ACT'];
    // TODO: Update validations from a central validation repository e.g. validate(type, input)

    const newManualEntry =
        isValidString(localPerson.manual) &&
        !isValidString(localPerson.businessName) &&
        !isValidString(localPerson.firstName) &&
        !isValidString(localPerson.lastName) &&
        !isValidString(localPerson.locationState) &&
        !isValidString(localPerson.locationTown) &&
        !isValidString(localPerson.locationAddress) &&
        !isValidString(localPerson.locationPostcode) &&
        !isValidString(localPerson.pic);

    // For name: EITHER Business OR (First + Last) required
    const validName = isValidString(localPerson.businessName);

    // For PIC: use validator
    const validPIC = localPerson.pic.trim().length > 0 ? validatePIC(localPerson.pic) : displayNonPICField ? true : false;

    // && validState && validTown
    const valid = displayNonPICField ? validName && validPIC : validPIC;

    return (
        <>
            {displayNonPICField && (
                <>
                    <Input
                        labelText={'Name'}
                        value={localPerson.businessName || ''}
                        onChange={(e) => setLocalPerson({ ...localPerson, businessName: e.target.value })}
                        maxLength={300}
                        errorText={'Please enter a name (Business or Individual)'}
                        isValid={newManualEntry ? undefined : validName}
                        data-cy="name-field"
                    />

                    <Input
                        labelText={'Address'}
                        value={localPerson.locationAddress || ''}
                        onChange={(e) => setLocalPerson({ ...localPerson, locationAddress: e.target.value })}
                        maxLength={300}
                        data-cy="address-field"
                    />
                    <Input labelText={'Town'} value={localPerson.locationTown || ''} onChange={(e) => setLocalPerson({ ...localPerson, locationTown: e.target.value })} data-cy="town-field" />
                    <Dropdown
                        labelText={'State'}
                        value={localPerson.locationState || ''}
                        options={states.map((x) => ({ id: x, title: x }))}
                        onChange={(item) => {
                            setLocalPerson({ ...localPerson, locationState: item.id });
                        }}
                    />
                    <Input
                        labelText={'Postcode'}
                        value={localPerson.locationPostcode || ''}
                        onChange={(e) => setLocalPerson({ ...localPerson, locationPostcode: e.target.value ? `${e.target.value}` : undefined })}
                        inputType={InputType.Number}
                        data-cy="postcode-field"
                    />
                </>
            )}
            <Input
                labelText={displayNonPICField ? 'PIC' : 'Destination PIC'}
                value={localPerson.pic || ''}
                required={displayNonPICField ? false : true}
                // ENVDB-535 fix - if personType is 'ORIGIN' or 'CONSIGNOR' then pic field should be readonly
                isReadOnly={person.type === 'ORIGIN' || person.type === 'CONSIGNOR'}
                onChange={(e) => setLocalPerson({ ...localPerson, pic: e.target.value })}
                minLength={8}
                maxLength={8}
                errorText={'Please enter a valid PIC'}
                isValid={localPerson.pic ? validPIC : undefined}
                data-cy="pic-field"
            />
            {additionalFields?.map((field) => {
                const validationProps = field.type === 'phone' ? { regex: /^(?:\+?61|0)[2-478](?:[ -]?[0-9]){8}$/ } : undefined;
                return (
                    <Input
                        key={field.key}
                        labelText={field.label}
                        value={localPerson[field.key] ?? ''}
                        onChange={(e) => setLocalPerson({ ...localPerson, locationTown: e.target.value })}
                        {...validationProps}
                    />
                );
            })}

            <Button buttonType="delete" buttonSize="small" children="Save" action={() => valid && onChange(localPerson)} disabled={!valid} data-cy="save-button" />
            <Button buttonType="tertiary" buttonSize="small" children="Cancel" action={onCancel} data-cy="cancel-button" />
        </>
    );
};

export default UserEdit;
