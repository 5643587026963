/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ConsignmentStatus = "DELETED" | "DRAFT" | "LOCKED" | "SUBMITTED" | "%future added value";
export type NavigationBarQueryVariables = {
    number: string;
    envdAccountId: string;
};
export type NavigationBarQueryResponse = {
    readonly consignment: {
        readonly id: string;
        readonly number: string | null;
        readonly updatedAt: unknown | null;
        readonly status: ConsignmentStatus;
    } | null;
};
export type NavigationBarQuery = {
    readonly response: NavigationBarQueryResponse;
    readonly variables: NavigationBarQueryVariables;
};



/*
query NavigationBarQuery(
  $number: String!
  $envdAccountId: String!
) {
  consignment(id: $number, envdAccountId: $envdAccountId) {
    id
    number
    updatedAt
    status
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "envdAccountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "number"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "envdAccountId",
        "variableName": "envdAccountId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "number"
      }
    ],
    "concreteType": "ConsignmentType",
    "kind": "LinkedField",
    "name": "consignment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "number",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationBarQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NavigationBarQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a65a96abef665916db5d0bd6b02ce84c",
    "id": null,
    "metadata": {},
    "name": "NavigationBarQuery",
    "operationKind": "query",
    "text": "query NavigationBarQuery(\n  $number: String!\n  $envdAccountId: String!\n) {\n  consignment(id: $number, envdAccountId: $envdAccountId) {\n    id\n    number\n    updatedAt\n    status\n  }\n}\n"
  }
};
})();
(node as any).hash = '50ab252b83326c451351d31a5dd156e5';
export default node;
