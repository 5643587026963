import { ActionType } from '@state';
import { IAnswer } from '@typings';
import { sortByQuestionIdAndIndex } from '@utils/question-sort';
import { isEmpty } from 'lodash';

export const OriginalAnswersActionTypes = {
    setOriginal: 'setOriginal',
    removeBatchOriginal: 'removeBatchOriginal',
    resetOriginal: 'resetOriginal',
    removeOriginal: 'removeOriginal',
    removeAndUpdateOriginal: 'removeAndUpdateOriginal',
    updateOriginal: 'updateOriginal',
};

export const OriginalAnswersInitialState: IAnswer[] = [];

const reducer = (state: IAnswer[], action: ActionType) => {
    switch (action.type) {
        case OriginalAnswersActionTypes.setOriginal:
            return action.payload;
        case OriginalAnswersActionTypes.removeBatchOriginal:
            return state
                .map((x: any) => (action.payload.some((payload: any) => x.questionId === payload.questionId && x.index === payload.index) ? null : x))
                .sort(sortByQuestionIdAndIndex)
                .coalesce();
        case OriginalAnswersActionTypes.resetOriginal:
            return OriginalAnswersInitialState;
        case OriginalAnswersActionTypes.removeOriginal:
            return state
                .map((x: any) => (x.questionId === action.payload.questionId && x.index === action.payload.index ? null : x))
                .sort(sortByQuestionIdAndIndex)
                .coalesce();
        case OriginalAnswersActionTypes.updateOriginal:
            const deletedAnswerState = state
                .map((x: any) => (action.payload.deletedAnswer.some((payload: IAnswer) => x.questionId === payload.questionId && x.index === payload.index) ? null : x))
                .sort(sortByQuestionIdAndIndex)
                .coalesce();

            const updatedAnswerState = deletedAnswerState.map((x) => {
                const replacedValue = action?.payload?.updatedAnswer?.find((y: IAnswer) => x.questionId === y.questionId && x.value === y.value) || {};
                return isEmpty(replacedValue) ? x : replacedValue;
            });

            return updatedAnswerState;
        default:
            return state;
    }
};

export default reducer;
