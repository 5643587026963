import Button from '@common/components/Button';
import { SelectableListItem } from '@components/Form/SelectableList';
import SelectableBlockView from '@components/SelectableBlockView';
import { ConsignmentSpecies } from '@utils/enums';
import { FA_NFASEU_CATTLE, FA_NGHD_GOAT, FA_NVD_CATTLE, FA_NVD_HRG_GOAT, IFormAlias } from '@utils/form-alias';
import React, { useCallback, useState } from 'react';

interface LocalState {
    forms: SelectableListItem[];
    species: ConsignmentSpecies;
}
interface Props {
    state: LocalState;
    setState: React.Dispatch<React.SetStateAction<LocalState>>;
    onPrevious(): void;
    onSubmit(selectedForms: SelectableListItem[]): void;
}

const requiredOptionalExclusionMap: Record<IFormAlias['program'], IFormAlias['program'][]> = {
    [FA_NVD_CATTLE.program]: [FA_NFASEU_CATTLE.program],
    [FA_NVD_HRG_GOAT.program]: [FA_NGHD_GOAT.program],
};

const ConsignmentAddStep3: React.FunctionComponent<Props> = ({ state, setState, onPrevious, onSubmit }: Props) => {
    const [submitCount, setSubmitCount] = useState<number>(0);

    const optionalItems = state.forms.filter((x) => !x.title!.includes('NVD'));

    // EPLAT-335 Sync optional forms with selected required form
    const filterOptionalBySelectedRequired = useCallback(
        (selected: SelectableListItem, prevOptionals: SelectableListItem[]) => {
            const excludedOptionals = requiredOptionalExclusionMap[selected.id];

            if (!excludedOptionals) return optionalItems;

            // filter excluded forms and merge with original array to preserve selected state
            return optionalItems.filter((form) => !excludedOptionals.includes(form.id)).map((form) => prevOptionals.find((prevOptional) => prevOptional.id === form.id) ?? form);
        },
        [optionalItems]
    );

    return (
        <>
            <div className="title-section">
                <h1>Select Forms</h1>
            </div>

            <div>
                <div className="wizard-step" data-cy="select-form">
                    <SelectableBlockView
                        submitCount={submitCount}
                        initialRequiredItems={state.forms.filter((x) => x.title!.includes('NVD'))}
                        requiredItemsProps={{
                            labelText: 'Select the NVD form you need',
                            errorText: 'You must select one NVD',
                        }}
                        initialOptionalItems={optionalItems}
                        optionalItemsProps={{
                            labelText: 'Select any additional forms if you need them',
                            errorText: '',
                        }}
                        onSubmit={(updatedForms) =>
                            setState((s) => {
                                const updated = { ...s, forms: updatedForms };
                                onSubmit(updated.forms);
                                return updated;
                            })
                        }
                        filterOptionalBySelectedRequired={filterOptionalBySelectedRequired}
                    />

                    <Button buttonType="secondary" className="m-r-12" children="Previous step" action={onPrevious} />
                    <Button
                        buttonType="primary"
                        children="Next step"
                        action={async () => {
                            setSubmitCount((s) => s + 1);
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default ConsignmentAddStep3;
