import { BgLpa } from '@assets/images';
import Button from '@common/components/Button';
import { Primitive } from '@typings';
import classnames from 'classnames';
import React from 'react';

import Spinner from './Spinner';

interface LoadingProps {
    error: Primitive | Error;
    timedOut: boolean;
    isLoading: boolean;
    retry: () => void;
    pastDelay: boolean;
}

const Loader: React.FunctionComponent<LoadingProps> = (props) => {
    const loading = !props.error && !props.timedOut;
    return (
        <div className={classnames({ error: props.error, warn: props.timedOut, loading })}>
            <style jsx>{`
                @import 'vars';
                @import 'utils';

                .loading {
                    padding: grid(18) 0;
                    margin: 0 auto;

                    &--content {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        h2 {
                            margin: grid(8) 0;
                        }
                        :global(svg) {
                            width: auto;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            z-index: -1;
                            color: $color-grey79;
                        }
                    }
                }
            `}</style>
            {loading && (
                <div className="loading--content">
                    <h1>Thanks for your patience.</h1>
                    <h1>We're loading your page.</h1>
                    <h2>Please wait...</h2>
                    <Spinner error={Boolean(props.error)} warn={props.timedOut} />
                    <BgLpa />
                </div>
            )}
            {props.error && (
                <>
                    Error! <Button buttonType="primary" buttonSize="small" children="Retry" className="Button--Delete" action={props.retry} />
                </>
            )}
            {props.timedOut && (
                <>
                    Taking a long time... <Button buttonType="primary" buttonSize="small" children="Retry" className="Button--Delete" action={props.retry} />
                </>
            )}
        </div>
    );
};
export default Loader;
