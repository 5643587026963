import { actionTypes as at, useUserState } from '@common/context/userContext';
import Button from '@components/Button';
import Checkbox from '@components/Form/Checkbox';
import Input, { InputType } from '@components/Form/Input';
import Modal from '@components/Modal';
import createOrSaveUser, { CreateOrSaveUserMutation } from '@containers/Consignments/mutations/createOrSaveUserMutation';
import useUpdateDynamicValue from '@effects/useUpdateDynamicValue';
import useValidation from '@effects/useValidation';
import { useGlobalState } from '@state';
import { accounts, IAnswer } from '@typings';
import { getCarrierRegNum } from '@utils/account';
import { ACCEPTED_FILE_EXTENSIONS_IMAGES, ACCEPTED_FILESIZE, ACCEPTED_IMAGE_EXTENSION_LABEL, ACCEPTED_MIMETYPES_IMAGES, BLANK_IMAGE, FILE_REGEX } from '@utils/constants';
import { SectionName } from '@utils/enums';
import { fetchFileUrl, isBase64Image, upload } from '@utils/file-ops';
import { useGetDynamicAnswers } from '@utils/question-getter';
import classnames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'relay-hooks';

import Signature from '../Signature';
import { IDynamicQuestionProps } from './DynamicQuestion';

const DynamicReadOnlyImageQuestion: React.FC<Pick<IDynamicQuestionProps, 'question' | 'index'>> = ({ question, index }) => {
    const answers = useGetDynamicAnswers(question, index);

    const [value, setValue] = useState<string>(answers?.firstOrDefault((answer) => answer.questionId === question.id && answer.index === index)?.value ?? '');
    const alt = question.text;

    const formatValue = useCallback(async (v: string) => {
        let retValue = v;

        // Fix up base64 updates if required
        const isB64 = isBase64Image(v);
        const isUrl = v?.startsWith('http');

        if (isB64) {
            const strippedValue = v?.replace(FILE_REGEX, '') ?? BLANK_IMAGE;
            if (strippedValue !== v) {
                // Strip contents
                retValue = strippedValue;
            }
        } else if (v?.startsWith('data:image/')) {
            // Do nothing
        } else if (!isUrl) {
            const urlValue = (await fetchFileUrl(v)) ?? BLANK_IMAGE;
            if (urlValue !== v) {
                // This is a file upload with the key as a value
                retValue = urlValue;
            }
        }

        return retValue;
    }, []);

    useEffect(() => {
        const action = async () => {
            const newValue = answers?.firstOrDefault((answer) => answer.questionId === question.id && answer.index === index)?.value ?? '';
            setValue(await formatValue(newValue));
        };
        action();
    }, [formatValue, answers, question.id, index]);

    if (!value) {
        return null;
    }

    // Draw using img tag, give it a white bg since image can be transparent (signature pad images)
    return (
        <div className="ReadOnly">
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';
                .ReadOnly {
                    display: flex;
                    flex-wrap: wrap;

                    :global(& + .form-row) {
                        margin-top: grid(6);
                    }

                    :global(p) {
                        font-size: 14px;
                    }

                    &--Question,
                    &--Answer {
                        padding: grid(2) grid(4);
                        margin: 0;
                    }

                    &--Question {
                        width: 30%;
                        font-weight: 600;
                    }
                    &--Answer {
                        word-break: break-word;
                        flex-grow: 1;
                    }
                }
                img {
                    background-color: $color-white;

                    max-width: grid(50);
                }
            `}</style>
            <p className="ReadOnly--Question">{alt}</p>
            <p className="ReadOnly--Answer">
                <img src={value} alt={alt} />
            </p>
        </div>
    );
};

const DynamicImageQuestion: React.FC<IDynamicQuestionProps> = ({ question, index }) => {
    const [{ originalAnswers, dynamicPendingAnswers, triggerValidatorCount }] = useGlobalState();
    const [{ user }, dispatch] = useUserState();
    const [mutate] = useMutation(CreateOrSaveUserMutation);
    const { isValid, checkIsValid, errorMessage, evaluated, resetIsValid } = useValidation();
    const [checkboxSaveSignature, setCheckboxSaveSignature] = useState<boolean>(
        user?.claims.signature && user?.claims.signature !== BLANK_IMAGE ? false : question.type === 'SIGNATURE' ? true : false
    );
    const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false);

    const update = useUpdateDynamicValue();

    const isReadOnly = question.readOnly;

    // Check to see if its a signature or a url
    const [answers, setAnswers] = useState<IAnswer[]>(
        dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === question.id && dpa.index === index) ? dynamicPendingAnswers.answers : originalAnswers
    );

    const [errorMsg, setErrorMsg] = useState<string>();
    const [value, setValue] = useState<string>(answers?.firstOrDefault((answer) => answer.questionId === question.id && answer.index === index)?.value ?? '');
    const [originalValue, setOriginalValue] = useState<string>(value);

    const formatValue = useCallback(async (v: string) => {
        let retValue = v;

        // Fix up base64 updates if required
        const isB64 = isBase64Image(v);
        const isUrl = v?.startsWith('http');

        if (isB64) {
            const strippedValue = v?.replace(FILE_REGEX, '') ?? BLANK_IMAGE;
            if (strippedValue !== v) {
                // Strip contents
                retValue = strippedValue;
            }
        } else if (v?.startsWith('data:image/')) {
            // Do nothing
        } else if (!isUrl) {
            const urlValue = (await fetchFileUrl(v)) ?? BLANK_IMAGE;
            if (urlValue !== v) {
                // This is a file upload with the key as a value
                retValue = urlValue;
            }
        }

        return retValue;
    }, []);

    useEffect(() => {
        setAnswers(dynamicPendingAnswers.answers.some((dpa) => dpa.questionId === question.id && dpa.index === index) ? dynamicPendingAnswers.answers : originalAnswers);
    }, [dynamicPendingAnswers.answers, index, question.id, originalAnswers]);

    useEffect(() => {
        const action = async () => {
            if (value) {
                const newValue = await formatValue(value);
                if (newValue !== value) {
                    setValue(newValue);
                }
            }
        };
        action();
    }, [value, formatValue]);

    useEffect(() => {
        if (!evaluated && value) {
            checkIsValid(question, value);
        }
    }, [evaluated, checkIsValid, question, value]);

    useEffect(() => {
        if (triggerValidatorCount > 0) {
            checkIsValid(question, value);
        }
        if (triggerValidatorCount === 0) {
            resetIsValid();
        }
    }, [triggerValidatorCount, checkIsValid, resetIsValid, question, value]);

    const modalFooter = useMemo(() => {
        return (
            <>
                <Button
                    buttonType="primary"
                    buttonSize="small"
                    type="submit"
                    onClick={async () => {
                        // We've already got the latest signature changes in local state so we just save them out as is
                        setShowSignatureModal(false);
                        setOriginalValue(value);

                        if (checkboxSaveSignature) {
                            const isTransporter = user?.claims.accounts?.some((acc: accounts) => acc.accountType === SectionName.TRANSPORTER.toUpperCase());
                            const userData = {
                                defaultAccount: user?.claims.defaultAccount || user?.claims?.accounts[0]?.envdAccountId,
                                firstName: user?.claims.firstName,
                                lastName: user?.claims.lastName,
                                mobilePhone: user?.claims.mobilePhone,
                                isTransporter: isTransporter,
                                rego: isTransporter ? getCarrierRegNum(user) : '',
                                receiveEmails: user?.claims.receiveEmails,
                            };
                            let response;
                            if (value && value !== BLANK_IMAGE) {
                                setCheckboxSaveSignature(false);
                                response = await createOrSaveUser(mutate, { ...userData, signature: value });
                            } else {
                                response = await createOrSaveUser(mutate, { ...userData, signature: 'null' });
                            }
                            dispatch({
                                type: at.user.setUserClaims,
                                value: response,
                            });
                        }
                    }}
                >
                    Add
                </Button>
                <Button
                    buttonType="secondary"
                    buttonSize="small"
                    type="submit"
                    onClick={() => {
                        update(question, index, originalValue ?? '');
                        setValue(originalValue ?? '');
                        setShowSignatureModal(false);
                    }}
                >
                    Cancel
                </Button>
            </>
        );
    }, [checkboxSaveSignature, dispatch, index, mutate, originalValue, question, update, user, value]);

    const modalChildren = useMemo(() => {
        return (
            <Signature
                title={question.text}
                value={value}
                isReadOnly={isReadOnly}
                onUpdate={(v?: string) => {
                    update(question, index, v ?? '');
                    setValue(v ?? '');
                }}
                hideClear={value === ''}
            />
        );
    }, [index, isReadOnly, question, update, value]);

    const modalOnOutClick = useCallback(() => {
        // Reset the signature data that the edit canvas will use, removing the changes made within it but not saved
        setShowSignatureModal(false);
    }, []);

    const onSavedSignature = () => {
        setCheckboxSaveSignature((value) => !value);
    };

    if (isReadOnly) {
        return <DynamicReadOnlyImageQuestion question={question} index={index} />;
    }

    // We want to ditch signature pad, so display a file upload request
    return (
        <>
            <style jsx>{`
                @import 'vars';
                @import 'utils';
                @import 'mixins';

                img {
                    max-width: grid(130);
                    width: 100%;
                    margin-top: grid(2);
                }

                .questionText {
                    @include text-label;
                }

                .SignatureOptionsContainer {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    :global(.BtnDrawSignature) {
                        margin-top: grid(2);
                    }
                }

                .DynamicImageQuestion--ShowBackground {
                    background-color: $color-fade;
                    border: 1px dashed $color-border;
                    border-radius: $border-radius;

                    @media (prefers-color-scheme: dark) {
                        background: invert($color-fade, 50%);
                    }
                }
            `}</style>
            {question.type === 'IMAGE' ? (
                <>
                    <Input
                        inputType={InputType.File}
                        labelText={question.text}
                        helperText={question.tip}
                        onChange={async (e) => {
                            setErrorMsg('');
                            const response = await upload(e.target.files, ACCEPTED_MIMETYPES_IMAGES, ACCEPTED_FILESIZE);
                            if (!response) {
                                return;
                            }

                            const { url, error } = response;

                            if (error) {
                                setErrorMsg(error);
                            }

                            if (url) {
                                // value = await getBase64(file);
                                update(question, index, url);
                                setValue(url);
                                checkIsValid(question, url);
                            }
                        }}
                        accept={ACCEPTED_FILE_EXTENSIONS_IMAGES}
                        isValid={isValid}
                        errorText={errorMessage}
                    />
                    {errorMsg ? <p className="text-error">{errorMsg}</p> : null}
                    <p className="small">
                        Allowed formats are {ACCEPTED_IMAGE_EXTENSION_LABEL} up to {ACCEPTED_FILESIZE}MB.
                    </p>
                </>
            ) : (
                <label className="questionText">{question.text}</label>
            )}

            {!isReadOnly && !value.startsWith('http') && (
                <div className="SignatureOptionsContainer">
                    <Button className={'BtnDrawSignature'} buttonType="link" buttonSize="small" onClick={() => setShowSignatureModal(true)}>
                        {Boolean(value) ? (question.type === 'IMAGE' ? 'Edit image' : 'Edit signature') : question.type === 'IMAGE' ? 'Draw image' : 'Draw signature'}
                    </Button>
                    {value !== BLANK_IMAGE && value !== '' && (
                        <Button
                            className={'BtnClearSignature'}
                            buttonType="link"
                            buttonSize="small"
                            onClick={() => {
                                const blankImageValue = '';
                                setValue(blankImageValue);
                                update(question, index, blankImageValue);
                            }}
                        >
                            {'Clear'}
                        </Button>
                    )}
                </div>
            )}
            {value && <img src={value} alt={question.text} className={classnames({ 'DynamicImageQuestion--ShowBackground': !value.startsWith('http') })} />}
            <Modal
                zIndex={99}
                id={'Signature-Modal'}
                savedSignature={question.type === 'SIGNATURE' ? user?.claims.signature : ''}
                modalHeader={question.type === 'SIGNATURE' ? 'Draw your Signature' : 'Draw your image'}
                modalFooter={modalFooter}
                show={showSignatureModal}
                onOutClick={modalOnOutClick}
                onUseMySignatureClick={() => {
                    update(question, index, user?.claims.signature);
                    setValue(user?.claims.signature);
                }}
                checkbox={question.type === 'SIGNATURE' && <Checkbox optionText={'Save the signature for future consignments'} checked={checkboxSaveSignature} onChange={onSavedSignature} />}
            >
                {modalChildren}
            </Modal>
        </>
    );
};

export default DynamicImageQuestion;
